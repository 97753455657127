import { fetchTasksStats } from '@/service/tasks';
import { useWalletInfoStore } from '@/stores/useWalletInfoStore';
import { useRequest } from 'ahooks';

export function useFetchTasksStats() {
  const { walletAddress } = useWalletInfoStore();

  const hook = useRequest(() => fetchTasksStats(), {
    ready: !!walletAddress,
  });

  return hook;
}
