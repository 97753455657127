import { fetchCoinList } from '@/service/coin';
import { useInfiniteScroll } from 'ahooks';
import { Data } from 'ahooks/lib/useInfiniteScroll/types';

export function useFetchCoinList({
  createdTimestampEnd,
  createdTimestampStart,
  nsfw,
  search,
  filter,
  sort,
  size,
  enabled,
}: {
  createdTimestampEnd?: number;
  createdTimestampStart?: number;
  nsfw?: boolean;
  search?: string;
  filter?: string;
  sort?: string;
  size: number;
  enabled: boolean;
}) {
  const hook = useInfiniteScroll(
    async (param?: Data & { page: number }) => {
      const data = await fetchCoinList({
        current: (param?.page || 0) + 1,
        size,
        createdTimestampEnd,
        createdTimestampStart,
        nsfw,
        search,
        filter,
        sort,
      });

      return {
        list: data.data.docs,
        total: data.data.totalDocs,
        page: data.data.page,
        hasNextPage: data.data.hasNextPage,
        totalPages: data.data.totalPages,
      };
    },
    {
      reloadDeps: [
        createdTimestampEnd,
        createdTimestampStart,
        nsfw,
        search,
        filter,
        sort,
        size,
        search,
        enabled,
      ],
      manual: !enabled,
    },
  );

  return hook;
}
