import { fetchCoinDetail } from '@/service/coin';
import { getCoinDetail } from '@/service/sol-coin';
import { CoinDetail } from '@/types/Coin';
import { useRequest } from 'ahooks';

export function useFetchCoinDetail({
  isTon,
  address,
  onSuccess,
}: {
  isTon: boolean;
  address?: string;
  onSuccess: (v: CoinDetail) => void;
}) {
  const hook = useRequest(
    async () => {
      if (isTon) {
        const res = await fetchCoinDetail(address!);
        return { ...res.data, isG6: true };
      } else {
        return await getCoinDetail(address!);
      }
    },
    {
      ready: !!address,
      refreshDeps: [isTon],
      onSuccess,
    },
  );

  return hook;
}
