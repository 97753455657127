import { useGlobalDataStore } from '@/context/GlobalDataContext';
import { useNativeToken } from '@/hooks/app/useNativeToken';
import { formatBigNumber } from '@/views/Swap';
import { LeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import classNames from 'classnames';
import numeral from 'numeral';
import { useMemo } from 'react';
import NumberKeyboard from './NumberKeyboard';
import SlideButton from './SlideButton';

const TagList = ({ clickTag }: { clickTag: (v: number) => void }) => {
  const list = [
    { label: '10%', value: 0.1 },
    { label: '25%', value: 0.25 },
    { label: '50%', value: 0.5 },
    { label: 'Max', value: 1 },
  ];

  return (
    <div className="flex gap-2 justify-center">
      {list.map((item) => {
        return (
          <div
            key={item.value}
            className="bg-[#1E1E1E] w-[74px] h-[33px] rounded-full flex justify-center items-center"
            onClick={() => clickTag(item.value)}
          >
            {item.label}
          </div>
        );
      })}
    </div>
  );
};

function formatDecimal(input: string) {
  const num = parseFloat(input);

  if (!isNaN(num)) {
    return input.replace(/(\.\d{2})\d+/, '$1'); // 保留2位小数
  } else {
    return input;
  }
}

export const EnterAmount = ({
  amount,
  pricePreview,
  setAmount,
  onConfirm,
  goBack,
}: {
  amount: string;
  pricePreview: string;
  setAmount: React.Dispatch<React.SetStateAction<string>>;
  onConfirm: () => void;
  goBack: () => void;
}) => {
  const [nativeSymbol, nativeIcon] = useNativeToken();
  const { balance, decimals } = useGlobalDataStore();
  const formattedBalance = formatBigNumber(balance || 0n, decimals);

  const currentBalance =
    +formattedBalance > 0.01 ? numeral(formattedBalance).subtract(0.01).value() || 0 : 0;

  console.log('curr', currentBalance);

  const insufficientBalance = useMemo(() => {
    return currentBalance < +amount;
  }, [amount, currentBalance]);

  const clickTag = (v: number) => {
    setAmount(numeral(currentBalance * v).format('0[.][00]'));
  };

  return (
    <div className="position">
      <Button
        type="text"
        className="text-secondaryText"
        onClick={() => {
          goBack();
        }}
      >
        <LeftOutlined /> Back
      </Button>
      <div className="mb-2 flex justify-center">
        <div className="flex items-center gap-2 text-[14px] bg-[#1E1E1E] rounded-full p-2 pr-3">
          <img src={nativeIcon} className="w-7 h-7" />
          Balance: {currentBalance} {nativeSymbol}
        </div>
      </div>
      <div className="text-[42px] flex text-center text-primaryText gap-2 justify-center">
        <div
          className={classNames('max-w-[220px] overflow-x-auto', !amount ? 'text-[#2e2e2e]' : '')}
        >
          {formatDecimal(amount) || 0}
        </div>
        <span className="text-[#2e2e2e]">{nativeSymbol}</span>
      </div>
      <div className="text-[14px] text-[#999] text-center mb-10">${pricePreview}</div>
      <div className="absolute bottom-5 left-[50%] flex flex-col gap-[10px] translate-x-[-50%]">
        <TagList clickTag={clickTag} />
        <NumberKeyboard amount={amount} setAmount={setAmount} />
        <SlideButton
          displayText={insufficientBalance ? 'Insufficient Balance' : ''}
          isDisabled={!+amount || insufficientBalance}
          onConfirm={onConfirm}
        />
      </div>
    </div>
  );
};
