import React from 'react';
import delIcon from '@/assets/icon/send/del-icon.svg';

const NumberKeyboard = ({
  amount,
  setAmount,
}: {
  amount: string;
  setAmount: (v: string) => void;
}) => {
  // 点击数字或小数点按钮时的处理
  const handleButtonClick = (value: string) => {
    if (value === '.' && amount.includes('.')) {
      // 如果已经有小数点，不允许再次输入小数点
      return;
    }
    if (/^\d*\.?\d{0,2}$/.test(amount + value)) {
      setAmount(amount + value);
    }
  };

  // 删除按钮功能
  const handleDelete = () => {
    setAmount(amount.slice(0, -1)); // 删除最后一位
  };

  return (
    <div className="w-[276px] mx-auto flex flex-wrap justify-between text-primaryText">
      {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => (
        <button
          className="w-[33%] text-[40px]"
          key={num}
          onClick={() => handleButtonClick(num.toString())}
        >
          {num}
        </button>
      ))}
      <button className="w-[33%] text-[40px]" onClick={() => handleButtonClick('.')}>
        .
      </button>
      <button className="w-[33%] text-[40px]" onClick={() => handleButtonClick('0')}>
        0
      </button>
      <button
        className="flex justify-center items-center w-[33%] text-[42px]"
        onClick={handleDelete}
      >
        <img src={delIcon} />
      </button>
    </div>
  );
};

export default NumberKeyboard;
