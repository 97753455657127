const storageKeys = {
  token: 'TOKEN',
  version: 'VERSION',
  chatId: 'CHAT_ID',
  tomoWalletConnected: 'TOMO_WALLET_CONNECTED',
  walletInfo: 'WALLET_INFO',
  startParam: 'START_PARAM',
};

export { storageKeys };
