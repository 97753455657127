import { switchUserWallet } from '@/service/user';
import { useUserStore } from '@/stores/useUserStore';
import { useRequest } from 'ahooks';

export function useSwitchUserWallet() {
  const { jwtInfo } = useUserStore();

  const hook = useRequest(
    ({ walletId }: { walletId: string }) => {
      return switchUserWallet(jwtInfo!.id, walletId);
    },
    {
      manual: true,
      ready: !!jwtInfo?.id,
    },
  );

  return hook;
}
