import { SaDrawer } from '@/components/SaDrawer';
import { useWalletInfoStore } from '@/stores/useWalletInfoStore';
import { Button } from 'antd';
import { ReactComponent as TomoIcon } from '@/assets/icon/app/tomo.svg';
import { useTonConnectModal, useTonConnectUI } from '@tonconnect/ui-react';
import tonSvg from '@/assets/icon/chain/ton-svg.svg';
import { generateTonProofPayload } from '@/service/proof';
import { useUserStore } from '@/stores/useUserStore';
import { useAppMessage } from '@/hooks/useAppMessage';

const ConnectModal = ({
  open,
  canClose = true,
  onClose,
}: {
  open: boolean;
  canClose?: boolean;
  onClose?: () => void;
}) => {
  const message = useAppMessage({ key: 'connect-modal' });
  const { connectTomoTonWallet } = useWalletInfoStore();
  const { token, userWallets } = useUserStore();
  const { open: openTonConnectModal } = useTonConnectModal();
  const [tonConnectUI] = useTonConnectUI();

  const beforeConnect = async (callback: () => void) => {
    if (!token || !userWallets) {
      message.error('Some errors have occurred, please try again');
      return;
    }
    callback();
  };

  const connectTon = async () => {
    const res = await generateTonProofPayload();
    tonConnectUI.setConnectRequestParameters({
      state: 'ready',
      value: {
        tonProof: res.payload,
      },
    });
    openTonConnectModal();
  };

  return (
    <SaDrawer open={open} title="Select wallet" onClose={onClose} canClose={canClose} height="auto">
      <div className="flex flex-col gap-y-[12px]">
        <Button type="primary" onClick={() => beforeConnect(connectTomoTonWallet)}>
          <TomoIcon /> Tomo Wallet{' '}
          <span className="text-[#483FFF] bg-primaryText p-[2px_6px] text-[12px] leading-4 scale-90 rounded-[2px]">
            Recommend
          </span>
        </Button>
        <Button
          className="bg-[#0098EA] text-primaryText hover:!bg-[#44aaEA] hover:!text-primaryText"
          type="primary"
          onClick={() => beforeConnect(connectTon)}
        >
          <img src={tonSvg} />
          Connect Wallet
        </Button>
      </div>
    </SaDrawer>
  );
};

export default ConnectModal;
