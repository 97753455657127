import { getG6Pool } from '@/contract/instance';
import { Address, fromNano } from '@ton/core';
import { useRequest } from 'ahooks';
import { useMemo } from 'react';
import { getProvider } from '@/utils';
import { Coin, CoinDetail } from '@/types/Coin';
import { useCoinDetailStore } from '../context/CoinDetailContext';

export function useCalcSellAmount({
  detail,
  amount,
  ready,
}: {
  detail?: CoinDetail;
  amount: bigint;
  ready: boolean;
}) {
  // const { provider } = useWallet({ address: poolAddress });
  const { isTonData } = useCoinDetailStore();

  const { data: value } = useRequest(
    async () => {
      const poolAddress = (detail as Coin).poolAddress;
      console.log(poolAddress, amount);

      const res = await getG6Pool(Address.parse(poolAddress!)).getCalcSellAmount(
        await getProvider(poolAddress!),
        amount,
      );

      return res.ton_to_gain;
    },
    {
      ready: isTonData(detail) && !!detail.poolAddress && ready,
      refreshDeps: [amount],
      debounceWait: 1000,
    },
  );

  const displayValue = useMemo(() => {
    try {
      return fromNano(value!);
    } catch (err) {
      return '';
    }
  }, [value]);

  return {
    value,
    displayValue,
  };
}
