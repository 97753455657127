import { ChainType, useWalletInfoStore } from '@/stores/useWalletInfoStore';
import { useMemo } from 'react';

export function useNativeToken() {
  const { chainType } = useWalletInfoStore();

  return useMemo(() => {
    switch (chainType) {
      case ChainType.TON:
        return ['TON', '/static/images/ton_symbol.svg'];
      default:
        return ['SOL', '/static/images/sol_symbol.png'];
    }
  }, [chainType]);
}
