import { useNativeToken } from '@/hooks/app/useNativeToken';
import { ChainType, useWalletInfoStore } from '@/stores/useWalletInfoStore';
import { Dropdown, MenuProps, Space } from 'antd';
import arrowIcon from '@/assets/icon/arrow-icon.svg';

const ChainSelector = () => {
  const [, nativeIcon] = useNativeToken();
  const { chainType, isTomoWallet, disconnect, connectTomoSolWallet, connectTomoTonWallet } =
    useWalletInfoStore();

  const items: MenuProps['items'] = [
    {
      key: ChainType.TON,
      label: 'TON',
      icon: <img className="w-6 h-6" src="/static/images/ton_symbol.svg" />,
    },
    {
      key: ChainType.SOLANA,
      label: 'Solana',
      // TODO: 支持solana链的时候注释掉
      disabled: true,
      // disabled: !isTomoWallet,
      icon: <img className="w-6 h-6 rounded-full" src="/static/images/sol_symbol.png" />,
    },
  ];

  const onClick: MenuProps['onClick'] = ({ key }) => {
    const selectedChain = key as ChainType;
    if (chainType === selectedChain) return;
    disconnect();
    switch (selectedChain) {
      case ChainType.TON:
        connectTomoTonWallet();
        break;
      case ChainType.SOLANA:
        connectTomoSolWallet();
        break;
      default:
        return;
    }
  };

  return (
    <Dropdown
      menu={{ items, style: { background: '#121212', borderRadius: '4px' }, onClick }}
      overlayClassName="border-[1px] border-solid border-[rgba(255,255,255,0.13)] rounded-[4px]"
    >
      <Space className="border-solid border-[1px] border-[#242424] px-[10px] h-9 rounded-full">
        <img src={nativeIcon} className="w-6 h-6 rounded-full" />
        <img src={arrowIcon} />
      </Space>
    </Dropdown>
  );
};

export default ChainSelector;
