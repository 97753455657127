import { getBitcoinAddressType } from '@/components/CoinOperate/SendConfirm';
import { chains } from '@/constants';
import { useTransactionContext } from '@/context/TransactionContext';
import { G6Math } from '@/contract/utils/Math';
import { MFAType } from '@/types/App';
import { TomoTokenItem } from '@/types/Tomo';
import { getRandomBigInt } from '@/utils';
import { Cell, toNano } from '@ton/core';
import { SendTransactionRequest } from '@tonconnect/sdk';
import { useRequest } from 'ahooks';
import { mapValues } from 'lodash';
import { useConfig, useSendTransaction } from 'tomo-tg-wallet-sdk';
import TonWeb from 'tonweb';
import { formatUnits, parseEther, parseUnits } from 'viem';
import { useTomoWallet } from './useTomoWallet';

export enum SendError {
  CANCEL = 'CANCEL',
}

function log({ type, param }: { type?: string; param?: any }) {
  console.log('start------------------------');

  console.log('type:', type);

  console.log('param', param);

  console.log('end------------------------');
}

export function useSendTransactionByTomo({
  to,
  token,
  amount,
  walletAddress,
  onSendSuccess,
  onSendError,
}: {
  to?: string;
  token?: TomoTokenItem;
  amount: string;
  walletAddress: string;
  value?: string;
  onSendSuccess?: () => void;
  onSendError?: (err: any) => void;
}) {
  const { sendBtcTransaction, sendEVMTransaction, sendSolTransaction, sendTonTransaction } =
    useSendTransaction();

  const { config } = useConfig();

  const { userInfo } = useTomoWallet();

  const { runAsync, loading } = useRequest(
    async ({
      password,
      data,
    }: {
      password: string;
      sendTransactionRequest?: SendTransactionRequest;
      data?: string;
    }) => {
      if (!userInfo) return Promise.reject('Invalid user');

      if (!amount) return Promise.reject('Invalid amount');

      if (token?.chain !== 'SOLANA') {
        if (!to) return Promise.reject('Invalid address');
      }

      switch (token!.chain) {
        case 'BITCOIN':
          if (!getBitcoinAddressType(to!)) return Promise.reject('Invalid address');

          log({
            type: 'sendBtcTransaction',
            param: {
              network: 'MAINNET',
              addressType: getBitcoinAddressType(to!)!,
              value: amount,
              toAddress: to,
              mfaType: MFAType.password,
              password,
            },
          });

          return sendBtcTransaction({
            network: 'MAINNET',
            addressType: getBitcoinAddressType(to!)!,
            value: amount,
            toAddress: to,
            mfaType: MFAType.password,
            password,
          });

        case 'TON':
          log({
            type: 'sendTonTransaction',
            param: {
              fromAddress: userInfo!.tonAddress,
              publicKey: userInfo!.tonPublicKey!,
              value: parseUnits(G6Math.from(amount).add('0.03').toString(), token!.decimals),
              toAddress: to!,
              tokenPrecision: token!.decimals,
              token: {
                chainId: chains[token!.chain].chainId!,
                image: token!.imageUrl,
                name: token!.name,
                symbol: token!.symbol,
                decimals: token!.decimals,
                address: token!.address as any,
              },
              mfaType: MFAType.password,
              password,
              memo: '',
            },
          });

          return sendTonTransaction({
            fromAddress: userInfo!.tonAddress,
            publicKey: userInfo!.tonPublicKey!,
            value: token!.isNative
              ? parseUnits(G6Math.from(amount).add('0.03').toString(), token!.decimals)
              : toNano('0.1'),
            toAddress: to!,
            // tokenContractAddress: token!.isNative ? undefined : token!.address,
            tokenPrecision: token!.decimals,
            token: {
              chainId: chains[token!.chain].chainId!,
              image: token!.imageUrl,
              name: token!.name,
              symbol: token!.symbol,
              decimals: token!.decimals,
              address: token!.address as any,
            },
            mfaType: MFAType.password,
            password,
            memo: '',
          });

        case 'SOLANA':
          log({
            type: 'sendSolTransaction',
            param: {
              fromAddress: walletAddress,
              toAddress: to,
              value: token!.isNative
                ? parseUnits(G6Math.from(amount).add('0.03').toString(), token!.decimals)
                : toNano('0.03'),
              token: {
                chainId: chains[token!.chain].chainId!,
                image: token!.imageUrl,
                name: token!.name,
                symbol: token!.symbol,
                decimals: token!.decimals,
                address: token!.address as any,
              },
              mfaType: MFAType.password,
              password,
              data: data,
            },
          });

          return sendSolTransaction({
            fromAddress: walletAddress,
            toAddress: to,
            value: token!.isNative ? parseUnits(amount, token!.decimals) : toNano('0.03'),
            token: {
              chainId: chains[token!.chain].chainId!,
              image: token!.imageUrl,
              name: token!.name,
              symbol: token!.symbol,
              decimals: token!.decimals,
              address: token!.address as any,
            },
            mfaType: MFAType.password,
            password,
            data: data,
          });

        default:
          if (!chains[token!.chain].chainId) return Promise.reject('Invalid chain');

          log({
            type: 'EVM',
            param: {
              chainId: chains[token!.chain].chainId! as any,
              fromAddress: walletAddress,
              toAddress: to,
              value: token!.isNative
                ? parseEther(amount, token!.decimals === 9 ? 'gwei' : 'wei')
                : undefined,
              rpc: '',
              config: config,
              data,
              tokenValue: token!.isNative ? undefined : parseUnits(amount, token!.decimals),
              mfaType: MFAType.password,
              password,
            },
          });

          return sendEVMTransaction({
            chainId: chains[token!.chain].chainId! as any,
            fromAddress: walletAddress,
            toAddress: to,
            value: token!.isNative
              ? parseEther(amount, token!.decimals === 9 ? 'gwei' : 'wei')
              : undefined,
            rpc: '',
            config: config,
            data,
            tokenValue: token!.isNative ? undefined : parseUnits(amount, token!.decimals),
            mfaType: MFAType.password,
            password,
          });
      }
    },
    {
      manual: true,
      onSuccess(res) {
        console.log('transaction(useSendTransactionByTomo)-response---------->', res);

        openModal('success');

        onSendSuccess && onSendSuccess();
      },
      onError(err) {
        console.log('transaction(useSendTransactionByTomo)-err---------->', err);

        openModal('network');

        onSendError && onSendError(err);
      },
    },
  );

  const { startAuth, openModal } = useTransactionContext();

  const send = ({ data }: { data?: string } = {}) => {
    return new Promise((resolve, reject) => {
      startAuth(MFAType.password, (password) => {
        if (!password) return reject(new Error(SendError.CANCEL));

        console.log('password', password);

        runAsync({
          password,
          data,
        })
          .then((res) => {
            resolve(res);
          })
          .catch(reject);
      });
    });
  };

  return {
    send,
    loading,
  };
}

export function useSendEVMTransactionByTomo({
  to,
  token,
  amount,
  walletAddress,
  value,
  onSendSuccess,
  onSendError,
}: {
  to?: string;
  token?: TomoTokenItem;
  amount: string;
  walletAddress: string;
  value?: string;
  onSendSuccess?: () => void;
  onSendError?: (err: any) => void;
}) {
  const { sendEVMTransaction } = useSendTransaction();

  const { config } = useConfig();

  const { userInfo } = useTomoWallet();

  const { runAsync, loading } = useRequest(
    async ({
      password,
      data,
    }: {
      password: string;
      sendTransactionRequest?: SendTransactionRequest;
      data?: string;
    }) => {
      if (!userInfo) return Promise.reject('Invalid user');

      if (!to) return Promise.reject('Invalid address');

      if (!amount) return Promise.reject('Invalid amount');

      if (!chains[token!.chain].chainId) return Promise.reject('Invalid chain');

      return sendEVMTransaction({
        chainId: chains[token!.chain].chainId! as any,
        fromAddress: walletAddress,
        toAddress: to,
        value: BigInt(value || '0'),
        config: config,
        data,
        tokenValue: parseEther(amount),
        mfaType: MFAType.password,
        password,
      });
    },
    {
      manual: true,
      onSuccess(res) {
        openModal('success');

        onSendSuccess && onSendSuccess();
      },
      onError(err) {
        console.log('err', err);

        openModal('network');

        setTransactionLoading(false);

        onSendError && onSendError(err);
      },
    },
  );

  const { startAuth, openModal, setTransactionLoading } = useTransactionContext();

  const send = ({ data }: { data?: string } = {}) => {
    return new Promise((resolve, reject) => {
      startAuth(MFAType.password, (password) => {
        if (!password) return reject(new Error(SendError.CANCEL));

        runAsync({
          password,
          data,
        })
          .then((res) => {
            resolve(res);
          })
          .catch(reject);
      });
    });
  };

  return {
    send,
    loading,
  };
}

export function useSendTonTransactionByTomo({
  onSendSuccess,
  onSendError,
}: {
  onSendSuccess?: () => void;
  onSendError?: (err: any) => void;
} = {}) {
  const { sendTonTransaction } = useSendTransaction();

  const { userInfo } = useTomoWallet();

  const { runAsync } = useRequest(
    async ({
      sendTransactionRequest,
      password,
    }: {
      sendTransactionRequest: SendTransactionRequest;
      password: string;
    }) => {
      if (!sendTransactionRequest) return Promise.reject('Invalid request');

      const { value, ...params } = getSendSendData(sendTransactionRequest);

      log({
        type: 'TOM',
        param: {
          ...params,
          value: BigInt(value),
          mfaType: MFAType.password,
          password,
        },
      });

      return sendTonTransaction({
        ...params,
        value: BigInt(value),
        mfaType: MFAType.password,
        password,
      })
        .then((res) => {
          console.log('transaction(useSendTonTransactionByTomo)-response---------->', res);

          return res;
        })
        .catch((err) => {
          console.log('transaction(useSendTonTransactionByTomo)-err---------->', err);
          return Promise.reject(err);
        });
    },
    {
      manual: true,
      onBefore() {
        setTransactionLoading(true);
      },
      onSuccess() {
        openModal('success');

        onSendSuccess && onSendSuccess();

        closeDetail();
      },
      onError(err) {
        console.log('err', err);

        openModal('network');

        onSendError && onSendError(err);

        setTransactionLoading(false);
      },
      onFinally() {
        setTransactionLoading(false);
      },
    },
  );

  const { startConfirmAuth, openModal, closeDetail, setTransactionLoading } =
    useTransactionContext();

  function getSendSendData(sendTransactionRequest: SendTransactionRequest) {
    const [item] = sendTransactionRequest.messages;

    return {
      fromAddress: userInfo!.tonAddress,
      publicKey: userInfo!.tonPublicKey!,
      value: item.amount,
      toAddress: item.address,
      memo: TonWeb.boc.Cell.oneFromBoc(TonWeb.utils.base64ToBytes(item.payload!)) as any,
    };
  }

  const handleSendTonTransaction = (sendTransactionRequest: SendTransactionRequest) => {
    return new Promise((resolve, reject) => {
      startConfirmAuth({
        onConfirm: (password) => {
          if (!password) {
            setTransactionLoading(false);

            return reject(new Error(SendError.CANCEL));
          }

          runAsync({
            password,
            sendTransactionRequest,
          })
            .then((res) => {
              resolve(res);
            })
            .catch(reject);
        },
        data: getSendSendData(sendTransactionRequest),
      });
    });
  };

  return {
    handleSendTonTransaction,
  };
}

export type TonSendPayload = {
  fromAddress: string;
  publicKey: string;
  value: string;
  toAddress: string;
  memo: string;
};
