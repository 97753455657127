import { Button } from '@/components/Button';
import { Input, ConfigProvider } from 'antd';
// Button as AntButton
import { useContext, useMemo, useState } from 'react';
import { fromNano, toNano } from '@ton/core';
import { useRequest, useUnmount } from 'ahooks';
import { useAppMessage } from '@/hooks/useAppMessage';
import { useAccountMethods } from '@/contract/hooks/useAccountMethods';
import { TonValue } from '@/components/TonValue';
import { CoinDetailContext } from '../../context/CoinDetailContext';
import { SymbolUnit } from './SymbolUnit';
import { Coin } from '@/types/Coin';
import classNames from 'classnames';
import { ConnectButton } from '@/components/ConnectButton';
import { useFetchTXResult } from '../../hooks/useFetchTXResult';
import { useGlobalDataStore } from '@/context/GlobalDataContext';
import { useSwapTonToJetton } from '@/hooks/ston.fi/useSwapTonToJetton';
import { useSwapableTokenAmount } from '@/hooks/ston.fi/useSwapableTokenAmount';
import { isUndefined } from 'lodash';
import { G6Math } from '@/contract/utils/Math';
import { ControlBar } from './ControlBar';
import { TransitionDetail } from './TransitionDetail';
import { SendError } from '@/hooks/useSendTransactionByTomo';
import { onPressEnter } from '@/views/Create';
import { useNativeToken } from '@/hooks/app/useNativeToken';
// import { SettingOutlined } from '@ant-design/icons';
// import { SlippageModal } from '@/components/SlippageModal';
// import { isUndefined } from 'lodash';

const loadingKey = 'LOADING';

export function BuyUseStonFi() {
  const { getBalance, ready } = useAccountMethods();

  const [nativeName] = useNativeToken();

  const { detail, refreshIsGraduated, isTonData } = useContext(CoinDetailContext);

  const { refreshBalanceAsync, slippageValue } = useGlobalDataStore();

  const [count, setCount] = useState('');

  const { runAsync: handleBuy } = useSwapTonToJetton({
    askJettonAddress: detail?.address,
    offerAmount: count,
    slippage: slippageValue || '0.01',
  });

  const { data: balance = toNano(0), loading: balanceLoading } = useRequest(
    async () => {
      return getBalance().then((balance) => {
        return balance ? balance : toNano(0);
      });
    },
    {
      ready: ready,
    },
  );

  const handlePut = (val: any) => {
    setCount(val);
  };

  const [queryId, setQueryId] = useState<string | undefined>();

  useFetchTXResult({
    queryId,
    type: 'buy',
    onSuccess() {
      message.success('Purchase success');

      refreshBalanceAsync?.().catch(() => null);

      setQueryId(undefined);

      message.messageInstance.destroy(loadingKey);
    },
    onFail() {
      message.error('Purchase failed, fee will be refunded later');

      message.messageInstance.destroy(loadingKey);
    },
  });

  const message = useAppMessage();

  const [symbol, setSymbol] = useState(nativeName);

  const isNative = useMemo(() => {
    return symbol === nativeName;
  }, [symbol, nativeName]);

  const getSymbol = (e: string) => {
    setSymbol(e);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCount(e.target.value);
  };

  const onBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^\d.]/g, '');

    setCount(value);
  };

  const { run: runBuy, loading: buyLoading } = useRequest(
    async () => {
      let askUnits: string | bigint = toNano(count);

      if (isNative) {
        if (new G6Math(count).isGreaterThan(fromNano(balance) || 0n)) {
          return Promise.reject(new Error('Insufficient balance'));
        }
      } else {
        // 100000000
        if (data && new G6Math(fromNano(data.askUnits)).isGreaterThan(fromNano(balance) || 0n)) {
          return Promise.reject(new Error('Insufficient balance'));
        } else {
          askUnits = (await getSwapableTokenAmount()).askUnits;
        }
      }

      return handleBuy({ offerAmount: askUnits });
    },
    {
      manual: true,
      ready: !!detail && isTonData(detail),
      onSuccess: (res) => {
        // message.success('Transaction sent successfully');

        refreshIsGraduated?.();

        setCount('');

        console.log('res-------->', res);

        refreshBalanceAsync?.().catch(() => null);
      },
      onError(err) {
        if (err.message === SendError.CANCEL) {
          return;
        }
        message.error(err.message || 'Transaction sent failed');
      },
    },
  );

  const {
    data,
    runAsync: getSwapableTokenAmount,
    priceImpactTooHigh,
  } = useSwapableTokenAmount({
    tokenAddress: detail?.address,
    offerAddressIsTon: isNative,
    offerUnits: count,
    slippageTolerance: slippageValue,
  });

  useUnmount(() => {
    message.messageInstance.destroy(loadingKey);
  });

  const units = [
    {
      label: `1 ${nativeName}`,
      value: '1',
    },
    {
      label: `5 ${nativeName}`,
      value: '5',
    },
    {
      label: `10 ${nativeName}`,
      value: '10',
    },
    {
      label: 'MAX',
      value: 'MAX',
    },
  ];

  return (
    <ConfigProvider
      wave={{ disabled: true }}
      theme={{
        components: {
          Input: {
            colorBgContainer: '#1B1B1B',
            activeBg: '#1B1B1B',
            addonBg: '#1B1B1B',
            activeBorderColor: '#242424',
            colorSplit: '#1B1B1B',
            colorBorder: '#242424',
            hoverBorderColor: '#242424',
            colorText: '#ffffff50',
            borderRadius: 60,
            controlHeight: 56,
            fontSize: 24,
          },
        },
      }}
    >
      <div className="my-[10px] w-full">
        <ControlBar
          balance={balance}
          balanceLoading={balanceLoading}
          getSymbol={getSymbol}
          suffix={nativeName}
          isNative={isNative}
        />
      </div>

      <div>
        <Input
          onPressEnter={onPressEnter}
          className="w-full "
          // addonAfter={<SymbolSelect symbol={symbol} setSymbol={setSymbol} detail={detail} />}
          suffix={<SymbolUnit detail={detail} isTon={isNative}></SymbolUnit>}
          defaultValue={count}
          value={count}
          onBlur={onBlur}
          onChange={handleChange}
        />
        <div
          className={classNames('flex items-center mt-3', {
            hidden: !isNative,
            'border-0 border-b border-solid border-opacity-35  border-white pb-[10px]':
              !!count && !isUndefined(data),
          })}
        >
          <ul
            className={classNames(
              'flex items-center leading-[15px] text-[12px] gap-[8px] font-normal ',
            )}
          >
            {units.map((item) => {
              return (
                <li
                  key={item.label}
                  className="bg-[#282828] px-[16px] py-[4px] rounded-[36px] cursor-pointer font-normal"
                  onClick={() => {
                    handlePut(item.value === 'MAX' ? fromNano(balance) : Number(item.value));
                  }}
                >
                  {item.label}
                </li>
              );
            })}
          </ul>
        </div>

        {!!count && !isUndefined(data) && (
          <div className="pl-3 mt-[8px] text-white text-opacity-35">
            {isNative ? 'Receive' : 'Cost'} <TonValue value={data.askUnits} fixed={6}></TonValue>{' '}
            {isNative ? detail?.symbol : nativeName}
          </div>
        )}

        <ConnectButton className="w-full mt-[18px] text-[15px] ">
          <Button
            loading={buyLoading}
            type="primary"
            disabled={!+count || !!priceImpactTooHigh}
            className={classNames('w-full mt-[18px] text-[15px] ')}
            onClick={() => {
              runBuy();
            }}
          >
            <img className="w-[20px]" src="/static/images/stonfi-logo.svg" />
            {priceImpactTooHigh ? 'Price impact is too high' : 'PLACE TRADE'}
          </Button>
        </ConnectButton>

        {data && count && (
          <div>
            <TransitionDetail offerAddressIsTon={isNative} data={data} />
          </div>
        )}
      </div>
    </ConfigProvider>
  );
}
