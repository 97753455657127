// import discordIcon from '@/assets/icon/icon_discord.svg';
import globalIcon from '@/assets/icon/icon_global.svg';
import twitterIcon from '@/assets/icon/icon_twitter.svg';
import { goAccount } from '@/utils/exploer';
import { Skeleton } from 'antd';
import classNames from 'classnames';
import { useCoinDetailStore } from '../context/CoinDetailContext';
import social3Icon from '@/assets/icon/icon_social3.svg';
import { FollowIcon } from '@/components/FollowIcon';
import { getImageUri } from '@/utils/coin-detail';
import { CoinDetail as CoinDetailType } from '@/types/Coin';
import { displayAddress } from '@/utils/ton';
import { formatAddress } from '@/utils/sol';
import { useAppRouter } from '@/hooks/router/useAppRouter';
import { useUserStore } from '@/stores/useUserStore';

export function CoinDetail() {
  const { detail, loading, isTonData } = useCoinDetailStore();

  const { jwtInfo } = useUserStore();

  const { goCreatorDetailWithUserId, goCreatorDetail } = useAppRouter();

  const toCreatorDetail = () => {
    if (isTonData(detail) && detail.creatorUserId) {
      goCreatorDetailWithUserId(detail.creatorUserId);
    } else if (detail?.creator) {
      goCreatorDetail(detail.creator);
    }
  };

  return (
    <Skeleton active loading={loading}>
      <div>
        <div className="flex">
          {/* <img src={detail?.imageUri} className="h-[100px] max-w-[130px] rounded-[4px] mr-[10px]" /> */}

          <div className="w-[102px] h-[102px] rounded-[4px] mr-[10px] overflow-hidden">
            <CoinImage detail={detail}></CoinImage>
          </div>

          <div className="flex-1">
            <div className="font-semibold text-[18px] leading-[18px] pb-[8px] text-primaryText">
              {detail?.name}
            </div>
            <div className="text-[12px] text-secondaryText font-[400]">
              <span className="mr-[3px]">Ticker:</span>
              {detail?.symbol}
            </div>
            <div className="text-[12px] text-secondaryText flex justify-between items-center gap-x-1 font-[400]">
              <div className="flex items-center justify-start">
                <span className="mr-[3px]">Created By:</span>
                <div
                  className="underline hover:text-white hover:underline"
                  onClick={toCreatorDetail}
                >
                  {isTonData(detail)
                    ? displayAddress(detail?.creator || '')
                    : formatAddress(detail?.creator)}
                </div>
              </div>
              {isTonData(detail) &&
                detail.creatorUserId &&
                detail.creatorUserId !== jwtInfo?.id && (
                  <FollowIcon
                    className="w-auto min-w-[56px] h-[20px] !leading-[20px]"
                    userId={detail?.creatorUserId}
                  />
                )}
            </div>

            <div className="flex gap-x-[12px] mt-[12px]">
              <div
                onClick={() => {
                  goAccount(detail?.address || '');
                }}
                className="py-[6px] px-[7.5px] rounded-[18px] bg-[#242424] inline-flex items-center"
              >
                <img src={social3Icon} className="w-[15px]"></img>
                <span className="ml-0.5 text-[12px] leaading-[15.6px]">Explorer</span>
              </div>
              <a
                href={detail?.website}
                className={classNames(
                  'flex justify-center items-center text-[12px] gap-x-[5px] bg-[#242424] px-[7px] rounded-[18px]',
                  {
                    hidden: !detail?.website,
                  },
                )}
                target="_blank"
                rel="noreferrer"
              >
                <img src={globalIcon} className="w-[10px] h-[10px]" />
                Site
              </a>

              <a
                className={classNames(
                  'flex justify-center items-center text-[12px] gap-x-[5px] bg-[#242424] px-[7px] rounded-[18px]',
                  {
                    hidden: !detail?.twitter,
                  },
                )}
                href={detail?.twitter}
                target="_blank"
                rel="noreferrer"
              >
                <img src={twitterIcon} className="w-[10px] h-[10px]" />
                Twitter
              </a>
            </div>
          </div>
        </div>
        {detail?.description && (
          <div className="text-xs mt-[16px] text-[#9B9B9B] font-[400]">{detail?.description}</div>
        )}
      </div>
    </Skeleton>
  );
}

export function CoinImage({ detail, className }: { detail: CoinDetailType; className?: string }) {
  const { isTonData } = useCoinDetailStore();
  const src = isTonData(detail) ? detail.imageUri : getImageUri(detail?.image_uri);

  return <img className={classNames('rounded-[4px]', className)} src={src}></img>;
}
