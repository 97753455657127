import { fetchUserCreateCoins } from '@/service/user';
import { useWalletInfoStore } from '@/stores/useWalletInfoStore';
import { getCoinCreatedList } from '@/test';
import { getImageUri } from '@/utils/coin-detail';
import { useRequest } from 'ahooks';
import { pick } from 'lodash';

export function useFetchUserCreateCoins(address?: string) {
  const { isTon } = useWalletInfoStore();

  const hook = useRequest(
    async () => {
      if (isTon) {
        const res = await fetchUserCreateCoins(address!);
        return res.docs.map((item) => {
          return {
            ...pick(item, ['name', 'imageUri', 'description', 'createdTimestamp']),
            poolAddress: item.poolAddress,
          };
        });
      } else {
        const res = await getCoinCreatedList(address!);
        return res.map((item) => {
          return {
            name: item.name,
            imageUri: getImageUri(item.image_uri),
            description: item.description,
            createdTimestamp: item.created_timestamp / 1000,
            poolAddress: item.mint,
          };
        });
      }
    },
    {
      ready: !!address,
      refreshDeps: [address],
    },
  );

  return hook;
}
