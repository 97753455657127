import { shortenAddress } from '@/utils/ton';
import { ButtonProps, ConfigProvider, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { ReactComponent as TomoIcon } from '@/assets/icon/app/tomo.svg';
import { UserInfo } from '@/types/Tomo';
import { useWalletInfoStore } from '@/stores/useWalletInfoStore';
import { Button } from '../Button';
import WalletButtonDropdown from './WalletButtonDropdown/WalletButtonDropdown';
import ConnectModal from './ConnectModal/ConnectModal';

const { Paragraph } = Typography;

function TomoUserInfo({ userInfo }: { userInfo: UserInfo }) {
  return (
    <div className="flex justify-start items-start flex-col gap-y-[10px]">
      <div className="flex flex-col justify-start w-full">
        <div className="text-base">TON:</div>
        <Paragraph
          className="break-all truncate text-secondaryText"
          copyable={{
            text: userInfo.tonAddress,
          }}
        >
          {shortenAddress(userInfo.tonAddress)}
        </Paragraph>
      </div>
      <div className="flex flex-col justify-start w-full">
        <div className="text-base">SOLANA:</div>
        <Paragraph
          className="break-all truncate text-secondaryText"
          copyable={{
            text: userInfo.solanaAddress,
          }}
        >
          {shortenAddress(userInfo.solanaAddress)}
        </Paragraph>
      </div>

      <div className="flex flex-col justify-start w-full">
        <div className="text-base">
          Wallet<span className="text-[8px] mr-1">EVM</span> :
        </div>
        <Paragraph
          className="break-all truncate text-secondaryText"
          copyable={{
            text: userInfo.walletAddress,
          }}
        >
          {shortenAddress(userInfo.walletAddress)}
        </Paragraph>
      </div>
    </div>
  );
}

export function ConnectButton({
  children,
  onlyTomo,
  className,
  block,
  controlHeight,
  afterDisconnect,
}: {
  children?: React.ReactNode;
  onlyTomo?: boolean;
  className?: string;
  block?: boolean;
  buttonType?: ButtonProps['type'];
  controlHeight?: number;
  afterDisconnect?: () => void;
}) {
  const { walletAddress: address, connectTomoTonWallet } = useWalletInfoStore();

  const [walletModalOpen, setWalletModalOpen] = useState(false);

  // const { tomo, needAuth } = useTomoSdk();

  // const message = useAppMessage();

  // const { goVerifyEmail } = useAppRouter();

  // const { run: runTomoLogin, loading } = useRequest(
  //   async () => {
  //     return tomo.onLogin('telegram', true);
  //   },
  //   {
  //     manual: true,
  //     onError: (err) => {
  //       message.error(err.message || 'Login failed');
  //     },
  //   },
  // );

  // usePrevious(address, (prev, next) => {
  //   if (!prev && next) {
  //     setWalletModalOpen(false);
  //   }

  //   return true;
  // });

  useEffect(() => {
    if (address && walletModalOpen) {
      setWalletModalOpen(false);
    }
  }, [address, walletModalOpen]);

  // useEffect(() => {
  //   if (!needAuth && tomo.userInfo) {
  //     setWalletModalOpen(false);
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [tomo.userInfo, needAuth]);

  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Button: {
              controlHeight: controlHeight || 48,
            },
          },
        }}
      >
        {address ? (
          children ? (
            children
          ) : (
            <>
              <div className="flex items-center justify-between">
                <div className="inline-flex items-center justify-center gap-x-[6px]">
                  <WalletButtonDropdown afterDisconnect={afterDisconnect} />
                  {/* <Paragraph
                    className="truncate w-9 h-9 rounded-full flex justify-center items-center border-solid border-[#242424] border-[1px] [&_path]:fill-primaryText [&_svg]:h-4 [&_svg]:w-4 !m-0"
                    copyable={{
                      text: address,
                    }}
                  /> */}
                </div>

                {/* <AppDropdown
                  onDisconnect={() => {
                    handleDisconnect();
                  }}
                /> */}
              </div>
            </>
          )
        ) : (
          <>
            {onlyTomo ? (
              <Button type="primary" onClick={connectTomoTonWallet}>
                <TomoIcon /> Connect Tomo Wallet
              </Button>
            ) : (
              <Button
                className={className}
                block={block}
                type="primary"
                onClick={() => {
                  setWalletModalOpen(true);
                }}
              >
                Connect Wallet
              </Button>
            )}
          </>
        )}

        <ConnectModal open={walletModalOpen} onClose={() => setWalletModalOpen(false)} />

        {/* <SaDrawer
          title={
            <div className="flex gap-x-[10px] items-center">
              <div className="flex justify-start items-center gap-x-[10px] max-w-[300px] truncate">
                {tomo.userInfo ? tomo.userInfo.username : shortenAddress(address)}
              </div>
              <Paragraph
                className="truncate !mb-0 break-all text-secondaryText [&_path]:fill-secondaryText"
                copyable={{
                  text: tomo.userInfo ? tomo.userInfo.username : address,
                }}
              ></Paragraph>
            </div>
          }
          height="auto"
          open={infoOpen}
          onClose={() => {
            setInfoOpen(false);
          }}
        >
          <div>{tomo.userInfo && <TomoUserInfo userInfo={tomo.userInfo} />}</div>

          <div className="flex items-center justify-between">
            <div className="text-secondaryText text-[12px]">{process.env.REACT_APP_VERSION}</div>
          </div>
        </SaDrawer> */}
      </ConfigProvider>
    </>
  );
}
