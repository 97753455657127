import { CurveProgress } from './CurveProgress';
import { Trade } from './Trade';
import { CoinDetail } from './CoinDetail';
import { HolderDistribution } from './HolderDistribution';
import { TonValue } from '@/components/TonValue';
import classNames from 'classnames';
import { useFetchJettonSoldable } from '../hooks/useFetchJettonSoldable';

function Card({ children, className }: { children: any; className?: string }) {
  return (
    <div
      className={classNames(
        'flex flex-col w-full h-auto gap-4 mb-[20px] rounded-[10px] p-[18px] bg-[#1B1B1B]',
        className,
      )}
    >
      {children}
    </div>
  );
}

export function CoinInfo() {
  return (
    <div className="shrink-0 md:w-full">
      <div className="py-4">
        <CoinDetail />
      </div>
      <div className="py-[24px] pb-[10px]">
        <CurveProgress />
      </div>
      <div className="py-[24px]">
        <JettonSoldable />
      </div>
      <div className="py-[24px]">
        <HolderDistribution />
      </div>
    </div>
  );
}

function JettonSoldable() {
  const { jettonSoldable, loading } = useFetchJettonSoldable();

  return jettonSoldable ? (
    <div className="flex justify-between items-center">
      <div>Available for sale:</div>
      <div>
        <TonValue value={jettonSoldable} loading={loading}></TonValue>
      </div>
    </div>
  ) : null;
}
