import request from '@/request';
import { UserWallets } from '@/types/User';

type CreateWalletPayload = {
  chain: string;
  signedProof: {
    payload: SignedProofPayload;
  };
};

export type SignedProofPayload = {
  address: string;
  network: string;
  proof: {
    timestamp: number;
    domain: { lengthBytes: number; value: string };
    signature: string;
    payload: string;
    state_init: string;
  };
};

export async function createWallet(payload?: CreateWalletPayload) {
  const res = await request.post<{ token: string }>(`/wallets`, payload);
  return res.data;
}

export async function getUserWallets(userId?: string) {
  const res = await request.get<UserWallets[]>(`/wallets/user/${userId}`);
  return res.data;
}
