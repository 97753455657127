import { SaDrawer } from '@/components/SaDrawer';
import { IWeb3ChainType } from '@/types/App';
import { Input, List } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { getSafeChainId } from '../context/SwapContext';

function Item({ asset, onClick }: { asset: IWeb3ChainType; onClick: () => void }) {
  const ref = useRef(null);

  return (
    <div
      className="flex justify-between items-center w-full cursor-pointer bg-primaryBackground"
      onClick={onClick}
      ref={ref}
    >
      <div className="flex justify-start items-center gap-x-[10px]">
        <img className="w-[32px] h-[32px] rounded-full" src={asset.icon} alt="" />
        <div>
          <div>{asset.name}</div>
        </div>
      </div>
    </div>
  );
}

const blockchainNumbers = [
  0, // Bitcoin
  1, // Ethereum
  501, // Solana
  56, // BNB Chain
  42161, // Arbitrum One
  8453, // Base
  81457, // Blast
  43114, // Avalanche
  137, // Polygon
  534352, // Scroll
  10, // Optimism
  59144, // Linea
  1100, // Ton
  8333, // B3
  8329, // Lorenzo
];

const list = [
  {
    type: 'SOL',
    name: 'Solana',
    networkType: 'main',
    icon: 'https://cryptologos.cc/logos/solana-sol-logo.svg',
    explorer: 'https://explorer.solana.com/',
  },
  {
    chain: {
      id: 42161,
      name: 'Arbitrum One',
      nativeCurrency: {
        name: 'Ether',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrls: {
        default: {
          http: ['https://arb1.arbitrum.io/rpc'],
        },
      },
      blockExplorers: {
        default: {
          name: 'Arbiscan',
          url: 'https://arbiscan.io',
          apiUrl: 'https://api.arbiscan.io/api',
        },
      },
      contracts: {
        multicall3: {
          address: '0xca11bde05977b3631167028862be2a173976ca11',
          blockCreated: 7654707,
        },
      },
    },
    type: 'EVM',
    name: 'Arbitrum One',
    icon2: 'https://icons.llamao.fi/icons/chains/rsz_arbitrum.jpg',
    icon: 'https://cryptologos.cc/logos/arbitrum-arb-logo.svg',
    explorer: 'https://arbiscan.io/',
  },
  {
    chain: {
      id: 43114,
      name: 'Avalanche',
      nativeCurrency: {
        decimals: 18,
        name: 'Avalanche',
        symbol: 'AVAX',
      },
      rpcUrls: {
        default: {
          http: ['https://api.avax.network/ext/bc/C/rpc'],
        },
      },
      blockExplorers: {
        default: {
          name: 'SnowTrace',
          url: 'https://snowtrace.io',
          apiUrl: 'https://api.snowtrace.io',
        },
      },
      contracts: {
        multicall3: {
          address: '0xca11bde05977b3631167028862be2a173976ca11',
          blockCreated: 11907934,
        },
      },
    },
    type: 'EVM',
    name: 'Avalanche',
    icon2: 'https://icons.llamao.fi/icons/chains/rsz_avalanche.jpg',
    icon: 'https://cryptologos.cc/logos/avalanche-avax-logo.svg',
    explorer: 'https://snowtrace.io',
  },
  {
    chain: {
      id: 8333,
      name: 'B3',
      nativeCurrency: {
        name: 'Ether',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrls: {
        default: {
          http: ['https://mainnet-rpc.b3.fun/http'],
        },
      },
      blockExplorers: {
        default: {
          name: 'Blockscout',
          url: 'https://explorer.b3.fun',
        },
      },
      sourceId: 8453,
    },
    type: 'EVM',
    name: 'B3',
    icon: 'https://explorer.b3.fun//assets/configs/network_icon.svg',
    explorer: 'https://explorer.b3.fun',
  },
  {
    chain: {
      formatters: {
        block: {
          type: 'block',
        },
        transaction: {
          type: 'transaction',
        },
        transactionReceipt: {
          type: 'transactionReceipt',
        },
      },
      serializers: {},
      contracts: {
        gasPriceOracle: {
          address: '0x420000000000000000000000000000000000000F',
        },
        l1Block: {
          address: '0x4200000000000000000000000000000000000015',
        },
        l2CrossDomainMessenger: {
          address: '0x4200000000000000000000000000000000000007',
        },
        l2Erc721Bridge: {
          address: '0x4200000000000000000000000000000000000014',
        },
        l2StandardBridge: {
          address: '0x4200000000000000000000000000000000000010',
        },
        l2ToL1MessagePasser: {
          address: '0x4200000000000000000000000000000000000016',
        },
        l2OutputOracle: {
          '1': {
            address: '0x56315b90c40730925ec5485cf004d835058518A0',
          },
        },
        multicall3: {
          address: '0xca11bde05977b3631167028862be2a173976ca11',
          blockCreated: 5022,
        },
        portal: {
          '1': {
            address: '0x49048044D57e1C92A77f79988d21Fa8fAF74E97e',
            blockCreated: 17482143,
          },
        },
        l1StandardBridge: {
          '1': {
            address: '0x3154Cf16ccdb4C6d922629664174b904d80F2C35',
            blockCreated: 17482143,
          },
        },
      },
      id: 8453,
      name: 'Base',
      nativeCurrency: {
        name: 'Ether',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrls: {
        default: {
          http: ['https://mainnet.base.org'],
        },
      },
      blockExplorers: {
        default: {
          name: 'Basescan',
          url: 'https://basescan.org',
          apiUrl: 'https://api.basescan.org/api',
        },
      },
      sourceId: 1,
    },
    type: 'EVM',
    name: 'Base',
    icon: 'https://icons.llamao.fi/icons/chains/rsz_base.jpg',
    explorer: 'https://basescan.org',
  },
  {
    chain: {
      id: 81457,
      name: 'Blast',
      nativeCurrency: {
        decimals: 18,
        name: 'Ether',
        symbol: 'ETH',
      },
      rpcUrls: {
        default: {
          http: ['https://rpc.blast.io'],
        },
      },
      blockExplorers: {
        default: {
          name: 'Blastscan',
          url: 'https://blastscan.io',
          apiUrl: 'https://api.blastscan.io/api',
        },
      },
      contracts: {
        multicall3: {
          address: '0xcA11bde05977b3631167028862bE2a173976CA11',
          blockCreated: 212929,
        },
      },
      sourceId: 1,
    },
    type: 'EVM',
    name: 'Blast',
    icon: 'https://icons.llamao.fi/icons/chains/rsz_blast.jpg',
    explorer: 'https://blastscan.io',
  },
  {
    chain: {
      id: 56,
      name: 'BNB Smart Chain',
      nativeCurrency: {
        decimals: 18,
        name: 'BNB',
        symbol: 'BNB',
      },
      rpcUrls: {
        default: {
          http: ['https://rpc.ankr.com/bsc'],
        },
      },
      blockExplorers: {
        default: {
          name: 'BscScan',
          url: 'https://bscscan.com',
          apiUrl: 'https://api.bscscan.com/api',
        },
      },
      contracts: {
        multicall3: {
          address: '0xca11bde05977b3631167028862be2a173976ca11',
          blockCreated: 15921452,
        },
      },
    },
    type: 'EVM',
    name: 'BNB Smart Chain',
    icon2: 'https://icons.llamao.fi/icons/chains/rsz_binance.jpg',
    icon: 'https://cryptologos.cc/logos/bnb-bnb-logo.svg',
    explorer: 'https://bscscan.com',
  },
  {
    chain: {
      id: 59144,
      name: 'Linea Mainnet',
      nativeCurrency: {
        name: 'Linea Ether',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrls: {
        default: {
          http: ['https://rpc.linea.build'],
          webSocket: ['wss://rpc.linea.build'],
        },
      },
      blockExplorers: {
        default: {
          name: 'Etherscan',
          url: 'https://lineascan.build',
          apiUrl: 'https://api.lineascan.build/api',
        },
      },
      contracts: {
        multicall3: {
          address: '0xcA11bde05977b3631167028862bE2a173976CA11',
          blockCreated: 42,
        },
      },
      testnet: false,
    },
    type: 'EVM',
    name: 'Linea Mainnet',
    icon: 'https://icons.llamao.fi/icons/chains/rsz_linea.jpg',
    explorer: 'https://lineascan.build',
  },
  {
    chain: {
      id: 1,
      name: 'Ethereum',
      nativeCurrency: {
        name: 'Ether',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrls: {
        default: {
          http: ['https://cloudflare-eth.com'],
        },
      },
      blockExplorers: {
        default: {
          name: 'Etherscan',
          url: 'https://etherscan.io',
          apiUrl: 'https://api.etherscan.io/api',
        },
      },
      contracts: {
        ensRegistry: {
          address: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
        },
        ensUniversalResolver: {
          address: '0xce01f8eee7E479C928F8919abD53E553a36CeF67',
          blockCreated: 19258213,
        },
        multicall3: {
          address: '0xca11bde05977b3631167028862be2a173976ca11',
          blockCreated: 14353601,
        },
      },
    },
    type: 'EVM',
    name: 'Ethereum',
    icon2: 'https://icons.llamao.fi/icons/chains/rsz_ethereum.jpg',
    icon: 'https://cryptologos.cc/logos/ethereum-eth-logo.svg',
    explorer: 'https://etherscan.io',
  },
  {
    chain: {
      formatters: {
        block: {
          type: 'block',
        },
        transaction: {
          type: 'transaction',
        },
        transactionReceipt: {
          type: 'transactionReceipt',
        },
      },
      serializers: {},
      contracts: {
        gasPriceOracle: {
          address: '0x420000000000000000000000000000000000000F',
        },
        l1Block: {
          address: '0x4200000000000000000000000000000000000015',
        },
        l2CrossDomainMessenger: {
          address: '0x4200000000000000000000000000000000000007',
        },
        l2Erc721Bridge: {
          address: '0x4200000000000000000000000000000000000014',
        },
        l2StandardBridge: {
          address: '0x4200000000000000000000000000000000000010',
        },
        l2ToL1MessagePasser: {
          address: '0x4200000000000000000000000000000000000016',
        },
        disputeGameFactory: {
          '1': {
            address: '0xe5965Ab5962eDc7477C8520243A95517CD252fA9',
          },
        },
        l2OutputOracle: {
          '1': {
            address: '0xdfe97868233d1aa22e815a266982f2cf17685a27',
          },
        },
        multicall3: {
          address: '0xca11bde05977b3631167028862be2a173976ca11',
          blockCreated: 4286263,
        },
        portal: {
          '1': {
            address: '0xbEb5Fc579115071764c7423A4f12eDde41f106Ed',
          },
        },
        l1StandardBridge: {
          '1': {
            address: '0x99C9fc46f92E8a1c0deC1b1747d010903E884bE1',
          },
        },
      },
      id: 10,
      name: 'OP Mainnet',
      nativeCurrency: {
        name: 'Ether',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrls: {
        default: {
          http: ['https://mainnet.optimism.io'],
        },
      },
      blockExplorers: {
        default: {
          name: 'Optimism Explorer',
          url: 'https://optimistic.etherscan.io',
          apiUrl: 'https://api-optimistic.etherscan.io/api',
        },
      },
      sourceId: 1,
    },
    type: 'EVM',
    name: 'OP Mainnet',
    icon2: 'https://icons.llamao.fi/icons/chains/rsz_optimism.jpg',
    icon: 'https://cryptologos.cc/logos/optimism-ethereum-op-logo.svg',
    explorer: 'https://optimistic.etherscan.io',
  },
  {
    chain: {
      id: 137,
      name: 'Polygon',
      nativeCurrency: {
        name: 'MATIC',
        symbol: 'MATIC',
        decimals: 18,
      },
      rpcUrls: {
        default: {
          http: ['https://polygon-rpc.com'],
        },
      },
      blockExplorers: {
        default: {
          name: 'PolygonScan',
          url: 'https://polygonscan.com',
          apiUrl: 'https://api.polygonscan.com/api',
        },
      },
      contracts: {
        multicall3: {
          address: '0xca11bde05977b3631167028862be2a173976ca11',
          blockCreated: 25770160,
        },
      },
    },
    type: 'EVM',
    name: 'Polygon',
    icon2: 'https://icons.llamao.fi/icons/chains/rsz_polygon.jpg',
    icon: 'https://cryptologos.cc/logos/polygon-matic-logo.svg',
    explorer: 'https://polygonscan.com',
  },
  {
    chain: {
      id: 534352,
      name: 'Scroll',
      nativeCurrency: {
        name: 'Ether',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrls: {
        default: {
          http: ['https://rpc.scroll.io'],
          webSocket: ['wss://wss-rpc.scroll.io/ws'],
        },
      },
      blockExplorers: {
        default: {
          name: 'Scrollscan',
          url: 'https://scrollscan.com',
          apiUrl: 'https://api.scrollscan.com/api',
        },
      },
      contracts: {
        multicall3: {
          address: '0xca11bde05977b3631167028862be2a173976ca11',
          blockCreated: 14,
        },
      },
      testnet: false,
    },
    type: 'EVM',
    name: 'Scroll',
    icon: 'https://scrollscan.com//assets/scroll/images/svg/logos/chain-light.svg',
    explorer: 'https://scrollscan.com',
  },
];

export function ChainSelect(props: {
  open: boolean;
  onSelect?: (chain: IWeb3ChainType) => void;
  onCancel: () => void;
}) {
  const [search, setSearch] = useState('');

  // const { chains: list } = useChains();

  // (list as IWeb3ChainType[]).unshift({
  //   name: 'TON',
  //   icon: '',
  //   type: 'TON',
  //   chain: {
  //     id: 1100,
  //   } as any,
  // });

  const chains = (list as IWeb3ChainType[])
    .filter((chain) => {
      return blockchainNumbers.includes(chain.chain?.id || getSafeChainId(chain)!);
    })
    .filter((chain) => {
      if (chain.type === 'EVM' || chain.type === 'SOL') {
        if (search) {
          if (
            chain.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
            chain.chain?.id === +search
          ) {
            return true;
          } else {
            return false;
          }
        }

        return true;
      }

      // TODO Confirm supported chains
      return false;
    });

  useEffect(() => {
    if (props.open) {
      setSearch('');
    }
  }, [props.open]);

  return (
    <SaDrawer titleCenter title="Select chain" open={props.open} onClose={props.onCancel}>
      <div>
        <Input
          placeholder="Search chain"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        ></Input>
      </div>

      <div className="[&_.g6-list-item]:!border-0">
        <List
          dataSource={chains}
          renderItem={(asset) => (
            <List.Item>
              <Item
                asset={asset}
                onClick={() => {
                  props.onSelect?.(asset);
                }}
              ></Item>
            </List.Item>
          )}
        />
      </div>
    </SaDrawer>
  );
}
