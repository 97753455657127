import { follow } from '@/service/follows';
import { useRequest } from 'ahooks';

//关注用户
export function useFollowUser({
  onSuccess,
}: {
  onSuccess?: () => void;
} = {}) {
  return useRequest(
    async (userId: string) => {
      const res = await follow(userId!);

      return res.data;
    },
    {
      manual: true,
      onSuccess() {
        onSuccess?.();
      },
    },
  );
}
