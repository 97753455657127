import { Address, beginCell, toNano } from '@ton/core';
import { encodeJettonContent } from '../scripts/jettonContentEncode';
import { encodeJettonSupplementContent } from '../scripts/jettonSupplementContentEncode';
import { Opcodes } from '../wrappers/G6Router';

type LaunchProps = {
  name: string;
  symbol: string;
  description: string;
  image: string;
  twitter?: string;
  telegram?: string;
  website?: string;
  decimals?: string;
} & {
  init_launch_jetton_amount: bigint;
  jetton_amount_to_reserve: bigint;
  ton_to_pay_for_init_buy?: bigint;
  query_id: bigint;
  ton_to_add_liquidity: bigint;
  k: bigint;
  x0: bigint;
  y0: bigint;
  // jetton_content: Cell;
  // jetton_supplement_content: Cell;
};

export function getLaunchParam(props: LaunchProps) {
  const { name, symbol, description, image, twitter, telegram, website, decimals } = props;

  let jettonContent = encodeJettonContent({
    name,
    symbol,
    description,
    image,
    decimals,
  });

  let jettonSupplementContent = encodeJettonSupplementContent({
    twitter: twitter || '',
    telegram: telegram || '',
    website: website || '',
  });

  const body = beginCell()
    .storeUint(Opcodes.op_launch, 32)
    .storeRef(jettonContent)
    .storeRef(
      beginCell().storeUint(props.k, 128).storeUint(props.x0, 64).storeUint(props.y0, 64).endCell(),
    )
    .storeRef(
      beginCell()
        .storeCoins(props.init_launch_jetton_amount)
        .storeCoins(props.jetton_amount_to_reserve)
        .storeCoins(props.ton_to_add_liquidity)
        .endCell(),
    )
    .storeRef(jettonSupplementContent)
    .storeCoins(props.ton_to_pay_for_init_buy ?? 0n)
    .storeUint(props.query_id ?? 0n, 64)
    .endCell();

  return { body };
}
