import { useFilter } from '@/hooks/useFilter';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useFetchCoinList } from './useFetchCoinList';
import { WebSocketContext } from '@/context/WebSocketContext';
import { Coin } from '@/types/Coin';
import { uniqBy, take } from 'lodash';

export function useFetchTonCoinList({ enabled }: { enabled: boolean }) {
  const { query } = useFilter();

  const { data, loading, loadMore } = useFetchCoinList({
    createdTimestampStart: +query.createdTimestampStart,
    search: query.search,
    filter: query.filter,
    sort: query.sort,
    size: +query.size,
    enabled,
  });

  const [socketData, setSocketData] = useState<Coin[]>([]);

  // const { data: g6Item } = useFetchG6();

  const { onMessage } = useContext(WebSocketContext);

  const list = useMemo(() => {
    if (!enabled) return [];

    const val = uniqBy([...socketData, ...(data?.list || [])], 'poolAddress').filter(
      (item) => !!item.poolAddress,
    );

    // if (g6Item && !inSearch) {
    //   val.unshift(g6Item);
    // }

    return take(val, 60);
  }, [enabled, socketData, data?.list]);

  useEffect(() => {
    if (!enabled) return;

    const event = onMessage?.((e) => {
      if (e.type === 'launch') {
        e.data._id = `${+new Date()}`;

        setSocketData((prev) => {
          return [e.data, ...prev];
        });
      }
    });

    return () => {
      event?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled]);

  return { list, data, loading, loadMore };
}
