import { getBlockchainClient } from '@/contract/utils/client';
import { toInternalAddress, toUserFriendlyAddress } from '@/utils';
import { Address, ContractProvider } from '@ton/core';
import { useTonAddress, useTonConnectUI } from '@tonconnect/ui-react';
import { useRequest } from 'ahooks';
import { useEffect, useMemo } from 'react';
import { displayAddress as parseDisplayAddress } from '../utils/ton';

export function useTonConnectWallet(
  { address, selfProvider }: { address?: string; selfProvider?: boolean } = { selfProvider: false },
) {
  const userAddress = useTonAddress();

  const [tonConnectUI] = useTonConnectUI();

  const isConnect = useMemo(() => {
    return !!userAddress;
  }, [userAddress]);

  const displayAddress = useMemo(() => {
    return parseDisplayAddress(userAddress);
  }, [userAddress]);

  const { data: provider } = useRequest(
    async () => {
      const BlockchainClient = await getBlockchainClient();

      // use any to avoid type error, the type ContractProvider here is different from the wrapper
      const provider = BlockchainClient.provider(Address.parse(address!), null);

      return provider as ContractProvider;
    },
    {
      ready: (isConnect && !!address) || selfProvider,
      refreshDeps: [isConnect, address],
      onError(err) {
        console.log('provider-err', err);
      },
    },
  );

  const connect = () => {
    tonConnectUI.openModal();
  };

  const disconnect = () => {
    tonConnectUI.connector?.disconnect?.();
  };

  // api use this address
  const internalAddress = useMemo(() => {
    try {
      return toInternalAddress(userAddress);
    } catch (err) {
      return '';
    }
  }, [userAddress]);

  const userFriendlyAddress = useMemo(() => {
    try {
      return toUserFriendlyAddress(userAddress);
    } catch (err) {
      return '';
    }
  }, [userAddress]);

  useEffect(() => {
    window.tonConnectUI = tonConnectUI;
  }, [tonConnectUI]);

  function isSelf(address: string) {
    return isSameAddress({ address1: userAddress, address2: address });
  }

  function isSameAddress({ address1, address2 }: { address1: string; address2: string }) {
    return toInternalAddress(address1) === toInternalAddress(address2);
  }

  return {
    isConnect,
    provider,
    address: userAddress,
    displayAddress,
    disconnect,
    connect,
    internalAddress,
    userFriendlyAddress,
    tonConnectUI,
    isSelf,
    isTomoWallet: false,
  };
}
