import React, { useMemo } from 'react';
import { getTimeAgo, TonTradeTable } from './TonTradeTable';
import { useCoinDetailStore } from '../../context/CoinDetailContext';
import { SolTradeTable } from './SolTradeTable';
import { G6Math } from '@/contract/utils/Math';
import { useAppRouter } from '@/hooks/router/useAppRouter';
import { SolCoinTradeItem } from '@/service/sol-coin';
import { formatByDecimals } from '@/utils/sol';
import { ColumnType } from 'antd/es/table';
import numeral from 'numeral';
import { TradeHistoryItem } from '@/service/trade';
import { useNativeToken } from '@/hooks/app/useNativeToken';
import { fromNano } from '@ton/core';
import { formatNumber } from '@/views/Home/modules/FlyV2';

function parseNano(val: number) {
  try {
    return fromNano(val);
  } catch (e) {
    return '-';
  }
}

const TradeTable = () => {
  const { isTonData, detail } = useCoinDetailStore();

  const { goCreatorDetail } = useAppRouter();

  const [nativeName] = useNativeToken();

  const columns = useMemo<ColumnType<SolCoinTradeItem | TradeHistoryItem>[]>(() => {
    const isTonTradeItem = (v: SolCoinTradeItem | TradeHistoryItem): v is TradeHistoryItem => {
      return isTonData(detail);
    };

    return [
      {
        title: 'Date',
        render(text, record) {
          return <span className="break-all">{getTimeAgo(record.timestamp)}</span>;
        },
      },

      {
        title: 'Type',
        align: 'center',
        render(text, record) {
          const isBuy = isTonTradeItem(record) ? record.isBuy : record.is_buy;
          return (
            <span className={isBuy ? 'text-[#17CF82]' : 'text-[#F54976]'}>
              {isBuy ? 'Buy' : 'Sell'}
            </span>
          );
        },
      },

      {
        title: nativeName,
        align: 'center',
        render(text, record) {
          const amount = isTonTradeItem(record) ? record.tonAmount : record.sol_amount;
          return (
            <span className="break-all">
              {formatNumber(
                G6Math.from(amount)
                  .divide(10 ** 9)
                  .toString(),
              )}
            </span>
          );
        },
      },
      {
        title: detail?.symbol,
        ellipsis: true,
        align: 'center',
        render(text, record) {
          return (
            <span className="break-all">
              {isTonTradeItem(record)
                ? numeral(+parseNano(record.jettonAmount)).format('0.00a')
                : numeral(formatByDecimals(record.token_amount)).format('0.00a')}
            </span>
          );
        },
      },
      {
        title: 'Account',
        dataIndex: 'address',
        key: 'address',
        align: 'right',
        render(text, record) {
          return (
            <span
              className="break-all cursor-pointer"
              onClick={() => {
                goCreatorDetail(record.user);
              }}
            >
              {/* {isTonTradeItem(record) ? displayAddress(record.user) : record.user.slice(0, 6)} */}
              {record.user?.slice(0, 6)}
            </span>
          );
        },
      },
    ];
  }, [detail, goCreatorDetail, isTonData, nativeName]);

  return (
    <div>
      {isTonData(detail) ? (
        <TonTradeTable columns={columns as ColumnType<TradeHistoryItem>[]} />
      ) : (
        <SolTradeTable columns={columns as ColumnType<SolCoinTradeItem>[]} />
      )}
    </div>
  );
};

export default TradeTable;
