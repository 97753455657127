import { useGlobalDataStore } from '@/context/GlobalDataContext';
import { ConfigProvider, Form, Input } from 'antd';
import { useEffect, useState } from 'react';
import { SaDrawer } from '../SaDrawer';

// const labelStyle: React.CSSProperties = {
//   width: '33.33%',
//   textAlign: 'center',
// };

// const options = [
//   {
//     label: '0.5%',
//     value: '0.5',
//     style: labelStyle,
//   },
//   { label: '1%', value: '1', style: labelStyle },
//   { label: '2%', value: '2', style: labelStyle },
// ];

export function SlippageModal({ children }: { children: React.ReactNode }) {
  const [open, setOpen] = useState(false);

  const [form] = Form.useForm();

  const { slippage, setSlippage } = useGlobalDataStore();

  useEffect(() => {
    if (open) {
      if (slippage) {
        form.setFieldsValue({
          slippage,
          slippageRadio: slippage,
        });
      }
    } else {
      const value = form.getFieldValue('slippage')?.replace(/[^\d.]/g, '');

      if (value) {
        setSlippage?.(+value >= 0.005 ? value : '0.01');
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const onBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^\d.]/g, '');

    form.setFieldsValue({
      slippage: value,
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      // slippage: ,
    });
  }, [form, slippage]);

  return (
    <>
      <div
        onClick={() => {
          setOpen(true);
        }}
      >
        {children}
      </div>
      <SaDrawer
        title="Set max slippage"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        height="45%"
      >
        <div className="w-full mt-[18px]">
          <ConfigProvider
            theme={{
              components: {
                Input: {
                  controlHeight: 50,
                },
              },
            }}
          >
            <Form layout="vertical" form={form} autoComplete="off">
              <Form.Item
                className="!mb-0"
                label={<div className="text-base text-primaryText font-normal">Max slippage</div>}
                name="slippage"
                rules={[{ required: true, message: 'Recommended range is 0.5%' }]}
              >
                <Input
                  onChange={onBlur}
                  onBlur={onBlur}
                  className="pl-[22px] text-primaryText"
                  suffix="%"
                />
              </Form.Item>
              <div className="text-[#9B9B9B] text-xs mt-[12px] font-normal">
                This is the maximum amount of slippage you are willing toaccept when placing trades.
              </div>
              {/* 
              <div className="my-[20px] text-base">
                <div>Enable front-running protection</div>
              </div>

              <Form.Item
                label={<div className="text-base">Priority fee</div>}
                name="fee"
                rules={[{ required: true, message: 'Recommended range is 0.5%' }]}
              >
                <Input
                  onChange={onBlur}
                  onBlur={onBlur}
                  className="pl-[22px] text-primaryText"
                  suffix="%"
                />
                <div className="text-secondaryText text-xs  mt-[12px]">
                  A higher priority fee will make your transactions confirm faster. This is the
                  transaction fee that the network charges for each trade.
                </div>
              </Form.Item> */}
            </Form>
          </ConfigProvider>
        </div>
      </SaDrawer>
    </>
  );
}
