import { useParseRouterQuery } from './router/useParseRouterQuery';

export function useFilter() {
  const { query } = useParseRouterQuery({
    createdTimestampStart: '',
    search: '',
    filter: '',
    sort: '',
    size: '60',
  });

  return {
    query,
  };
}
