export function AppImage({
  src,
  className,
  style,
}: {
  src?: string;
  className: string;
  style?: any;
}) {
  return <img src={src || '/logo.png'} className={className} style={style} />;
}
