import { Connection, LAMPORTS_PER_SOL } from '@solana/web3.js';

export const getSolanaConnection = () => {
  return new Connection(
    'https://chaotic-frosty-lambo.solana-mainnet.quiknode.pro/11c486c987b01f531cf26d748893b2beb2870165',
  );
};

export function lamportsToSol(lamports: number) {
  return lamports / LAMPORTS_PER_SOL;
}

// 转换 SOL 为 lamports
export function solToLamports(sol: number) {
  return sol * LAMPORTS_PER_SOL;
}

// 格式化 SOL 金额（指定小数位数）
export function formatSol(amount: number, decimals = 2) {
  return lamportsToSol(amount).toFixed(decimals);
}

export function formatByDecimals(amount: number, decimals: number = 6) {
  return amount / 10 ** decimals;
}

export function formatAddress(address?: string) {
  return address?.slice(0, 6);
}
