import icon from '@/assets/icon/app/tomo-alert.png';
import { SaDrawer } from '@/components/SaDrawer';
import { ConfigProvider, QRCode } from 'antd';
import { useState } from 'react';
import { Button as AntButton } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { useAppRouter } from '@/hooks/router/useAppRouter';

function Button({ icon, text, onClick }: { icon: any; text: any; onClick?: any }) {
  return (
    <div
      className="w-full h-[48px] flex justify-center items-center bg-white rounded-lg text-black text-base font-bold mb-3 active:opacity-90"
      onClick={onClick}
    >
      <img src={icon} />
      {text}
    </div>
  );
}

const isAndroid = () => {
  return /Android/i.test(navigator.userAgent);
};

export function MnemonicPhrase() {
  const { goBack } = useAppRouter();

  return (
    <div className=" flex flex-col justify-center items-center gap-y-6 p-[22px]">
      <ConfigProvider
        theme={{
          components: {
            Button: {
              controlHeight: 36,
            },
          },
        }}
      >
        <AntButton
          type="text"
          className="text-secondaryText ml-[-15px] mr-auto"
          onClick={() => {
            goBack();
          }}
        >
          <LeftOutlined /> Back
        </AntButton>
      </ConfigProvider>
      <img src={icon} />

      <div className="text-secondaryText text-sm">
        If your mnemonic phrase is exposed, your crypto assets are at risk of being stolen. Anyone
        with access to this phrase can fully control, transfer, or spend your assets without your
        consent.Please use either the Tomo App or Tomo Extension with the same social login method
        to securely export your mnemonic phrase.
      </div>

      <div className="w-full mt-[30px]">
        {isAndroid() ? (
          <>
            {/* <Button
              onClick={() => {
                window.open('https://play.google.com/store/apps/details?id=tomo.app.unyx');
              }}
              icon={'/static/images/az.svg'}
              text="Apple store"
            ></Button> */}

            <Button
              onClick={() => {
                window.open('https://play.google.com/store/apps/details?id=tomo.app.unyx');
              }}
              icon={'/static/images/googlepay.svg'}
              text="Google Pay"
            ></Button>
          </>
        ) : (
          <Button
            onClick={() => {
              window.open('https://apps.apple.com/us/app/tomo-inc/id6468010287');
            }}
            icon={'/static/images/apple.svg'}
            text="Apple store"
          ></Button>
        )}
      </div>
    </div>
  );
}

export function DownloadQr(props: { url?: string; children?: any }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="w-full">{props.children}</div>

      <SaDrawer
        title="Scan to download"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        height="auto"
      >
        <div className="w-full">
          <div className="py-6 flex justify-center">
            <QRCode value={props.url || '-'} bgColor="#FFF" color="#000" />
          </div>
        </div>
      </SaDrawer>
    </>
  );
}
