import request from '@/request';
import { ListQuery } from '@/types/App';
import { Coin, GraduatedCoin } from '@/types/Coin';
import { MessageData } from '@/types/Message';
import { TomoTokenItem } from '@/types/Tomo';

type ListPayload = ListQuery<{
  createdTimestampEnd?: number;
  createdTimestampStart?: number;
  nsfw?: boolean;
  search?: string;
  filter?: string;
  sort?: string;
}>;

type GraduatedListPayload = ListQuery<{
  search?: string;
  filter?: string;
  sort?: string;
  order?: string;
}>;

export function fetchCoinList({
  current,
  size,
  createdTimestampEnd,
  createdTimestampStart,
  nsfw,
  search,
  filter,
  sort,
}: ListPayload) {
  return request.get<Ajax.ListResponse<Coin>>('/coin', {
    current,
    size,
    createdTimestampEnd,
    createdTimestampStart,
    nsfw,
    search,
    filter,
    sort,
  });
}

export function fetchCoinDetail(poolAddress: string) {
  return request.get<Coin>(`/coin/${poolAddress}`);
}

export function fetchInitialBroadcast() {
  return request.get<MessageData[]>(`/coin/broadcast`);
}

export function fetchG6List(payload: ListPayload) {
  return request.get(`/coin/g6List`, payload);
}

export function fetchG6Graduated(payload: GraduatedListPayload) {
  return request.get<Ajax.ListResponse<GraduatedCoin>>(`/coin/graduated`, payload);
}

export function fetchG6() {
  return request.get<Coin>(`/coin/g6`);
}

export function fetchAllToken(payload: {
  page?: number;
  limit?: number;
  keyword?: string;
  chain?: string;
  chainId?: number;
}) {
  return request.get<Ajax.ListResponse<TomoTokenItem>>(`/tokens`, payload);
}

export function fetchProfileToken() {
  return request.get<TomoTokenItem[]>(`/tokens/tomo/holding`, null);
}

export type FetchCoinPayload = {
  queryId: number;
  name: string;
  ticker: string;
};

export async function fetchCoinResult({ queryId, name, ticker }: FetchCoinPayload) {
  const res = await request.get<Coin>(`/coin/result/${queryId}?name=${name}&symbol=${ticker}`);
  return res.data;
}
