import { AppImage } from '@/components/AppImage';
import classNames from 'classnames';

export function DetailField({
  label,
  value,
  onClick,
}: {
  label: any;
  value?: any;
  onClick?: () => void;
}) {
  return (
    <div className="w-full flex justify-between items-start" onClick={() => onClick?.()}>
      <div className="text-secondaryText">{label}</div>
      <div className="text-primaryText max-w-[280px] break-all text-right">{value || '-'}</div>
    </div>
  );
}

export function ImageField({
  src,
  label,
  className,
  size = 24,
  onClick,
  suffix,
  labelClassName,
}: {
  src?: string;
  label?: string;
  className?: string;
  size?: number;
  onClick?: () => void;
  suffix?: any;
  labelClassName?: string;
}) {
  return (
    <div
      className={classNames('flex justify-start items-center gap-x-[5px]', className)}
      onClick={() => {
        onClick?.();
      }}
    >
      <AppImage
        src={src}
        className="rounded-full"
        style={{
          width: `${size}px`,
          height: `${size}px`,
        }}
      />
      <span
        className={classNames('text-primaryText', labelClassName)}
        style={{
          fontSize: `${size}px`,
        }}
      >
        {label}
      </span>

      {suffix}
    </div>
  );
}
