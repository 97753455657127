import { fetchG6 } from '@/service/coin';
import { useRequest } from 'ahooks';

export function useFetchG6() {
  const hook = useRequest(
    async () => {
      const res = await fetchG6();

      if (res.data) {
        // res.data._id = `${+new Date()}`;
        res.data.isG6 = true;
        res.data.isImportant = true;
      }

      return res.data;
    },
    {
      pollingInterval: 60 * 1000,
    },
  );

  return hook;
}
