import { isSameAddress } from '@/hooks/useTomoWallet';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { TomoWalletTgSdkV2 } from '@tomo-inc/tomo-telegram-sdk';
import {
  useIsConnectionRestored,
  useTonAddress,
  useTonConnectUI,
  useTonWallet,
} from '@tonconnect/ui-react';
import { useAsyncEffect, useSessionStorageState } from 'ahooks';
import { storageKeys } from '@/constants/storage';
import { isEmpty } from 'lodash';
import { useAppMessage } from '@/hooks/useAppMessage';
import { generateTonProofPayload } from '@/service/proof';
import { useCreateWallet } from '@/hooks/app/user/useCreateWallet';
import { useSwitchUserWallet } from '@/hooks/app/user/useSwitchUserWallet';
import { useUserStore } from './useUserStore';
import { SignedProofPayload } from '@/service/wallets';
import { Spin } from 'antd';

new TomoWalletTgSdkV2({ injected: true });
const tomo_sol = window?.tomo_sol;
const tomo_ton = window?.tomo_ton;

export enum WalletType {
  TOMO,
  TON,
}

export enum ChainType {
  TON = 'TON',
  SOLANA = 'SOLANA',
}

type WalletInfoContextType = {
  isConnect: boolean;
  chainType?: ChainType;
  isTomoWallet: boolean;
  walletType?: WalletType;
  walletAddress: string;
  isTon: boolean;
  connectLoading: boolean;
  connectTomoTonWallet: () => void;
  connectTomoSolWallet: () => void;
  disconnect: () => void;
  isSelf: (v?: string) => boolean;
};

export const WalletInfoContext = createContext<WalletInfoContextType>({
  isConnect: false,
  chainType: undefined,
  walletAddress: '',
  isTomoWallet: false,
  walletType: undefined,
  isTon: false,
  connectLoading: false,
  connectTomoTonWallet: () => {},
  connectTomoSolWallet: () => {},
  disconnect: () => {},
  isSelf: () => false,
});

type WalletInfo = {
  walletAddress: string;
  walletType?: WalletType;
  chainType?: ChainType;
  isTon: boolean;
};

export const useWalletInfoStore = () => useContext(WalletInfoContext);

export const useProvideWalletInfo = (): WalletInfoContextType => {
  const [walletInfo, setWalletInfo] = useSessionStorageState<WalletInfo>(storageKeys.walletInfo);
  const walletAddress = walletInfo?.walletAddress;
  const walletType = walletInfo?.walletType;
  const chainType = walletInfo?.chainType;
  const tonAddress = useTonAddress();
  const [connectLoading, setConnectLoading] = useState(false);
  const [tonConnectUI] = useTonConnectUI();
  const message = useAppMessage();
  const isConnectionRestored = useIsConnectionRestored();
  const wallet = useTonWallet();
  const { runAsync: switchWallet } = useSwitchUserWallet();
  const { userWallets } = useUserStore();

  const isTon = useMemo(() => chainType === ChainType.TON, [chainType]);
  const isTomoWallet = useMemo(() => walletType === WalletType.TOMO, [walletType]);

  const setAboutWallet = useCallback(
    (address: string, walletType?: WalletType, chainType?: ChainType) => {
      setWalletInfo({
        walletAddress: address,
        walletType,
        chainType,
        isTon,
      });
    },
    [isTon, setWalletInfo],
  );

  const disconnect = () => {
    if (isTomoWallet) {
      if (chainType === ChainType.TON) {
        tomo_ton.disconnect();
      } else if (chainType === ChainType.SOLANA) {
        tomo_sol.disconnectWallet();
      }
    } else {
      tonConnectUI.connector?.disconnect?.();
    }
    setAboutWallet('', undefined, undefined);
  };

  const { run: createWallet } = useCreateWallet({ disconnect });

  useEffect(() => {
    // tonConnectUi连接成功时触发
    if (tonAddress) {
      setAboutWallet(tonAddress, WalletType.TON, ChainType.TON);
    }
  }, [setAboutWallet, tonAddress]);

  useAsyncEffect(async () => {
    if (!walletInfo || isEmpty(walletInfo) || !walletInfo?.walletAddress) {
      // 如果缓存里没有存钱包地址 去钱包处查询
      if (tonAddress) {
        setAboutWallet(tonAddress, WalletType.TON, ChainType.TON);
      } else if (tomo_ton.isConnected) {
        setAboutWallet(tomo_ton.account.address, WalletType.TOMO, ChainType.TON);
      } else if (tomo_sol.isConnected) {
        setAboutWallet(tomo_sol.account.address, WalletType.TOMO, ChainType.SOLANA);
      }
    }
  }, []);

  // 判断是否已有对应钱包 有的话直接切换过去 没有的话就新建
  const handleWallet = useCallback(
    (payload: SignedProofPayload) => {
      const walletInfo = userWallets?.find((item) => item.address === tonAddress);
      if (walletInfo) {
        switchWallet({ walletId: walletInfo._id });
      } else {
        createWallet(payload);
      }
    },
    [createWallet, switchWallet, tonAddress, userWallets],
  );

  useEffect(() => {
    // 验证ton-proof
    if (isConnectionRestored) {
      if (wallet?.connectItems?.tonProof && !('error' in wallet.connectItems.tonProof)) {
        const payload = {
          address: wallet.account.address,
          network: wallet.account.chain,
          proof: {
            ...wallet.connectItems.tonProof.proof,
            state_init: wallet.account.walletStateInit,
          },
        };

        handleWallet(payload);
      }
    }
  }, [handleWallet, isConnectionRestored, wallet]);

  const connectTomoTonWallet = async () => {
    try {
      setConnectLoading(true);
      console.log('tomo_ton', tomo_ton);
      const { payload } = await generateTonProofPayload();
      console.log('payload', payload);
      const res = await tomo_ton.connect({
        tonProof: payload,
      });

      console.log('res', res);
      const proof = res?.tonProof.proof;
      const walletPayload = {
        address: res.address,
        network: '-239',
        proof: {
          ...proof,
          domain: {
            ...proof.domain,
            lengthBytes: Number(proof.domain.lengthBytes),
          },
          timestamp: Number(proof.timestamp),
          state_init: '',
        },
      };
      handleWallet(walletPayload);
      setAboutWallet(res.address, WalletType.TOMO, ChainType.TON);
      setConnectLoading(false);
    } catch (err) {
      setConnectLoading(false);
      console.error('connect ton err', err);
      message.error('Some errors have occurred, please try again');
    }
  };

  const connectTomoSolWallet = async () => {
    try {
      setConnectLoading(true);
      console.log('tomo_sol', tomo_sol);
      const res = await tomo_sol.connectWallet();
      console.log('res', res);
      setAboutWallet(res.address, WalletType.TOMO, ChainType.SOLANA);
      setConnectLoading(false);
    } catch (err) {
      setConnectLoading(false);
      console.error(err);
      message.error('Some errors have occurred, please try again');
    }
  };

  const isSelf = (address?: string) => {
    if (chainType === ChainType.TON) {
      if (!walletInfo?.walletAddress) return false;
      return isSameAddress({ address1: walletInfo.walletAddress, address2: address });
    } else {
      return address === walletAddress;
    }
  };

  return {
    isConnect: !!walletAddress,
    chainType,
    walletType,
    isTomoWallet,
    walletAddress: walletAddress || '',
    isTon,
    connectLoading,
    connectTomoTonWallet,
    connectTomoSolWallet,
    disconnect,
    isSelf,
  };
};

const WalletInfoProvider = ({ children }: { children: React.ReactNode }) => {
  const data = useProvideWalletInfo();

  return (
    <WalletInfoContext.Provider value={data}>
      <Spin spinning={data.connectLoading} fullscreen />
      {children}
    </WalletInfoContext.Provider>
  );
};

export default WalletInfoProvider;
