import request from '@/request';
import { Tasks } from '@/types/Tasks';

export async function fetchTasksStats() {
  const res = await request.get<Tasks>(`/tasks/stats`, null);
  return res.data;
}

export function updateTaskStatus(taskName: string) {
  return request.post(`/tasks/complete`, { taskName });
}
