import { Button } from '../Button';
import { SaDrawer } from '../SaDrawer';
import copyRightIcon2 from '@/assets/icon/icon_copyRight2.svg';
import { TomoTokenItem } from '@/types/Tomo';
import { ConfigProvider, Input, Typography } from 'antd';
import { useMemo, useState } from 'react';
import { useSendTransactionByTomo } from '@/hooks/useSendTransactionByTomo';
import { G6Math } from '@/contract/utils/Math';
import { useAppMessage } from '@/hooks/useAppMessage';

const { TextArea } = Input;

const { Paragraph } = Typography;

export declare type BTCNetworkAddressType = 'P2PKH' | 'P2WPKH' | 'P2TR' | 'P2SH';

export function getBitcoinAddressType(address: string): BTCNetworkAddressType | undefined {
  if (/^1[0-9A-Za-z]{25,34}$/.test(address)) {
    return 'P2PKH';
  } else if (/^3[0-9A-Za-z]{25,34}$/.test(address)) {
    return 'P2SH';
  } else if (/^bc1q[0-9a-z]{38,59}$/.test(address)) {
    return 'P2WPKH';
  } else if (/^bc1p[0-9a-z]{58}$/.test(address)) {
    return 'P2TR';
  } else {
    return;
  }
}

export function SendConfirm(props: {
  token?: TomoTokenItem;
  open: boolean;
  walletAddress?: string;
  amount: string;
  onCancel: () => void;
  onSuccess: () => void;
  to: string;
}) {
  const { token, walletAddress, amount } = props;

  const message = useAppMessage();

  const { send, loading } = useSendTransactionByTomo({
    to: props.to,
    token: token!,
    amount,
    walletAddress: walletAddress!,
    onSendSuccess() {
      props.onSuccess?.();
    },
  });

  const handleSend = () => {
    if (!props.to) {
      message.error('Please input recipient’s address');
      return;
    }

    send({}).catch(() => null);
  };

  const price = useMemo(() => {
    try {
      return G6Math.from(amount).multiply(token!.priceUsd).toString();
    } catch (e) {
      return '';
    }
  }, [token, amount]);

  return (
    <SaDrawer title="Send" open={props.open} onClose={props.onCancel} height="auto">
      <div className="w-full">
        <div className="text-center bg-[#1e1e1e] align-center py-2 rounded mb-2">
          <div className="text-primaryText font-bold">
            <span className="text-[28px] leading-[42px] mb-1.5 mr-1">{amount}</span>
            <span className="text-[16px] leading-6">{token?.symbol}</span>
          </div>
          <div className="text-[16px] leading-6 text-[#9B9B9B]">${price}</div>
        </div>
        <div className="bg-[#1e1e1e] align-center pt-5 pb-2 rounded text-[#9B9B9B] text-[14px] leading-[18px] px-4 font-normal">
          <div className="flex items-center justify-between mb-3">
            <span>Network</span>
            <span className="px-2 py-[3px] text-[12px] rounded text-primaryText">
              {token?.chain}
            </span>
          </div>
          <div className="flex items-center justify-between my-3">
            <span>Form</span>
            <div className="flex items-center">
              <div className="text-[14px] text-primaryText leading-5 break-words max-w-[168px]">
                {walletAddress}
              </div>

              <Paragraph
                className="truncate  break-all text-secondaryText [&_path]:fill-secondaryText"
                copyable={{
                  text: walletAddress,
                }}
              ></Paragraph>
            </div>
          </div>
          <div className="flex items-center justify-between my-3">
            <span>To</span>
            <div className="flex items-center">
              <div className="text-[14px] text-primaryText leading-5 break-words max-w-[168px]">
                {props?.to}
              </div>

              <Paragraph
                className="truncate  break-all text-secondaryText [&_path]:fill-secondaryText [&_p]:!mb-0"
                copyable={{
                  text: props?.to,
                }}
              ></Paragraph>
            </div>
          </div>
          <hr className="mx-[-16px] my-3 border-[#282828]" />
          <div className="flex items-center justify-between my-3">
            <span>Network Fee</span>
            <div className="text-right text-primaryText">
              <div className="text-[14px] leading-5">Fast - - gwei</div>
              <div className="text-[14px] leading-[18px] mt-1.5">$-</div>
            </div>
          </div>
          <div className="flex items-center justify-between my-3">
            <span>Total Amount</span>
            <div className="text-primaryText text-[14px] leading-5">
              {amount} {token?.symbol}
            </div>
          </div>
        </div>

        <ConfigProvider
          theme={{
            components: {
              Button: {
                controlHeight: 48,
              },
            },
          }}
        >
          <Button
            block
            loading={loading}
            type="primary"
            className="mt-[30px]"
            onClick={() => {
              handleSend();
            }}
          >
            Sign & Send
          </Button>
        </ConfigProvider>
      </div>
    </SaDrawer>
  );
}
