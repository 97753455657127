import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { G6Math } from '@/contract/utils/Math';
import classNames from 'classnames';
import { Tag } from 'antd';
import { AppKline } from '../AppKline';
import { CoinDetail } from '@/types/Coin';
import { DateType, KlineData, useFetchKLineData } from './hooks/useFetchKLineData';
import { CandleStick, TradeCreate } from '@/test';
import { useSolWebSocketStore } from '@/context/SolWebSocketContext';
import { formatByDecimals, lamportsToSol } from '@/utils/sol';
import { ReactComponent as NoData } from '@/assets/icon/app/no-data.svg';

function parseValue(value: string) {
  return G6Math.from(value)
    .multiply(10 ** 18)
    .toNumber();
}

const dateOptions = [
  {
    label: '1m',
    value: 'minute',
  },
  // {
  //   label: '1H',
  //   value: 'hour',
  // },
  {
    label: '1D',
    value: 'day',
  },
  {
    label: '1W',
    value: 'week',
  },
  {
    label: '1M',
    value: 'month',
  },
  // {
  //   label: '1Y',
  //   value: 'year',
  // },
];

function parseData({ data, type }: { data: KlineData[] | CandleStick[]; type: DateType }) {
  const val = data.map((item) => {
    return {
      time: formatXAxis(item.time, type),
      open: parseValue(item.open.toString()),
      high: parseValue(item.high.toString()),
      low: parseValue(item.low.toString()),
      close: parseValue(item.close.toString()),
      // vol: item.vol,
    };
  });

  return val;
}

function formatXAxis(value: number, type: DateType) {
  switch (type) {
    case 'minute':
    case 'hour':
      return dayjs(value).format('MM/DD HH:mm');
    case 'day':
      return dayjs(value).format('MM-DD');
    case 'week':
      return dayjs(value).format('MM-DD');
    case 'month':
      return dayjs(value).format('YY-MM');
    case 'year':
      return dayjs(value).format('YYYY-MM');
  }
}

const resolutionMap: Record<DateType, number> = {
  minute: 1,
  hour: 60,
  day: 60 * 24,
  week: 60 * 24 * 7,
  month: 60 * 24 * 30,
  year: 60 * 24 * 30 * 12,
};
// '1H', '1D', '1W', '1M', '1Y'

const displayDataCount = new Map([
  ['minute', 60],
  ['hour', 60],
  ['day', 30],
  ['week', 30],
  ['month', 30],
  ['year', 30],
]);

export function CustomKLine({ detail }: { detail?: CoinDetail }) {
  const [dateType, setDateType] = useState<DateType>('minute');
  const [klineSource, setKlineSourceData] = useState<KlineData[]>([]);
  const { latestMintMessage } = useSolWebSocketStore();
  const [mintMessage, setMintMessage] = useState<TradeCreate>();
  const { loadMore } = useFetchKLineData({
    dateType,
    onSuccess: (v) => setKlineSourceData(v),
  });

  useEffect(() => {
    setKlineSourceData([]);
  }, [dateType]);

  // sol链获取实时价格
  useEffect(() => {
    if (latestMintMessage) {
      setMintMessage(latestMintMessage);
    }
  }, [latestMintMessage]);

  // sol链获取实时价格
  useEffect(() => {
    if (mintMessage) {
      const timestamp = dayjs(mintMessage.timestamp * 1000)
        .startOf('minute')
        .valueOf();
      // 判断当前数组里是否已有这个时间戳的数据
      const data = klineSource.find((item) => item.time === timestamp);
      const price =
        lamportsToSol(mintMessage.sol_amount) / formatByDecimals(mintMessage.token_amount);
      // 如果已有对应数据 就修改最后一条数据
      if (data) {
        setKlineSourceData((pre) => [
          ...pre.slice(0, -1),
          {
            ...data,
            time: timestamp,
            close: price,
            high: price > data.high ? price : data.high,
            low: price < data.low ? price : data.low,
          },
        ]);
      } else {
        // 如果没有对应数据 就添加
        setKlineSourceData((pre) => [
          ...pre,
          {
            open: price,
            time: timestamp,
            close: price,
            high: price,
            low: price,
          },
        ]);
      }
      setMintMessage(undefined);
    }
  }, [klineSource, latestMintMessage, mintMessage]);

  const klineData = useMemo(() => {
    if (!klineSource) return [];

    return parseData({
      data: klineSource,
      type: dateType,
    });
  }, [klineSource, dateType]);

  return (
    <>
      <div className="w-full overflow-hidden">
        {klineData?.length ? (
          <AppKline
            detail={detail}
            data={klineData}
            displayDataCount={displayDataCount.get(dateType)}
            loadMore={loadMore}
          />
        ) : (
          <div className="flex justify-center items-center flex-col text-secondaryText h-[365px]">
            <NoData />
            <div>No Data</div>
          </div>
        )}
      </div>

      <div className="flex justify-between items-center gap-x-[25px]">
        {dateOptions.map((item) => {
          return (
            <Tag.CheckableTag
              key={item.value}
              className={classNames(
                'active:text-[#282828] bg-transparent rounded-full px-3 py-[2px] text-secondaryText text-[12px] leading-[12px] font-semibold',
                dateType === item.value && '!text-[#000] border-primaryText !bg-primaryText',
              )}
              checked={item.value === dateType}
              onChange={() => setDateType(item.value as any)}
            >
              {item.label}
            </Tag.CheckableTag>
          );
        })}
      </div>
    </>
  );
}
