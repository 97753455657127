import { Button } from '@/components/Button';
import { ConfigProvider, FormInstance } from 'antd';
// import { SymbolSuffix } from '@/views/Coin/components/Trade/Sell';
import { NativeTokenSuffix } from '@/views/Coin/components/Trade/SymbolSelect';
import { Input } from 'antd';
import { useFetchFee } from './hooks/useFetchFee';
import { SaDrawer } from '../SaDrawer';
import { onPressEnterBlur } from '@/utils';
import { useNativeToken } from '@/hooks/app/useNativeToken';

export function InitialPurchaseModal({
  amount,
  open,
  loading,
  form,
  setAmount,
  onClose,
  onCreate,
}: {
  amount: string;
  open: boolean;
  loading: boolean;
  form: FormInstance;
  imageUri: string;
  setAmount: (v: string) => void;
  onClose?: () => void;
  onCreate: () => void;
}) {
  // symbol,
  // imageUri,
  const [symbolName] = useNativeToken();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(e.target.value);
  };

  const onBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^\d.]/g, '');

    setAmount(value);
  };

  const { fee } = useFetchFee({ tonAmountToPayForInitBuy: amount });

  return (
    <SaDrawer
      height="375px"
      title={`Want buy ${form.getFieldValue('ticker')}?`}
      open={open}
      onClose={() => onClose?.()}
    >
      <div className="mb-[34px] text-[14px] leading-[16.8px] mt-[3px] text-tertiaryText font-normal">
        It’s optional, but buying a small amount of coins helps protect your coin from snipers
      </div>

      <ConfigProvider
        wave={{ disabled: true }}
        theme={{
          components: {
            Input: {
              colorBgContainer: '#1b1b1b',
              activeBg: '#1b1b1b',
              addonBg: '#1b1b1b',
              activeBorderColor: '#242424',
              colorSplit: '#242424',
              colorBorder: '#242424',
              hoverBorderColor: '#242424',
              colorText: '#fff03',
              borderRadius: 60,
              controlHeight: 56,
              fontSize: 20,
            },
          },
        }}
      >
        <div>
          <Input
            onPressEnter={onPressEnterBlur}
            className="w-full b-0 mb-3"
            addonAfter={
              <NativeTokenSuffix />
              // <SymbolSuffix symbol={symbol} icon={imageUri} />
            }
            defaultValue={amount}
            value={amount}
            onBlur={onBlur}
            onChange={handleChange}
          />
        </div>
      </ConfigProvider>

      {/* <div className="text-[14px] leading-[14px] font-Inter font-normal opacity-50">Cost: 0.0 TON</div> */}

      <div className="flex items-center mb-5 justify-between mt-10">
        <span className="text-tertiaryText mr-[5px]">COST OF DEPLOY:</span>
        <span className="text-[16px] font-medium">
          {fee || '-'} {symbolName}
        </span>
      </div>

      <ConfigProvider
        theme={{
          components: {
            Button: {
              controlHeight: 48,
            },
          },
        }}
      >
        <Button block type="primary" disabled={loading} loading={loading} onClick={onCreate}>
          Create coin
        </Button>
      </ConfigProvider>
    </SaDrawer>
  );
}
