import request from '@/request';

export function generatePayload() {
  return request.post<{ payload: string }>(`/user/ton-proof/generate-payload`, null);
}

export async function generateTonProofPayload() {
  const res = await request.get<{ payload: string }>(`/wallets/generate-ton-proof-payload`, null);
  return res.data;
}

export function checkProof(payload: any) {
  console.log('checkProof-payload', {
    proof: payload,
    domain: window.location.host,
  });

  return request.post<{ token: string }>(`/user/ton-proof/check-proof`, {
    proof: payload,
    domain: window.location.host,
  });
}
