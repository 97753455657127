import { SlippageModal } from '@/components/SlippageModal';
import { TokenValue } from '@/components/TonValue';
import { SymbolSwitch } from './SymbolSwitch';
import { useState, useContext, useEffect } from 'react';
import { CoinDetailContext } from '../../context/CoinDetailContext';
import { useNativeToken } from '@/hooks/app/useNativeToken';

export function ControlBar({
  balance,
  balanceLoading,
  suffix,
  controlType,
  getSymbol,
  hideSlippage,
  isNative = false,
}: {
  balance: bigint;
  balanceLoading: boolean;
  suffix?: string;
  controlType?: string;
  getSymbol: (e: string) => void;
  hideSlippage?: boolean;
  isNative?: boolean;
}) {
  const [nativeName] = useNativeToken();

  const [symbol, setSymbol] = useState(nativeName);

  const { detail } = useContext(CoinDetailContext);

  useEffect(() => {
    getSymbol(symbol);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [symbol]);

  return (
    <div className="w-full">
      {/* <div className="text-[14px] font-normal text-[#ffffff50]">
        <WalletOutlined className="mr-[3px]" />
        <TonValue value={balance} loading={balanceLoading} suffix={suffix}></TonValue>
      </div> */}
      <div className="text-[12px] font-semibold bg-[#483FFF1A] w-full p-[10px]">
        <span>My holder：</span>
        <TokenValue value={balance} loading={balanceLoading} suffix={suffix} isNative={isNative} />
      </div>
      <div className="flex justify-between items-center text-[#ffffff50] mt-[10px]">
        {controlType === 'sell' ? (
          <div></div>
        ) : (
          <div className="flex items-center text-[12px]">
            <div>Switch to</div>
            <SymbolSwitch symbol={symbol} setSymbol={setSymbol} detail={detail} />
          </div>
        )}

        {!hideSlippage && (
          <SlippageModal>
            <div className="underline decoration-[#ffffff50] underline-offset-1 text-xs">
              Set max slippage
            </div>
          </SlippageModal>
        )}
      </div>
      {/* <SlippageModal>
        <AntButton className="text-[#ffffff50]" type="text" icon={<SettingOutlined />}></AntButton>
      </SlippageModal> */}
    </div>
  );
}
