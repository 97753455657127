import { SaDrawer } from '@/components/SaDrawer';
import { useFetchCreateResult } from '@/hooks/app/create/useFetchCreateResult';
import { useAppRouter } from '@/hooks/router/useAppRouter';
import { Coin } from '@/types/Coin';
import { CheckCircleFilled, LoadingOutlined } from '@ant-design/icons';
import { Button, FormInstance } from 'antd';
import { useEffect, useState } from 'react';

const SuccessDrawer = ({
  form,
  queryId,
  initialAmount,
  open,
  onClose,
}: {
  form: FormInstance;
  queryId?: bigint;
  initialAmount: string;
  open: boolean;
  onClose: () => void;
}) => {
  const [coinRes, setCoinRes] = useState<Coin | undefined>();
  const [loading, setLoading] = useState(false);
  const name = form.getFieldValue('name');

  useFetchCreateResult({
    name,
    ticker: form.getFieldValue('ticker'),
    queryId: Number(queryId),
    enabled: open && !coinRes,
    onSuccess: (res) => {
      if (res) {
        setCoinRes(res);
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    if (open) {
      setCoinRes(undefined);
      setLoading(true);
    }
  }, [open]);

  const { goCoinDetail } = useAppRouter();

  const goDetail = () => {
    if (coinRes?.poolAddress) {
      goCoinDetail({ poolAddress: coinRes.poolAddress });
    }
  };

  const viewGroup = () => {
    if (coinRes?.telegram) {
      window.Telegram.WebApp.openTelegramLink(coinRes.telegram);
    }
  };

  return (
    <SaDrawer open={open} title="" onClose={onClose} height="430px">
      {loading ? (
        <div className="flex justify-center items-center h-[200px]">
          <LoadingOutlined className="text-[80px]" />
        </div>
      ) : (
        <div className="flex flex-col items-center px-2 pb-9">
          <div className="bg-white rounded-full w-16 h-16 mb-6">
            <CheckCircleFilled className="text-[#17CF82] text-[64px]" />
          </div>
          <div className="mb-5">
            <div className="text-center text-primaryText text-[20px] leading-6 mb-2">
              {initialAmount ? 'Create and purchase successfully' : 'Token creation successful'}
            </div>
            <div className="text-tertiaryText text-[14px] leading-4 font-normal">
              At the same time as the successful creation, in order to better activate the {name}{' '}
              community, we created a telegram group for {name}, and users holding {name} can enter
              the group for friendly discussions
            </div>
          </div>
          <div className="py-4 w-full">
            <Button type="primary" block className="h-12 font-medium" onClick={goDetail}>
              View {name}
            </Button>
          </div>
          <div className="font-normal text-tertiaryText" onClick={viewGroup}>
            View {name} Telegram Group
          </div>
        </div>
      )}
    </SaDrawer>
  );
};

export default SuccessDrawer;
