import { tonApi } from '@/service/tonApi';
import { useRequest } from 'ahooks';
import { useCoinDetailStore } from '../context/CoinDetailContext';
import { getSolanaConnection, lamportsToSol } from '@/utils/sol';
import { PublicKey } from '@solana/web3.js';
import { G6Math } from '@/contract/utils/Math';

export function useFetchHolderDistribution() {
  const { detail, isTonData } = useCoinDetailStore();

  const { data, loading, refresh } = useRequest(
    async () => {
      if (isTonData(detail)) {
        const res = await tonApi.jettons.getJettonHolders(detail!.address!, {
          offset: 0,
          limit: 10,
        });
        const total = res.addresses.reduce((pre, item) => {
          return pre + BigInt(item.balance);
        }, 0n);
        return res.addresses.map((item) => {
          return {
            address: item.address,
            ownerAddress: item.owner.address,
            percent: (new G6Math(item.balance).divide(total).toNumber() * 100).toFixed(2),
          };
        });
      } else {
        const connection = getSolanaConnection();
        const mintPublicKey = new PublicKey(detail!.mint);
        const res = await connection.getTokenLargestAccounts(mintPublicKey);
        return res.value.slice(0, 10).map((item) => {
          return {
            address: new PublicKey(item.address).toBase58(),
            ownerAddress: new PublicKey(item.address).toBase58(),
            percent: (lamportsToSol(item.uiAmount ?? 0) * 100).toFixed(2),
          };
        });
      }
    },
    {
      ready: !!detail,
      debounceWait: 3 * 1000,
      onError: (error) => {
        console.error('useFetchHolderDistribution error', error);
      },
    },
  );

  return { data, loading, refresh };
}
