import { useNativeToken } from '@/hooks/app/useNativeToken';
import { useAppMessage } from '@/hooks/useAppMessage';
import { useWalletInfoStore } from '@/stores/useWalletInfoStore';
import { shortenAddress } from '@/utils/ton';
import { CopyOutlined, LeftOutlined } from '@ant-design/icons';
import { Button, Divider } from 'antd';
import SlideButton from './SlideButton';
import { useRef } from 'react';
import { validAddress } from '../SendOnlyNative';

const ListItem = ({ title, value }: { title: string; value: React.ReactNode }) => {
  return (
    <div className="flex justify-between items-center text-[14px] leading-5 p-[14px_20px] font-normal">
      <div className="text-[#777777]">{title}</div>
      <div>{value}</div>
    </div>
  );
};

export const Confirming = ({
  address,
  pricePreview,
  amount,
  loading,
  onConfirm,
  goBack,
}: {
  address: string;
  pricePreview: string;
  amount: string;
  loading: boolean;
  onConfirm: () => void;
  goBack: () => void;
}) => {
  const { walletAddress, chainType } = useWalletInfoStore();
  const [nativeSymbol, nativeIcon] = useNativeToken();
  const message = useAppMessage({ key: 'send-confirm' });
  const confirmingSlideButtonRef = useRef({ reset: () => {} });

  const handleConfirm = () => {
    if (!validAddress(address, chainType)) {
      message.error('Invalid address');
      confirmingSlideButtonRef.current.reset();
      return;
    }

    if (!amount) {
      message.error('Please input amount');
      confirmingSlideButtonRef.current.reset();
      return;
    }

    if (!address) {
      message.error('Please input recipient’s address');
      confirmingSlideButtonRef.current.reset();
      return;
    }
    onConfirm();
  };

  const copyClick = async (address: string) => {
    try {
      await navigator.clipboard.writeText(address || '');

      message.success('Copy success');
    } catch (error) {
      message.error('Copy failed');
    }
  };

  return (
    <div>
      <Button
        type="text"
        className="text-secondaryText"
        onClick={() => {
          goBack();
        }}
      >
        <LeftOutlined /> Back
      </Button>
      <div className="flex justify-center flex-col items-center py-8">
        <img src={nativeIcon} className="w-14 h-14" />
        <div className="text-[32px] leading-[38px] font-bold text-primaryText mt-[13px] mb-1">
          -{amount} {nativeSymbol}
        </div>
        <div className="text-[16px] leading-[20px] text-[#999] font-normal">${pricePreview}</div>
      </div>
      <Divider className="my-[10px]" />
      <ListItem
        title="Network"
        value={
          <div className="flex gap-[6px] items-center">
            <img src={nativeIcon} />
            <div>{nativeSymbol}</div>
          </div>
        }
      />
      <ListItem
        title="From"
        value={
          <div className="flex gap-[6px] items-center">
            <div>{shortenAddress(walletAddress)}</div>
            <CopyOutlined onClick={() => copyClick(walletAddress)} />
          </div>
        }
      />
      <ListItem
        title="To"
        value={
          <div className="flex gap-[6px] items-center">
            <div>{shortenAddress(address)}</div>
            <CopyOutlined onClick={() => copyClick(address)} />
          </div>
        }
      />
      <div className="absolute bottom-5 left-[50%] translate-x-[-50%] w-[calc(100vw-40px)]">
        <SlideButton ref={confirmingSlideButtonRef} loading={loading} onConfirm={handleConfirm} />
      </div>
    </div>
  );
};
