import { useG6PoolMethods } from '@/contract/hooks/useG6PoolMethods';
import { useRequest } from 'ahooks';
import { useContext, useMemo } from 'react';
import { CoinDetailContext } from '../context/CoinDetailContext';
import { Coin } from '@/types/Coin';

export function useFetchJettonSoldable() {
  const { detail, isTonData } = useContext(CoinDetailContext);

  const { getPoolData } = useG6PoolMethods();

  const { data, loading } = useRequest(
    async () => {
      return await getPoolData((detail as Coin).poolAddress);
    },
    {
      ready: isTonData(detail) && !!detail?.poolAddress,
    },
  );

  const jettonSoldable = useMemo(() => {
    return isTonData(detail) ? data?.jettonSoldable : 0n;
  }, [data?.jettonSoldable, detail, isTonData]);

  return { jettonSoldable, loading };
}
