import { Button } from '@/components/Button';
import { Input, ConfigProvider } from 'antd';
import { InputTokenType, useG6PoolMethods } from '@/contract/hooks/useG6PoolMethods';
import { useContext, useState } from 'react';
import { Address, fromNano, toNano } from '@ton/core';
import { useRequest, useUnmount } from 'ahooks';
import { useAppMessage } from '@/hooks/useAppMessage';
import { TonValue } from '@/components/TonValue';
import { CoinDetailContext, useCoinDetailStore } from '../../context/CoinDetailContext';
import { useCalcSellAmount } from '../../hooks/useCalcSellAmount';
import { G6Math } from '@/contract/utils/Math';
import classNames from 'classnames';
import { ConnectButton } from '@/components/ConnectButton';
import { PoolComplete } from './PoolComplete';
import { useGlobalDataStore } from '@/context/GlobalDataContext';
import { SymbolUnit } from './SymbolUnit';
import { ControlBar } from './ControlBar';
import { SendError } from '@/hooks/useSendTransactionByTomo';
import { onPressEnter } from '@/views/Create';
import { getProvider } from '@/utils';
import { getG6Pool } from '@/contract/instance';
import { useNativeToken } from '@/hooks/app/useNativeToken';
import { Coin, CoinDetail } from '@/types/Coin';
import { useFetchJettonBalance } from '../../hooks/useFetchJettonBalance';

export function SymbolSuffix({ icon, symbol }: { icon?: string; symbol?: string }) {
  return (
    <div className="inline-flex justify-start items-center gap-x-[5px] min-w-[60px] relative bottom-[-1px]">
      <div className="w-[24px]">
        <img className="w-[24px] h-[24px] rounded-full" src={icon} />
      </div>
      <span className="text-[15px]  font-semibold max-w-[200px] text-ellipsis overflow-hidden">
        {symbol}
      </span>
    </div>
  );
}

const loadingKey = 'LOADING';

export function Sell() {
  const [nativeName] = useNativeToken();

  const { handleSell } = useG6PoolMethods();

  const { detail, isGraduated } = useContext(CoinDetailContext);

  const [count, setCount] = useState('');

  const { balance, balanceLoading, refreshBalance } = useFetchJettonBalance();

  const handlePut = (val: any) => {
    if (isGraduated) return;

    const value = new G6Math(balance).multiply(val);

    setCount(fromNano(value.toNumber().toFixed(0)));
  };

  const message = useAppMessage();

  const [queryId, setQueryId] = useState<string | undefined>();

  const { refreshBalanceAsync } = useGlobalDataStore();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCount(e.target.value);
  };

  const onBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^\d.]/g, '');

    setCount(value);
  };

  // const { data } = useFetchTXResult({
  //   queryId,
  //   type: 'sell',
  //   onSuccess() {
  //     message.success('Sell success');

  //     refreshBalanceAsync?.().catch(() => null);

  //     setQueryId(undefined);

  //     message.messageInstance.destroy(loadingKey);
  //   },
  //   onFail() {
  //     message.error('Sell failed');

  //     message.messageInstance.destroy(loadingKey);
  //   },
  // });

  // const resultLoading = useMemo(() => {
  //   return data?.data.status === 'not_found';
  // }, [data?.data?.status]);

  const { run: runSell, loading: sellLoading } = useRequest(
    () => {
      const amount = new G6Math(count).subtract('0.0000001');

      if (new G6Math(amount).isGreaterThan(fromNano(balance) || 0n)) {
        return Promise.reject(new Error('Insufficient balance'));
      }

      return handleSell(toNano(amount.toString()), InputTokenType.ByJetton);
    },
    {
      manual: true,
      onSuccess(res) {
        // message.success('Transaction sent successfully');

        refreshBalance();

        setCount('');

        // setQueryId(res?.queryId.toString());

        // message.messageInstance.open({
        //   type: 'loading',
        //   key: loadingKey,
        //   content: 'Waiting for sell result',
        //   duration: 1000,
        // });
      },
      onError(err) {
        if (err.message === SendError.CANCEL) {
          return;
        }
        message.error(err.message || 'Transaction sent failed');
      },
    },
  );

  const { displayValue: tonAmount } = useCalcSellAmount({
    detail,
    amount: toNano(count),
    ready: !isGraduated && !!+count,
  });

  useUnmount(() => {
    message.messageInstance.destroy(loadingKey);
  });

  return (
    <ConfigProvider
      wave={{ disabled: true }}
      theme={{
        components: {
          Input: {
            colorBgContainer: '#1B1B1B',
            activeBg: '#1B1B1B',
            addonBg: '#1B1B1B',
            activeBorderColor: '#242424',
            colorSplit: '#1B1B1B',
            colorBorder: '#242424',
            hoverBorderColor: '#242424',
            colorText: '#ffffff50',
            borderRadius: 60,
            controlHeight: 56,
            fontSize: 24,
          },
        },
      }}
    >
      <div className="my-[12px] w-full">
        <ControlBar
          balance={balance}
          balanceLoading={balanceLoading}
          controlType={'sell'}
          suffix={detail?.symbol}
          getSymbol={() => {
            //
          }}
        />
      </div>

      <div>
        <Input
          className="w-full"
          onPressEnter={onPressEnter}
          readOnly={isGraduated}
          // addonAfter={<SymbolSuffix symbol={detail?.symbol} icon={detail?.imageUri} />}
          suffix={<SymbolUnit detail={detail} isTon={false}></SymbolUnit>}
          defaultValue={count}
          value={count}
          onBlur={onBlur}
          onChange={handleChange}
        />
        <div className={classNames('flex items-center mt-3 pl-3')}>
          <ul className="flex items-center text-[12px] leading-[15px] font-normal gap-[8px] ">
            <li
              className={classNames(
                'bg-[#282828] px-[16px] py-[4px] rounded-[36px] font-normal',
                isGraduated ? 'cursor-not-allowed' : 'cursor-pointer',
              )}
              onClick={() => {
                handlePut(0.25);
              }}
            >
              25%
            </li>
            <li
              className={classNames(
                'bg-[#282828] px-[16px] py-[4px] rounded-[36px] font-normal',
                isGraduated ? 'cursor-not-allowed' : 'cursor-pointer',
              )}
              onClick={() => {
                handlePut(0.5);
              }}
            >
              50%
            </li>
            <li
              className={classNames(
                'bg-[#282828] px-[16px] py-[4px] rounded-[36px] font-normal',
                isGraduated ? 'cursor-not-allowed' : 'cursor-pointer',
              )}
              onClick={() => {
                handlePut(0.75);
              }}
            >
              75%
            </li>
            <li
              className={classNames(
                'bg-[#282828] px-[16px] py-[4px] rounded-[36px] font-normal',
                isGraduated ? 'cursor-not-allowed' : 'cursor-pointer',
              )}
              onClick={() => {
                handlePut(1);
              }}
            >
              MAX
            </li>
          </ul>

          {/* <div className="text-[12px] font-normal text-[#ffffff50] ml-[6px]">
            <TonValue value={balance} loading={balanceLoading} suffix={detail?.symbol}></TonValue>
          </div> */}
        </div>
        {!isGraduated && (
          <ConnectButton className="w-full mt-[18px] text-[15px] ">
            <Button
              type="primary"
              disabled={isGraduated || !+count}
              loading={sellLoading}
              className="w-full mt-[18px] text-[15px] "
              onClick={() => {
                runSell();
              }}
            >
              {isGraduated ? 'Complete' : 'PLACE TRADE'}
            </Button>
          </ConnectButton>
        )}
        <PoolComplete />
        {!!+tonAmount && (
          <>
            <div className="pt-2  pl-3">
              Receive: {tonAmount} {nativeName}
            </div>
            <SellFee detail={detail} />
          </>
        )}
      </div>
    </ConfigProvider>
  );
}

export function SellFee({ detail }: { detail: CoinDetail }) {
  const [nativeName] = useNativeToken();
  const { isTonData } = useCoinDetailStore();

  const { data, loading } = useRequest(
    async () => {
      const poolAddress = (detail as Coin).poolAddress;
      const pool = getG6Pool(Address.parse(poolAddress!));

      return pool.getSellTxFee(await getProvider(poolAddress!));
    },
    {
      ready: isTonData(detail) && !!detail.poolAddress,
    },
  );

  return (
    <div className="pt-1 pl-3">
      Pre-pay transaction fee:{' '}
      <TonValue value={data?.sellTransferTxFee || 0n} loading={loading}></TonValue> {nativeName}
    </div>
  );
}
