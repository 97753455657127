import { Button } from '@/components/Button';
import { useAppRouter } from '@/hooks/router/useAppRouter';
import { LeftOutlined } from '@ant-design/icons';
import { ConfigProvider } from 'antd';
import coinLogo from '@/assets/icon/icon_coinLogo.svg';
import copyIcon from '@/assets/icon/icon_copy.svg';
import shareIcon from '@/assets/icon/icon_share.svg';
import transDownIcon from '@/assets/icon/icon_transDown.svg';
import transUpIcon from '@/assets/icon/icon_transUp.svg';
import { CoinOperate } from '@/components/CoinOperate';
import { useState } from 'react';
import { Transactions } from '@/components/Transactions';

export default function () {
  const { goBack } = useAppRouter();

  const [operateList] = useState([
    { value: 'Sent', src: transUpIcon, date: 'Apr 16, 2024', USDT: '-0.2' },
    { value: 'Receive', src: transDownIcon, date: 'Apr 16, 2024', USDT: '-0.2' },
    { value: 'Sent', src: transUpIcon, date: 'Apr 16, 2024', USDT: '-0.2' },
  ]);

  return (
    <div className="bg-primaryBackground sticky top-0 z-20">
      <div className="flex justify-start items-center h-[48px] sticky top-0">
        <ConfigProvider
          theme={{
            components: {
              Button: {
                controlHeight: 48,
              },
            },
          }}
        >
          <Button
            type="text"
            className="text-secondaryText w-full relative"
            onClick={() => {
              goBack();
            }}
          >
            <LeftOutlined className="absolute left-2.5" />
            <div className="font-semibold flex items-center text-primaryText text-[18px] leading-6">
              <img src={coinLogo} className="w-5 mr-[6px]" />
              ETH
            </div>
          </Button>
        </ConfigProvider>
      </div>

      <div className="text-center py-[39px]">
        <div className="font-bold text-[36px] leading-[43.2px] text-primaryText">6,124.06</div>
        <div className="mt-1 text-secondaryText text-[14px] leading-[19.6px]">$3,126.18</div>
      </div>

      <CoinOperate></CoinOperate>

      <div className="p-4 border-y border-[#242424]">
        <div className="text-[13px] leading-[16.9px] mb-1 text-[#9B9B9B]">My Base Address</div>
        <div className="flex items-center justify-between">
          <div className="text-[14px] leading-[19.6px] text-primaryText">
            0x7f212857123a56...080c0f1461e2827
          </div>
          <div className="flex items-center justify-end">
            <img src={copyIcon} className="h-5 mr-5" />
            <img src={shareIcon} className="h-5 "></img>
          </div>
        </div>
      </div>

      <div className="px-4">
        <div className="py-1 text-[#9B9B9B] text-[13px] leading-[16.9px]">Transactions</div>
        <Transactions operateList={operateList}></Transactions>
      </div>
    </div>
  );
}
