import { SaDrawer } from '@/components/SaDrawer';
import { Avatar, List } from 'antd';
import { useParseRouterParam } from '@/hooks/router/useParseRouterParam';
import { useFetchFollowers } from '../../hooks/useFetchFollowers';
import { useUnFollowUser } from '../../hooks/useUnFollowUser';
import { useFollowUser } from '../../hooks/useFollowUser';
import { useFetchFollowing } from '../../hooks/useFetchFellowing';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useProfileStore } from '@/context/ProfileContext';
import { useAppRouter } from '@/hooks/router/useAppRouter';
import { useAppMessage } from '@/hooks/useAppMessage';

export const FollowersModel = forwardRef(
  ({ open, onClose }: { open: boolean; onClose: () => void }, ref) => {
    const { param } = useParseRouterParam({ address: '' });
    const address = param.address;
    const message = useAppMessage();
    const { userInfo } = useProfileStore();
    const { data, loading, refresh: refreshFollowers } = useFetchFollowers(userInfo?.id);
    const [followId, setFollowId] = useState('');

    useImperativeHandle(ref, () => ({
      refreshFollowers,
    }));

    const { goCreatorDetailWithUserId } = useAppRouter();
    const { refresh } = useFetchFollowing(address);
    const { runAsync: unFollowUser } = useUnFollowUser({
      onSuccess() {
        refresh();
      },
    });
    const { runAsync: followUser } = useFollowUser({
      onSuccess() {
        refresh();
      },
    });
    const toggleFollow = async (isFollowing: boolean, id: string) => {
      // Example logic to toggle follow/unfollow
      setFollowId(id);
      try {
        if (isFollowing) {
          await unFollowUser(id);
          message.success('UnFollow success');
        } else {
          await followUser(id);
          message.success('Follow success');
        }
        refreshFollowers();
      } catch (error) {
        message.error('Failed to toggle follow');
      }
    };

    return (
      <SaDrawer
        title={`Follower (${(data || []).length})`}
        height="calc(100vh - 100px)"
        open={open}
        onClose={onClose}
      >
        <List
          loading={loading}
          itemLayout="horizontal"
          dataSource={data || []}
          renderItem={(item) => (
            <List.Item
              className="h-full bg-secondaryBackground !p-3 mb-2 rounded-lg"
              style={{ borderBlockEnd: '0' }}
              onClick={() => {
                goCreatorDetailWithUserId(item.user_id);
                onClose();
              }}
            >
              <div className="flex items-center gap-3">
                <Avatar size={56}>
                  {item.username[0] || ((item.firstName ?? '') + ' ' + (item.lastName ?? ''))[0]}
                </Avatar>
                <div className="flex flex-col">
                  <div className="text-[16px] font-medium text-white leading-6">
                    {item.username || (item.firstName ?? '') + ' ' + (item.lastName ?? '')}
                  </div>
                  <div className="font-normal text-[#949BA5]">
                    <span className="mr-1 text-primaryText">{item.follows_count}</span>Followers
                  </div>
                </div>
              </div>
              {/* <Button
              className={classNames("text-[12px] leading-[12px] p-[10px] font-normal h-auto min-w-20", item.isFollowing ? "bg-[#282828] text-[#9B9B9B]" : "bg-primary")}
              loading={followId === item._id ? (unFollowUserLoading || followUserLoading) : false}
              onClick={() => {
                toggleFollow(item.isFollowing, item._id);
              }}
            >
              <span>{item.isFollowing ? 'Following' : 'Follow'}</span>
            </Button> */}
            </List.Item>
          )}
        />
      </SaDrawer>
    );
  },
);
