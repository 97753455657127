import { Button } from '@/components/Button';
import { Input, ConfigProvider } from 'antd';
import { InputTokenType, useG6PoolMethods } from '@/contract/hooks/useG6PoolMethods';
import { useContext, useMemo, useState } from 'react';
import { fromNano, toNano } from '@ton/core';
import { useRequest, useUnmount } from 'ahooks';
import { useAppMessage } from '@/hooks/useAppMessage';
import { CoinDetailContext } from '../../context/CoinDetailContext';
import { SymbolUnit } from './SymbolUnit';
import { useCalcBuyAmount } from '../../hooks/useCalcBuyAmount';
import classNames from 'classnames';
import { useCalcBuyAmountByJetton } from '../../hooks/useCalcBuyAmountByJetton';
import { ConnectButton } from '@/components/ConnectButton';
import { PoolComplete } from './PoolComplete';
import { G6Math } from '@/contract/utils/Math';
import { useGlobalDataStore } from '@/context/GlobalDataContext';
import { ControlBar } from './ControlBar';
import { SendError } from '@/hooks/useSendTransactionByTomo';
import { onPressEnter } from '@/views/Create';
import { SellFee } from './Sell';
import { useGetNativeBalance } from '@/hooks/app/useGetNativeBalance';
import { useNativeToken } from '@/hooks/app/useNativeToken';
import { Coin } from '@/types/Coin';

const loadingKey = 'LOADING';

export function Buy() {
  const { handleBuy } = useG6PoolMethods();

  // const { getBalance, ready } = useAccountMethods();

  const { detail, isGraduated, refreshIsGraduated, isTonData } = useContext(CoinDetailContext);

  const { refreshBalanceAsync } = useGlobalDataStore();

  const [count, setCount] = useState('');

  const [nativeName] = useNativeToken();

  // const { data: balance = toNano(0), loading: balanceLoading } = useRequest(
  //   async () => {
  //     return getBalance().then((balance) => {
  //       return balance ? balance : toNano(0);
  //     });
  //   },
  //   {
  //     ready: ready,
  //   },
  // );

  const { balance, balanceLoading } = useGetNativeBalance();

  const handlePut = (val: any) => {
    if (isGraduated) return;

    setCount(val);
  };

  // const [queryId, setQueryId] = useState<string | undefined>();

  // useFetchTXResult({
  //   queryId,
  //   type: 'buy',
  //   onSuccess() {
  //     message.success('Purchase success');

  //     refreshBalanceAsync?.().catch(() => null);

  //     setQueryId(undefined);

  //     message.messageInstance.destroy(loadingKey);
  //   },
  //   onFail() {
  //     message.error('Purchase failed, fee will be refunded later');

  //     message.messageInstance.destroy(loadingKey);
  //   },
  // });

  const message = useAppMessage();

  const [symbol, setSymbol] = useState(nativeName);

  const isNative = useMemo(() => {
    return symbol === nativeName;
  }, [symbol, nativeName]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCount(e.target.value);
  };

  const onBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^\d.]/g, '');

    setCount(value);
  };

  const { run: runBuy, loading: buyLoading } = useRequest(
    () => {
      if (isNative) {
        if (new G6Math(count).isGreaterThan(fromNano(balance) || 0n)) {
          return Promise.reject(new Error('Insufficient balance'));
        }
      } else {
        if (new G6Math(tonAmount).isGreaterThan(fromNano(balance) || 0n)) {
          return Promise.reject(new Error('Insufficient balance'));
        }
      }

      return handleBuy({
        tonAmountToPay: toNano(count),
        buyType: isNative ? InputTokenType.ByTon : InputTokenType.ByJetton,
        poolAddress: (detail as Coin)!.poolAddress,
      });
    },
    {
      manual: true,
      ready: isTonData(detail) && !!detail?.poolAddress,
      onSuccess: () => {
        // message.success('Transaction sent successfully');

        refreshIsGraduated?.();

        setCount('');

        // setQueryId(res?.queryId.toString());

        refreshBalanceAsync?.().catch(() => null);

        // message.messageInstance.open({
        //   type: 'loading',
        //   key: loadingKey,
        //   content: 'Waiting for purchase result',
        //   duration: 1000,
        // });
      },
      onError(err) {
        if (err.message === SendError.CANCEL) {
          return;
        }
        message.error(err.message || 'Transaction sent failed');
      },
    },
  );

  function toSafeNano(val: string) {
    try {
      return toNano(val);
    } catch {
      return toNano('0');
    }
  }

  const { displayValue: tokenAmount } = useCalcBuyAmount({
    poolAddress: (detail as Coin)?.poolAddress,
    amount: toSafeNano(count),
    ready: isTonData(detail) && isNative && !isGraduated && !!count,
  });

  const { displayValue: tonAmount } = useCalcBuyAmountByJetton({
    poolAddress: (detail as Coin)?.poolAddress,
    amount: toSafeNano(count),
    ready: isTonData(detail) && !isNative && !isGraduated && !!+count,
  });

  useUnmount(() => {
    message.messageInstance.destroy(loadingKey);
  });

  const units = [
    {
      label: 'Reset',
      value: 'Reset',
    },
    {
      label: `1 ${nativeName}`,
      value: '1',
    },
    {
      label: `5 ${nativeName}`,
      value: '5',
    },
    {
      label: `10 ${nativeName}`,
      value: '10',
    },
    // {
    //   label: 'MAX',
    //   value: 'MAX',
    // },
  ];

  return (
    <ConfigProvider
      wave={{ disabled: true }}
      theme={{
        components: {
          Input: {
            colorBgContainer: '#1B1B1B',
            activeBg: '#1B1B1B',
            addonBg: '#1B1B1B',
            activeBorderColor: '#242424',
            colorSplit: '#1B1B1B',
            colorBorder: '#242424',
            hoverBorderColor: '#242424',
            colorText: '#ffffff50',
            borderRadius: 60,
            controlHeight: 56,
            fontSize: 24,
          },
        },
      }}
    >
      <div className="my-[10px] w-full">
        <ControlBar
          balance={balance}
          balanceLoading={balanceLoading}
          getSymbol={(v) => {
            setSymbol(v);
          }}
          suffix={nativeName}
          isNative={isNative}
        />
      </div>
      <div>
        <Input
          onPressEnter={onPressEnter}
          className="w-full pl-[22px] text-primaryText"
          readOnly={isGraduated}
          // addonAfter={<SymbolSelect symbol={symbol} setSymbol={setSymbol} detail={detail} />}
          suffix={<SymbolUnit detail={detail} isTon={isNative}></SymbolUnit>}
          defaultValue={count}
          value={count}
          onBlur={onBlur}
          onChange={handleChange}
        />

        <div
          className={classNames('flex items-center mt-3 pl-3', {
            hidden: !isNative,
          })}
        >
          <ul className="flex items-center leading-[15px] text-[12px] font-semibold gap-[8px] ">
            {units.map((item) => {
              return (
                <li
                  key={item.label}
                  className={classNames(
                    'bg-[#282828] px-[16px] py-[4px] rounded-[36px] font-normal',
                    isGraduated ? 'cursor-not-allowed' : 'cursor-pointer',
                  )}
                  onClick={() => {
                    handlePut(item.value === 'Reset' ? undefined : Number(item.value));
                  }}
                >
                  {item.label}
                </li>
              );
            })}
          </ul>

          {/* <div className="text-[14px] font-normal text-[#ffffff50] ml-[6px]">
            <TonValue value={balance} loading={balanceLoading} suffix={'TON'}></TonValue>
          </div> */}
        </div>

        {!isGraduated && (
          <ConnectButton className="w-full mt-[18px] text-[15px] ">
            <Button
              disabled={isGraduated || !+count}
              loading={buyLoading}
              type="primary"
              className={classNames('w-full mt-[18px] text-[15px] ')}
              onClick={() => {
                runBuy();
              }}
            >
              {isGraduated ? 'Complete' : 'PLACE TRADE'}
            </Button>
          </ConnectButton>
        )}

        <PoolComplete />

        {!!count &&
          (!isNative ? (
            <div>
              {!!+tonAmount && (
                <div className="my-[10px]  pl-3">
                  {tonAmount} {nativeName}
                </div>
              )}
            </div>
          ) : (
            <div>
              {!!+tokenAmount && (
                <>
                  <div className="my-[10px]  pl-3">
                    {tokenAmount} {detail?.symbol}
                  </div>

                  <SellFee detail={detail} />
                </>
              )}
            </div>
          ))}
      </div>
    </ConfigProvider>
  );
}
