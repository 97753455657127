export default {
  tonContractAddress: 'EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c',
};

interface ChainInfo {
  logo: string | undefined;
  name: string;
  chainId: number | undefined;
  explorer: string;
  explorerAddress: string;
}

export const chains: Record<string, ChainInfo> = {
  BITCOIN: {
    logo: 'https://cryptologos.cc/logos/bitcoin-btc-logo.png',
    name: 'Bitcoin',
    chainId: undefined, // Bitcoin does not have a chainId as it is not an EVM-based network
    explorer: 'https://www.blockchain.com/explorer',
    explorerAddress: 'https://www.blockchain.com/explorer/addresses/btc/',
  },
  ETH: {
    logo: 'https://cryptologos.cc/logos/ethereum-eth-logo.png',
    name: 'Ethereum',
    chainId: 1,
    explorer: 'https://etherscan.io',
    explorerAddress: 'https://etherscan.io/address/',
  },
  SOLANA: {
    logo: 'https://cryptologos.cc/logos/solana-sol-logo.png',
    name: 'Solana',
    chainId: 501, // Solana does not have a chainId as it is not an EVM-based network
    explorer: 'https://explorer.solana.com',
    explorerAddress: 'https://explorer.solana.com/address/',
  },
  BASE: {
    logo: 'https://icons.llamao.fi/icons/chains/rsz_base.jpg',
    name: 'Base',
    chainId: 8453,
    explorer: 'https://basescan.org',
    explorerAddress: 'https://basescan.org/address/',
  },
  LINEA: {
    logo: 'https://icons.llamao.fi/icons/chains/rsz_linea.jpg',
    name: 'Linea',
    chainId: 59144,
    explorer: 'https://lineascan.build',
    explorerAddress: 'https://lineascan.build/address/',
  },
  OPTIMISM: {
    logo: 'https://cryptologos.cc/logos/optimism-ethereum-op-logo.svg',
    name: 'Optimism',
    chainId: 10,
    explorer: 'https://optimistic.etherscan.io',
    explorerAddress: 'https://optimistic.etherscan.io/address/',
  },
  ARBITRUM: {
    logo: 'https://cryptologos.cc/logos/arbitrum-arb-logo.svg',
    name: 'Arbitrum',
    chainId: 42161,
    explorer: 'https://arbiscan.io',
    explorerAddress: 'https://arbiscan.io/address/',
  },
  BSC: {
    logo: 'https://cryptologos.cc/logos/bnb-bnb-logo.svg',
    name: 'Binance Smart Chain',
    chainId: 56,
    explorer: 'https://bscscan.com',
    explorerAddress: 'https://bscscan.com/address/',
  },
  POLYGON_POS: {
    logo: 'https://cryptologos.cc/logos/polygon-matic-logo.svg',
    name: 'Polygon (PoS)',
    chainId: 137,
    explorer: 'https://polygonscan.com/',
    explorerAddress: 'https://polygonscan.com/address/',
  },
  BLAST: {
    logo: 'https://icons.llamao.fi/icons/chains/rsz_blast.jpg',
    name: 'Blast',
    chainId: 81457,
    explorer: 'https://blastscan.io',
    explorerAddress: 'https://blastscan.io/address/',
  },
  SCROLL: {
    logo: 'https://scrollscan.com//assets/scroll/images/svg/logos/chain-light.svg',
    name: 'Scroll',
    chainId: 534352,
    explorer: 'https://scroll-explorer.com',
    explorerAddress: 'https://scroll-explorer.com/address/',
  },
  AVAX: {
    logo: 'https://cryptologos.cc/logos/avalanche-avax-logo.svg',
    name: 'Avalanche',
    chainId: 43114,
    explorer: 'https://snowtrace.io',
    explorerAddress: 'https://snowtrace.io/address/',
  },
  MANTLE: {
    logo: 'https://cryptologos.cc/logos/mantle-mnt-logo.svg',
    name: 'Mantle',
    chainId: 5000,
    explorer: 'https://explorer.mantle.xyz',
    explorerAddress: 'https://explorer.mantle.xyz/address/',
  },
  CRONOS: {
    logo: 'https://cryptologos.cc/logos/cronos-cro-logo.svg',
    name: 'Cronos',
    chainId: 25,
    explorer: 'https://cronos.org/explorer',
    explorerAddress: 'https://cronos.org/explorer/address/',
  },
  GNOSIS: {
    logo: 'https://cryptologos.cc/logos/gnosis-gno-gno-logo.svg',
    name: 'Gnosis',
    chainId: 100,
    explorer: 'https://gnosisscan.io',
    explorerAddress: 'https://gnosisscan.io/address/',
  },
  ZKLINK_NOVA: {
    logo: 'https://explorer.zklink.io/images/icons/cion.png',
    name: 'zkLink Nova',
    chainId: 810180,
    explorer: 'https://explorer.zklink.io',
    explorerAddress: 'https://explorer.zklink.io/address/',
  },
  ZKSYNC: {
    logo: 'https://zksync.io/icons/apps/zksync-bridge.svg',
    name: 'zkSync',
    chainId: 324,
    explorer: 'https://explorer.zksync.io',
    explorerAddress: 'https://explorer.zksync.io/address/',
  },
  MODE: {
    logo: 'https://explorer.mode.network/assets/configs/network_logo_dark.jpg',
    name: 'Mode',
    chainId: 34443,
    explorer: 'https://explorer.mode.network',
    explorerAddress: 'https://explorer.mode.network/address/',
  },
  TAIKO: {
    logo: 'https://icons.llamao.fi/icons/chains/rsz_taiko.jpg',
    name: 'Taiko',
    chainId: 167000,
    explorer: 'https://taikoscan.io',
    explorerAddress: 'https://taikoscan.io/address/',
  },
  FANTOM: {
    logo: 'https://cryptologos.cc/logos/fantom-ftm-logo.svg',
    name: 'Fantom',
    chainId: 250,
    explorer: 'https://ftmscan.com',
    explorerAddress: 'https://ftmscan.com/address/',
  },
  MANTA: {
    logo: 'https://icons.llamao.fi/icons/chains/rsz_manta.jpg',
    name: 'Manta',
    chainId: 169,
    explorer: 'https://pacific-explorer.manta.network',
    explorerAddress: 'https://pacific-explorer.manta.network/address/',
  },
  BOUNCEBIT: {
    logo: 'https://icons.llamao.fi/icons/chains/rsz_bouncebit.jpg',
    name: 'BounceBit',
    chainId: 6001,
    explorer: 'https://bbscan.io',
    explorerAddress: 'https://bbscan.io/address/',
  },
  BOB: {
    logo: 'https://icons.llamao.fi/icons/chains/rsz_bob.jpg',
    name: 'Bob',
    chainId: 60808,
    explorer: 'https://explorer.gobob.xyz',
    explorerAddress: 'https://explorer.gobob.xyz/address/',
  },
  POLYGON_ZKEVM: {
    logo: 'https://icons.llamao.fi/icons/chains/rsz_polygon%20zkevm.jpg',
    name: 'Polygon zkEVM',
    chainId: 1101,
    explorer: 'https://zkevm.polygonscan.com',
    explorerAddress: 'https://zkevm.polygonscan.com/address/',
  },
  B3: {
    logo: 'https://explorer.b3.fun//assets/configs/network_icon.svg',
    name: 'B3',
    chainId: 8333,
    explorer: 'https://explorer.b3.fun',
    explorerAddress: 'https://explorer.b3.fun/address/',
  },
  MEVM: {
    logo: 'https://docs.movementnetwork.xyz/img/logo.svg',
    name: 'MEVM',
    chainId: 30730,
    explorer: 'https://explorer.movementlabs.xyz',
    explorerAddress: 'https://explorer.movementlabs.xyz/account',
  },
  LORENZO: {
    logo: 'https://docs.lorenzo-protocol.xyz/~gitbook/image?url=https%3A%2F%2F3127159353-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FO2IxV7UUnOLeIt1TpThj%252Ficon%252FDVchPNLA70R9R8E4K02y%252FUntitled-1.png%3Falt%3Dmedia%26token%3Df5b81478-759c-46a8-a9b6-85c8ba918f6a&width=32&dpr=2&quality=100&sign=48975294&sv=1',
    name: 'Lorenzo',
    chainId: 8329,
    explorer: 'https://scan.lorenzo-protocol.xyz',
    explorerAddress: 'https://scan.lorenzo-protocol.xyz/address/',
  },
  B2: {
    logo: 'https://explorer.bsquared.network/svgs/logo/bsquared-testnet.svg',
    name: 'B2',
    chainId: 213,
    explorer: 'https://explorer.bsquared.network',
    explorerAddress: 'https://explorer.bsquared.network/address/',
  },
  BITLAYER: {
    logo: 'https://icons.llamao.fi/icons/chains/rsz_bitlayer.jpg',
    name: 'BitLayer',
    chainId: 200901,
    explorer: 'https://www.btrscan.com/',
    explorerAddress: 'https://www.btrscan.com/address/',
  },
  TON: {
    logo: 'https://cryptologos.cc/logos/toncoin-ton-logo.svg',
    name: 'The Open Network (TON)',
    chainId: 1100,
    explorer: 'https://tonviewer.com/',
    explorerAddress: 'https://tonviewer.com/',
  },
};
