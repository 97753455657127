import { useAppRouter } from '@/hooks/router/useAppRouter';
import { ReactComponent as Home } from '@/assets/icon/nav/home.svg';
import { ReactComponent as HomeSelected } from '@/assets/icon/nav/home-selected.svg';
import { ReactComponent as Swap } from '@/assets/icon/nav/swap.svg';
import { ReactComponent as SwapSelected } from '@/assets/icon/nav/swap-selected.svg';
import { ReactComponent as Task } from '@/assets/icon/nav/task.svg';
import { ReactComponent as TaskSelected } from '@/assets/icon/nav/task-selected.svg';
import { ReactComponent as Profile } from '@/assets/icon/nav/profile.svg';
import { ReactComponent as ProfileSelected } from '@/assets/icon/nav/profile-selected.svg';
import { ReactComponent as Create } from '@/assets/icon/nav/create.svg';
import { ReactComponent as CreateSelected } from '@/assets/icon/nav/create-selected.svg';
import classNames from 'classnames';
import { PlusOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { useUserStore } from '@/stores/useUserStore';

// const items = [
//   {
//     icon: <Home />,
//     selectedIcon: <HomeSelected />,
//     label: 'Home',
//     path: '/',
//   },
//   {
//     icon: <Swap />,
//     selectedIcon: <SwapSelected />,
//     label: 'Swap',
//     path: '/swap',
//   },
//   {
//     icon: (
//       <div className="w-[44px] h-[44px] bg-white flex justify-center items-center rounded-full">
//         <PlusOutlined className="text-[18px]" />
//       </div>
//     ),
//     selectedIcon: (
//       <div className="w-[44px] h-[44px] bg-primary flex justify-center items-center rounded-full">
//         <PlusOutlined className="text-[18px]" />
//       </div>
//     ),
//     path: '/Create',
//   },
//   {
//     icon: <Task />,
//     selectedIcon: <TaskSelected />,
//     label: 'Task',
//     path: '/task',
//   },
//   {
//     icon: <Profile />,
//     selectedIcon: <ProfileSelected />,
//     label: 'profile',
//     path: '/profile',
//   },
// ];

export function Footer() {
  const router = useAppRouter();
  const { jwtInfo } = useUserStore();

  const { pathname } = useLocation();

  const isCurrentPath = (path: string) => {
    return pathname === path;
  };

  const items = useMemo(() => {
    return [
      {
        icon: <Home />,
        selectedIcon: <HomeSelected />,
        label: 'Home',
        path: '/',
      },
      {
        icon: <Create />,
        selectedIcon: <CreateSelected />,
        label: 'New Coin',
        path: '/create',
      },
      // {
      //   icon: (
      //     <div className="w-[44px] h-[44px] bg-white flex justify-center items-center rounded-full">
      //       <PlusOutlined className="text-[18px]" />
      //     </div>
      //   ),
      //   selectedIcon: (
      //     <div className="w-[44px] h-[44px] bg-primary flex justify-center items-center rounded-full">
      //       <PlusOutlined className="text-[18px]" />
      //     </div>
      //   ),
      //   path: '/Create',
      // },
      {
        icon: <Task />,
        selectedIcon: <TaskSelected />,
        label: 'Task',
        path: '/task',
      },
      {
        icon: <Profile />,
        selectedIcon: <ProfileSelected />,
        label: 'profile',
        path: `/profileWithId/${jwtInfo?.id || ''}`,
      },
    ];
  }, [jwtInfo?.id]);

  return (
    <div className="flex justify-between items-center px-horizontal w-full">
      {items.map((item, index) => {
        return (
          <div
            className={classNames(
              'flex justify-center items-center flex-col text-secondaryText active:opacity-85 text-[10px]',
              {
                '!text-primaryText': isCurrentPath(item.path),
              },
            )}
            onClick={() => {
              if (jwtInfo?.id && item.path === '/profile') {
                router.push(`/profile/id/${jwtInfo?.id}`);
              } else {
                router.push(item.path);
              }
            }}
            key={index}
          >
            {isCurrentPath(item.path) ? item?.selectedIcon : item?.icon}
            {item.label}
          </div>
        );
      })}
    </div>
  );
}
