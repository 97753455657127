import { useGlobalDataStore } from '@/context/GlobalDataContext';
import { HeldItem } from '@/context/ProfileContext';
import { fetchUserHeldCoins } from '@/service/user';
import { useWalletInfoStore } from '@/stores/useWalletInfoStore';
import { getCoinBalanceList } from '@/test';
import { getImageUri } from '@/utils/coin-detail';
import { formatByDecimals } from '@/utils/sol';
import { useRequest } from 'ahooks';
import { sumBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

export function useFetchUserHeldCoins({
  address,
  onSuccess,
}: {
  address?: string;
  onSuccess?: (data: any) => void;
}) {
  const { isTon } = useWalletInfoStore();
  const { usdPrice } = useGlobalDataStore();
  const [solData, setSolData] = useState<HeldItem[]>([]);
  const limit = 50;
  const [offset, setOffset] = useState(0);
  const [solLoading, setSolLoading] = useState(false);

  const hook = useRequest(
    async () => {
      const res = await fetchUserHeldCoins(address!);
      return {
        list: res.data.docs.map((item) => {
          return {
            name: item.name,
            imageUri: item.imageUri,
            poolAddress: item.poolAddress,
            price: item.price,
            balance: item.balance,
          };
        }),
        tonBalance: res.data.tonBalance,
      };
    },
    {
      debounceWait: 500,
      ready: !!address && isTon,
      refreshDeps: [address],
      onSuccess(data) {
        onSuccess?.(data);
      },
    },
  );

  const { run: fetch } = useRequest(getCoinBalanceList, {
    manual: true,
    debounceWait: 1000,
    ready: !!address,
    refreshDeps: [address],
    onSuccess: (result) => {
      solLoading && setSolLoading(false);
      if (result.length === limit && !result.find((item) => item.value === 0)) {
        setOffset((pre) => pre + limit);
        // 继续递归查询
        fetch({ user: address!, offset: offset + limit, limit });
      }
      const res = result.map((item) => {
        return {
          name: item.name,
          balance: formatByDecimals(item.balance).toFixed(0),
          imageUri: getImageUri(item.image_uri),
          poolAddress: item.mint,
          usdPrice: item.value * usdPrice,
        };
      });
      setSolData((prevItems) => [...prevItems, ...res]);
    },
  });

  useEffect(() => {
    if (!isTon && !!address && !solData.length) {
      setSolLoading(true);
      fetch({ user: address, offset, limit });
    }
  }, [address, fetch, isTon, offset, solData.length]);

  const solBalance = useMemo(() => sumBy(solData, 'usdPrice'), [solData]);

  return {
    list: isTon ? hook.data?.list : solData,
    loading: isTon ? hook.loading : solLoading,
    memoNativeBalance: isTon ? hook.data?.tonBalance : solBalance,
  };
}
