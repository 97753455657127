import { useAppMessage } from '@/hooks/useAppMessage';
import { unFollow } from '@/service/follows';
import { useRequest } from 'ahooks';

// 取消关注用户
export function useUnFollowUser({
  onSuccess,
}: {
  onSuccess?: () => void;
} = {}) {
  const message = useAppMessage();

  return useRequest(
    async (userId: string) => {
      const res = await unFollow(userId);

      return res.data;
    },
    {
      manual: true,
      onSuccess() {
        onSuccess?.();
      },
      onError() {
        message.error('Failed to unfollow');
      },
    },
  );
}
