import { G6Router } from '@/contract/wrappers/G6Router';
import { fromNano, toNano } from '@ton/core';
import { useRequest } from 'ahooks';
import { getProvider } from '@/utils';

export function useFetchFee({ tonAmountToPayForInitBuy }: { tonAmountToPayForInitBuy?: string }) {
  // const { provider } = useWallet({ address: process.env.REACT_APP_G6_ROUTER_ADDRESS });

  const { data, loading } = useRequest(
    async () => {
      const feeResult = await G6Router.getLaunchTxFee(
        await getProvider(process.env.REACT_APP_G6_ROUTER_ADDRESS!)!,
        {
          tonAmountToPayForInitBuy: tonAmountToPayForInitBuy
            ? toNano(tonAmountToPayForInitBuy)
            : 0n,
        },
      );

      return fromNano(feeResult.fee);
    },
    {
      refreshDeps: [tonAmountToPayForInitBuy],
      onSuccess(res) {
        console.log('launch-fee', res);
      },
      onError(err) {
        console.log('launch-fee-err', err);
      },
    },
  );

  return {
    fee: data,
    loading,
  };
}
