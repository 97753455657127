import { TonSendPayload } from '@/hooks/useSendTransactionByTomo';
import { DetailField } from '@/views/Swap/components/Common';
import { fromNano, toNano } from '@ton/core';
import { Button } from 'antd';
import { SaDrawer } from '../SaDrawer';

interface TransactionDetailModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  sendPayload: TonSendPayload | null;
  transactionLoading?: boolean;
}

export function TransactionDetailModal({
  open,
  onClose,
  onConfirm,
  sendPayload,
  transactionLoading,
}: TransactionDetailModalProps) {
  return (
    <SaDrawer title={'Confirm transaction'} open={open} onClose={onClose} height="auto">
      <div className="flex flex-col justify-start items-start w-full gap-y-3">
        <DetailField label="From" value={sendPayload?.fromAddress}></DetailField>

        <DetailField label="To" value={sendPayload?.toAddress}></DetailField>

        <DetailField
          label="Value"
          value={sendPayload?.value ? fromNano(sendPayload?.value) : '-'}
        ></DetailField>
      </div>

      <Button
        loading={transactionLoading}
        className="mt-8"
        type="primary"
        block
        onClick={() => {
          onConfirm();
        }}
      >
        Send
      </Button>
    </SaDrawer>
  );
}
