import { useG6PoolMethods } from '@/contract/hooks/useG6PoolMethods';
import { useRequest } from 'ahooks';
import { useParseRouterParam } from '@/hooks/router/useParseRouterParam';
import { Skeleton } from 'antd';
import { useContext } from 'react';
import { CoinDetailContext, useCoinDetailStore } from '../context/CoinDetailContext';
import { isUndefined } from 'lodash';

export function CurveProgress() {
  const { getPoolProgress } = useG6PoolMethods();

  const { param } = useParseRouterParam({ address: '' });

  const { detail, isTonData } = useCoinDetailStore();

  const { data: progress, loading } = useRequest(
    async () => {
      return getPoolProgress(param.address).then((data) => {
        return data ? +data.toFixed(2) : 0;
        // return data ? +data.toFixed(4) : 0;
      });
    },
    {
      ready: isTonData(detail),
      throttleWait: 1000,
      pollingInterval: 5000,
    },
  );

  const context = useContext(CoinDetailContext);

  return (
    <Skeleton active loading={isUndefined(progress) && (context.loading || loading)}>
      <div className="font-semibold text-base flex justify-between items-center">
        <div className="text-primaryText">Bonding curve progress</div>
        <div className="text-yellow">{progress ? `${progress * 100}%` : '-'}</div>
      </div>

      <div className="bg-[#242424] w-full h-[18px] mt-[16px]">
        <div
          className="bg-yellow w-full h-full"
          style={{
            width: `${progress! * 100}%`,
          }}
        ></div>
      </div>
    </Skeleton>
  );
}
