import request from '@/request';

type GetSwapSenderArgumentPayload = {
  amount: string;
  from: string;
  slippage?: string;
  to: string;
  userWalletAddress: string;
};

export function getSwapSenderArguments(payload: GetSwapSenderArgumentPayload) {
  return request.post(`/v1/aggregator/dex/getSwapSenderArguments`, payload);
}

export function postSwapComplete() {
  return request.post(`/tasks/user/swap-complete`, null);
}
