import { getBlockchainClient } from '@/contract/utils/client';
import { Address, ContractProvider } from '@ton/core';
import { TonConnectUI } from '@tonconnect/ui-react';
import { displayAddress } from './ton';

export function isMobile(): boolean {
  return window.innerWidth <= 500;
}

export function openLink(href: string, target = '_self') {
  window.open(href, target, 'noreferrer noopener');
}

export function getDisplayName(str: string) {
  try {
    const isAddress = Address.isAddress(Address.parse(str));

    if (isAddress) {
      return displayAddress(str);
    } else {
      return str;
    }
  } catch (err) {
    return str;
  }
}

export function getQueryParam(key: string) {
  const urlObj = new URL(window.location.href);

  const params = new URLSearchParams(urlObj.search);

  const queryParams: any = {};

  for (const [key, value] of params.entries()) {
    queryParams[key] = value;
  }

  return queryParams[key];
}

export function getRandomBigInt(max = 2n ** 32n) {
  const high = Math.floor(Math.random() * 2 ** 32);

  const low = Math.floor(Math.random() * 2 ** 32);

  const randomBigInt = (BigInt(high) << 32n) + BigInt(low);

  return randomBigInt % max;
}

export const onPressEnterBlur = (e: React.KeyboardEvent<HTMLInputElement>) => {
  (e.target as HTMLInputElement).blur();
};

export function toUserFriendlyAddress(address?: string) {
  try {
    return Address.parse(address!).toString({
      bounceable: false,
    });
  } catch (err) {
    return '';
  }
}

export function toInternalAddress(address?: string) {
  try {
    return Address.parse(address!).toString();
  } catch (err) {
    return '';
  }
}

export function getTonConnectUI() {
  return window.tonConnectUI as TonConnectUI;
}

export async function getProvider(address: string) {
  const BlockchainClient = await getBlockchainClient();

  const provider = BlockchainClient.provider(Address.parse(address!), null);

  return provider as ContractProvider;
}

export const checkBrowserSupport = () => {
  if (typeof window === 'undefined') {
    return;
  }

  // check browser support
  if (!navigator.credentials || !navigator.credentials.create) {
    throw new Error('Browser not supported.');
  }
};
