import { displayAddress } from '@/utils/ton';
import { Address } from '@ton/core';
import { Skeleton, Spin } from 'antd';
import { useCoinDetailStore } from '../context/CoinDetailContext';
import { useFetchHolderDistribution } from '../hooks/useFetchHolderDistribution';
import { formatAddress } from '@/utils/sol';
import { useSolWebSocketStore } from '@/context/SolWebSocketContext';
import { useEffect } from 'react';

export function HolderDistribution() {
  const { detail, isTonData, loading: contextLoading } = useCoinDetailStore();

  const { latestMintMessage } = useSolWebSocketStore();

  const { data, loading, refresh } = useFetchHolderDistribution();

  useEffect(() => {
    refresh();
  }, [latestMintMessage, refresh]);

  function isBondingCurve(address: string) {
    try {
      if (isTonData(detail)) {
        return Address.parse(detail!.poolAddress).equals(Address.parse(address));
      }
      return detail?.associated_bonding_curve === address;
    } catch (e) {
      return false;
    }
  }

  function isDev(address: string) {
    try {
      if (isTonData(detail)) {
        return Address.parse(detail!.creator).equals(Address.parse(address));
      }
      return detail?.creator === address;
    } catch (e) {
      return false;
    }
  }

  return (
    <>
      <div className="text-[18px] leading-6 mb-4 font-semibold text-primaryText">
        Holder distribution
      </div>
      <Skeleton active loading={!data?.length}>
        <Spin spinning={(contextLoading || loading) && !!data?.length}>
          <div>
            {data?.map((item, i) => {
              return (
                <div
                  key={item.address}
                  className="flex justify-between items-center font-normal h-[32px] mb-[8px]"
                >
                  <div className="flex justify-start items-center">
                    <div className="text-[#9B9B9B] min-w-[25px]">{i + 1}.</div>

                    <div className="text-primaryText">
                      {isTonData(detail)
                        ? displayAddress(item.ownerAddress)
                        : formatAddress(item.ownerAddress)}
                    </div>

                    {isBondingCurve(item.ownerAddress) && (
                      <span className="ml-[10px] text-[#9B9B9B]">(Bonding curve)</span>
                    )}

                    {isDev(item.ownerAddress) && (
                      <span className="ml-[10px] text-[#9B9B9B]">(Dev)</span>
                    )}
                  </div>
                  <div>{item.percent}%</div>
                </div>
              );
            })}
          </div>
        </Spin>
      </Skeleton>
    </>
  );
}
