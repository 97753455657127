import classNames from 'classnames';
import { Table, Empty, ConfigProvider, Input } from 'antd';
import { MultiChainToken } from '@/views/Home/components/MultiChainToken';
import { ReactComponent as IconEmpty } from '@/assets/icon/icon_empty.svg';
import { useAppRouter } from '@/hooks/router/useAppRouter';
import { useRequest } from 'ahooks';
import { ConnectButton } from '@/components/ConnectButton';
import { TomoTokenItem } from '@/types/Tomo';
import { ColumnType, TableProps } from 'antd/es/table';
import { useAppMessage } from '@/hooks/useAppMessage';
import { G6Math } from '@/contract/utils/Math';
import { priceFormat } from '@/utils/priceFormat';
import { fetchAllToken } from '@/service/coin';
import { useParseRouterQuery } from '@/hooks/router/useParseRouterQuery';
import { useRef, useState } from 'react';
import { useWalletInfoStore } from '@/stores/useWalletInfoStore';
import { formatNumber } from '../FlyV2';

type OnChange = NonNullable<TableProps<TomoTokenItem>['onChange']>;

type GetSingle<T> = T extends (infer U)[] ? U : never;

type Sorts = GetSingle<Parameters<OnChange>[2]>;

const CustomHeader = ({ name }: { name: string }) => (
  <div className="text-[12px] text-[#656565]">{name}</div>
);

export const CustomEmpty = ({ className }: { className?: string }) => {
  return (
    <div className={classNames('w-full h-full flex justify-center items-center', className)}>
      <Empty
        image={<IconEmpty />}
        imageStyle={{ height: 105, display: 'flex', justifyContent: 'center' }}
        description={<div></div>}
      >
        <div className="text-center text-[14px] leading-[21px] w-[200px] m-auto text-[#656565] font-[Poppins] font-[400]">
          Switch to Tomo Wallet to unlock full multi-chain functionality.
        </div>

        <div className="mt-4">
          <ConfigProvider
            theme={{
              components: {
                Button: {
                  controlHeight: 36,
                },
              },
            }}
          >
            <ConnectButton onlyTomo></ConnectButton>
          </ConfigProvider>
        </div>
      </Empty>
    </div>
  );
};
const columns: ColumnType<TomoTokenItem>[] = [
  {
    title: <CustomHeader name="Name/24h Vol" />,
    dataIndex: 'marketCapUsd',
    key: 'marketCapUsd',
    showSorterTooltip: false,
    sorter: true,
    render: (_, record) => (
      <div className="flex">
        <MultiChainToken symbol={record.symbol} token={record.imageUrl} chain={record.chain} />

        <div className="font-[Poppins] ml-[14px] relative">
          {/* <img src={logoIcon} className="w-[14px] h-[14px] absolute top-[4px] right-[-15px]" /> */}
          <div className="text-[16px] font-[500] leading-[20.8px] text-[#FFFFFF]">
            {record.symbol}
          </div>
          <div className="text-[12px] leading-[15.6px] font-[400] text-[#656565]">
            {priceFormat(record.marketCapUsd)}
          </div>
        </div>
      </div>
    ),
  },
  {
    title: <CustomHeader name="Price" />,
    dataIndex: 'priceUsd',
    key: 'priceUsd',
    showSorterTooltip: false,

    sorter: true,
    render: (_, record) => (
      <div className="flex justify-end truncate">
        <div className="font-[Poppins] text-[16px] leading-[20.8px] text-[#FFFFFF]">
          ${formatNumber(record.priceUsd)}
        </div>
      </div>
    ),
  },
  {
    title: <CustomHeader name="Price Change" />,
    dataIndex: 'priceChangeH24',
    key: 'priceChangeH24',

    width: 110,
    fixed: 'right',
    showSorterTooltip: false,
    sorter: true,
    render: (_, record) => {
      const val = +G6Math.from(record.priceChangeH24).toFixed(2);

      return (
        <div className="flex justify-end">
          <div
            className={classNames(
              'w-[88px] h-[32px] flex items-center justify-center rounded-[4px] text-primaryText font-[600]',
              {
                'bg-[#15BC76]': val > 0,
                'bg-secondaryBackground': val === 0,
                'bg-[#F54976]': val < 0,
              },
            )}
          >
            {isNaN(val) ? '-' : `${val}%`}
          </div>
        </div>
      );
    },
  },
];

export function TokenList() {
  const { goTomoCoinDetail } = useAppRouter();

  const message = useAppMessage();

  const ref = useRef<HTMLDivElement | null>(null);

  const { query, updateQuery } = useParseRouterQuery({
    current: '',
    page: '1',
    limit: '12',
    keyword: '',
    sort: 'marketCapUsd',
    sortOrder: '',
  });

  const { data, loading } = useRequest(
    async () => {
      const res = await fetchAllToken({
        ...query,
        page: +query.page,
        limit: +query.limit,
        keyword: query.keyword,
      });

      return res.data;
    },
    {
      onSuccess() {
        ref.current?.scrollTo(0, 0);
      },
      onError(err) {
        message.info(err.message || 'Fetch token failed');
      },
      refreshDeps: [query.page, query.limit, query.keyword, query.sort, query.sortOrder],
    },
  );

  const rowClick = (record: TomoTokenItem) => {
    goTomoCoinDetail({
      address: record.address || '',
      chain: record.chain,
      token: JSON.stringify(record!),
    });
  };

  const [search, setSearch] = useState('');

  const handleChange: OnChange = (pagination, filters, sorter: any) => {
    const map: any = {
      descend: 'desc',
      ascend: 'asc',
    };

    updateQuery({
      sort: sorter.field,
      sortOrder: map[sorter.order] || '',
      page: pagination.current?.toString(),
      limit: pagination.pageSize?.toString(),
    });
  };

  return (
    <div className="w-full h-full overflow-y-auto" ref={ref}>
      <div className="px-2 mt-2">
        <Input
          placeholder="Token name or address"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              updateQuery({
                keyword: search,
                page: '1',
                limit: '12',
                sort: 'marketCapUsd',
                sortOrder: '',
              });
            }
          }}
        ></Input>
      </div>
      <Table
        rowKey={(record) => `${record.id}${record.priceChangeH24}`}
        virtual
        onHeaderRow={(column) => {
          return {
            className: 'sticky top-0',
          };
        }}
        className="reset-table"
        dataSource={data?.docs}
        columns={columns}
        loading={loading}
        onChange={handleChange}
        pagination={{
          current: data?.page,
          total: data?.totalDocs,
          position: ['bottomCenter'],
          // onChange(page, pageSize) {
          //   updateQuery({
          //     ...query,
          //     page: page.toString(),
          //     limit: pageSize.toString(),
          //   });
          // },
        }}
        onRow={(record) => {
          return {
            onClick: () => rowClick(record),
          };
        }}
      ></Table>
    </div>
  );
}

export function Hot() {
  const { isTomoWallet, walletAddress } = useWalletInfoStore();

  return (
    <div className="w-full h-full">
      {isTomoWallet && walletAddress ? <TokenList /> : <CustomEmpty className="pt-[70px]" />}
    </div>
  );
}
