import { Button } from '@/components/Button';
import { Input, ConfigProvider } from 'antd';
import { useG6PoolMethods } from '@/contract/hooks/useG6PoolMethods';
import { useContext, useState } from 'react';
import { fromNano, toNano } from '@ton/core';
import { useRequest, useUnmount } from 'ahooks';
import { useAppMessage } from '@/hooks/useAppMessage';
import { TonValue } from '@/components/TonValue';
import { CoinDetailContext } from '../../context/CoinDetailContext';
import { G6Math } from '@/contract/utils/Math';
import classNames from 'classnames';
import { ConnectButton } from '@/components/ConnectButton';
import { useSwapJettonToTon } from '@/hooks/ston.fi/useSwapJettonToTon';
import { useSwapableTokenAmount } from '@/hooks/ston.fi/useSwapableTokenAmount';
import { TransitionDetail } from './TransitionDetail';
import { isUndefined } from 'lodash';
import { ControlBar } from './ControlBar';
import { useGlobalDataStore } from '@/context/GlobalDataContext';
import { SymbolUnit } from './SymbolUnit';
import { SendError } from '@/hooks/useSendTransactionByTomo';
import { onPressEnter } from '@/views/Create';
import { useNativeToken } from '@/hooks/app/useNativeToken';
import { useFetchJettonBalance } from '../../hooks/useFetchJettonBalance';
import { formatNumber } from '@/views/Home/modules/FlyV2';

export function SymbolSuffix({ icon, symbol }: { icon?: string; symbol?: string }) {
  return (
    <div className="inline-flex justify-start items-center gap-x-[5px] min-w-[60px] relative bottom-[-1px]">
      <div className="w-[24px]">
        <img className="w-[24px] h-[24px] rounded-full" src={icon} />
      </div>
      <span className="text-[15px]  font-semibold max-w-[200px] text-ellipsis overflow-hidden">
        {symbol}
      </span>
    </div>
  );
}

const loadingKey = 'LOADING';

export function SellUseStonFi() {
  const [symbolName] = useNativeToken();

  const { detail } = useContext(CoinDetailContext);

  const [count, setCount] = useState('');

  const { slippageValue } = useGlobalDataStore();

  const getSymbol = (e: string) => {};

  const { balance, balanceLoading, refreshBalance } = useFetchJettonBalance();

  const handlePut = (val: any) => {
    const value = new G6Math(balance).multiply(val);

    setCount(fromNano(value.toNumber().toFixed(0)));
  };

  const message = useAppMessage();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCount(e.target.value);
  };

  const onBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^\d.]/g, '');

    setCount(value);
  };

  const { runAsync: handleSell } = useSwapJettonToTon({
    offerJettonAddress: detail?.address,
    offerAmount: count,
    minAskAmount: '1',
  });

  const { run: runSell, loading: sellLoading } = useRequest(
    () => {
      const amount = new G6Math(count).subtract('0.0000001');

      if (new G6Math(amount).isGreaterThan(fromNano(balance) || 0n)) {
        return Promise.reject(new Error('Insufficient balance'));
      }

      return handleSell();
    },
    {
      manual: true,
      onSuccess() {
        // message.success('Transaction sent successfully');

        refreshBalance();

        setCount('');
      },
      onError(err) {
        if (err.message === SendError.CANCEL) {
          return;
        }

        message.error(err.message || 'Transaction sent failed');
      },
    },
  );

  const { data, priceImpactTooHigh, errorReason } = useSwapableTokenAmount({
    tokenAddress: detail?.address,
    offerAddressIsTon: false,
    offerUnits: count,
    slippageTolerance: slippageValue,
  });

  useUnmount(() => {
    message.messageInstance.destroy(loadingKey);
  });

  return (
    <ConfigProvider
      wave={{ disabled: true }}
      theme={{
        components: {
          Input: {
            colorBgContainer: '#1B1B1B',
            activeBg: '#1B1B1B',
            addonBg: '#1B1B1B',
            activeBorderColor: '#242424',
            colorSplit: '#1B1B1B',
            colorBorder: '#242424',
            hoverBorderColor: '#242424',
            colorText: '#ffffff50',
            borderRadius: 60,
            controlHeight: 56,
            fontSize: 24,
          },
        },
      }}
    >
      <div className="my-[10px] w-full">
        <ControlBar
          balance={balance}
          balanceLoading={balanceLoading}
          getSymbol={getSymbol}
          controlType={'sell'}
          suffix={detail?.symbol}
        />
      </div>

      <div>
        <Input
          className="w-full"
          onPressEnter={onPressEnter}
          // addonAfter={<SymbolSuffix symbol={detail?.symbol} icon={detail?.imageUri} />}
          suffix={<SymbolUnit detail={detail} isTon={false}></SymbolUnit>}
          defaultValue={count}
          value={count}
          onBlur={onBlur}
          onChange={handleChange}
        />

        <div
          className={classNames('flex items-center mt-3', {
            'border-0 border-b border-solid border-opacity-35  border-white pb-[10px]':
              !!count && !isUndefined(data),
          })}
        >
          <ul className="flex items-center text-[12px] leading-[15px] font-normal gap-[8px] ">
            <li
              className={classNames(
                'cursor-pointer bg-[#282828] px-[16px] py-[4px] rounded-[36px] font-normal',
              )}
              onClick={() => {
                handlePut(0.25);
              }}
            >
              25%
            </li>
            <li
              className={classNames(
                'cursor-pointer bg-[#282828] px-[16px] py-[4px] rounded-[36px] font-normal',
              )}
              onClick={() => {
                handlePut(0.5);
              }}
            >
              50%
            </li>
            <li
              className={classNames(
                'cursor-pointer bg-[#282828] px-[16px] py-[4px] rounded-[36px] font-normal',
              )}
              onClick={() => {
                handlePut(0.75);
              }}
            >
              75%
            </li>
            <li
              className={classNames(
                'cursor-pointer bg-[#282828] px-[16px] py-[4px] rounded-[36px] font-normal',
              )}
              onClick={() => {
                handlePut(1);
              }}
            >
              MAX
            </li>
          </ul>

          {/* <div className="pl-3 mt-[8px] text-white text-opacity-35">
            <TonValue value={balance} loading={balanceLoading} suffix={detail?.symbol}></TonValue>
          </div> */}
        </div>

        {data && (
          <>
            <div className="pl-3 mt-[8px] text-white text-opacity-35">
              Receive{' '}
              {formatNumber(
                G6Math.from(data.askUnits)
                  .divide(10 ** 9)
                  .toString(),
              )}{' '}
              {symbolName}
            </div>

            {/* <SellFee poolAddress={detail?.poolAddress} /> */}
          </>
        )}

        <ConnectButton className="w-full mt-[18px] text-[15px] ">
          <Button
            disabled={!+count || !!priceImpactTooHigh || !!errorReason}
            loading={sellLoading}
            type="primary"
            className="w-full mt-[18px] text-[15px] "
            onClick={() => {
              runSell();
            }}
          >
            <img className="w-[20px]" src="/static/images/stonfi-logo.svg" />
            {errorReason
              ? errorReason
              : priceImpactTooHigh
                ? 'Price impact is too high'
                : 'PLACE TRADE'}
          </Button>
        </ConnectButton>

        {data && count && (
          <div>
            <TransitionDetail offerAddressIsTon={false} data={data} />
          </div>
        )}
      </div>
    </ConfigProvider>
  );
}
