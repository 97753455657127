import { DownOutlined, LoadingOutlined, RightOutlined, SwapOutlined } from '@ant-design/icons';
import { Button, Card, ConfigProvider, Input, Skeleton } from 'antd';
import { useContext, useEffect, useMemo, useState } from 'react';
import { TokenSelect } from './components/TokenSelect';
import { SwapContext, useSwapContext } from './context/SwapContext';
import { ReactComponent as SettingIcon } from '@/assets/icon/app/swap-setting.svg';
import { ChainSelect } from './components/ChainSelect';
import { SlippageSetting } from './components/SlippageSetting';
import { DetailField, ImageField } from './components/Common';
import { RouteSelection } from './components/RouteSelection';
import { SwapHistory } from './components/SwapHistory';
import { CustomEmpty } from '../Home/modules/Hot';
import { TomoTokenItem } from '@/types/Tomo';
import { chains } from '@/constants';
import { formatUnits } from 'viem';
import { useSendTransactionByTomo } from '@/hooks/useSendTransactionByTomo';
import { getWalletAddress } from '@/components/CoinOperate/Send';
import { G6Math } from '@/contract/utils/Math';
import { IWeb3ChainType } from '@/types/App';
import { useAppMessage } from '@/hooks/useAppMessage';
import { useBalance } from '@/hooks/useTomoSdk';
import { useSessionStorageState } from 'ahooks';
import { postSwapComplete } from '@/service/swap';
import { useWalletInfoStore } from '@/stores/useWalletInfoStore';
import { useSendTransactionByTomoV2 } from '@/hooks/tomo/useSendTransactionByTomoV2';
import { useGetTokenBalance } from '@/hooks/app/useGetTokenBalance';
import numeral from 'numeral';

const formatAmount = (v: any) => {
  return numeral(v).format('0[.][000000]');
};

function DefaultSelectButton({
  onClick,
  className,
  label,
}: {
  onClick?: () => void;
  className?: string;
  label: string;
}) {
  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            controlHeight: 28,
          },
        },
        token: {},
      }}
    >
      <Button className={className} type="text" shape="round" onClick={onClick}>
        Select {label} <DownOutlined />
      </Button>
    </ConfigProvider>
  );
}

function From() {
  const [open, setOpen] = useState(false);

  const [chainSelectOpen, setChainSelectOpen] = useState(false);

  const { setFromToken, fromToken, setFromChain } = useContext(SwapContext);

  const { amount, setAmount, fromChain } = useContext(SwapContext);
  const [temAmount, setTemAmount] = useState(formatAmount(amount));

  const message = useAppMessage();

  // swap成功后amount会被清空 用来显示的amount也需要清空
  useEffect(() => {
    if (!amount && !!temAmount) {
      setTemAmount('');
    }
  }, [amount, temAmount]);

  const handleSelectChain = (chain: IWeb3ChainType) => {
    setFromChain?.(chain);
    setChainSelectOpen(false);
    setFromToken?.(undefined);
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const regex = /^\d*\.?\d{0,6}$/;

    if (regex.test(inputValue)) {
      setAmount?.(inputValue);
      setTemAmount(inputValue);
    }
  };

  return (
    <>
      <div className="bg-secondaryBackground py-[12px] px-[16px] rounded-lg flex flex-col gap-y-[12px]">
        <div className="flex justify-between items-center">
          {fromChain ? (
            <ImageField
              src={fromChain!.icon}
              label={fromChain!.name}
              size={20}
              suffix={<DownOutlined className="text-[10px]" />}
              labelClassName="!text-[12px]"
              onClick={() => {
                setChainSelectOpen(true);
              }}
            />
          ) : (
            <DefaultSelectButton
              className="ml-[-15px]"
              label="chain"
              onClick={() => {
                setChainSelectOpen(true);
              }}
            />
          )}

          <ChainSelect
            onSelect={(chain) => {
              handleSelectChain?.(chain);
            }}
            open={chainSelectOpen}
            onCancel={() => {
              setChainSelectOpen(false);
            }}
          />
        </div>

        <div className="flex justify-between items-center">
          <div className="text-2xl max-w-[200px]">
            <Input
              value={temAmount}
              onChange={(e) => {
                handleInput?.(e);
              }}
              className="text-xl px-0 py-0 font-normal text-primaryText"
              placeholder="0.0"
              variant="borderless"
            />
          </div>
          {fromToken ? (
            <ImageField
              size={28}
              key={fromToken?.symbol}
              className="cursor-pointer"
              onClick={() => {
                setOpen(true);
              }}
              labelClassName="!text-[16px]"
              suffix={<DownOutlined />}
              src={fromToken?.imageUrl}
              label={fromToken?.symbol}
            />
          ) : (
            <DefaultSelectButton
              label="token"
              className="mr-[-15px]"
              onClick={() => {
                if (!fromChain?.name) {
                  message.error('Please select from chain first');

                  return;
                }
                setOpen(true);
              }}
            />
          )}
        </div>

        <div className="flex justify-end items-center gap-x-[11px]">
          <div className="ml-auto text-tertiaryText font-[400] text-[13px]">
            Balance :
            <span className="text-primaryText ml-1">
              {fromToken ? (
                <Balance
                  key={fromToken?.symbol}
                  token={fromToken}
                  onSetMax={(val) => {
                    setTemAmount(formatAmount(val));
                    setAmount?.(val.toString());
                  }}
                />
              ) : (
                '-'
              )}
            </span>
          </div>
        </div>
      </div>
      <TokenSelect
        chain={fromChain}
        open={open}
        onSelect={(asset) => {
          setFromToken?.(asset);
          setOpen(false);
        }}
        onCancel={() => {
          setOpen(false);
        }}
      ></TokenSelect>
    </>
  );
}

export function formatBigNumber(value?: string | bigint, decimals?: number) {
  try {
    return formatUnits(BigInt(value!), decimals!);
  } catch (err) {
    return '-';
  }
}

export function LazyBalance({
  token,
  queryBalanceData,
}: {
  token?: TomoTokenItem;
  queryBalanceData?: {
    chainId: number;
    address: string;
    decimals: number;
  };
  onLoaded?: (val: string | bigint) => void;
}) {
  const [val, setValue] = useSessionStorageState<{ deadline: number; val: string }>(
    `${token?.chain}-${token?.address}-${token?.symbol}`,
  );

  const value = useMemo(() => {
    try {
      return parseFloat(
        G6Math.from(
          formatBigNumber(BigInt(val?.val || '0'), queryBalanceData?.decimals || token?.decimals),
        ).toFixed(6),
      );
    } catch (err) {
      return 0;
    }
  }, [queryBalanceData?.decimals, token?.decimals, val]);

  if (val && val.deadline > +new Date()) {
    return <span>{value}</span>;
  }

  return (
    <Balance
      onLoaded={(val) => {
        setValue({
          deadline: +new Date() + 3 * 60 * 1000,
          val: val.toString(),
        });
      }}
    ></Balance>
  );
}

export function Balance({
  token,
  onSetMax,
  queryBalanceData,
  onLoaded,
}: {
  token?: TomoTokenItem;
  queryBalanceData?: {
    chainId: number;
    address: string;
    decimals: number;
  };
  onSetMax?: (val: number) => void;
  onLoaded?: (val: string | bigint) => void;
}) {
  // const {
  //   data: balanceData,
  //   isLoading,
  //   isFetched,
  // } = useBalance({
  //   chainId: queryBalanceData?.chainId || chains[token?.chain || '']?.chainId,
  //   token: queryBalanceData?.address || token?.address || '',
  // });

  const {
    data: balanceData,
    isLoading,
    isFetched,
  } = useGetTokenBalance({
    chainId: queryBalanceData?.chainId || chains[token?.chain || '']?.chainId,
    token: queryBalanceData?.address || token?.address || '',
  });

  // console.log('balanceData------->', balanceData);

  // console.log('token------->', {
  //   chainId: chains[token?.chain || '']?.chainId,
  //   token: token?.address || '',
  // });

  useEffect(() => {
    if (isFetched && balanceData) {
      onLoaded?.(balanceData.value);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetched, balanceData?.value]);

  const value = useMemo(() => {
    try {
      return G6Math.from(
        formatBigNumber(BigInt(balanceData!.value), queryBalanceData?.decimals || token?.decimals),
      ).toNumber();
    } catch (err) {
      return 0;
    }
  }, [balanceData, queryBalanceData?.decimals, token?.decimals]);

  return (
    <span className="ml-1 text-tertiaryText">
      {isLoading ? (
        <LoadingOutlined />
      ) : (
        <>
          {formatAmount(value)}

          {onSetMax && (
            <div
              className="bg-[#242424] font-medium ml-2 text-center py-[4px] px-[3px] text-green text-[12px] leading-[12px] active:opacity-85 inline-block rounded"
              onClick={() => {
                onSetMax?.(value);
              }}
            >
              MAX
            </div>
          )}
        </>
      )}
    </span>
  );
}

function To() {
  const [open, setOpen] = useState(false);

  const { setToToken, toToken, setToChain, toChain, swapData } = useContext(SwapContext);

  const [chainSelectOpen, setChainSelectOpen] = useState(false);
  const message = useAppMessage();

  const handleSelectChain = (chain: IWeb3ChainType) => {
    setToChain?.(chain);
    setChainSelectOpen(false);
    setToToken?.(undefined);
  };

  return (
    <>
      <div className="bg-secondaryBackground py-[12px] px-[16px] rounded-lg flex flex-col gap-y-[12px] mt-[2px]">
        <div className=" flex justify-between items-center">
          {toChain ? (
            <ImageField
              onClick={() => {
                setChainSelectOpen(true);
              }}
              src={toChain?.icon}
              label={toChain?.name}
              size={20}
              labelClassName="!text-[12px]"
              suffix={<DownOutlined className="text-[10px]" />}
            />
          ) : (
            <DefaultSelectButton
              className="ml-[-15px]"
              label="chain"
              onClick={() => {
                setChainSelectOpen(true);
              }}
            />
          )}

          <ChainSelect
            onSelect={(chain) => {
              handleSelectChain?.(chain);
            }}
            open={chainSelectOpen}
            onCancel={() => {
              setChainSelectOpen(false);
            }}
          />
        </div>
        <div className="flex justify-between items-center">
          <div>
            <div className="text-secondaryText font-normal text-[13px] leading-[17px]">
              Est. Receive
            </div>
            <div className="text-xl text-primaryText font-[400]">
              {formatAmount(formatBigNumber(swapData?.min_receive_amount, toToken?.decimals))}
            </div>
          </div>

          {toToken ? (
            <ImageField
              size={28}
              key={toToken?.symbol}
              className="cursor-pointer"
              onClick={() => {
                setOpen(true);
              }}
              src={toToken?.imageUrl}
              label={toToken?.symbol}
              labelClassName="!text-[16px]"
              suffix={<DownOutlined />}
            />
          ) : (
            <DefaultSelectButton
              label="token"
              className="mr-[-15px]"
              onClick={() => {
                if (!toChain?.name) {
                  message.error('Please select from chain first');

                  return;
                }
                setOpen(true);
              }}
            />
          )}
        </div>
      </div>

      <TokenSelect
        chain={toChain}
        open={open}
        onSelect={(asset) => {
          setToToken?.(asset);
          setOpen(false);
        }}
        onCancel={() => {
          setOpen(false);
        }}
      ></TokenSelect>
    </>
  );
}

function SwapIcon() {
  const { reverseToken } = useContext(SwapContext);

  return (
    <div>
      <div
        onClick={() => {
          reverseToken?.();
        }}
        className="absolute left-[calc(50%_-_18px)] select-none active:scale-95 flex justify-center items-center cursor-pointer w-[36px] h-[36px] rounded-full bg-secondaryBackground border-[1px] border-solid border-primaryBackground mx-auto mt-[-18px]"
      >
        <SwapOutlined className="rotate-90" />
      </div>
    </div>
  );
}

function SwapButton() {
  const message = useAppMessage({ key: 'swap-button' });
  const { swapData, fromToken, amount, setAmount } = useContext(SwapContext);
  const { walletAddress } = useWalletInfoStore();

  const { data: balanceData } = useGetTokenBalance({
    chainId: chains[fromToken?.chain || '']?.chainId,
    token: fromToken?.address || '',
  });

  // const { send, loading } = useSendTransactionByTomo({
  //   token: fromToken!,
  //   amount: amount!,
  //   to: swapData?.transaction.to,
  //   value: swapData?.transaction.value,
  //   walletAddress: getWalletAddress(fromToken)!,
  //   onSendSuccess() {
  //     setAmount?.('');
  //   },
  // });

  const { send, loading } = useSendTransactionByTomo({
    token: fromToken!,
    amount: amount!,
    to: swapData?.transaction.to,
    value: swapData?.transaction.value,
    walletAddress: walletAddress!,
    onSendSuccess() {
      setAmount?.('');
      postSwapComplete();
    },
  });

  const handleSend = async () => {
    if (!amount) {
      message.error('Invalid amount');
      return;
    } else if (
      !balanceData?.value ||
      formatUnits(balanceData?.value, balanceData?.decimals) < amount
    ) {
      message.error('Insufficient balance');
      return;
    }
    send({
      data: swapData?.transaction.data || swapData?.origin_data,
    }).catch(() => null);
  };

  return (
    <Button
      loading={loading}
      disabled={!swapData}
      className="mt-[30px]"
      type="primary"
      block
      onClick={() => {
        handleSend();
      }}
    >
      Continue
    </Button>
  );
}

function TonDetail() {
  const { swapData, unSupported, swapDataLoading, readyToBuildTx } = useContext(SwapContext);

  return (
    <Card className="mt-[8px] bg-transparent [&_.g6-card-body]:p-[18px]">
      <Skeleton className="min-w-[80px]" active paragraph={{ rows: 4 }} loading={swapDataLoading}>
        <div className="flex flex-col gap-y-[12px]">
          <DetailField
            label="Est. Gas"
            value={!readyToBuildTx ? '-' : swapData?.transaction.gas || '-'}
          ></DetailField>
          <DetailField label="Est. Time" value={'-'}></DetailField>
          <Slippage />
          {/* <DetailField label="Quote route"></DetailField> */}
          <DetailField
            label="Provider"
            value={!readyToBuildTx ? '-' : unSupported ? 'Not supported' : swapData?.dex_name}
          ></DetailField>
          {/* <RouteSelectionField /> */}
        </div>
      </Skeleton>
    </Card>
  );
}

function Detail() {
  const { allAreTon } = useContext(SwapContext);

  return allAreTon ? <TonDetail /> : <DefaultDetail />;
}

function DefaultDetail() {
  const { swapData, unSupported, swapDataLoading } = useContext(SwapContext);

  return (
    <Card className="mt-[8px] bg-transparent [&_.g6-card-body]:p-[16px]">
      <Skeleton className="min-w-[80px]" active paragraph={{ rows: 4 }} loading={swapDataLoading}>
        <div className="flex flex-col gap-y-[12px]">
          <DetailField label="Est. Gas" value={swapData?.transaction.gas || '-'}></DetailField>
          <DetailField label="Est. Time" value={'-'}></DetailField>
          <Slippage />
          {/* <DetailField label="Quote route"></DetailField> */}
          <DetailField
            label="Provider"
            value={unSupported ? 'Not supported' : swapData?.dex_name}
          ></DetailField>
          {/* <RouteSelectionField /> */}
        </div>
      </Skeleton>
    </Card>
  );
}

function RouteSelectionField() {
  const [open, setOpen] = useState(false);

  return (
    <>
      <DetailField
        onClick={() => {
          setOpen(true);
        }}
        label="Bridge route"
        value={<ImageField src="/static/images/ton_symbol.svg" label="Ton" size={12} />}
      ></DetailField>

      <RouteSelection
        open={open}
        onCancel={() => {
          setOpen(false);
        }}
      />
    </>
  );
}

function Slippage() {
  const [open, setOpen] = useState(false);

  const { slippageConf } = useContext(SwapContext);

  return (
    <>
      <DetailField
        onClick={() => {
          setOpen(true);
        }}
        label="Slippage"
        value={
          <div>
            {slippageConf.value}% <RightOutlined />
          </div>
        }
      ></DetailField>

      <SlippageSetting
        open={open}
        onCancel={() => {
          setOpen(false);
        }}
      />
    </>
  );
}

function History() {
  const [open, setOpen] = useState(false);

  return (
    <>
      <SettingIcon
        className="active:opacity-85"
        onClick={() => {
          setOpen(true);
        }}
      />

      <SwapHistory
        open={open}
        onCancel={() => {
          setOpen(false);
        }}
      />
    </>
  );
}

function Swap() {
  return (
    <div className="mx-auto relative px-[8px]">
      <div className="mb-[8px] h-[48px] px-[10px] flex justify-between items-center">
        <div className="text-2xl text-primaryText">Swap</div>

        {/* <History /> */}
      </div>

      <From />

      <SwapIcon />

      <To />

      <Detail />

      <SwapButton />
    </div>
  );
}

function SwapMain() {
  const {
    fromToken,
    toToken,
    fromBalance,
    fromBalanceLoading,
    toBalance,
    toBalanceLoading,
    amount,
    setAmount,
    setFromToken,
    setToToken,
    reverseToken,
    slippageConf,
    setSlippageConf,
    swapData,
    setFromChain,
    setToChain,
    toChain,
    fromChain,
    unSupported,
    allAreTon,
    swapDataLoading,
    tonSwapData,
    tonSwapDataLoading,
    readyToBuildTx,
  } = useSwapContext();

  return (
    <div>
      <SwapContext.Provider
        value={{
          toChain,
          fromChain,
          fromToken,
          toToken,
          fromBalance,
          fromBalanceLoading,
          toBalance,
          toBalanceLoading,
          amount,
          setAmount,
          setFromToken,
          setToToken,
          reverseToken,
          slippageConf,
          setSlippageConf,
          swapData,
          setFromChain,
          setToChain,
          unSupported,
          allAreTon,
          swapDataLoading,
          tonSwapData,
          tonSwapDataLoading,
          readyToBuildTx,
        }}
      >
        <Swap />
      </SwapContext.Provider>
    </div>
  );
}

export default function SwapWidget() {
  const { isTomoWallet, walletAddress: address } = useWalletInfoStore();

  return (
    <div className="w-full h-full">{isTomoWallet && address ? <SwapMain /> : <CustomEmpty />}</div>
  );
}
