import { getWalletAddress } from '@/components/CoinOperate/Send';
import { chains } from '@/constants';
import { G6Math } from '@/contract/utils/Math';
import { useTomoWallet } from '@/hooks/useTomoWallet';
import { getSwapSenderArguments } from '@/service/swap';
import { fetchSwapData, SwapData } from '@/service/tomo';
import { useWalletInfoStore } from '@/stores/useWalletInfoStore';
import { IWeb3ChainType } from '@/types/App';
import { TomoTokenItem } from '@/types/Tomo';
import { toNano } from '@ton/core';
import { useRequest, useSessionStorageState } from 'ahooks';
import { createContext, useMemo } from 'react';
import { parseUnits } from 'viem';

type Token = TomoTokenItem;

type SlippageConf = {
  type: 'auto' | 'custom';
  value: string;
};

export const SwapContext = createContext<{
  fromToken?: Token;
  toToken?: Token;
  fromChain?: IWeb3ChainType;
  toChain?: IWeb3ChainType;
  setFromToken?: (from?: Token) => void;
  setToToken?: (to?: Token) => void;
  setFromChain?: (from?: any) => void;
  setToChain?: (to?: any) => void;
  reverseToken?: () => void;
  fromBalance: bigint;
  fromBalanceLoading: boolean;
  toBalance: bigint;
  toBalanceLoading: boolean;
  amount?: string;
  setAmount?: (val: string) => void;
  slippageConf: SlippageConf;
  setSlippageConf?: (val: SlippageConf) => void;
  swapData?: SwapData;
  unSupported: boolean;
  allAreTon: boolean;
  swapDataLoading: boolean;
  tonSwapData: any;
  tonSwapDataLoading: boolean;
  readyToBuildTx: boolean;
}>({
  fromBalance: 0n,
  fromBalanceLoading: false,
  toBalance: 0n,
  toBalanceLoading: false,
  slippageConf: {
    type: 'auto',
    value: '1',
  },
  unSupported: false,
  allAreTon: false,
  swapDataLoading: false,
  tonSwapData: {},
  tonSwapDataLoading: false,
  readyToBuildTx: false,
});

function padPrefix(key: string) {
  return `SwapContext_${key}`;
}

export function getSafeChainId(chain: IWeb3ChainType): number | undefined {
  try {
    return chain.chain?.id || chains[chain?.name?.toLocaleUpperCase()].chainId!;
  } catch (e) {
    return;
  }
}

export const useSwapContext = () => {
  const [fromToken, setFromToken] = useSessionStorageState<Token>(padPrefix('fromToken'));

  const [toToken, setToToken] = useSessionStorageState<Token>(padPrefix('toToken'));

  const [fromChain, setFromChain] = useSessionStorageState<IWeb3ChainType>(padPrefix('fromChain'));

  const [toChain, setToChain] = useSessionStorageState<IWeb3ChainType>(padPrefix('toChain'));

  const [amount, setAmount] = useSessionStorageState<string>(padPrefix('amount'));

  const [slippageConf, setSlippageConf] = useSessionStorageState<SlippageConf>(
    padPrefix('slippageConf'),
    {
      defaultValue: {
        type: 'auto',
        value: '1',
      },
    },
  );

  function reverseToken() {
    setFromToken(toToken);
    setToToken(fromToken);
    setFromChain(toChain);
    setToChain(fromChain);
  }

  const { walletAddress } = useWalletInfoStore();

  const { data: fromBalance = toNano(0), loading: fromBalanceLoading } = useRequest(
    async () => {
      return 0n;
    },
    {
      ready: !!walletAddress && !!fromToken,
      refreshDeps: [fromToken],
      onError(err) {
        console.log(err);
      },
    },
  );

  const { data: toBalance = toNano(0), loading: toBalanceLoading } = useRequest(
    async () => {
      return 0n;
    },
    {
      ready: !!walletAddress && !!toToken,
      refreshDeps: [toToken],
      onError(err) {
        console.log(err);
      },
    },
  );

  // const { userInfo } = useTomoWallet();

  const allAreTon = useMemo(() => {
    return false;
  }, []);

  const { data: tonSwapData, loading: tonSwapDataLoading } = useRequest(
    async () => {
      const res = await getSwapSenderArguments({
        from: fromToken!.address,
        to: toToken!.address,
        userWalletAddress: walletAddress!,
        amount: parseUnits(amount!, fromToken!.decimals).toString(),
        slippage: G6Math.from(slippageConf!.value).divide(100).toString(),
      });

      return (res as any).data as SwapData;
    },
    {
      ready: !!walletAddress && !!toToken && !!fromToken && !!amount && allAreTon,
      refreshDeps: [toToken, fromToken, amount, slippageConf],
      onSuccess(res) {
        console.log('getSwapSenderArguments---->', res);
      },
      onError(err) {
        console.log('getSwapSenderArguments-err------->', err);
      },
      debounceWait: 1500,
    },
  );

  const readyToBuildTx = useMemo(() => {
    return !!walletAddress && !!toToken && !!fromToken && !!amount && !!fromChain && !!toChain;
  }, [walletAddress, amount, fromChain, fromToken, toChain, toToken]);

  const { data: swapData, loading: swapDataLoading } = useRequest(
    async () => {
      const res = await fetchSwapData({
        fromChainid: getSafeChainId(fromChain!)!,
        toChainid: getSafeChainId(toChain!)!,
        fromAddress: fromToken!.address,
        toAddress: toToken!.address,
        amount: parseUnits(amount!, fromToken!.decimals).toString(),
        slippage: G6Math.from(slippageConf!.value).multiply(10).toString(),
        // fromWalletAddress: getWalletAddress(fromToken, userInfo)!,
        // toWalletAddress: getWalletAddress(toToken, userInfo)!,
        // TODO: true address
        fromWalletAddress: walletAddress!,
        toWalletAddress: walletAddress!,
      });

      return (res as any).data as SwapData;
    },
    {
      ready: readyToBuildTx,
      refreshDeps: [toToken, fromToken, amount, fromChain, toChain, slippageConf],
      onSuccess(res) {
        console.log('fetchSwapData---->', res);
      },
      onError(err) {
        console.log('fetchSwapData-err------->', err);
      },
      debounceWait: 1000,
    },
  );

  const unSupported = useMemo(() => {
    return !!(
      fromToken &&
      toToken &&
      fromChain &&
      toChain &&
      !!amount &&
      !swapData &&
      !swapDataLoading
    );
  }, [amount, fromChain, fromToken, swapData, swapDataLoading, toChain, toToken]);

  return {
    fromToken,
    toToken,
    fromBalance,
    fromBalanceLoading,
    toBalance,
    toBalanceLoading,
    amount,
    setAmount,
    setFromToken,
    setToToken,
    reverseToken,
    fromChain,
    setFromChain,
    toChain,
    setToChain,
    slippageConf: slippageConf!,
    setSlippageConf,
    swapData: swapData,
    unSupported,
    allAreTon,
    swapDataLoading,
    tonSwapData,
    tonSwapDataLoading,
    readyToBuildTx,
  };
};
