import { Segmented, ConfigProvider } from 'antd';

export function ButtonGroup({ onValueChange }: { onValueChange: any }) {
  const handleChange = (val: any) => {
    onValueChange(val);
  };

  return (
    <ConfigProvider
      wave={{ disabled: true }}
      theme={{
        components: {
          Segmented: {
            itemActiveBg: '#483fff',
            itemColor: '#9B9B9B',
            itemHoverBg: '#483fff',
            itemHoverColor: '#9B9B9B',
            itemSelectedBg: '#483fff',
            itemSelectedColor: '#fff',
            controlHeight: 42,
            borderRadius: 56,
            borderRadiusSM: 56,
            trackBg: '#242424',
            trackPadding: 4,
            fontSize: 15,
            fontWeightStrong: 600,
          },
        },
      }}
    >
      <Segmented
        className=" font-semibold"
        block
        options={['BUY', 'SELL']}
        onChange={(value) => {
          handleChange(value);
        }}
      />
    </ConfigProvider>
  );
}
