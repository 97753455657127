import { Tabs } from '@/components/Tabs';
import { useState, useEffect, useMemo } from 'react';
import { AccountInfo } from './components/AccountInfo';
import { CoinsHeld } from './components/CoinsHeld';
import { CoinsCreated } from './components/CoinsCreated';
import React from 'react';
import { useWindowResize } from '@/hooks/useWindowResize';
import { useParseRouterQuery } from '@/hooks/router/useParseRouterQuery';
import { useParseRouterParam } from '@/hooks/router/useParseRouterParam';
import { ConnectButton } from '@/components/ConnectButton';
import { useAppRouter } from '@/hooks/router/useAppRouter';
import { useLocation } from 'react-router-dom';
import { Button, ConfigProvider } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import ProfileProvider from '@/context/ProfileContext';
import { useUserStore } from '@/stores/useUserStore';
import { useWalletInfoStore } from '@/stores/useWalletInfoStore';

function Card({ children }: { children?: React.ReactNode }) {
  return <div className="mb-6">{children}</div>;
}

const tabs = [
  {
    label: 'Assets',
    key: 'coinsHeld',
  },
  {
    label: 'Memes',
    key: 'coinsCreated',
  },
];

export function Profile() {
  const [tabItem, setTabItem] = useState('coinsHeld');

  const { query, updateQuery } = useParseRouterQuery({
    current: 'coinsHeld',
  });

  const tabsChange = (key: string) => {
    setTabItem(key);
    updateQuery({ ...query, current: key });
  };

  const { width } = useWindowResize();

  const isMobile = useMemo(() => {
    return width <= 767;
  }, [width]);

  const DynamicComponent = useMemo(() => {
    switch (tabItem) {
      case 'info':
        return <AccountInfo />;
      case 'coinsHeld':
        return <CoinsHeld />;
      // case 'replies':
      //   return <Comments />;
      // case 'notifications':
      //   return <Notifications />;
      case 'coinsCreated':
        return <CoinsCreated />;
      // case 'followers':
      //   return <Followers  />;
      // case 'following':
      //   return <Following  />;
      // case 'message':
      //   return <Replies />;
      default:
        return null;
    }
  }, [tabItem]);

  useEffect(() => {
    setTabItem(query.current || 'coinsHeld');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.current]);

  return (
    <div className="w-[62%] mx-auto md:w-auto">
      <AccountInfo />
      <Card>
        <Tabs
          isMobile={isMobile}
          className="w-full px-0 mt-1 profileTabs font-normal"
          activeKey={tabItem}
          items={tabs}
          onChange={tabsChange}
        ></Tabs>
        {DynamicComponent}
      </Card>
    </div>
  );
}

export default function () {
  const { param } = useParseRouterParam({ address: '', userId: '' });

  const { jwtInfo } = useUserStore();

  const { goProfileWithUserId } = useAppRouter();

  const { isConnect } = useWalletInfoStore();

  useEffect(() => {
    if (!param.address && !param.userId && jwtInfo?.id) {
      goProfileWithUserId(jwtInfo?.id);
    }
  }, [param.address, jwtInfo?.id, param.userId, goProfileWithUserId]);

  const { pathname } = useLocation();

  const isUserDetail = useMemo(() => {
    return pathname.startsWith('/user');
  }, [pathname]);

  const { goBack } = useAppRouter();

  return (
    <div className="w-full h-full">
      {isConnect && (param.address || param.userId) ? (
        <>
          {isUserDetail && (
            <div className="flex justify-start items-center h-[48px] bg-primaryBackground sticky top-0 z-20">
              <ConfigProvider
                theme={{
                  components: {
                    Button: {
                      controlHeight: 36,
                    },
                  },
                }}
              >
                <Button
                  type="text"
                  className="text-secondaryText"
                  onClick={() => {
                    goBack();
                  }}
                >
                  <LeftOutlined /> Back
                </Button>
              </ConfigProvider>
            </div>
          )}
          <ProfileProvider>
            <Profile />
          </ProfileProvider>
        </>
      ) : (
        <div className="w-full h-full flex justify-center items-center">
          <ConnectButton />
        </div>
      )}
    </div>
  );
}
