import { Modal } from 'antd';
import { AxiosError } from 'axios';
import { useUserStore } from '@/stores/useUserStore';
import { getTonConnectUI } from '@/utils';

function disconnect() {
  try {
    getTonConnectUI()?.disconnect();
  } catch (e) {
    //
  }
}

/**
 * error handler
 * @param err
 * @returns
 */
export const errorHandler = async (err: AxiosError) => {
  switch (err.status) {
    case 401:
      if (useUserStore.getState().token) {
        Modal.confirm({
          zIndex: 10000,
          className: 'error-modal',
          icon: null,
          centered: true,
          content: 'Login has expired, please reconnect wallet',
          async onOk() {
            disconnect();
            useUserStore.getState().loginOut();
            window.location.reload();
          },
        });
      }

      return Promise.reject(err);
    default:
      return Promise.reject(err);
  }
};
