import { ConfigProvider } from 'antd';
import { useResponsive } from '@/hooks/useResponsive';

export const FormTheme: React.FC<{ children: any }> = ({ children }) => {
  const { isMobile } = useResponsive();

  return (
    <ConfigProvider
      theme={{
        components: {
          Input: {
            controlHeight: 48,
            colorBgContainer: '#1B1B1B',
            borderRadius: 4,
            hoverBorderColor: '#1B1B1B',
            colorTextPlaceholder: '#444444',
            controlOutlineWidth: 2,
            lineWidth: 1,
            fontFamily: 'Inter',
          },
          Form: {
            labelFontSize: isMobile ? 15 : 20,
            fontWeightStrong: 600,
            verticalLabelPadding: '0 0 9px',
            lineHeight: 1,
            itemMarginBottom: isMobile ? 18 : 30,
          },
        },
        token: {
          controlOutline: 'transparent',
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};
