import React, { createContext, useContext, useMemo } from 'react';
import { useParseRouterParam } from '@/hooks/router/useParseRouterParam';
import { useFetchUserHeldCoins } from '@/views/Profile/hooks/useFetchUserHeldCoins';
import { ChainType, useWalletInfoStore } from '@/stores/useWalletInfoStore';
import { useGetUserNativeBalance } from '@/hooks/app/useGetUserNativeBalance';
import { useGlobalDataStore } from './GlobalDataContext';
import { G6UserInfo } from '@/types/User';
import { useRequest } from 'ahooks';
import { fetchUserInfoByAddressAndChainId, fetchUserInfoByUserId } from '@/service/user';
import { useUserStore } from '@/stores/useUserStore';
import { useLocation } from 'react-router-dom';

export type HeldItem = {
  poolAddress: string;
  price?: string;
  imageUri: string;
  name: string;
  balance?: string | number;
  usdPrice?: number;
};

type ProfileContextType = {
  heldCoinList?: HeldItem[];
  memoNativeBalance?: number;
  fetchHeldCoinLoading: boolean;
  currentBalance: bigint;
  currentBalanceLoading: boolean;
  userInfo?: G6UserInfo;
  isSelfUserInfo: boolean;
  userAddress?: string;
  isUserDetail: boolean;
  refreshUserInfo: () => void;
};

export const ProfileContext = createContext<ProfileContextType>({
  heldCoinList: [],
  memoNativeBalance: 0,
  fetchHeldCoinLoading: false,
  currentBalance: 0n,
  currentBalanceLoading: false,
  userInfo: undefined,
  isSelfUserInfo: false,
  userAddress: '',
  isUserDetail: false,
  refreshUserInfo: () => {},
});

export const useProfileStore = () => useContext(ProfileContext);

export const useProfile = (): ProfileContextType => {
  const { param } = useParseRouterParam({ userId: '', address: '' });
  const { isSelf, chainType } = useWalletInfoStore();
  const { jwtInfo } = useUserStore();

  const { data: userInfo, refresh: refreshUserInfo } = useRequest(
    async () => {
      if (param.userId) {
        return fetchUserInfoByUserId(param.userId);
      } else if (param.address && chainType) {
        return fetchUserInfoByAddressAndChainId({
          chain: chainType === ChainType.TON ? 'ton' : 'sol',
          address: param.address,
        });
      }
    },
    {
      ready: !!param.userId || (!!param.address && !!chainType),
      refreshDeps: [param.userId, param.address, chainType],
    },
  );

  const { pathname } = useLocation();

  const isUserDetail = useMemo(() => {
    return pathname.startsWith('/user');
  }, [pathname]);

  const userAddress = param.address || userInfo?.address;

  const isSelfUserInfo = useMemo(() => {
    return param?.userId === jwtInfo?.id || isSelf(userAddress);
  }, [isSelf, jwtInfo?.id, param?.userId, userAddress]);

  const {
    list: heldCoinList,
    loading: fetchHeldCoinLoading,
    memoNativeBalance,
  } = useFetchUserHeldCoins({
    address: userAddress,
  });

  const { balance, isBalanceLoading } = useGlobalDataStore();
  const { data: userBalance = 0n, loading: userBalanceLoading } = useGetUserNativeBalance({
    address: userAddress,
    enabled: !!userAddress && !isSelfUserInfo,
  });
  const [currentBalance, currentBalanceLoading] = useMemo(() => {
    return isSelfUserInfo ? [balance, isBalanceLoading] : [userBalance, userBalanceLoading];
  }, [balance, isBalanceLoading, isSelfUserInfo, userBalance, userBalanceLoading]);

  return {
    heldCoinList,
    userInfo,
    memoNativeBalance,
    fetchHeldCoinLoading,
    currentBalance,
    currentBalanceLoading,
    isSelfUserInfo,
    userAddress,
    isUserDetail,
    refreshUserInfo,
  };
};

const ProfileProvider = ({ children }: { children: React.ReactNode }) => {
  const data = useProfile();

  return <ProfileContext.Provider value={data}>{children}</ProfileContext.Provider>;
};

export default ProfileProvider;
