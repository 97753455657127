import { CapsuleNavigation } from '@/components/CapsuleNavigation';
import { useParseRouterQuery } from '@/hooks/router/useParseRouterQuery';
import { Header } from './components/Header';
import { InformationCarousel } from './components/InformationCarousel';
import { Departure } from './modules/Departure';
import { G6TokenList } from './modules/Departure/G6List';
import { Fly } from './modules/Fly';
import { Hot } from './modules/Hot';
// import { Test } from './Test';
import { FlyV2 } from './modules/FlyV2';
// import { FilterPopup } from './components/FilterPopup';

export default function Home() {
  const { query, updateQuery } = useParseRouterQuery({
    current: 'departure',
  });

  return (
    <div>
      <div className="sticky top-0 bg-primaryBackground z-20">
        <Header></Header>
        {/* <Test /> */}
      </div>

      {/* <InformationCarousel /> */}
      {/* sticky top-[60px] z-30 */}

      <div className="px-[16px] my-[12px]  bg-primaryBackground [&_.g6-list-item]:!border-0">
        <CapsuleNavigation
          active={query.current}
          onChange={(current) => {
            updateQuery({ current });
          }}
          items={[
            { label: 'Departure', value: 'departure' },
            { label: '🔥 In fly', value: 'hot' },
          ]}
        />
      </div>

      {query.current === 'departure' ? <Departure /> : <FlyV2 />}
    </div>
  );
}
