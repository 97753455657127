import { Button, ConfigProvider, Input, Modal } from 'antd';
import { useEffect, useState } from 'react';

function Biometric() {
  return <></>;
}

function Password() {
  return <></>;
}

export function AuthModal({
  open,
  onClose,
  onConfirm,
}: {
  open: boolean;
  onClose?: () => void;
  onConfirm?: (pwd: string) => void;
}) {
  // const { getMFAParams } = useMFAVerify();

  const [OTPStatus, setOTPStatus] = useState();

  const [passwordConfirm, sePasswordConfirm] = useState('');

  // const {} = useRequest(
  //   () => {
  //     return getMFAParams({
  //       type: MFAType.password,
  //     });
  //   },
  //   {
  //     manual: true,
  //   },
  // );

  useEffect(() => {
    if (!open) {
      setOTPStatus(undefined);
      sePasswordConfirm('');
    }
  }, [open]);

  return (
    <Modal
      centered
      open={open}
      maskClosable={false}
      title={'Password'}
      footer={null}
      width="90%"
      onCancel={() => {
        onClose?.();

        onConfirm?.('');
      }}
      zIndex={999999}
    >
      <div className="flex justify-center items-center flex-col gap-y-[24px] px-[12px] py-[12px]">
        <div className="w-full">
          <div className="w-full flex justify-center">
            <ConfigProvider
              theme={{
                components: {
                  Input: {
                    borderRadius: 8,
                    controlHeight: 56,
                  },
                },
              }}
            >
              <Input.OTP
                style={{ width: '240px' }}
                status={OTPStatus}
                className="text-[18px] mx-auto"
                length={4}
                value={passwordConfirm}
                mask="*"
                onChange={(e) => {
                  sePasswordConfirm(e);
                }}
              />
            </ConfigProvider>
          </div>

          <Button
            className="mt-[30px]"
            block
            type="primary"
            disabled={passwordConfirm.length !== 4}
            onClick={() => {
              onConfirm?.(passwordConfirm);
            }}
          >
            Continue
          </Button>
        </div>
      </div>
    </Modal>
  );
}
