import { toNano } from '@ton/core';
import { useRequest } from 'ahooks';
import { useContext } from 'react';
import { CoinDetailContext } from '../context/CoinDetailContext';
import { useWalletInfoStore } from '@/stores/useWalletInfoStore';
import { useG6PoolMethods } from '@/contract/hooks/useG6PoolMethods';
import { getSolanaConnection } from '@/utils/sol';
import { PublicKey } from '@solana/web3.js';
import { SolCoinDetail } from '@/types/Coin';

export function useFetchJettonBalance() {
  const { detail, isTonData } = useContext(CoinDetailContext);

  const { isConnect, walletAddress } = useWalletInfoStore();

  const { getJettonBalance } = useG6PoolMethods();

  const {
    data: balance = toNano(0),
    loading: balanceLoading,
    refresh: refreshBalance,
  } = useRequest(
    async () => {
      if (isTonData(detail) && detail.poolAddress) {
        return getJettonBalance(detail.poolAddress).then((balance) => {
          return balance ? balance : toNano(0);
        });
      } else {
        const connection = getSolanaConnection();
        const publicKey = new PublicKey(walletAddress);

        const tokenAccount = await connection.getTokenAccountsByOwner(publicKey, {
          mint: new PublicKey((detail as SolCoinDetail)!.mint),
        });

        const tokenAccountPubkey = tokenAccount?.value?.[0].pubkey;
        if (tokenAccountPubkey) {
          const tokenBalance = await connection.getTokenAccountBalance(tokenAccountPubkey);
          return BigInt(tokenBalance.value.amount ?? 0);
        } else {
          return 0n;
        }
      }
    },
    {
      ready: isConnect && !!detail,
      onSuccess(res) {
        console.log('getJettonBalance------->', res);
      },
    },
  );

  return { balance, balanceLoading, refreshBalance };
}
