import { Table, ConfigProvider, Button } from 'antd';
import { ColumnType } from 'antd/es/table';
import { useMemo, useState, useEffect } from 'react';
import { SolCoinTradeItem } from '@/service/sol-coin';
import { useFetchSolCoinTrade } from '../../hooks/useFetchSolCoinTrade';
import classNames from 'classnames';

export function SolTradeTable({ columns }: { columns: ColumnType<SolCoinTradeItem>[] }) {
  const [displayList, setDisplayList] = useState<SolCoinTradeItem[]>([]);

  const {
    data = [],
    loading,
    runAsync,
  } = useFetchSolCoinTrade({
    onSuccess: (data) => {
      setDisplayList(data.slice(0, 10));
    },
  });

  const hasMore = useMemo(() => {
    return data.length > 0 && displayList.length < data?.length;
  }, [data.length, displayList.length]);

  useEffect(() => {
    const refetch = async () => {
      const res = await runAsync();
      setDisplayList(res.slice(0, displayList.length));
    };
    const intervalId = setInterval(() => {
      refetch();
    }, 10 * 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [displayList.length, runAsync]);

  return (
    <>
      <ConfigProvider
        wave={{ disabled: true }}
        theme={{
          components: {
            Table: {
              colorBgContainer: '#ffffff00',
              borderColor: '#ffffff10',
              headerBg: '#ffffff00',
              cellFontSize: 14,
              cellPaddingBlock: 15,
              cellPaddingInline: 0,
              footerBg: '#ffffff00',
              headerColor: '#ffffff50',
              headerSplitColor: '#ffffff00',
              lineWidth: 2,
            },
          },
        }}
      >
        <Table<SolCoinTradeItem>
          columns={columns as any}
          loading={loading}
          dataSource={displayList}
          rowKey={(record) => record.signature + record.tx_index}
          className={classNames('mt-[14px] tradeTable md:mt-0 font-[400]')}
          pagination={false}
          summary={() =>
            hasMore ? (
              <Table.Summary>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={2} colSpan={5}>
                    <div className="text-center">
                      <Button
                        loading={loading}
                        size="small"
                        onClick={() => {
                          setDisplayList((pre) => [
                            ...pre,
                            ...data.slice(displayList.length, displayList.length + 10),
                          ]);
                        }}
                      >
                        <span className="text-white">Load more</span>
                      </Button>
                    </div>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            ) : (
              <></>
            )
          }
        />
      </ConfigProvider>
    </>
  );
}
