import { Avatar, List } from 'antd';
import { useMemo, useRef, useState } from 'react';
import { useWindowResize } from '@/hooks/useWindowResize';
import { useParseRouterParam } from '@/hooks/router/useParseRouterParam';
import { useG6PoolMethods } from '@/contract/hooks/useG6PoolMethods';
import { useInViewport, useRequest } from 'ahooks';
import { TokenValue } from '@/components/TonValue';
import { G6Math } from '@/contract/utils/Math';
import { useAppRouter } from '@/hooks/router/useAppRouter';
import { LazyBalance } from '@/views/Swap';
import { fetchProfileToken } from '@/service/coin';
import { TomoTokenItem } from '@/types/Tomo';
import { MultiChainToken } from '@/views/Home/components/MultiChainToken';
import { Token } from '@/types/App';
import { useGlobalDataStore } from '@/context/GlobalDataContext';
import { useWalletInfoStore } from '@/stores/useWalletInfoStore';
import { useNativeToken } from '@/hooks/app/useNativeToken';
import { HeldItem, useProfileStore } from '@/context/ProfileContext';
import numeral from 'numeral';

export function OtherItem({ item }: { item: TomoTokenItem }) {
  const { goTomoCoinDetail } = useAppRouter();

  const ref = useRef(null);

  const [inViewport] = useInViewport(ref);

  const listClick = (item: TomoTokenItem) => {
    goTomoCoinDetail({
      address: item.address || '',
      chain: item.chain,
      token: JSON.stringify(item!),
    });
  };

  return (
    <>
      <List.Item
        ref={ref}
        className="h-full"
        style={{ marginBottom: '8px', borderBottomWidth: '0', padding: '4px 0' }}
      >
        <div className="flex items-center w-full justify-between" onClick={() => listClick(item)}>
          <div className="flex items-center">
            <MultiChainToken token={item.imageUrl} chain={item.chain}></MultiChainToken>
            <div className="mb-[-4px] leading-8 ml-3 text-[16px] font-medium">{item.symbol}</div>
          </div>
          <div className="text-right">
            <div className="text-[16px] font-medium leading-5 text-white">
              {inViewport && <LazyBalance token={item}></LazyBalance>}
            </div>
          </div>
        </div>
      </List.Item>
    </>
  );
}

export function NativeTokenItem() {
  const [nativeName, nativeIcon] = useNativeToken();

  // const { getBalance, ready } = useAccountMethods();

  // const { data: balance = toNano(0), loading: balanceLoading } = useRequest(
  //   async () => {
  //     return getBalance().then((balance) => {
  //       return balance ? balance : toNano(0);
  //     });
  //   },
  //   {
  //     ready: ready,
  //   },
  // );

  const { currentBalance, currentBalanceLoading } = useProfileStore();

  const { usdPrice } = useGlobalDataStore();

  const jettonToTon = useMemo(() => {
    try {
      const val = new G6Math(currentBalance || 0n)
        .multiply(usdPrice)
        .divide(10n ** 9n)
        .toFixed(2);

      if (val === 'NaN') return '0.00';

      return val;
    } catch (err) {
      return '0.00';
    }
  }, [currentBalance, usdPrice]);

  return (
    <>
      <div className="flex items-center w-full justify-between px-4 mb-2">
        <div className="flex items-center">
          <Avatar size={32} src={nativeIcon} />
          <div className="leading-8 ml-3 text-[16px] font-medium text-primaryText">
            {nativeName}
          </div>
        </div>
        <div className="text-right">
          <div className="text-[16px] font-medium leading-5 text-white">
            <TokenValue value={currentBalance || 0n} loading={currentBalanceLoading}></TokenValue>
          </div>

          <div className="text-[12px] leading-[15.6px] text-secondaryText mt-1">
            {jettonToTon} USDT
          </div>
        </div>
      </div>
    </>
  );
}

export function Item({
  item,
  isMobile,
  isMeme,
}: {
  item: HeldItem;
  isMobile: boolean;
  isMeme: boolean;
}) {
  const { usdPrice } = useGlobalDataStore();

  const { isTon } = useWalletInfoStore();

  const ref = useRef(null);

  const jettonToTon = useMemo(() => {
    try {
      const val = new G6Math(item.balance || 0)
        .multiply(new G6Math(item.price ?? 0))
        .multiply(usdPrice)
        .toFixed(2);

      if (val === 'NaN') return '-';

      return val;
    } catch (err) {
      return '-';
    }
  }, [item.balance, item.price, usdPrice]);

  const { goCoinDetail } = useAppRouter();

  const listClick = (item: Token) => {
    console.log('item', item);

    goCoinDetail({ poolAddress: item.poolAddress });
  };

  return (
    <>
      <List.Item
        ref={ref}
        className="h-full"
        style={{ marginBottom: '8px', borderBottomWidth: '0', padding: '4px 0' }}
      >
        <div
          className="flex items-center w-full justify-between gap-1"
          onClick={() => listClick(item)}
        >
          <div className="flex items-center truncate">
            <Avatar size={isMobile ? 32 : 48} src={item.imageUri} />
            <div className="leading-8 ml-3 text-[16px] font-medium text-primaryText truncate">
              {item.name}
            </div>
          </div>
          <div className="text-right flex-shrink-0">
            <div className="text-[16px] font-medium leading-5 text-white">
              <div>{numeral(item.balance).format('0.00')}</div>
            </div>
            <div className="text-[12px] leading-[15.6px] text-secondaryText mt-1">
              {isTon ? jettonToTon : item.usdPrice?.toFixed(6)} USDT
            </div>
          </div>
        </div>
      </List.Item>
    </>
  );
}

const tokens = [
  'TON',
  'BTC',
  'ETH',
  'SOL',
  'BNB',
  'ARB-ETH',
  'BASE-ETH',
  'MATIC',
  'OP-ETH',
  'LINEA-ETH',
  'BLAST-ETH',
  'AVAX',
  'SCROLL-ETH',
  'B3-ETH',
  'TRX',
  'WHY',
  'USDT',
  'SHIB',
  'LINK',
  'USDC',
];

export function CoinsHeld() {
  const tagsData = ['Meme coin', 'Other Assets'];

  const [tagChecked, setTagChecked] = useState('Meme coin');

  const handleChange = (key: string) => {
    setTagChecked(key);
  };

  return (
    <div>
      {/* {isTomoWallet && isSelf(param.address) && (
        <div className="mb-5 mx-4 mt-1">
          {tagsData.map<React.ReactNode>((tag) => (
            <Tag.CheckableTag
              key={tag}
              className={classNames(
                tagChecked === tag && '!text-primaryText border-primaryText !bg-primaryBorder',
                'active:text-[#282828] !bg-transparent border-[#282828] rounded-full px-4 py-[6px] text-secondaryText text-3 leading-[18px]',
              )}
              checked={tagChecked === tag}
              onChange={() => handleChange(tag)}
            >
              {tag}
            </Tag.CheckableTag>
          ))}
        </div>
      )} */}
      {tagChecked === 'Meme coin' ? <MemeCoin></MemeCoin> : <OtherCoin></OtherCoin>}
    </div>
  );
}

function MemeCoin() {
  const { heldCoinList, fetchHeldCoinLoading } = useProfileStore();

  const { width } = useWindowResize();

  const isMobile = useMemo(() => {
    return width <= 768;
  }, [width]);

  return (
    <>
      <NativeTokenItem />
      {!!heldCoinList?.length && (
        <List
          loading={fetchHeldCoinLoading}
          className="mx-4"
          itemLayout="horizontal"
          dataSource={heldCoinList}
          renderItem={(item) => <Item item={item} isMobile={isMobile} isMeme={true} />}
        />
      )}
    </>
  );
}

function sortDataBySymbols(data: any, symbols: any) {
  return data.sort((a: any, b: any) => {
    return symbols.indexOf(a.symbol) - symbols.indexOf(b.symbol);
  });
}

function OtherCoin() {
  const { data } = useRequest(() => {
    return fetchProfileToken();
  });

  return (
    <>
      <List
        className="mx-4"
        itemLayout="horizontal"
        dataSource={data?.data}
        renderItem={(item) => <OtherItem item={item} />}
      />
    </>
  );
}
