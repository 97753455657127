import * as echarts from 'echarts';
import { useEffect, useRef, useState } from 'react';
import { CoinDetail } from '@/types/Coin';
import { KlineData } from '../KLine/dataFeed';
import { G6Math } from '@/contract/utils/Math';
import { isNumber } from 'lodash';
import { useMemoizedFn, usePrevious, useThrottleFn } from 'ahooks';

type Data = Omit<KlineData, 'time'> & { time: string; volume?: number };

function splitData(rawData: Data[]) {
  const categoryData: any[] = [];
  const values: (number | undefined)[][] = [];
  const vols: number[] = [];

  rawData.forEach((item) => {
    categoryData.push(item.time);
    values.push([item.open, item.close, item.low, item.high, item.volume]);
    vols.push(0);
  });

  return {
    categoryData,
    values,
    vols,
  };
}

function tooltipFormatter(v: any) {
  if (!isNumber(v)) return v;
  return G6Math.from(v)
    .divide(10 ** 18)
    .toString();
}

export function AppKline({
  detail,
  data,
  displayDataCount = 60,
  enableFormatters = true,
  loadMore,
}: {
  detail?: Partial<CoinDetail>;
  data?: Data[];
  displayDataCount?: number;
  enableFormatters?: boolean;
  loadMore?: () => void;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const chartRef = useRef<echarts.EChartsType | null>(null);
  const [alreadyInit, setAlreadyInit] = useState(false);

  const initChart = useMemoizedFn(() => {
    console.log('initChart', detail?.name);

    if (!ref.current || !data) return;

    chartRef.current = echarts.init(ref.current);

    const data0 = splitData(data!);

    const option = {
      // animation: false,
      tooltip: {
        trigger: 'axis',
        show: false,
      },
      grid: [
        {
          left: '3%',
          top: '40px',
          height: '280px',
          right: '3%',
        },
        {
          left: '0%',
          right: '0%',
          top: '350x',
          height: '50px',
        },
      ],
      xAxis: [
        {
          type: 'category',
          data: data0.categoryData,
          scale: true,
          boundaryGap: true,
          axisLine: {
            onZero: false,
            lineStyle: {
              color: '#656565',
            },
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          splitNumber: 20,
        },
        {
          type: 'category',
          gridIndex: 1,
          data: data0.categoryData,
          axisLabel: { show: false },
        },
      ],
      yAxis: [
        {
          scale: true,
          splitLine: {
            show: false,
          },
          splitArea: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          position: 'right',
        },
        {
          gridIndex: 1,
          splitNumber: 3,
          axisTick: { show: false },
          splitLine: { show: false },
          axisLabel: { show: true },
          axisLine: {
            onZero: false,
            lineStyle: {
              color: 'red',
            },
          },
          position: 'right',
        },
      ],
      dataZoom: [
        {
          type: 'inside',
          xAxisIndex: [0, 0],
          maxValueSpan: displayDataCount,
          start: 20,
          end: 100,
        },
        {
          show: true,
          xAxisIndex: [0, 1],
          type: 'inside',
          top: '97%',
          start: 20,
          end: 100,
        },
      ],
      series: [
        {
          name: detail?.name,
          type: 'candlestick',
          data: data0.values,
          large: true,
          barMaxWidth: 20,
          tooltip: {
            valueFormatter: enableFormatters ? tooltipFormatter : undefined,
          },
          itemStyle: {
            color: '#17CF82',
            color0: '#F54976',
            borderColor: '#17CF82',
            borderColor0: '#F54976',
          },
        },
        {
          name: 'Volume',
          type: 'bar',
          xAxisIndex: 1,
          yAxisIndex: 1,
          data: data0.vols,
          large: true,
          barMaxWidth: 20,
          itemStyle: {
            color: function (params: any) {
              let colorList;
              if (data0.vols[params.dataIndex] < data0.vols[params.dataIndex - 1]) {
                colorList = '#F54976';
              } else {
                colorList = '#17CF82';
              }
              return colorList;
            },
          },
        },
      ],
    };

    chartRef.current.setOption(option);
  });

  useEffect(() => {
    const handleDataZoom = (event: any) => {
      const startValue = event.batch[0].start;
      if (startValue === 0) {
        // 图表拖动到最左侧时加载更多
        loadMore?.();
      }
    };

    if (chartRef.current) {
      chartRef.current.on('dataZoom', handleDataZoom);
    }

    return () => {
      if (chartRef.current) {
        chartRef.current.off('dataZoom', handleDataZoom);
      }
    };
  }, [loadMore]);

  const { run: updateChartAction } = useThrottleFn(
    (newData: Data[]) => {
      if (!chartRef.current) return;

      const data0 = splitData(newData);

      const option = {
        xAxis: [{ data: data0.categoryData }, { data: data0.categoryData, gridIndex: 1 }],
        series: [{ data: data0.values }, { data: data0.vols, xAxisIndex: 1, yAxisIndex: 1 }],
      };

      if (!alreadyInit) {
        // 第一次取到数据的时候，显示最新的数据，也就是最右侧的数据
        Object.assign(option, {
          dataZoom: [
            {
              type: 'inside',
              xAxisIndex: 0,
              maxValueSpan: displayDataCount,
              start: (Math.max(newData.length - displayDataCount, 0) / newData.length) * 100,
              end: 100,
            },
            {
              show: true,
              xAxisIndex: [0, 1],
              type: 'inside',
              top: '97%',
              maxValueSpan: displayDataCount,
              start: (Math.max(newData.length - displayDataCount, 0) / newData.length) * 100,
              end: 100,
            },
          ],
        });
      } else {
        const newLength = newData.length - (previousData?.length || 0);
        // 后续取到数据的时候，判断取到了几条新数据，显示到对应数据处
        Object.assign(option, {
          dataZoom: [
            {
              type: 'inside',
              xAxisIndex: 0,
              maxValueSpan: displayDataCount,
              start: (Math.max(newLength, 0) / newData.length) * 100,
              end: 100,
            },
            {
              show: true,
              xAxisIndex: [0, 1],
              type: 'inside',
              top: '97%',
              maxValueSpan: displayDataCount,
              start: (Math.max(newLength, 0) / newData.length) * 100,
              end: 100,
            },
          ],
        });
      }

      chartRef.current.setOption(option);

      setAlreadyInit(true);
    },
    {
      wait: 200,
    },
  );

  useEffect(() => {
    initChart();
  }, [initChart]);

  const previousData = usePrevious(data);

  useEffect(() => {
    if (data) {
      chartRef.current?.dispatchAction({
        type: 'hideTip',
      });

      updateChartAction(data);

      // chartRef.current?.getZr().on('touchstart', () => {
      //   chartRef.current?.dispatchAction({
      //     type: 'hideTip',
      //   });
      // });

      // chartRef.current?.getZr().on('touchmove', () => {
      //   chartRef.current?.dispatchAction({
      //     type: 'hideTip',
      //   });
      // });

      // chartRef.current?.getZr().on('touchend', (event) => {
      //   const pointInPixel = [event.offsetX, event.offsetY];

      //   chartRef.current?.dispatchAction({
      //     type: 'showTip',
      //     x: pointInPixel[0],
      //     y: pointInPixel[1],
      //   });
      // });
    }
  }, [data, updateChartAction]);

  return (
    <div>
      <div ref={ref} className="w-full h-[365px]"></div>
    </div>
  );
}
