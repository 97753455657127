// import chartIcon from '@/assets/icon/icon_chart.png';
// import setIcon from '@/assets/icon/icon_set.png';
import { CoinMarketCap } from '@/components/CoinCard';
import { useCoinDetailStore } from '../context/CoinDetailContext';
import { CustomKLine } from '@/components/CustomKLine';
import { CoinImage } from './CoinDetail';
import JoinGroup from './JoinGroup';
import { formatNumber } from '@/views/Home/modules/FlyV2';

export function TradeView() {
  const { detail, isTonData } = useCoinDetailStore();

  return (
    <div className="flex flex-col w-full gap-2 pb-[14px] md:p-0">
      <div className="p-[6px_13px_6px_16px] flex justify-between items-center">
        {detail && (
          <>
            <div className="flex items-center gap-2">
              <CoinImage detail={detail} className="w-[30px] h-[30px] rounded-full" />
              <div>
                <div className="text-[16px] leading-[20px] text-primaryText">{detail?.symbol}</div>
                <div className="text-xs font-normal text-secondaryText">{detail?.name}</div>
              </div>
            </div>
            <JoinGroup />
          </>
        )}
      </div>
      <div className="p-[8px_16px]">
        <div className="text-[26px] leading-7 mb-[6px] font-semibold md:mb-[3px] text-primaryText">
          {isTonData(detail) ? (
            <div>${detail?.price ? formatNumber(detail.price) : '0.00'}</div>
          ) : (
            <CoinMarketCap unit="USDT" detail={detail} />
          )}
        </div>
        {!isTonData(detail) && (
          <div className="text-xs font-normal text-secondaryText">Market Cap</div>
        )}
      </div>

      <CustomKLine detail={detail} />
    </div>
  );
}

export function MarketCard() {
  return <TradeView />;
}
