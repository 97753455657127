import { TransactionModalType } from '@/context/TransactionContext';
import { useAppRouter } from '@/hooks/router/useAppRouter';
import { CheckCircleFilled, ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { useMemo } from 'react';
import { NavLink } from 'react-router-dom';

function GasTip() {
  return (
    <>
      <div>
        <ExclamationCircleFilled className="text-[64px] text-yellow" />
      </div>

      <div>Oops, Not enough Gas fee to Continue</div>
    </>
  );
}

function TradePassword() {
  return (
    <>
      <div>
        <ExclamationCircleFilled className="text-[64px] text-yellow" />
      </div>

      <div>Oops, Not enough Gas fee to Continue</div>
    </>
  );
}

function NetworkTip() {
  return (
    <>
      <div>
        <ExclamationCircleFilled className="text-[64px] text-red" />
      </div>

      <div>Network error, please try later</div>
    </>
  );
}

function ErrorTip() {
  return (
    <>
      <div>
        <ExclamationCircleFilled className="text-[64px] text-red" />
      </div>

      <div>Some errors have occurred, please try again</div>
    </>
  );
}

function SuccessTip() {
  return (
    <>
      <div>
        <CheckCircleFilled className="text-[64px] text-green" />
      </div>

      <div className="text-center">
        <div className="text-[20px] mb-[20px]">Transaction Sent</div>

        <div>
          Your transaction has been sent to the blockchain network and will be confirmed in a few
          seconds
        </div>
      </div>
    </>
  );
}

function CustomTip({
  customContent,
  onClose,
}: {
  customContent?: TransactionCustomContent;
  onClose?: () => void;
}) {
  return (
    <>
      <div>{customContent?.icon ?? <CheckCircleFilled className="text-[64px] text-green" />}</div>

      <div className="text-center">
        <div className="text-[20px] mb-[20px]">{customContent?.title}</div>

        <div>{customContent?.content}</div>
      </div>

      <div className="w-full">
        <Button
          type="primary"
          block
          onClick={() => {
            onClose?.();
            customContent?.handleClickButton?.();
          }}
        >
          {customContent?.buttonText ?? 'Continue'}
        </Button>
      </div>

      {customContent?.extra}
    </>
  );
}

export type TransactionCustomContent = {
  icon?: React.ReactNode;
  title: React.ReactNode;
  content: React.ReactNode;
  extra: React.ReactNode;
  buttonText?: string;
  handleClickButton?: () => void;
};

export function TransactionModal({
  open,
  onClose,
  type,
  customContent,
}: {
  open: boolean;
  onClose?: () => void;
  type: TransactionModalType;
  customContent?: TransactionCustomContent;
}) {
  const isNormalType = useMemo(() => type !== 'trade-password' && type !== 'custom', [type]);
  return (
    <Modal
      centered
      open={open}
      title={null}
      closable={false}
      footer={null}
      width="90%"
      zIndex={999999}
    >
      <div className="flex justify-center items-center flex-col gap-y-[24px] py-[12px]">
        {isNormalType && (
          <>
            {type === 'gas' && <GasTip />}

            {type === 'network' && <NetworkTip />}

            {type === 'error' && <ErrorTip />}

            {type === 'success' && <SuccessTip />}

            {type === 'pending' && (
              <>
                <div>
                  <ExclamationCircleFilled className="text-[64px] text-yellow" />
                </div>

                <div>Transaction Pending</div>
              </>
            )}

            <div className="w-full">
              <Button type="primary" block onClick={onClose} disabled={type === 'pending'}>
                {type === 'pending' ? 'Pending' : 'Continue'}
              </Button>
            </div>
          </>
        )}

        {type === 'custom' && <CustomTip customContent={customContent} onClose={onClose} />}

        {type === 'trade-password' && (
          <>
            <div className="w-full">
              <TradePassword />

              <Button
                type="primary"
                block
                onClick={() => {
                  onClose?.();
                }}
              >
                Continue
              </Button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
}
