import request from '@/request';
import { Coin } from '@/types/Coin';
import { G6UserInfo, User } from '@/types/User';

export function fetchUserInfo() {
  return request.get<User>(`/user`, null);
}

export function register(payload: Partial<User>) {
  return request.post<User>(`/user/register`, payload);
}

export function updateInfo(payload: Omit<Partial<User>, 'address'>) {
  return request.put<User>(`/user`, payload);
}

export function updateChatId(payload: { tgChatId: string }) {
  return request.put<User>(`/user/chatId`, payload);
}

export function fetchUserInfoByAddress(address: string) {
  return request.get<User>(`/user/${address}`, null);
}

export async function fetchUserCreateCoins(address: string) {
  const res = await request.get<{ docs: Coin[] }>(`/coin/user-created-coins/${address}`, null);
  return res.data;
}

export type TonHeldItem = {
  address: string;
  poolAddress: string;
  name: string;
  symbol: string;
  imageUri: string;
  creator: string;
  marketCap: number;
  price: string;
  balance: number;
};

export function fetchUserHeldCoins(address: string) {
  return request.get<{ docs: TonHeldItem[]; tonBalance: number }>(
    `/coin/user-held-coins/${address}`,
    null,
  );
}

export async function authenticateUser(initData?: WebApp['initData']) {
  const res = await request.post<{ token: string }>(`/users/authenticate`, { initData });
  return res.data;
}

export async function switchUserWallet(id: string, walletId: string) {
  const res = await request.put<{ token: string }>(`/users/${id}/switch/wallets`, { walletId });
  return res.data;
}

export async function fetchUserInfoByUserId(userId: string) {
  const res = await request.get<G6UserInfo>(`/users/${userId}`);
  return res.data;
}

export async function fetchUserInfoByAddressAndChainId({
  chain,
  address,
}: {
  chain: string;
  address: string;
}) {
  const res = await request.get<G6UserInfo>(`/users/${chain}/${address}`);
  return res.data;
}
