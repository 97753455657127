import { useFetchCoinDetail } from '@/views/Coin/hooks/useFetchCoinDetail';
import { Coin, CoinDetail, SolCoinDetail } from '@/types/Coin';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useParseRouterParam } from '@/hooks/router/useParseRouterParam';
import { useRequest } from 'ahooks';
import { useG6PoolMethods } from '@/contract/hooks/useG6PoolMethods';
import { isUndefined } from 'lodash';
import { useWalletInfoStore } from '@/stores/useWalletInfoStore';
import { useSolWebSocketStore } from '@/context/SolWebSocketContext';

export const CoinDetailContext = createContext<{
  detail: CoinDetail;
  isGraduated?: boolean;
  refreshIsGraduated?(): void;
  graduatedLoading: boolean;
  loading: boolean;
  isTonData: (v: CoinDetail) => v is Coin;
}>({
  detail: undefined,
  isGraduated: false,
  refreshIsGraduated: undefined,
  graduatedLoading: false,
  loading: false,
  isTonData: (v: CoinDetail): v is Coin => false,
});

export const useCoinDetailStore = () => useContext(CoinDetailContext);

export const useProvideCoinDetail = () => {
  const { latestMintMessage } = useSolWebSocketStore();
  const { isTon } = useWalletInfoStore();
  const { param } = useParseRouterParam({
    address: '',
  });
  const [coinDetail, setCoinDetail] = useState<CoinDetail>();

  useEffect(() => {
    if (!isTon && latestMintMessage) {
      setCoinDetail((pre) => ({
        ...(pre as SolCoinDetail),
        usd_market_cap: latestMintMessage?.usd_market_cap || 0,
      }));
    }
  }, [isTon, latestMintMessage]);

  const { loading: detailLoading } = useFetchCoinDetail({
    isTon,
    address: param.address,
    onSuccess: (v) => {
      setCoinDetail(v);
    },
  });

  const { getPoolData } = useG6PoolMethods();

  const {
    data: isGraduated,
    loading: graduatedLoading,
    refresh: refreshIsGraduated,
  } = useRequest(
    async () => {
      const res = await getPoolData(param.address);
      return !!res?.graduated && !!param.address;
    },
    {
      ready: isTon && !!param.address,
    },
  );

  const loading = useMemo(() => {
    return detailLoading || (isUndefined(isGraduated) && graduatedLoading);
  }, [detailLoading, graduatedLoading, isGraduated]);

  const isTonData = (v: CoinDetail): v is Coin => {
    return !!(v as Coin)?.isG6;
  };

  return {
    detail: coinDetail,
    isGraduated,
    loading,
    refreshIsGraduated,
    graduatedLoading,
    isTonData,
  };
};

const CoinDetailProvider = ({ children }: { children: React.ReactNode }) => {
  const data = useProvideCoinDetail();

  return <CoinDetailContext.Provider value={data}>{children}</CoinDetailContext.Provider>;
};

export default CoinDetailProvider;
