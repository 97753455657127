import { SaDrawer } from '@/components/SaDrawer';
import { useAppRouter } from '@/hooks/router/useAppRouter';
import { useAppMessage } from '@/hooks/useAppMessage';
import { useTomoSdk } from '@/hooks/useTomoSdk';
import { LeftOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Button, ConfigProvider, Input } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useVerifyContext } from './VerifyContext';

export function VerifyEmailPassword({ open }: { open?: boolean }) {
  const { tomo } = useTomoSdk();

  const message = useAppMessage();

  const [step, setStep] = useState(0);

  const { goHome } = useAppRouter();

  const { code, email, password, setConfirmPassword, confirmPassword, setPassword, reset } =
    useVerifyContext();

  const OTPStatus = useMemo(() => {
    if (confirmPassword?.length === 4 && confirmPassword !== confirmPassword) {
      return 'error';
    }

    return '';
  }, [confirmPassword]);

  const { run, loading } = useRequest(
    async () => {
      const res = await tomo.onVerifyEmailCode({
        code: code!,
        email: email!,
        tradePassword: password!,
      });

      if ([8000, 10000].includes(res.code)) {
        return Promise.reject(new Error(res.message));
      }

      return res;

      // await tomo.onUpdateUserInfo();
    },
    {
      manual: true,
      onSuccess() {
        reset();

        goHome();

        tomo.onLogout();

        message.success('Bind email successfully, please log in again');
      },
      onError(err) {
        message.error(err.message || 'Failed to bind email');
      },
    },
  );

  const handleSetPwd = () => {
    if (password !== confirmPassword) {
      message.error('Password mismatch');
      return;
    }

    run();
  };

  const { goBack } = useAppRouter();

  useEffect(() => {
    if (open) {
      setStep(0);
      setPassword('');
      setConfirmPassword('');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <div className="w-full py-[18px] px-4">
      {!step ? (
        <>
          <ConfigProvider
            theme={{
              components: {
                Button: {
                  controlHeight: 36,
                },
              },
            }}
          >
            <Button
              type="text"
              className="text-secondaryText ml-[-15px]"
              onClick={() => {
                goBack();
              }}
            >
              <LeftOutlined /> Back
            </Button>
          </ConfigProvider>

          <div className="text-xl mt-2">Set Password</div>

          <div className="mt-2 text-[13px]">Enter your password</div>
        </>
      ) : (
        <div className="flex justify-start items-center">
          <Button
            type="text"
            onClick={() => {
              setStep(0);
              setConfirmPassword('');
            }}
          >
            <LeftOutlined />
          </Button>
          Confirm password
        </div>
      )}

      <ConfigProvider
        theme={{
          components: {
            Button: {
              controlHeight: 48,
            },
            Input: {
              borderRadius: 8,
              controlHeight: 56,
            },
          },
          token: {
            borderRadius: 8,
          },
        }}
      >
        <div className="w-full h-full flex flex-col justify-start items-center bg-primaryBackground z-50 gap-y-[20px] px-[16px] mt-12">
          {/* <div>paymentPwdExists： {paymentPasswd.paymentPwdExists}</div> */}

          {!step ? (
            <>
              <div>
                <Input.OTP
                  className="h-[50px] text-[18px]"
                  inputMode="numeric"
                  style={{ width: '240px' }}
                  length={4}
                  value={password}
                  onChange={(e) => {
                    setPassword(e.replace(/\D/g, ''));
                  }}
                />
              </div>

              <Button
                block
                type="primary"
                onClick={() => {
                  setStep(1);
                  setConfirmPassword('');
                }}
                loading={loading}
                disabled={!tomo.userInfo || password?.length !== 4}
              >
                Continue
              </Button>
            </>
          ) : (
            <>
              <div>
                <Input.OTP
                  status={OTPStatus}
                  inputMode="numeric"
                  className="h-[50px] text-[18px]"
                  style={{ width: '240px' }}
                  length={4}
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.replace(/\D/g, ''));
                  }}
                />
              </div>

              <Button
                block
                type="primary"
                onClick={() => {
                  handleSetPwd();
                }}
                loading={loading}
                disabled={!tomo.userInfo || password?.length !== 4}
              >
                Set Password
              </Button>
            </>
          )}
        </div>
      </ConfigProvider>
    </div>
  );
}
