import { SaDrawer } from '@/components/SaDrawer';
import { useAppRouter } from '@/hooks/router/useAppRouter';
import { useAppMessage } from '@/hooks/useAppMessage';
import { useTomoSdk } from '@/hooks/useTomoSdk';
import { LeftOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Button, ConfigProvider, Form, Input } from 'antd';
import { useState } from 'react';
import { useVerifyContext } from './VerifyContext';

type DrawerProps = {
  open: boolean;
  onClose: () => void;
};

export default function VerifyEmail() {
  const [form] = Form.useForm();

  const { tomo } = useTomoSdk();

  const message = useAppMessage();

  const { setEmail } = useVerifyContext();

  const { goVerifyEmailInput } = useAppRouter();

  const { run, loading } = useRequest(
    async () => {
      const res = await tomo.onSendEmailCode({ email: form.getFieldValue('email') });

      if ([8000, 10000].includes(res.code)) {
        return Promise.reject(new Error(res.message));
      }

      return res;
    },
    {
      manual: true,
      onSuccess() {
        message.success('Email sent successfully');

        setEmail(form.getFieldValue('email'));

        goVerifyEmailInput();
      },
      onError(err) {
        message.error(err.message || 'Failed to send email');
      },
    },
  );

  const handleSend = () => {
    form
      .validateFields()
      .then(() => {
        run();
      })
      .catch(() => null);
  };

  const { goBack } = useAppRouter();

  return (
    <>
      <div className="py-[18px] px-4">
        <ConfigProvider
          theme={{
            components: {
              Button: {
                controlHeight: 36,
              },
            },
          }}
        >
          <Button
            type="text"
            className="text-secondaryText ml-[-15px]"
            onClick={() => {
              goBack();
            }}
          >
            <LeftOutlined /> Back
          </Button>
        </ConfigProvider>

        <div className="text-xl">Add Recovery Email</div>

        <div className="mt-2 text-secondaryText text-[13px]">
          If you lose access to your account, you can easily restore it by logging into the TOMO App
          with your email address to complete the recovery process.
        </div>
        <ConfigProvider
          theme={{
            components: {
              Input: {
                controlHeight: 48,
              },
            },
          }}
        >
          <Form className="w-full mt-[42px]" form={form}>
            <Form.Item
              required
              className="w-full mb-0"
              name="email"
              validateDebounce={1000}
              rules={[
                {
                  required: true,
                  message: 'Please enter your email',
                  type: 'email',
                },
              ]}
            >
              <Input
                placeholder="Enter your email"
                className="w-full email-input"
                allowClear
              ></Input>
            </Form.Item>

            <Button
              block
              onClick={() => {
                handleSend();
              }}
              loading={loading}
              type="primary"
              className="mt-[23px]"
            >
              Confirm
            </Button>
          </Form>
        </ConfigProvider>
      </div>
    </>
  );
}
