import { useUserStore } from '@/stores/useUserStore';
import { useEffect, useMemo } from 'react';
import {
  useTomo,
  usePaymentPasswd,
  useBiometricManager,
  useSendTransaction,
  useChains,
  useBalance,
} from 'tomo-tg-wallet-sdk';

export { useBalance };

export function useTomoSdk({
  onAuthStart,
  onAuthorized,
}: { onAuthStart?: () => void; onAuthorized?: () => void } = {}) {
  const tomo = useTomo();

  const chains = useChains();

  const transaction = useSendTransaction();

  const paymentPasswd = usePaymentPasswd();

  const biometric = useBiometricManager();

  const { setToken } = useUserStore();

  const needAuth = useMemo(() => {
    return tomo.userInfo && +paymentPasswd.paymentPwdExists === 0;
  }, [paymentPasswd.paymentPwdExists, tomo.userInfo]);

  useEffect(() => {
    if (!tomo.userInfo) {
      if (+paymentPasswd.paymentPwdExists === 0 && +biometric.bioInited === 0) {
        onAuthStart?.();
      } else {
        onAuthorized?.();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [biometric.bioInited, paymentPasswd.paymentPwdExists, tomo.userInfo]);

  useEffect(() => {
    if (tomo.userInfo?.token) {
      console.log('tomo.userInfo------->', JSON.stringify(tomo.userInfo));

      setToken(`Bearer ${tomo.userInfo?.token}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tomo.userInfo]);

  return {
    tomo,
    paymentPasswd,
    needAuth,
    biometric,
    chains,
    transaction,
  };
}
