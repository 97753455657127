import { useNativeToken } from '@/hooks/app/useNativeToken';
import { CoinDetail } from '@/types/Coin';
import { CoinImage } from '../CoinDetail';

export function SymbolUnit({ detail, isTon }: { detail: CoinDetail; isTon?: boolean }) {
  const [nativeName, nativeIcon] = useNativeToken();

  return (
    <>
      {isTon ? (
        <div className="inline-flex justify-start items-center gap-x-[12px] relative text-white">
          <span className="text-[15px]  font-semibold">{nativeName}</span>
          <div>
            <img className="w-[32px] h-[32px] rounded-full" src={nativeIcon} />
          </div>
        </div>
      ) : (
        <div className="inline-flex justify-start items-center text-white gap-x-[12px] overflow-hidden relative">
          <span className="text-[15px] font-semibold max-w-[140px] text-ellipsis overflow-hidden">
            {detail?.symbol}
          </span>
          <div>
            <CoinImage detail={detail} className="w-[32px] h-[32px] !rounded-full" />
          </div>
        </div>
      )}
    </>
  );
}
