import { useMemoizedFn, useRequest } from 'ahooks';
import { SendTransactionRequest } from '@tonconnect/sdk';
import { TransactionModalType, useTransactionContext } from '@/context/TransactionContext';
import { useTonConnectWallet } from './useTonConnectWallet';
import { useWalletInfoStore } from '@/stores/useWalletInfoStore';
import { TomoWalletTgSdkV2 } from '@tomo-inc/tomo-telegram-sdk';
import { TransactionCustomContent } from '@/components/TransactionModal';

new TomoWalletTgSdkV2({ injected: true });
const tomo_ton = window.tomo_ton;

export function useSendTransaction({
  openSuccessModal = true,
  successModalType = 'success',
  successModalContent,
}: {
  openSuccessModal?: boolean;
  successModalType?: TransactionModalType;
  successModalContent?: TransactionCustomContent;
} = {}) {
  const { tonConnectUI } = useTonConnectWallet();

  const { openModal, setType } = useTransactionContext();

  const { isTomoWallet } = useWalletInfoStore();

  const handleSetType = useMemoizedFn((type: TransactionModalType) => {
    setType(type);
  });

  const { runAsync: sendTransaction } = useRequest(
    async ({ sendTransactionRequest }: { sendTransactionRequest: SendTransactionRequest }) => {
      if (isTomoWallet) {
        return await tomo_ton.sendTx(sendTransactionRequest);
      } else {
        return await tonConnectUI.sendTransaction(sendTransactionRequest);
      }
    },
    {
      manual: true,
      onSuccess() {
        openSuccessModal && openModal(successModalType, successModalContent);
      },
      onError() {
        handleSetType('network');
      },
    },
  );

  return {
    sendTransaction,
  };
}
