import { getUserWallets } from '@/service/wallets';
import { useUserStore } from '@/stores/useUserStore';
import { useRequest } from 'ahooks';

export function useFetchUserWallets() {
  const { token, jwtInfo, setUserWallets } = useUserStore();

  const hook = useRequest(
    () => {
      return getUserWallets(jwtInfo!.id);
    },
    {
      onSuccess: (data) => {
        setUserWallets(data);
      },
      ready: !!token && !!jwtInfo?.id,
      refreshDeps: [jwtInfo?.id],
    },
  );

  return hook;
}
