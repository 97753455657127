export function Transactions({ operateList }: { operateList: Array<object> }) {
  return (
    <>
      {operateList.map((item: any) => {
        return (
          <div className="flex items-center justify-between py-2.5" key={item.value}>
            <div className="flex items-center">
              <img src={item.src} className="h-8 mr-2.5" />
              <div>
                <div className="font-semibold text-primaryText text-[16px] leading-5 mb-0.5">
                  {item.value}
                </div>
                <div className="text-[#9B9B9B] text-[12px] leading-[15.6px]">{item.date}</div>
              </div>
            </div>
            <div className="font-semibold text-primaryText text-[16px] leading-5 text-right">
              <div>{item.USDT} USDT</div>
              {item.state && (
                <div className="text-[12px] leading-[15.6px] text-[#D79D0A] font-normal">
                  {item.state}
                </div>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
}
