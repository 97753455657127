import { ArrowDownOutlined, ArrowUpOutlined, SwapOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { TomoTokenItem } from '@/types/Tomo';
import { SendOnlyNative } from './SendOnlyNative';
import { ReceiveOnlyNative } from './ReceiveOnlyNative';
import Refer from '../Refer/Refer';

export function CoinOperate({ token }: { token?: TomoTokenItem }) {
  const [sendOpen, setSendOpen] = useState(false);

  const [receiveOpen, setReceiveOpen] = useState(false);

  const [referOpen, setReferOpen] = useState(false);

  return (
    <div>
      <div className="flex justify-around py-4">
        <div className="text-center" onClick={() => setSendOpen(true)}>
          <ArrowUpOutlined size={14} className="p-[17px] bg-[#FFFFFF0D] rounded-full" />
          <div className="mt-2 text-3 leading-[15px] font-normal">Send</div>
        </div>
        <div className="text-center" onClick={() => setReceiveOpen(true)}>
          <ArrowDownOutlined size={14} className="p-[17px] bg-[#FFFFFF0D] rounded-full" />
          <div className="mt-2 text-3 leading-[15px] font-normal">Recevie</div>
        </div>
        <div className="text-center" onClick={() => setReferOpen(true)}>
          <SwapOutlined size={14} className="p-[17px] bg-[#FFFFFF0D] rounded-full" />
          <div className="mt-2 text-3 leading-[15px] font-normal">Refer</div>
        </div>
      </div>

      <SendOnlyNative open={sendOpen} onCancel={() => setSendOpen(false)} />

      <ReceiveOnlyNative open={receiveOpen} onCancel={() => setReceiveOpen(false)} />

      <Refer open={referOpen} onCancel={() => setReferOpen(false)} />
    </div>
  );
}
