import { Button } from '@/components/Button';
import { useAppRouter } from '@/hooks/router/useAppRouter';
import { LeftOutlined } from '@ant-design/icons';
import { ConfigProvider } from 'antd';
import social1Icon from '@/assets/icon/icon_social1.svg';
import social2Icon from '@/assets/icon/icon_social2.svg';
import social3Icon from '@/assets/icon/icon_social3.svg';
import stats1Icon from '@/assets/icon/icon_stats1.svg';
import stats2Icon from '@/assets/icon/icon_stats2.svg';
import stats3Icon from '@/assets/icon/icon_stats3.svg';
import stats4Icon from '@/assets/icon/icon_stats4.svg';
import stats5Icon from '@/assets/icon/icon_stats5.svg';
import stats6Icon from '@/assets/icon/icon_stats6.svg';
import stats7Icon from '@/assets/icon/icon_stats7.svg';
import transDownIcon from '@/assets/icon/icon_transDown.svg';
import transUpIcon from '@/assets/icon/icon_transUp.svg';
import transIcon from '@/assets/icon/icon_trans.svg';
import { CoinOperate } from '@/components/CoinOperate';
import { useMemo, useState } from 'react';
import classNames from 'classnames';
import { Transactions } from '@/components/Transactions';
import { useRequest } from 'ahooks';
import { fetchDetailToken, searchToken } from '@/service/tomo';
import { useAppMessage } from '@/hooks/useAppMessage';
import { shortenAddress } from '@/utils/ton';
import { useParseRouterQuery } from '@/hooks/router/useParseRouterQuery';
import { Loading } from '@/components/Loading';
import { priceFormat } from '@/utils/priceFormat';
import { chains } from '@/constants';
import { TomoCustomKLine } from './CustomKLine';
import { TomoTokenItem } from '@/types/Tomo';
import { Balance } from '@/views/Swap';
import { G6Math } from '@/contract/utils/Math';
import { useBalance } from '@/hooks/useTomoSdk';
import { formatNumber } from '../Home/modules/FlyV2';

export default function () {
  const { goBack } = useAppRouter();

  const { query } = useParseRouterQuery({
    address: '',
    chain: '',
    token: '',
  });

  const message = useAppMessage();

  const { data, loading } = useRequest(
    async () => {
      const res = await fetchDetailToken({
        address: query.address || '',
        chain: query.chain,
      });

      return res;
    },
    {
      onSuccess(res) {
        console.log('res', res);
      },
      onError(err) {
        message.error(err.message || 'Fetch token failed');
      },
    },
  );

  const result = useMemo(() => {
    return data?.result;
  }, [data?.result]);

  const statesList = useMemo(() => {
    return [
      { label: 'Market Cap', icon: stats1Icon, value: priceFormat(result?.mc) },
      { label: 'FDV', icon: stats2Icon, value: priceFormat(result?.fdvUsd) },
      { label: 'Liquidity', icon: stats3Icon, value: priceFormat(result?.liquidity) },
      { label: '24h Turnover', icon: stats4Icon, value: result?.history24hPrice },
      { label: '24h Change', icon: stats5Icon, value: `${result?.priceChange24hPercent}%` },
      { label: 'Contract', icon: stats6Icon, value: shortenAddress(result?.address) },
      { label: 'Total', icon: stats7Icon, value: result?.supply },
    ];
  }, [result]);

  const [operateList] = useState([
    // {
    //   value: 'Swap for USDC',
    //   src: transIcon,
    //   date: 'Apr 16, 2024',
    //   USDT: '-0.2',
    //   state: 'Pending',
    // },
    // { value: 'Sent', src: transUpIcon, date: 'Apr 16, 2024', USDT: '-0.2' },
    // { value: 'Receive', src: transDownIcon, date: 'Apr 16, 2024', USDT: '-0.2' },
    // { value: 'Sent', src: transUpIcon, date: 'Apr 16, 2024', USDT: '-0.2' },
  ]);

  const tokenData = useMemo(() => {
    try {
      const res = JSON.parse(decodeURIComponent(query.token)) as TomoTokenItem;

      console.log('tokenData', res);

      return res;
    } catch (err) {
      console.log('err', err);

      return;
    }
  }, [query.token]);

  const { data: tokenItem } = useRequest(
    async () => {
      const res = await searchToken({
        address: query.address || '',
        chain: query.chain,
      });

      return res.result[0];
    },
    {
      ready: !query.token,
    },
  );

  return (
    <Loading loading={loading}>
      <div className="bg-primaryBackground sticky top-0 z-20 font-normal">
        <div className="flex justify-start items-center h-[48px] sticky top-0 bg-primaryBackground z-50">
          <ConfigProvider
            theme={{
              components: {
                Button: {
                  controlHeight: 36,
                },
              },
            }}
          >
            <Button type="text" className="text-secondaryText" onClick={goBack}>
              <LeftOutlined />
              <img src={tokenData?.imageUrl} className="w-[30px] h-[30px] rounded-full"></img>
              <span className="text-primaryText text-[16px] font-medium">{tokenData?.symbol}</span>
            </Button>
          </ConfigProvider>
        </div>
        <div className="px-4 py-2 flex items-center justify-between">
          <div>
            <div className="text-[26px] leading-[28.6px] font-semibold text-primaryText">
              {formatNumber(result?.price)}
            </div>
            <div
              className={classNames('text-[#15BC76] text-[12px] leading-[15.6px] mt-2', {
                '!text-[#F54976]': tokenData && tokenData?.priceChangeH24 < 0,
              })}
            >
              {tokenData?.priceChangeH24 || '-'}%
            </div>
          </div>
          <div className="text-secondaryText text-[12px] leading-[15.6px] flex gap-1 flex-col">
            <div>24h vol</div>
            <div>24h high</div>
            <div>24h low</div>
          </div>
          <div className="text-primaryText text-[12px] leading-[15.6px] flex gap-1 flex-col text-right">
            <div>${G6Math.from(tokenData?.volumeH24 || '0').toFixed(4)}</div>
            <div>{formatNumber(result?.high24h)}</div>
            <div>{formatNumber(result?.low24h)}</div>
          </div>
        </div>

        <TomoCustomKLine tokenName={data?.result.name} />

        <div className="py-4">
          <CoinOperate token={tokenData || tokenItem} />
        </div>

        <div className="px-4 py-6">
          <div className="text-[18px] leading-[23.4px] mb-4 font-semibold">Your Balance</div>
          <div className="font-normal text-primaryText flex items-center gap-[35px]">
            <div className="w-1/2">
              <div className="text-[11px] leading-[14px] opacity-60">Value</div>
              <div className="font-semibold text-[18px] leading-6 mt-2">
                <Balance token={tokenData || tokenItem} />
              </div>
            </div>
            {/* <div className="w-1/2">
              <div className="text-[11px] leading-[14px] opacity-60">Quantity</div>
              <div className="font-semibold text-[18px] leading-6 mt-2">0</div>
            </div> */}
          </div>
        </div>

        <div className="px-4 py-6">
          <div className="text-[18px] leading-[23.4px] mb-3 font-semibold">About</div>
          <div className="text-[14px] leading-[19.6px]">-</div>
          <div className="mt-4">
            {result?.extensions.website && (
              <div
                onClick={() => {
                  window.open(result?.extensions.website);
                }}
                className="py-[6px] px-[7.5px] rounded-[18px] bg-[#242424] inline-flex items-center mr-1.5"
              >
                <img src={social1Icon} className="w-[15px]"></img>
                <span className="ml-0.5 text-[12px] leaading-[15.6px]">Site</span>
              </div>
            )}

            {result?.extensions.twitter && (
              <div
                onClick={() => {
                  window.open(result?.extensions.twitter);
                }}
                className="py-[6px] px-[7.5px] rounded-[18px] bg-[#242424] inline-flex items-center mr-1.5"
              >
                <img src={social2Icon} className="w-[15px]"></img>
                <span className="ml-0.5 text-[12px] leaading-[15.6px]">Twitter</span>
              </div>
            )}

            {chains[tokenData?.chain || '']?.explorer && (
              <div
                onClick={() => {
                  window.open(
                    `${chains[tokenData?.chain || '']?.explorerAddress}${tokenData?.address}`,
                  );
                }}
                className="py-[6px] px-[7.5px] rounded-[18px] bg-[#242424] inline-flex items-center"
              >
                <img src={social3Icon} className="w-[15px]"></img>
                <span className="ml-0.5 text-[12px] leaading-[15.6px]">Explorer</span>
              </div>
            )}
          </div>
        </div>

        <div className="px-4 py-6">
          <div className="text-[18px] leading-[23.4px] mb-4 font-semibold">Stats</div>
          {statesList.map((item) => {
            return (
              <div
                className="py-[7px] font-medium text-[14px] flex items-center justify-between"
                key={item.label}
              >
                <div className="text-[#9B9B9B] flex items-center">
                  <img src={item.icon} className="w-4 mr-[7.3px]"></img>
                  <span className="leading-[18.2px]">{item.label}</span>
                </div>
                <div
                  className={classNames(
                    'leading-[19.6px] text-primaryText',
                    item.label === '24h Change' &&
                      item &&
                      ((item.value ? +item.value : 0) > 0 ? '!text-[#17CF82]' : 'text-[#F54976]'),
                  )}
                >
                  {item.value}
                </div>
              </div>
            );
          })}
        </div>

        {/* <div className="px-4 py-6">
          <div className="text-[18px] leading-[23.4px] mb-4 font-semibold">Transactions</div>
          <Transactions operateList={operateList}></Transactions>
        </div> */}

        {/* <div className="w-full flex justify-end items-center gap-x-3 p-4 fixed bottom-0 bg-primaryBackground">
        <Button
          block
          className="text-[16px] font-medium leading-5 py-[14px] h-auto w-[275px] md:w-[100%] !bg-primary !text-primaryText"
          type="primary"
        >
          Sell
        </Button>
        <Button
          block
          className="text-[16px] font-medium leading-5 py-[14px] h-auto w-[275px] md:w-[100%] !bg-primaryText !text-primaryBackground"
          type="primary"
        >
          Buy
        </Button>
      </div> */}
      </div>
    </Loading>
  );
}
