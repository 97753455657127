import { useAppMessage } from '@/hooks/useAppMessage';
import { createWallet, SignedProofPayload } from '@/service/wallets';
import { useUserStore } from '@/stores/useUserStore';
import { useRequest } from 'ahooks';

export function useCreateWallet({ disconnect }: { disconnect: () => void }) {
  const userStore = useUserStore();
  const message = useAppMessage();

  const { run } = useRequest(
    async (signedProofPayload: SignedProofPayload) => {
      const reqBody = {
        chain: 'ton',
        signedProof: {
          payload: signedProofPayload,
        },
      };
      const response = await createWallet(reqBody);
      return response;
    },
    {
      manual: true,
      onSuccess(data) {
        userStore.setToken(data.token);
        // if (Temporary.getChatId()) {
        //   runUpdate(Temporary.getChatId()!);
        // }
      },
      onError(err) {
        disconnect();
        console.log('checkProof error', err);
        message.error('Failed to check proof');
      },
    },
  );

  return { run };
}
