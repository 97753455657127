import { Table, ConfigProvider, Button } from 'antd';
import { useFetchCoinTrade } from '../../hooks/useFetchCoinTrade';
import { useParseRouterParam } from '@/hooks/router/useParseRouterParam';
import { ColumnType } from 'antd/es/table';
import { TradeHistoryItem } from '@/service/trade';
import dayjs from 'dayjs';
import { useState } from 'react';

export function getTimeAgo(timestamp: number) {
  const currentTime = dayjs();

  const targetTime = dayjs.unix(timestamp);

  const diffMinutes = currentTime.diff(targetTime, 'minute');
  const diffHours = currentTime.diff(targetTime, 'hour');
  const diffDays = currentTime.diff(targetTime, 'day');

  if (diffMinutes < 1) {
    return `${currentTime.diff(targetTime, 'second')}s ago`;
  } else if (diffHours < 1) {
    return `${diffMinutes}m ago`;
  } else if (diffDays < 1) {
    return `${diffHours}h ago`;
  } else {
    return `${diffDays}d ago`;
  }
}

export function TonTradeTable({ columns }: { columns: ColumnType<TradeHistoryItem>[] }) {
  const { param } = useParseRouterParam({ address: '' });
  const [tableData, setTableData] = useState<TradeHistoryItem[]>([]);

  const { data, loading, run } = useFetchCoinTrade({
    address: param.address,
    onSuccess: (v) => {
      setTableData((pre) => [...pre, ...v.docs]);
    },
  });

  return (
    <ConfigProvider
      wave={{ disabled: true }}
      theme={{
        components: {
          Table: {
            colorBgContainer: '#ffffff00',
            borderColor: '#ffffff10',
            headerBg: '#ffffff00',
            cellFontSize: 14,
            cellPaddingBlock: 15,
            cellPaddingInline: 0,
            footerBg: '#ffffff00',
            headerColor: '#ffffff50',
            headerSplitColor: '#ffffff00',
            lineWidth: 2,
          },
        },
      }}
    >
      <Table
        columns={columns as any}
        rowKey={(record) => record._id}
        className="mt-[14px] tradeTable md:mt-0 font-[400]"
        pagination={false}
        loading={loading}
        dataSource={tableData}
        summary={() =>
          data?.hasNextPage ? (
            <Table.Summary>
              <Table.Summary.Row>
                <Table.Summary.Cell index={2} colSpan={5}>
                  <div className="text-center">
                    <Button
                      loading={loading}
                      size="small"
                      onClick={() => {
                        run({ current: data.nextPage });
                      }}
                    >
                      <span className="text-white">Load more</span>
                    </Button>
                  </div>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          ) : (
            <></>
          )
        }
      />
    </ConfigProvider>
  );
}
