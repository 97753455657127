import { useCoinDetailStore } from '@/views/Coin/context/CoinDetailContext';
import { useRequest } from 'ahooks';
import request from '@/request';
import dayjs from 'dayjs';
import { getCandlesticks } from '@/test';
import { SolCoinDetail } from '@/types/Coin';
import { useEffect, useState } from 'react';

export type DateType = 'minute' | 'hour' | 'day' | 'week' | 'month' | 'year';

export type KlineData = {
  open: number;
  high: number;
  low: number;
  close: number;
  // vol: number;
  time: number;
};

// 页面上展示60条数据，每次请求120条数据，拖拽到最左侧时加载更多，coefficient是在加载更多的时候用
function getDefaultRange(type: DateType = 'minute', coefficient: number = 1) {
  switch (type) {
    case 'minute':
      return {
        from: dayjs()
          .subtract(120 * coefficient, 'minute')
          .unix(),
        to: dayjs().unix(),
        countBack: 120 * coefficient,
      };
    case 'hour':
      return {
        from: dayjs()
          .subtract(120 * coefficient, 'hour')
          .unix(),
        to: dayjs().unix(),
        countBack: 120 * coefficient,
      };
    case 'day':
      return {
        from: dayjs()
          .subtract(60 * coefficient, 'day')
          .unix(),
        to: dayjs().unix(),
        countBack: 60 * coefficient,
      };
    case 'week':
      return {
        from: dayjs()
          .subtract(60 * coefficient, 'week')
          .unix(),
        to: dayjs().unix(),
        countBack: 60 * coefficient,
      };
    case 'month':
      return {
        from: dayjs()
          .subtract(60 * coefficient, 'month')
          .unix(),
        to: dayjs().unix(),
        countBack: 60 * coefficient,
      };
    case 'year':
      return {
        from: dayjs()
          .subtract(60 * coefficient, 'year')
          .unix(),
        to: dayjs().unix(),
        countBack: 60 * coefficient,
      };
  }
}

const dateTypeMap = {
  minute: '1m',
  hour: '1H',
  day: '1D',
  week: '1W',
  month: '1M',
  year: '1Y',
};

export function useFetchKLineData({
  dateType,
  onSuccess,
}: {
  dateType: DateType;
  onSuccess: (v: KlineData[]) => void;
}) {
  const { detail, isTonData } = useCoinDetailStore();
  const [coefficient, setCoefficient] = useState(1);

  const { data: tonData, runAsync: tonRunAsync } = useRequest(
    async (coefficient: number) => {
      const { from, to, countBack } = getDefaultRange(dateType, coefficient);
      const res = await request.get<KlineData[]>(`/trade/kline/${detail?.address}`, {
        from,
        to,
        countBack,
        resolution: dateTypeMap[dateType],
      });
      return { list: res.data, hasMore: res.data.length === countBack };
    },
    {
      ready: !!detail && isTonData(detail),
      throttleWait: 2000,
      onSuccess(data) {
        console.log('kdata----------->', data);
        onSuccess(data.list);
      },
    },
  );

  useEffect(() => {
    setCoefficient(1);
    tonRunAsync(1);
  }, [dateType, tonRunAsync]);

  const loadMore = () => {
    if (isTonData(detail) && tonData?.hasMore) {
      const newCoe = coefficient + 1;
      setCoefficient(newCoe);
      tonRunAsync(newCoe);
    }
  };

  const { data: solData } = useRequest(
    async () => {
      const res = await getCandlesticks({
        mint: (detail as SolCoinDetail)?.mint,
      });
      return {
        list: res.map((item) => ({ ...item, time: item.timestamp * 1000 })),
        hasMore: false,
      };
    },
    {
      ready: !!detail && !isTonData(detail),
      onSuccess(data) {
        onSuccess(data.list);
      },
    },
  );

  return {
    data: isTonData(detail) ? tonData?.list : solData?.list,
    loadMore,
  };
}
