import { SaDrawer } from '@/components/SaDrawer';
import { useAppMessage } from '@/hooks/useAppMessage';
import { fetchAllToken } from '@/service/coin';
import { IWeb3ChainType } from '@/types/App';
import { shortenAddress } from '@/utils/ton';
import { MultiChainToken } from '@/views/Home/components/MultiChainToken';
import { useInViewport, useRequest } from 'ahooks';
import { Input, List } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { ISwapTokenType } from 'tomo-tg-wallet-sdk/dist/hooks/useSwapTokens';
import VirtualList from 'rc-virtual-list';
import { getSafeChainId } from '../context/SwapContext';
import { TomoTokenItem } from '@/types/Tomo';
import { formatNumber } from '@/views/Home/modules/FlyV2';

function Item({ asset, onClick }: { asset: ISwapTokenType; onClick: () => void }) {
  const ref = useRef(null);

  const [inViewport] = useInViewport(ref);

  return (
    <div
      className="flex justify-between items-center w-full cursor-pointer pr-3"
      onClick={onClick}
      ref={ref}
    >
      <div className="flex justify-start items-center gap-x-[10px]">
        <MultiChainToken
          symbol={asset.symbol}
          logoSize={32}
          token={asset.imageUrl}
          chain={asset.chain}
        />

        <div>
          <div className="text-primaryText text-base">
            <div>{asset.symbol}</div>
            <div className="text-xs text-secondaryText">{shortenAddress(asset.address)}</div>
          </div>
        </div>
      </div>

      <div className="flex justify-end items-end gap-x-[5px] flex-col">
        {/* <div>{inViewport && <LazyBalance token={asset as TomoTokenItem}></LazyBalance>}</div> */}
        <div className="text-xs text-secondaryText">${formatNumber(asset.priceUsd)}</div>
      </div>
    </div>
  );
}

export function TokenSelect(props: {
  open: boolean;
  onSelect?: (token: any) => void;
  onCancel: () => void;
  chain?: IWeb3ChainType;
}) {
  const [search, setSearch] = useState('');

  // const { tokens, loading } = useSwapTokens({
  //   chain: props.chain!,
  //   content: search,
  // });

  // const tokens = useMemo(() => {
  //   return take(list, 200);

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [list?.length]);

  useEffect(() => {
    if (props.open) {
      setSearch('');
    }
  }, [props.open]);

  const message = useAppMessage();

  const { data, loading } = useRequest(
    async () => {
      const res = await fetchAllToken({
        page: 1,
        limit: 150,
        keyword: search,
        chainId: props.chain?.chain?.id || getSafeChainId(props.chain!),
      });

      return res.data;
    },
    {
      onError(err) {
        message.info(err.message || 'Fetch token failed');
      },
      refreshDeps: [search, props.chain],
      debounceWait: 800,
    },
  );

  return (
    <SaDrawer titleCenter title="Select token" open={props.open} onClose={props.onCancel}>
      <div>
        <Input
          placeholder="Search token"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        ></Input>
      </div>

      <div className="overflow-y-auto mt-[16px]">
        <List
          split={false}
          rowKey={(record) => `${record.id}${record.priceChangeH24}`}
          loading={loading}
          dataSource={data?.docs}
        >
          <VirtualList
            data={data?.docs || []}
            height={window.innerHeight - 120}
            itemHeight={47}
            itemKey="address"
          >
            {(item) => (
              <List.Item>
                <Item asset={item} onClick={() => props.onSelect?.(item)}></Item>
              </List.Item>
            )}
          </VirtualList>
        </List>
      </div>
    </SaDrawer>
  );
}
