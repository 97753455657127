import { ReactComponent as Follow } from '@/assets/icon/app/follow.svg';
import { ReactComponent as Unfollow } from '@/assets/icon/app/unfollow.svg';
import { useParseRouterParam } from '@/hooks/router/useParseRouterParam';
import { useAppMessage } from '@/hooks/useAppMessage';
import { useUserStore } from '@/stores/useUserStore';
import { useFetchFollowing } from '@/views/Profile/hooks/useFetchFellowing';
import { useFollowUser } from '@/views/Profile/hooks/useFollowUser';
import { useUnFollowUser } from '@/views/Profile/hooks/useUnFollowUser';
import classNames from 'classnames';
import { useMemo } from 'react';

export function FollowIcon({
  className,
  userId,
  onSuccessCallback,
}: {
  className?: string;
  userId?: string;
  onSuccessCallback?: () => void;
}) {
  const { jwtInfo } = useUserStore();

  const { data: followingData, refresh } = useFetchFollowing(jwtInfo?.id);

  const { param } = useParseRouterParam({ userId: '' });

  const message = useAppMessage({ key: 'follow' });
  const { run: unFollowUser } = useUnFollowUser({
    onSuccess() {
      refresh();
      onSuccessCallback?.();

      message.success('Unfollowed successfully');
    },
  });

  const { run: followUser } = useFollowUser({
    onSuccess() {
      refresh();
      onSuccessCallback?.();

      message.success('Followed successfully');
    },
  });

  const isFollowing = useMemo(() => {
    const addresses = followingData?.following?.map((item) => item.user_id);

    return addresses?.includes(userId || param?.userId);
  }, [followingData, userId, param?.userId]);

  return (
    <>
      {userId !== jwtInfo?.id && (
        <span>
          {isFollowing ? (
            <div
              className={classNames(
                className,
                'bg-[#282828] px-[10px] text-center text-[12px] leading-[32px] rounded-[20px] text-[#9B9B9B] w-[79px]',
              )}
              onClick={() => {
                unFollowUser(userId || param?.userId);
              }}
            >
              Following
            </div>
          ) : (
            <div
              className={classNames(
                className,
                'bg-[#483FFF] px-[10px] text-center text-[12px] leading-[32px] rounded-[20px] text-primaryText w-[79px] cursor-pointer',
              )}
              onClick={() => {
                followUser(userId || param?.userId);
              }}
            >
              Follow
            </div>
          )}
        </span>
      )}
    </>
  );
}
