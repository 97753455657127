import { SaDrawer } from '@/components/SaDrawer';
import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Button } from '@/components/Button';
import { ReactComponent as Filter } from '@/assets/icon/app/filter.svg';
import { useParseRouterQuery } from '@/hooks/router/useParseRouterQuery';
import { cloneDeep } from 'lodash';

const Title = ({ label }: { label: string }) => (
  <div className="text-[14px] leading-[14px] text-tertiaryText mb-[16px] mt-[8px]">{label}</div>
);

export enum FilterType {
  OVERALL = 'overall',
  TOP_10_MCAP = 'top_10_mcap',
  TOP_20_MCAP = 'top_20_mcap',
  TOP_10_REPLY = 'top_10_reply',
  TOP_20_REPLY = 'top_20_reply',
  LAST_24_HOURS = 'last_24_hours',
}

export enum SortByType {
  MCAP = 'mcap',
  BUMP_ORDER = 'bump_order',
  LAST_REPLY = 'last_reply',
  REPLY_COUNT = 'reply_count',
  NEWEST = 'newest',
  OLDEST = 'oldest',
}

export function FilterPopup() {
  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  const [filterList, setFilterList] = useState([
    {
      title: 'Filter',
      list: [
        {
          label: 'Overall',
          value: FilterType.OVERALL,
        },
        {
          label: 'Top 10 MCAP',
          value: FilterType.TOP_10_MCAP,
        },
        {
          label: 'Top 20 MCAP',
          value: FilterType.TOP_20_MCAP,
        },
        // {
        //   label: 'Top 10 reply',
        //   value: FilterType.TOP_10_REPLY,
        // },
        // {
        //   label: 'Top 20 reply',
        //   value: FilterType.TOP_20_REPLY,
        // },
        {
          label: 'Last 24 hours',
          value: FilterType.LAST_24_HOURS,
        },
      ],
      check: FilterType.OVERALL,
    },
    {
      title: 'Sort',
      list: [
        {
          label: 'MCAP',
          value: SortByType.MCAP,
        },
        {
          label: 'Bump order',
          value: SortByType.BUMP_ORDER,
        },
        // {
        //   label: 'Last reply',
        //   value: SortByType.LAST_REPLY,
        // },
        // {
        //   label: 'Reply count',
        //   value: SortByType.REPLY_COUNT,
        // },
        {
          label: 'Newest',
          value: SortByType.NEWEST,
        },
        {
          label: 'Oldest',
          value: SortByType.OLDEST,
        },
      ],
      check: SortByType.MCAP,
    },
  ]);

  const data = useMemo(() => {
    return {
      filter: filterList[0].check,
      sort: filterList[1].check,
    };
  }, [filterList]);

  const tagClick = (value: string, index: number) => {
    const list = filterList.map((v, k) => {
      if (k === index) {
        v.check = (v.check === value ? '' : value) as any;
      }
      return v;
    });

    setFilterList(list);
  };

  const submit = () => {
    console.log('data', data);

    updateQuery(data);

    onClose();
  };

  const { query, updateQuery } = useParseRouterQuery({
    filter: '',
    sort: '',
  });

  useEffect(() => {
    const newFilterList = cloneDeep(filterList);
    newFilterList[0].check = query.filter as FilterType;
    newFilterList[1].check = query.sort as SortByType;

    setFilterList(newFilterList);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, query.filter, query.sort]);

  return (
    <>
      <div
        className="px-[12px] active:opacity-90 transition-opacity"
        onClick={() => {
          setOpen(true);
        }}
      >
        <Filter
          className={classNames({
            '[&_path]:fill-primary': query.filter || query.sort,
          })}
        />
      </div>
      <SaDrawer open={open} onClose={onClose} title="FILTERS" height="auto">
        <div className="font-[Poppins] overflow-y-auto">
          {filterList.map((v, k) => {
            return (
              <div key={k}>
                <Title label={v.title} />
                <div className="flex flex-wrap">
                  {v.list.map((item, index: number) => {
                    return (
                      <div
                        key={index}
                        style={{
                          width: 'calc((100% - 32px) / 3)',
                        }}
                        className={classNames(
                          'h-[36px] rounded-[33px] border-solid border-[1px] border-[#282828] mb-[12px] text-[12px] leading-[12px] p-[8px] flex items-center justify-center text-[#BDBDBD] font-[400]',
                          {
                            'mr-[16px]': (index + 1) % 3 !== 0,
                            'border-[#fff] text-[#fff] bg-[#ffffff19] font-[500]':
                              v.check === item.value,
                          },
                        )}
                        onClick={() => {
                          tagClick(item.value, k);
                        }}
                      >
                        {item.label}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
          <div className="py-[16px] w-full px-[16px]">
            <Button className="bg-[#483FFF] w-full h-[48px]" onClick={submit}>
              Continue
            </Button>
          </div>
        </div>
      </SaDrawer>
    </>
  );
}
