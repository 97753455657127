import classNames from 'classnames';

export function CapsuleNavigation({
  active,
  items,
  onChange,
}: {
  active?: string;
  items: { label: any; value: string }[];
  onChange?: (value: string) => void;
}) {
  return (
    <div className="flex justify-between items-center bg-secondaryBackground p-[4px] rounded-[35px] ">
      {items.map((item) => {
        return (
          <div
            onClick={() => {
              onChange?.(item.value);
            }}
            className={classNames(
              'h-full rounded-[35px] text-center text-sm py-[7px] font-[400] text-[#656565]',
              {
                '!bg-primary font-[500] text-primaryText': active === item.value,
              },
            )}
            key={item.value}
            style={{
              width: `${100 / items.length}%`,
            }}
          >
            {item.label}
          </div>
        );
      })}
    </div>
  );
}
