import { Button } from '@/components/Button';
import { useUpdateTaskStatus } from '@/hooks/app/tasks/useUpdateTaskStatus';
import { useFetchTasksStats } from '@/hooks/app/tasks/useFetchTasksStats';
import { TaskCode } from '@/types/Tasks';
import { useCoinDetailStore } from '../context/CoinDetailContext';

const JoinGroup = () => {
  const { detail } = useCoinDetailStore();
  const { runAsync: updateTaskStatus } = useUpdateTaskStatus();
  const { data, refreshAsync } = useFetchTasksStats();
  const taskInfo = data?.tasks.find((item) => item.taskCode === TaskCode.JoinAnyTokenGroup);

  const joinGroup = async () => {
    if (detail?.telegram) {
      window.Telegram.WebApp.openTelegramLink(detail.telegram);
      if (!taskInfo?.isCompleted && taskInfo?.taskCode) {
        await updateTaskStatus(taskInfo.taskCode);
        refreshAsync();
      }
    }
  };

  return (
    <Button
      type="primary"
      className="h-8 font-normal text-[12px] leading-[12px]"
      onClick={joinGroup}
    >
      Join Group
    </Button>
  );
};

export default JoinGroup;
