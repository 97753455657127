import { Item } from '@/components/CoinCard';
import { Skeleton } from 'antd';
import { useFetchG6 } from '../../hooks/useFetchG6';
import { motion, AnimatePresence } from 'framer-motion';
import { useRef } from 'react';

export function G6Item() {
  const { data: g6Item, loading } = useFetchG6();

  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div
      className="w-full h-full min-h-[137px] mt-[12px] px-[12px] relative overflow-hidden"
      ref={containerRef}
    >
      <Skeleton
        active={loading}
        loading={loading && !g6Item}
        avatar={{ shape: 'square' }}
        paragraph={{ rows: 2 }}
      >
        <AnimatePresence>
          {g6Item && (
            <motion.div
              className="absolute w-[calc(100%_-_20px)] h-full"
              key={g6Item._id}
              initial={{ opacity: 0, x: 50, scale: 0.8 }}
              animate={{ opacity: 1, x: 0, scale: 1 }}
              exit={{ opacity: 0, x: -50, scale: 0.8 }}
              transition={{ duration: 0.5 }}
            >
              <Item item={g6Item!} isG6 />
            </motion.div>
          )}
        </AnimatePresence>
      </Skeleton>
    </div>
  );
}
