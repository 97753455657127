import { Coin } from '@/types/Coin';
import { Button, List, Skeleton } from 'antd';
import { useMemo } from 'react';
import { CoinItem } from '@/components/CoinCard';
import { isNumber, range } from 'lodash';
import classNames from 'classnames';
import { useFetchAllListToken } from '../hooks/useFetchAllListToken';

export function CoinList({ inSearch }: { inSearch?: boolean }) {
  const { data, list, loading, loadMore } = useFetchAllListToken();
  // data?.data.hasNextPage

  const LoadMore = useMemo(() => {
    return (
      <div
        className={classNames('w-full flex justify-center items-center my-[20px]', {
          '!hidden': !data?.hasNextPage || (!data?.list.length && loading),
        })}
      >
        <Button
          loading={loading}
          disabled={!data?.hasNextPage}
          onClick={() => {
            loadMore();
          }}
        >
          <span className="text-white">{data?.hasNextPage ? ' Load more' : 'No more data'}</span>
        </Button>
      </div>
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.hasNextPage, data?.list.length, loading]);

  return (
    <div className="h-full [&_.g6-list-item]:!border-0 ">
      <List
        className="coins"
        dataSource={loading ? (range(0, 10) as any) : list}
        loadMore={LoadMore}
        renderItem={(item) => {
          if (isNumber(item)) {
            return (
              <List.Item className="h-full">
                <Skeleton
                  active={loading}
                  loading={loading}
                  avatar={{ shape: 'square' }}
                  paragraph={{ rows: 3 }}
                ></Skeleton>
              </List.Item>
            );
          }

          return (
            <List.Item className="h-full">
              <CoinItem item={item as Coin} />
            </List.Item>
          );
        }}
      ></List>
    </div>
  );
}
