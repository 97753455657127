import { IChainType } from 'tomo-tg-wallet-sdk/dist/state/type';

export type ListQuery<T = any> = {
  current: number;
  size: number;
} & {
  [key in keyof T]?: any;
};

export type Token = any;

export enum MFAType {
  'biometric' = 'biometric',
  'password' = 'password',
}

export type IWeb3Type = 'EVM' | 'SOL' | 'BTC' | 'SUI' | 'ALL' | 'TON';

export type IWeb3NetworkType = 'main' | 'test' | 'custom';

export type IWeb3ChainType = {
  chain?: IChainType;
  type: IWeb3Type;
  name: string;
  icon?: string | undefined;
  networkType?: IWeb3NetworkType | undefined;
};
