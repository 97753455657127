import { useWalletInfoStore } from '@/stores/useWalletInfoStore';
import { formatByDecimals, lamportsToSol } from '@/utils/sol';
import { LoadingOutlined } from '@ant-design/icons';
import { fromNano } from '@ton/core';
import { useMemo } from 'react';

type Props = {
  value?: string | bigint;
  fixed?: number;
  loading?: boolean;
  suffix?: string;
  isNative?: boolean;
};

export function TokenValue({ value, fixed, loading, suffix, isNative = true }: Props) {
  const { isTon } = useWalletInfoStore();

  return isTon ? (
    <TonValue {...{ value, fixed, loading, suffix }} />
  ) : (
    <SolValue {...{ value, fixed, loading, suffix, isNative }} />
  );
}

export function TonValue({
  value,
  fixed,
  loading,
  suffix,
}: {
  value?: string | bigint;
  fixed?: number;
  loading?: boolean;
  suffix?: string;
}) {
  const val = useMemo(() => {
    try {
      if (!value) return '0';

      return fromNano(value);
    } catch {
      return '0';
    }
  }, [value]);

  const displayValue = useMemo(() => {
    return +val ? `${parseFloat((+val).toFixed(fixed || 2))} ${suffix || ''}` : '0.00';
  }, [fixed, suffix, val]);

  return loading ? <LoadingOutlined></LoadingOutlined> : <span title={val}>{displayValue}</span>;
}

export function SolValue({
  value,
  fixed,
  loading,
  suffix,
  isNative = true,
}: {
  value?: string | bigint;
  fixed?: number;
  loading?: boolean;
  suffix?: string;
  isNative: boolean;
}) {
  const val = useMemo(() => {
    try {
      if (!value) return '0';

      return isNative ? lamportsToSol(Number(value)) : formatByDecimals(Number(value));
    } catch {
      return '0';
    }
  }, [isNative, value]);

  const displayValue = useMemo(() => {
    return +val ? `${parseFloat((+val).toFixed(fixed || 2))} ${suffix || ''}` : '0.00';
  }, [fixed, suffix, val]);

  return loading ? <LoadingOutlined /> : <span title={val.toString()}>{displayValue}</span>;
}
