import { useFilter } from '@/hooks/useFilter';
import { getCoinForYou } from '@/service/sol-coin';
import { CoinsForYou, getCoinList } from '@/test';
import { useInfiniteScroll } from 'ahooks';
import { Data } from 'ahooks/lib/useInfiniteScroll/types';
import { uniqBy } from 'lodash';
import { useMemo, useState } from 'react';

export function useFetchSolanaCoinList({
  nsfw,
  sort,
  limit = 50,
  enabled,
}: {
  nsfw?: boolean;
  sort?: string;
  limit?: number;
  enabled: boolean;
}) {
  const { query } = useFilter();

  const { data, loading, loadMore } = useInfiniteScroll(
    async (param?: Data & { page: number }) => {
      let data;
      if (!query.search) {
        data = await getCoinForYou({
          offset: (param?.page || 0) * limit,
          limit,
          // createdTimestampEnd,
          // createdTimestampStart,
          nsfw,
          // search,
          // filter,
          // sort,
        });
      } else {
        data = await getCoinList({
          offset: (param?.page || 0) * limit,
          limit,
          nsfw,
          searchTerm: query.search,
        });
      }

      return {
        list: data,
        page: (param?.page || 0) + 1,
        hasNextPage: data.length === limit,
      };
    },
    {
      reloadDeps: [nsfw, sort, limit, enabled, query.search],
      manual: !enabled,
    },
  );

  const [socketData, setSocketData] = useState<CoinsForYou[]>([]);

  // const { data: g6Item } = useFetchG6();

  // const { onMessage } = useContext(WebSocketContext);

  const list = useMemo(() => {
    if (!enabled) return [];

    const val = uniqBy([...socketData, ...(data?.list || [])], 'mint');

    // if (g6Item && !inSearch) {
    //   val.unshift(g6Item);
    // }

    return val;
    // return take(val, 60);
  }, [enabled, socketData, data?.list]);

  // useEffect(() => {
  //   if (!enabled) return;

  //   const event = onMessage?.((e) => {
  //     if (e.type === 'launch') {
  //       e.data._id = `${+new Date()}`;

  //       setSocketData((prev) => {
  //         return [e.data, ...prev];
  //       });
  //     }
  //   });

  //   return () => {
  //     event?.();
  //   };

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return { list, data, loading, loadMore };
}
