import { fetchCoinTrade, ListResult, TradeHistoryItem } from '@/service/trade';
import { useRequest } from 'ahooks';

export function useFetchCoinTrade({
  address,
  onSuccess,
}: {
  address: string;
  onSuccess: (v: ListResult<TradeHistoryItem>) => void;
}) {
  const hook = useRequest(
    async (param: { current: number }) => {
      return await fetchCoinTrade(address, { current: param?.current ?? 0, size: 10 });
    },
    { onSuccess },
  );

  return hook;
}
