import { useGlobalDataStore } from '@/context/GlobalDataContext';
import { getProvider, getRandomBigInt } from '@/utils';
import { fetchLaunchConf } from '@/service/common';
import { SendTransactionRequest } from '@tonconnect/sdk';
import { getLaunchParam } from '../action/launch';
import { G6Config } from '../G6Config';
import { G6Math } from '../utils/Math';
import { G6Router } from '../wrappers/G6Router';
import { useSendTransaction } from '@/hooks/useSendTransaction';
import { useWalletInfoStore } from '@/stores/useWalletInfoStore';

export const useG6RouterMethods = () => {
  const { walletAddress: address, isTomoWallet } = useWalletInfoStore();

  const { sendTransaction } = useSendTransaction({ openSuccessModal: false });

  // const { provider } = useWallet({ address: process.env.REACT_APP_G6_ROUTER_ADDRESS });

  const { balance } = useGlobalDataStore();

  const handleLaunch = async (payload: {
    name: string;
    symbol: string;
    description: string;
    image: string;
    website?: string;
    twitter?: string;
    telegram?: string;
    tonAmountToPayForInitBuy?: bigint;
  }) => {
    if (!address) {
      return Promise.reject(new Error('not connected'));
    }

    try {
      const { name, symbol, description, image, website, twitter, telegram } = payload;

      const feeResult = await G6Router.getLaunchTxFee(
        await getProvider(process.env.REACT_APP_G6_ROUTER_ADDRESS!),
        {
          tonAmountToPayForInitBuy: payload.tonAmountToPayForInitBuy || 0n,
        },
      );

      // const feeResult = await BlockchainClient.runMethod(G6Config.G6RouterAddress, 'get_launch_tx_fee');

      const fee = feeResult.fee;

      const { data } = await fetchLaunchConf();

      const queryId = getRandomBigInt();

      console.log('data--------------->', {
        name,
        symbol,
        description,
        image,
        website,
        twitter,
        telegram,
        decimals: data.decimals.toString(),

        init_launch_jetton_amount: BigInt(data.init_launch_jetton_amount),
        jetton_amount_to_reserve: BigInt(data.jetton_amount_to_reserve),

        query_id: queryId,
        ton_to_add_liquidity: BigInt(data.ton_to_add_liquidity),
        k: BigInt(data.k),
        x0: BigInt(data.x0),
        y0: BigInt(data.y0),
      });

      const { body: launchBody } = getLaunchParam({
        name,
        symbol,
        description,
        image,
        website,
        twitter,
        telegram,
        decimals: data.decimals.toString(),

        init_launch_jetton_amount: BigInt(data.init_launch_jetton_amount),
        jetton_amount_to_reserve: BigInt(data.jetton_amount_to_reserve),
        ton_to_pay_for_init_buy: payload.tonAmountToPayForInitBuy,
        query_id: queryId,
        ton_to_add_liquidity: BigInt(data.ton_to_add_liquidity),
        k: BigInt(data.k),
        x0: BigInt(data.x0),
        y0: BigInt(data.y0),
      });

      if (new G6Math(fee).isGreaterThan(balance || 0n)) {
        return Promise.reject(new Error('Insufficient balance'));
      }

      const transaction: SendTransactionRequest = {
        messages: [
          {
            address: G6Config.G6RouterAddress.toString(), // destination address
            amount: fee.toString(), //Toncoin in nanotons
            payload: isTomoWallet
              ? launchBody.toBoc().toString('hex')
              : launchBody.toBoc().toString('base64'),
          },
        ],
        validUntil: Math.floor(Date.now() / 1000) + 360,
        from: address,
      };

      const launchResult = await sendTransaction({ sendTransactionRequest: transaction });
      console.log('launchResult:', launchResult);
      return Promise.resolve(queryId);
    } catch (error) {
      console.error('error:', error);

      return Promise.reject(error);
    }
  };

  return {
    handleLaunch,
  };
};
