import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

export function warpUserAddress(address: string) {
  return `/user/${address}`;
}

export function useAppRouter() {
  const navigate = useNavigate();

  const goHome = () => {
    navigate('/');
  };

  const goCreate = () => {
    navigate('/create');
  };

  const goSearch = () => {
    navigate('/search');
  };

  const goCoinDetail = ({ poolAddress }: { poolAddress: string }) => {
    navigate(`/coin/${poolAddress}`);
  };

  const goTomoCoinDetail = (payload: { address: string; chain: string; token: string }) => {
    navigate(
      `/tomoCoin?address=${payload.address}&chain=${payload.chain}&token=${encodeURIComponent(payload.token)}`,
    );
  };

  const goProfile = useCallback(
    (address: string) => {
      navigate(`/profile/${address}`);
    },
    [navigate],
  );

  const goProfileWithUserId = useCallback(
    (userId: string) => {
      navigate(`/profileWithId/${userId}`);
    },
    [navigate],
  );

  const goCreatorDetail = useCallback(
    (address: string) => {
      navigate(`/user/${address}`);
    },
    [navigate],
  );

  const goCreatorDetailWithUserId = useCallback(
    (userId: string) => {
      navigate(`/userWithId/${userId}`);
    },
    [navigate],
  );

  const goUserDetail = (address: string) => {
    navigate(warpUserAddress(address));
  };

  const goCoinOperate = (address: string) => {
    navigate(`/detail/${address}`);
  };

  const goSwap = () => {
    navigate(`/swap`);
  };

  const push = (path: string) => {
    navigate(path, { replace: false });
  };

  const goBack = () => {
    navigate(-1);
  };

  const goVerifyEmail = () => {
    navigate(`/verifyEmail`);
  };

  const goVerifyEmailInput = () => {
    navigate(`/verifyEmailInput`);
  };

  const goVerifyEmailPassword = () => {
    navigate(`/verifyEmailPassword`);
  };

  return {
    goCreate,
    goCoinDetail,
    goTomoCoinDetail,
    goHome,
    goProfile,
    goProfileWithUserId,
    goCreatorDetailWithUserId,
    goCreatorDetail,
    goCoinOperate,
    goSwap,
    push,
    goSearch,
    goBack,
    goUserDetail,
    goVerifyEmail,
    goVerifyEmailInput,
    goVerifyEmailPassword,
  };
}
