import { ConfigProvider, Tabs, TabsProps } from 'antd';
import { useState } from 'react';
import { FilterPopup } from '../../components/FilterPopup';
import { TokenList } from './All';
import { G6TokenList } from './G6List';
import { G6Item } from './All/components/G6Item';
import { useWalletInfoStore } from '@/stores/useWalletInfoStore';

const items: TabsProps['items'] = [
  {
    key: 'all',
    label: 'ALL',
  },
  {
    key: 'g6',
    label: '👑 G6 LIST',
  },
];

export function Departure() {
  const [active, setActive] = useState('all');

  const { isTon } = useWalletInfoStore();

  const onChange = (key: string) => {
    console.log(key);
    setActive(key);
  };

  return (
    <div>
      {isTon && active === 'all' && <G6Item></G6Item>}

      <ConfigProvider
        theme={{
          components: {
            Tabs: {
              colorPrimary: '#fff',
              itemColor: '#656565',
            },
          },
          token: {},
        }}
      >
        <Tabs
          tabBarExtraContent={{
            right: <FilterPopup></FilterPopup>,
          }}
          activeKey={active}
          items={items}
          onChange={onChange}
          className="[&_.g6-tabs-nav-wrap]:px-[16px] sticky top-[60px] bg-primaryBackground z-20 [&_.g6-tabs-nav]:!mb-0"
        />
      </ConfigProvider>

      <div className="px-[12px] [&_.g6-list-item]:!border-0 [&_.g6-list-item]:!py-0 pt-[8px]">
        {active === 'all' ? <TokenList /> : <G6TokenList />}
      </div>
    </div>
  );
}
