import { SwapableTokenAmountRes } from '@/hooks/ston.fi/useSwapableTokenAmount';
import { TonValue } from '@/components/TonValue';
import { G6Math } from '@/contract/utils/Math';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Collapse, CollapseProps } from 'antd';
import classNames from 'classnames';
import { inRange } from 'lodash';
import { useContext, useMemo } from 'react';
import { CoinDetailContext } from '../../context/CoinDetailContext';
import { useNativeToken } from '@/hooks/app/useNativeToken';

function Field({ label, value, className }: { label: string; value?: any; className?: string }) {
  return (
    <div className={classNames('flex justify-between items-center')}>
      <div className={classNames('text-[#ffffff50]', className)}>{label}</div>
      <div className={classNames(className)}>{value}</div>
    </div>
  );
}

export function TransitionDetail({
  data,
  offerAddressIsTon,
}: {
  data?: SwapableTokenAmountRes;
  offerAddressIsTon: boolean;
}) {
  // const [offerAddressIsTon, setOfferAddressIsTon] = useState(true);

  const { detail } = useContext(CoinDetailContext);

  const [nativeName] = useNativeToken();

  const priceImpact = useMemo(() => {
    try {
      return +data!.priceImpact || 0;
    } catch (err) {
      return 0;
    }
  }, [data]);

  const items: CollapseProps['items'] = useMemo(() => {
    return [
      {
        key: '1',
        label: (
          <div className="max-w-[270px]">
            <div className="flex justify-start items-start">
              {!inRange(priceImpact, 0, 0.031) && (
                <div>
                  <ExclamationCircleFilled
                    className={classNames('mr-[3px] text-orange-500', {
                      '!text-red-600': priceImpact >= 0.1,
                    })}
                  />
                </div>
              )}

              {offerAddressIsTon ? (
                <div>
                  <div className="text-[16px]">1 {nativeName} </div>
                  <div className="text-[#ffffff50]">
                    {`≈ ${data?.swapRate ? data.swapRate : '0'} ${detail?.symbol}`}
                  </div>
                </div>
              ) : (
                <div>
                  <div className="text-[16px]">1 {detail?.symbol} </div>
                  <div className="text-[#ffffff50]">
                    {' '}
                    {`≈ ${data?.swapRate ? data.swapRate : '0'} ton`}
                  </div>
                </div>
              )}
            </div>
          </div>
        ),
        children: (
          <div className="flex justify-start items-stretch gap-y-[5px] flex-col w-full">
            <Field
              label="Max. slippage"
              value={`${new G6Math(data?.slippageTolerance || '0').multiply(100).toFixed(2)} %`}
            />
            <Field
              label="Minimum received"
              value={<TonValue value={data?.minAskUnits}></TonValue>}
            />
            <Field
              className={classNames({
                'text-orange-500': inRange(priceImpact, 0.031, 0.1),
                '!text-red-600': priceImpact >= 0.1,
              })}
              label="Price impact"
              value={`${new G6Math(data?.priceImpact || '0').multiply(100).toFixed(2)} %`}
            />
          </div>
        ),
      },
    ];
  }, [
    data?.minAskUnits,
    data?.priceImpact,
    data?.slippageTolerance,
    data?.swapRate,
    detail?.symbol,
    nativeName,
    offerAddressIsTon,
    priceImpact,
  ]);

  // [&_.g6-collapse-header]:!px-0

  return (
    <Collapse
      className="[&_.g6-collapse-header]:!pb-0 "
      expandIconPosition="end"
      ghost
      items={items}
    />
  );
}
