import classNames from 'classnames';
import { Table, Input } from 'antd';
import { MultiChainToken } from '@/views/Home/components/MultiChainToken';
import { ReactComponent as IconEmpty } from '@/assets/icon/icon_empty.svg';
import { useAppRouter } from '@/hooks/router/useAppRouter';
import { ColumnType, TableProps } from 'antd/es/table';
import { useAppMessage } from '@/hooks/useAppMessage';
import { G6Math } from '@/contract/utils/Math';
import { priceFormat } from '@/utils/priceFormat';
import { useParseRouterQuery } from '@/hooks/router/useParseRouterQuery';
import { useRef, useState } from 'react';
import { Coin, GraduatedCoin } from '@/types/Coin';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useFetchG6List } from '../Fly/hooks/useFetchG6List';

type OnChange = NonNullable<TableProps<GraduatedCoin>['onChange']>;

type GetSingle<T> = T extends (infer U)[] ? U : never;

type Sorts = GetSingle<Parameters<OnChange>[2]>;

const CustomHeader = ({ name }: { name: string }) => (
  <div className="text-[12px] text-[#656565]">{name}</div>
);

export const formatNumber = (number?: number | string) => {
  if (!number) return '-';

  if (G6Math.from(number).isGreaterThan('0.0001')) {
    return parseFloat(G6Math.from(number).toFixed(4));
  }

  const str = G6Math.from(number).toString();

  const [integerPart, fractionalPart] = str.split('.');

  if (!fractionalPart || !fractionalPart.includes('0') || fractionalPart.length <= 4) {
    return str;
  }

  // 小数点后紧跟着的0的个数
  const zeroCount = fractionalPart.split('').findIndex((item) => item !== '0') - 1;

  return (
    <span>
      {integerPart}.00
      <small
        className="relative bottom-[-1px]"
        style={{ fontSize: '50%', verticalAlign: 'text-bottom' }}
      >
        {zeroCount}
      </small>
      {parseInt(fractionalPart).toString().slice(0, 2)}
      {/* {fractionalPart.slice(zeroCount)} */}
    </span>
  );
};

const columns: ColumnType<GraduatedCoin>[] = [
  {
    title: <CustomHeader name="Name/24h Vol" />,
    dataIndex: 'volume_24h',
    key: 'volume_24h',
    showSorterTooltip: false,
    sorter: true,
    render: (_, record) => (
      <div className="flex">
        <MultiChainToken symbol={record.symbol} token={record.imageUri} chain="TON" />

        <div className="font-[Poppins] ml-[14px] relative truncate">
          {/* <img src={logoIcon} className="w-[14px] h-[14px] absolute top-[4px] right-[-15px]" /> */}
          <div className="text-[16px] font-[500] leading-[20.8px] text-[#FFFFFF] truncate">
            {record.symbol}
          </div>
          <div className="text-[12px] leading-[15.6px] font-[400] text-[#656565]">
            {record.volume24h ? priceFormat(record.volume24h) : '$0.00'}
          </div>
        </div>
      </div>
    ),
  },
  {
    title: <CustomHeader name="Price" />,
    dataIndex: 'price',
    key: 'price',
    showSorterTooltip: false,
    width: 50,
    sorter: true,
    render: () => <></>,
  },
  {
    title: <CustomHeader name="Price Change" />,
    dataIndex: 'price_change_24h',
    key: 'priceChangeH24',
    fixed: 'right',
    showSorterTooltip: false,
    sorter: true,
    render: (_, record) => {
      const val = +G6Math.from(record.priceChange24h).toFixed(2);
      const isNegative = val < 0;

      return (
        <div className="flex justify-end items-end flex-col">
          <div className="font-[Poppins] text-[16px] leading-[20.8px] text-[#FFFFFF]">
            ${formatNumber(record.price)}
          </div>
          <div
            className={classNames('text-primaryText', {
              '!text-[#15BC76]': !isNegative,
              '!text-[#F54976]': isNegative,
            })}
          >
            <ArrowRightOutlined
              className={classNames(!isNegative ? '-rotate-45' : 'rotate-45', 'mr-[2px]')}
            />
            {!isNegative ? '+' : '-'}
            {isNaN(val) ? '-' : `${val}%`}
          </div>
        </div>
      );
    },
  },
];

export function TokenList() {
  const { goCoinDetail } = useAppRouter();

  const message = useAppMessage();

  const ref = useRef<HTMLDivElement | null>(null);

  const { query, updateQuery } = useParseRouterQuery({
    current: '',
    page: '1',
    limit: '12',
    keyword: '',
    sort: 'marketCapUsd',
    sortOrder: '',
  });

  const { data, loading } = useFetchG6List({
    search: query.keyword,
    sort: query.sort,
    size: +query.limit,
    order: query.sortOrder,
  });

  const rowClick = (record: GraduatedCoin) => {
    goCoinDetail({
      poolAddress: record.poolAddress,
    });
  };

  const [search, setSearch] = useState('');

  const handleChange: OnChange = (pagination, filters, sorter: any) => {
    const map: any = {
      descend: 'desc',
      ascend: 'asc',
    };

    updateQuery({
      sort: sorter.field,
      sortOrder: map[sorter.order] || '',
      page: pagination.current?.toString(),
      limit: pagination.pageSize?.toString(),
    });
  };

  return (
    <div className="w-full h-full overflow-y-auto" ref={ref}>
      <div className="px-2 mt-2">
        <Input
          placeholder="Token name or address"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              updateQuery({
                keyword: search,
                page: '1',
                limit: '12',
                sort: 'marketCapUsd',
                sortOrder: '',
              });
            }
          }}
        ></Input>
      </div>
      <Table
        rowKey={(record) => record.poolAddress}
        onHeaderRow={() => {
          return {
            className: 'sticky top-0 !font-normal',
          };
        }}
        className="reset-table"
        dataSource={data?.data.docs}
        columns={columns}
        loading={loading}
        onChange={handleChange}
        // pagination={{
        //   current: data?.data,
        //   total: data?.totalDocs,
        //   position: ['bottomCenter'],
        //   // onChange(page, pageSize) {
        //   //   updateQuery({
        //   //     ...query,
        //   //     page: page.toString(),
        //   //     limit: pageSize.toString(),
        //   //   });
        //   // },
        // }}
        onRow={(record) => {
          return {
            onClick: () => rowClick(record),
          };
        }}
      ></Table>
    </div>
  );
}

export function FlyV2() {
  return (
    <div className="w-full h-full">
      <TokenList />
    </div>
  );
}
