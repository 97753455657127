import { http, createConfig } from 'wagmi';
import {
  arbitrum,
  avalanche,
  b3,
  base,
  blast,
  bsc,
  linea,
  mainnet,
  optimism,
  polygon,
  scroll,
} from 'wagmi/chains';

export const evmChainList = [
  arbitrum,
  avalanche,
  b3,
  base,
  blast,
  bsc,
  linea,
  mainnet,
  optimism,
  polygon,
  scroll,
] as const;

export const config = createConfig({
  chains: evmChainList,
  transports: {
    [arbitrum.id]: http(),
    [avalanche.id]: http(),
    [b3.id]: http(),
    [base.id]: http(),
    [blast.id]: http(),
    [bsc.id]: http(),
    [linea.id]: http(),
    [mainnet.id]: http(),
    [optimism.id]: http(),
    [polygon.id]: http(),
    [scroll.id]: http(),
  },
});
