import { UserInfo } from '@/types/Tomo';

const userInfo = {
  authType: 'V2ADDRESS',
  avatar: '',
  bitcoinP2pkhAddress: '1KVTeYHdNYsj6uFAUGdFXcYqu5r12KZLef',
  bitcoinP2shAddress: '3CU3bv4Nu2JafFsroDD1zjXNws75DgdhRV',
  bitcoinP2trAddress: 'bc1p9sdlpeamsjfljpszlljjcnxeftn6etg95vss2twk0p8h42njhjfq4p6nxu',
  bitcoinP2wpkhAddress: 'bc1qv0lz3yyquh5tt6ryrz4vqmpk783ngj7sh72rkk',
  createdTime: '2024-08-31T08:09:04.000+00:00',
  depositAddress: '0x4db85caf0fd8a77c2936e7bce7a1a994628d09ae',
  email: '0x34603355c6fc663006cf9f38708420e5b079b27e@dmail.ai',
  enablePassTrade: false,
  fromHoobe: false,
  id: 790517,
  imName: '790517',
  inviteCode: 'VA3CM6F933',
  newUser: false,
  nickname: 'jpxd99',
  projectToken:
    'eyJraWQiOiIyMzMxMjQ2MTcyNiIsInR5cCI6IkpXVCIsImFsZyI6IlJTMjU2In0.eyJhcHAiOiJ0b21vIiwidXNlcl9pZCI6NzkwNTE3LCJleHAiOjE3Mjc3MDk0MDQsImlhdCI6MTcyNTExNzQwNCwiY2xpZW50X2lkIjoiSVV2d0VQZXVtV0d3MFRPT1cxWDlvOGhIaDRETmVNNWpvY1BPNkJVQTdJVU1FVWhkekozUG9MYVBwbFJ0WE92MVl5cEVLa2FIWWZxT3IzRHlxcWhkaVRPZSIsInVzZXJuYW1lIjoidGVsZWdyYW1AanB4ZDk5In0.MUBZlp7XCK3BL-Rcam3tQsnYU83dEy60W2KZSJyD6qBOwlsnZpbPh9NRQ1CSjEsMCkzQTp1tY5dJfi2rjYOxaTg_35WZAlMm0n_cHpjbIkeASEKfR-jItjl3vjyT7yg2qRoLqVgdNId1exAHE7H8UEQZPE8qT-CQNWos-tyOtJ8',
  set_trade_password: true,
  socialType: 'telegram',
  solanaAddress: 'CwVgvxFNJ96uWZqkfMjVrjtcsQw7z9AdzPQKeRtPJWwK',
  tgId: '1728771969',
  tgName: 'jpxd99',
  token:
    'eyJraWQiOiIyMzMxMjQ2MTcyNiIsInR5cCI6IkpXVCIsImFsZyI6IlJTMjU2In0.eyJhcHAiOiJ0b21vIiwidXNlcl9pZCI6NzkwNTE3LCJleHAiOjE3Mjc3MDk0MDQsImlhdCI6MTcyNTExNzQwNCwiY2xpZW50X2lkIjoiSVV2d0VQZXVtV0d3MFRPT1cxWDlvOGhIaDRETmVNNWpvY1BPNkJVQTdJVU1FVWhkekozUG9MYVBwbFJ0WE92MVl5cEVLa2FIWWZxT3IzRHlxcWhkaVRPZSIsInVzZXJuYW1lIjoidGVsZWdyYW1AanB4ZDk5In0.MUBZlp7XCK3BL-Rcam3tQsnYU83dEy60W2KZSJyD6qBOwlsnZpbPh9NRQ1CSjEsMCkzQTp1tY5dJfi2rjYOxaTg_35WZAlMm0n_cHpjbIkeASEKfR-jItjl3vjyT7yg2qRoLqVgdNId1exAHE7H8UEQZPE8qT-CQNWos-tyOtJ8',
  tonAddress: 'UQDGENxI_uyXvMzfUoHl03U5IRAUXfir9XUt_wMP0X3TRqkZ',
  tonPublicKey: '257ecafd698ab0b64309e1f5ae0dfb05603f3bb57b6b129b1f6b1e51a527befd',
  tronAddress: 'TA6hgTvw687ESvs8YJgszdobLm349wpUXn',
  updatedTime: '2024-08-31T08:24:25.000+00:00',
  username: 'telegram@jpxd99',
  v2Address: '0x34fd41a49156eb82e74fb2b2f92a4c5084798f42',
  walletAddress: '0x34fd41a49156eb82e74fb2b2f92a4c5084798f42',
};

const token = 'Bearer ' + userInfo.token;

export const getTomoMockData = () => {
  const data = {
    key: '9eef2af56006385f28723f7c8241ffe35535a45172f7ddcf80e63a5a790ac485',
    uid: '1724167815691164884',
    userInfo: undefined as UserInfo | undefined,
    token: undefined,
  };

  // if (+process.env.REACT_APP_MOCK!) {
  //   Object.assign(data, { userInfo, token });
  // }

  return data;
};
