import { useTonAddress, useTonConnectUI } from '@tonconnect/ui-react';
import { getBlockchainClient } from '../utils/client';
import { Address } from '@ton/core';
import { useWallet } from '@/hooks/useWallet';
import { useWalletInfoStore } from '@/stores/useWalletInfoStore';

export const useAccountMethods = () => {
  const [tonConnectUI] = useTonConnectUI();

  // const address = useTonAddress();

  const { walletAddress } = useWalletInfoStore();

  const checkTonConnect = () => {
    if (!tonConnectUI.connected) {
      return false;
    }
    return true;
  };

  const getBalance = async () => {
    if (!walletAddress) return 0;

    const balance = (await getBlockchainClient()).getBalance(Address.parse(walletAddress!));

    return balance;
  };

  const getBalanceByAddress = async (address: string) => {
    const balance = (await getBlockchainClient()).getBalance(Address.parse(address));

    return balance;
  };

  return {
    getBalance,
    getBalanceByAddress,
    ready: !!walletAddress,
  };
};
