import { useAccountMethods } from '@/contract/hooks/useAccountMethods';
import { ChainType, useWalletInfoStore } from '@/stores/useWalletInfoStore';
import { useRequest } from 'ahooks';
import { toNano } from '@ton/core';
import { useMemo } from 'react';
import { TomoWalletTgSdkV2 } from '@tomo-inc/tomo-telegram-sdk';

new TomoWalletTgSdkV2({ injected: true });
const tomo_sol = window?.tomo_sol;
const tomo_ton = window?.tomo_ton;

export function useGetNativeBalance() {
  const { getBalance, ready: accountReady } = useAccountMethods();
  const { isTomoWallet, chainType, walletAddress } = useWalletInfoStore();

  const tonRequest = useRequest(
    async () => {
      return getBalance().then((balance) => {
        return balance ? balance : toNano(0);
      });
    },
    {
      ready: accountReady && !isTomoWallet && chainType === ChainType.TON,
      pollingInterval: 30 * 1000,
    },
  );

  const tomoTonRequest = useRequest(
    async () => {
      const { balance } = await tomo_ton.getBalance(walletAddress);
      return balance;
    },
    {
      ready: !!walletAddress && isTomoWallet && chainType === ChainType.TON,
      pollingInterval: 30 * 1000,
    },
  );

  const tomoSolRequest = useRequest(
    async () => {
      const { balance } = await tomo_sol.getBalance(walletAddress);
      return balance;
    },
    {
      ready: !!walletAddress && isTomoWallet && chainType === ChainType.SOLANA,
      pollingInterval: 30 * 1000,
    },
  );

  const result = useMemo(() => {
    if (chainType === ChainType.SOLANA) {
      return tomoSolRequest;
    } else if (chainType === ChainType.TON) {
      if (isTomoWallet) {
        return tomoTonRequest;
      }
      return tonRequest;
    }
  }, [chainType, isTomoWallet, tomoSolRequest, tomoTonRequest, tonRequest]);

  const decimals = useMemo(() => {
    switch (chainType) {
      case ChainType.SOLANA:
        return 9;
      case ChainType.TON:
        return 9;
      default:
        return 9;
    }
  }, [chainType]);

  return {
    balance: result?.data || 0n,
    decimals,
    balanceLoading: result?.loading || false,
    refreshBalanceAsync: result?.refreshAsync,
  };
}
