import { G6Math } from '@/contract/utils/Math';
import numeral from 'numeral';

export function priceFormat(number?: string | number) {
  try {
    return number ? numeral(G6Math.from(number).toFixed(6)).format('$0.00a').toUpperCase() : '-';
  } catch (err) {
    return '-';
  }
}
