import GlobalDataProvider from '@/context/GlobalDataContext';
import { useWebSocketContext, WebSocketContext } from '@/context/WebSocketContext';
import { Temporary } from '@/preferences/Temporary';
import { getQueryParam } from '@/utils';
import { useUserStore } from '@/stores/useUserStore';
import { useEffect } from 'react';
import { TransactionContextProvider } from '@/context/TransactionContext';
import SolWebSocketProvider from '@/context/SolWebSocketContext';
import { useFetchUserWallets } from '@/views/Profile/hooks/useFetchUserWallets';
import { useAuthenticateUser } from '@/hooks/app/user/useAuthenticateUser';
import { jwtDecode } from 'jwt-decode';
import { useMount, useSessionStorageState } from 'ahooks';
import { JwtInfo } from '@/types/User';
import dayjs from 'dayjs';
import OpenInTelegram from './OpenInTelegram/OpenInTelegram';
import { storageKeys } from '@/constants/storage';
import ConnectModal from '../ConnectButton/ConnectModal/ConnectModal';
import { useWalletInfoStore } from '@/stores/useWalletInfoStore';

export function tgInit(tg: WebApp) {
  if (!tg) return;

  tg.expand?.();

  tg.setHeaderColor?.('#121212');

  tg.setBackgroundColor?.('#121212');

  // tg.isClosingConfirmationEnabled = true;
  tg.enableClosingConfirmation();

  tg.disableVerticalSwipes();

  tg.onEvent('viewportChanged', () => {
    setTimeout(() => {
      tg.expand?.();
    }, 300);
  });

  window.addEventListener('touchend', () => {
    setTimeout(() => {
      tg.expand?.();
    }, 1000);
  });
}

export function GlobalWarp({ children }: { children: any }) {
  const { token, jwtInfo, setJwtInfo } = useUserStore();
  const [, setStartParam] = useSessionStorageState(storageKeys.startParam);

  const { latestCoin, onMessage } = useWebSocketContext();

  const { authenticate } = useAuthenticateUser();

  const { isConnect } = useWalletInfoStore();

  useFetchUserWallets();

  useEffect(() => {
    console.log('jwtInfo', jwtInfo, 'initData', window.Telegram.WebApp);
  }, [jwtInfo]);

  useMount(() => {
    if (token) {
      // 如果当前已有token的话就解析
      const decoded = jwtDecode<JwtInfo>(token);
      // 判断token是否过期 token失效是30天 提前一天判断
      if (dayjs().isAfter(dayjs(decoded.exp).subtract(1, 'day'))) {
        authenticate();
      } else {
        setJwtInfo(decoded);
      }
    } else {
      // 获取token
      authenticate();
    }
  });

  useMount(() => {
    if (window.Telegram.WebApp.initDataUnsafe.start_param) {
      setStartParam(window.Telegram.WebApp.initDataUnsafe.start_param);
    }
  });

  // const vCnsoleStatus = useExternal(
  //   window.location.href.includes('enableDebug=1')
  //     ? 'https://unpkg.com/vconsole@latest/dist/vconsole.min.js'
  //     : '',
  //   {
  //     js: {
  //       async: true,
  //     },
  //   },
  // );

  // useEffect(() => {
  //   if (vCnsoleStatus === 'ready') {
  //     new window.VConsole();
  //   }
  // }, [vCnsoleStatus]);

  useEffect(() => {
    const chatId = getQueryParam('tgChatId');

    if (chatId) {
      Temporary.setChatId(chatId);
    }
  }, []);

  return (
    <>
      <GlobalDataProvider>
        <WebSocketContext.Provider
          value={{
            latestCoin,
            onMessage,
          }}
        >
          <SolWebSocketProvider>
            <TransactionContextProvider>
              {/* <ProfileModal open={profileModalOpen}></ProfileModal> */}
              <ConnectModal open={!isConnect} canClose={false} />
              {process.env.NODE_ENV !== 'development' && (
                <OpenInTelegram open={!window.Telegram.WebApp.initData} />
              )}
              {children}
            </TransactionContextProvider>
          </SolWebSocketProvider>
        </WebSocketContext.Provider>
      </GlobalDataProvider>
    </>
  );
}
