import { ChainType, useWalletInfoStore, WalletType } from '@/stores/useWalletInfoStore';
import { Button, Dropdown, MenuProps } from 'antd';
import React, { useMemo } from 'react';
import TomoIconUrl from '@/assets/icon/app/tomo.png';
import TonIcon from '@/assets/icon/app/ton.svg';
import logoutIcon from '@/assets/icon/header/logout.svg';
import { shortenAddress } from '@/utils/ton';
import arrowIcon from '@/assets/icon/arrow-icon.svg';
import { useTonConnectModal } from '@tonconnect/ui-react';
import { useUserStore } from '@/stores/useUserStore';

enum MenuKey {
  Disconnect,
  TOMO,
  TON,
}

type ListType = {
  label: string;
  icon: string;
  key: MenuKey;
};

const WalletButtonDropdown = ({ afterDisconnect }: { afterDisconnect?: () => void } = {}) => {
  const { chainType, walletType, walletAddress, disconnect, connectTomoTonWallet } =
    useWalletInfoStore();
  const { open } = useTonConnectModal();

  const { loginOut } = useUserStore();

  function handleDisconnect() {
    loginOut();

    disconnect();

    afterDisconnect?.();
  }

  const walletIcon = useMemo(() => {
    switch (walletType) {
      case WalletType.TOMO:
        return TomoIconUrl;
      case WalletType.TON:
        return TonIcon;
      default:
        return '';
    }
  }, [walletType]);

  const items: MenuProps['items'] = useMemo(() => {
    let walletList: ListType[] = [];

    if (chainType === ChainType.TON) {
      if (walletType === WalletType.TOMO) {
        walletList = [{ key: MenuKey.TON, icon: TonIcon, label: 'TON' }];
      } else {
        walletList = [{ key: MenuKey.TOMO, icon: TomoIconUrl, label: 'Tomo' }];
      }
    }

    const disconnectItem = {
      key: MenuKey.Disconnect,
      label: 'Disconnect wallet',
      icon: <img src={logoutIcon} />,
    };

    return walletList
      .map((item) => {
        return { ...item, icon: <img src={item.icon} className="h-4 w-4" /> };
      })
      .concat(disconnectItem);
  }, [chainType, walletType]);

  const onClick: MenuProps['onClick'] = ({ key }) => {
    const menuKey = Number(key) as MenuKey;
    if (menuKey === MenuKey.Disconnect) {
      handleDisconnect();
    } else if (menuKey === MenuKey.TOMO) {
      handleDisconnect();
      connectTomoTonWallet();
    } else if (menuKey === MenuKey.TON) {
      handleDisconnect();
      open();
    }
  };

  return (
    <Dropdown menu={{ items, onClick }}>
      <Button
        key={walletAddress}
        type="default"
        className="p-[10px] pr-[12px] h-9 border-[#242424]"
      >
        <img src={walletIcon} key={walletAddress} className="w-6 rounded-full" />
        <span className="ml-[-2px] md:text-[12px]">{shortenAddress(walletAddress)}</span>
        <img src={arrowIcon} />
      </Button>
    </Dropdown>
  );
};

export default WalletButtonDropdown;
