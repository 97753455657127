import iconLogoColor from '@/assets/icon/icon_logo_color.svg';
import inviteTaskIcon from '@/assets/icon/task/invite-task.png';
import { Button } from 'antd';
import { useTonConnectModal } from '@tonconnect/ui-react';
import { useFetchTasksStats } from '@/hooks/app/tasks/useFetchTasksStats';
import { TaskCode } from '@/types/Tasks';
import { keyBy } from 'lodash';
import { shareLink, startAppParam } from '@/constants/startAppParam';
import { useUserStore } from '@/stores/useUserStore';
import base64url from 'base64url';
import { Card } from './components/Card';
import GeneralTasks from './components/GeneralTasks';
import { shareTgUrl } from '@/utils/telegram';

const LongTermTasks = ({ points, referFriend }: { points?: number; referFriend: () => void }) => (
  <Card
    title="Invite Friends"
    icon={inviteTaskIcon}
    pts={`Each person/+${points ?? 10} Pts`}
    onClick={referFriend}
  />
);

export default function Task() {
  const { jwtInfo } = useUserStore();

  const { open } = useTonConnectModal();
  const { data, refresh } = useFetchTasksStats();
  const taskKeyByTaskCode = keyBy(data?.tasks, 'taskCode');

  const referFriend = () => {
    if (!jwtInfo) {
      open();
    } else {
      const param = base64url.encode(`?${startAppParam.InviteCode}=${jwtInfo.id}`);
      shareTgUrl(`${shareLink}?startapp=${param}`);
    }
  };

  return (
    <div className="font-[Poppins]">
      <div className="flex justify-between items-center py-[22px] px-[25px]">
        <div>
          <div>
            <div className="text-[32px] leading-[48px] font-[700] text-[#FFF8E7]">
              {data?.totalPoints.toLocaleString() ?? 0}
            </div>
            <div className="text-[14px] leading-[21px]  font-[400] text-[#BDBDBD]">
              Points reward
            </div>
          </div>
        </div>
        <div>
          <img src={iconLogoColor} className="w-[71px] h-[80px]" />
        </div>
      </div>
      <div className="pt-[16px] px-[14px] pb-[22px] h-[138px]  border-solid border-[#242424] border-[1px] mx-[16px] rounded-[8px] flex flex-col justify-between">
        <div className="flex justify-between items-center leading-[30px]">
          <div className="text-[#BDBDBD] text-[14px] font-[400]">Friends Invited</div>
          <div className="text-[#fff] text-[20px] font-[700]">
            {taskKeyByTaskCode?.[TaskCode.InviteNewUser]?.taskTotalPoints ?? 0}
          </div>
        </div>
        <Button
          // disabled
          type="primary"
          className="w-[230px] h-[48px] text-[#fff] text-[16px] font-[500]  m-auto my-[0]"
          onClick={referFriend}
        >
          {/* Coming soon */}
          Refer a friend
        </Button>
      </div>
      <div className="px-[16px] pb-[25px]">
        <div className="text-[18px] leading-[23.4px] font-[600] mt-[24px] mb-[16px] text-primaryText">
          Long-term tasks
        </div>
        <LongTermTasks
          points={taskKeyByTaskCode[TaskCode.InviteNewUser]?.points}
          referFriend={referFriend}
        />
        <div className="text-[18px] leading-[23.4px] font-[600] mt-[24px] mb-[16px] text-primaryText">
          General tasks
        </div>
        <GeneralTasks tasks={taskKeyByTaskCode} refresh={refresh} />
      </div>
    </div>
  );
}
