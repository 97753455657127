import React from 'react';
import { Card } from './Card';
import { useAppRouter } from '@/hooks/router/useAppRouter';
import { useWalletInfoStore } from '@/stores/useWalletInfoStore';
import { TaskInfo, TaskCode } from '@/types/Tasks';
import { useTonConnectModal } from '@tonconnect/ui-react';
import { Dictionary } from 'lodash';
import followTwitterIcon from '@/assets/icon/task/follow-twitter.svg';
import followTelegramIcon from '@/assets/icon/task/follow-telegram.svg';
import buyIcon from '@/assets/icon/task/buy-coin.svg';
import sellIcon from '@/assets/icon/task/sell-coin.svg';
import joinIcon from '@/assets/icon/task/join-group.svg';
import tradingIcon from '@/assets/icon/task/trading.svg';
import { useUpdateTaskStatus } from '@/hooks/app/tasks/useUpdateTaskStatus';
import { useAppMessage } from '@/hooks/useAppMessage';

type TaskList = {
  title: string;
  icon: string;
  key: string;
  pts?: number;
  onClick: (v?: TaskInfo) => void;
}[];

const GeneralTasks = ({
  tasks,
  refresh,
}: {
  tasks?: Dictionary<TaskInfo>;
  refresh: () => void;
}) => {
  const { walletAddress } = useWalletInfoStore();
  const { goHome, goCreate } = useAppRouter();
  const { runAsync: updateTaskStatus } = useUpdateTaskStatus();
  const message = useAppMessage();

  const { open } = useTonConnectModal();

  const handleClick = (callback: () => void) => {
    if (!walletAddress) {
      open();
    } else {
      callback();
    }
  };

  const toFollowTwitter = async (v?: TaskInfo) => {
    try {
      if (!v?.taskCode) return;
      window.Telegram.WebApp.openLink('https://x.com/G6onTON');
      await updateTaskStatus(v?.taskCode);
      refresh();
    } catch (err) {
      message.error('Some errors have occurred, please try again');
    }
  };

  const toFollowTelegram = async (v?: TaskInfo) => {
    try {
      if (!v?.taskCode) return;
      window.Telegram.WebApp.openTelegramLink('https://t.me/G6Club_TON');
      await updateTaskStatus(v?.taskCode);
      refresh();
    } catch (err) {
      message.error('Some errors have occurred, please try again');
    }
  };

  const toTrade = () => {
    handleClick(() => goHome());
  };

  const toCreateToken = () => {
    handleClick(() => goCreate());
  };

  const list: TaskList = [
    {
      title: 'Follow Twitter',
      icon: followTwitterIcon,
      key: TaskCode.FollowTwitter,
      pts: tasks?.[TaskCode.FollowTwitter]?.points,
      onClick: (v) => toFollowTwitter(v),
    },
    {
      title: 'Follow Telegram',
      icon: followTelegramIcon,
      key: TaskCode.FollowTelegram,
      pts: tasks?.[TaskCode.FollowTelegram]?.points,
      onClick: toFollowTelegram,
    },
    {
      title: 'Create G6 Token',
      icon: tradingIcon,
      key: TaskCode.CreateG6Token,
      pts: tasks?.[TaskCode.CreateG6Token]?.points,
      onClick: toCreateToken,
    },
    {
      title: 'Buy MEME coin',
      icon: buyIcon,
      key: TaskCode.BuyMeMeCoin,
      pts: tasks?.[TaskCode.BuyMeMeCoin]?.points,
      onClick: toTrade,
    },
    {
      title: 'Sell MEME coin',
      icon: sellIcon,
      key: TaskCode.SellMeMeCoin,
      pts: tasks?.[TaskCode.SellMeMeCoin]?.points,
      onClick: toTrade,
    },
    {
      title: 'Join any token group',
      icon: joinIcon,
      key: TaskCode.JoinAnyTokenGroup,
      pts: tasks?.[TaskCode.JoinAnyTokenGroup]?.points,
      onClick: toTrade,
    },
  ];

  return (
    <div className="flex flex-col gap-2">
      {list.map((item) => {
        return (
          <Card
            key={item.title}
            title={item.title}
            pts={`+${item.pts || 10} Pts`}
            icon={item.icon}
            isFinish={tasks?.[item.key]?.isCompleted ?? false}
            onClick={() => item.onClick(tasks?.[item.key])}
          />
        );
      })}
    </div>
  );
};

export default GeneralTasks;
