import { Button } from 'antd';
import React from 'react';
import iconCheck from '@/assets/icon/icon_check.svg';

export const Card = ({
  title,
  pts,
  icon,
  isFinish = false,
  onClick,
}: {
  title: string;
  pts: string;
  icon: string;
  isFinish?: boolean;
  onClick: () => void;
}) => (
  <div className="px-[16px] py-[12px] rounded-[8px] bg-[#1B1B1B] flex justify-between items-center">
    <div className="flex mr-[10px]">
      <img src={icon} className="w-[38px] h-[38px]" />
      <div className="ml-[12px]">
        <div className="font-[500] leading-[21px] text-[14px] text-primaryText">{title}</div>
        <div className="text-[12px] leading-[14.4px] text-[#FFBA0A] mt-[2px] font-normal">
          {pts}
        </div>
      </div>
    </div>
    <div>
      {isFinish ? (
        <Button
          color="primary"
          className="w-[68px] h-[30px] text-[12px] text-[#121212] font-[500] bg-[transparent]"
          style={{ border: '1px solid rgba(255, 255, 255, 0.3)' }}
        >
          <img src={iconCheck} />
        </Button>
      ) : (
        <Button
          color="primary"
          className=" w-[68px] h-[30px] text-[12px] text-[#121212] font-[500] bg-white"
          onClick={onClick}
        >
          Go
        </Button>
      )}
    </div>
  </div>
);
