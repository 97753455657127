import { SaDrawer } from '../SaDrawer';
import { useEffect, useMemo, useState } from 'react';
import { TomoTokenItem, UserInfo } from '@/types/Tomo';
import { G6Math } from '@/contract/utils/Math';
import { parseUnits } from 'viem';
import baseX from 'base-x';
import { Address } from '@ton/core';
import { ChainType, useWalletInfoStore } from '@/stores/useWalletInfoStore';
import { useGlobalDataStore } from '@/context/GlobalDataContext';
import { useSendTransactionByTomoV2 } from '@/hooks/tomo/useSendTransactionByTomoV2';
import { EnterAddress } from './Send/EntryAddress';
import { EnterAmount } from './Send/EnterAmount';
import { Confirming } from './Send/Confirming';
import Confirmed from './Send/Confirmed';
// import { useGetTokenBalance } from '@/hooks/app/useGetTokenBalance';

const bs58 = baseX('123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz');

function isValidSolanaAddress(address: string) {
  try {
    const decoded = bs58.decode(address);

    return decoded.length === 32;
  } catch (error) {
    return false;
  }
}

function isValidTONAddress(address: string) {
  return Address.isAddress(Address.parse(address));
}

export function validAddress(address: string, chain?: ChainType) {
  try {
    switch (chain) {
      case ChainType.TON:
        return isValidTONAddress(address);

      case ChainType.SOLANA:
        return isValidSolanaAddress(address);

      default:
        return isValidSolanaAddress(address);
    }
  } catch (error) {
    return false;
  }
}

export function getWalletAddress(token?: TomoTokenItem, userInfo?: UserInfo) {
  switch (token?.chain) {
    case 'BITCOIN':
      return userInfo?.bitcoinP2pkhAddress;

    case 'TON':
      return userInfo?.tonAddress;

    case 'SOLANA':
      return userInfo?.solanaAddress;

    case 'TRON':
      return 'Not supported yet';

    default:
      return userInfo?.walletAddress;
  }
}

export function SendOnlyNative(props: {
  open: boolean;
  onSetRoute?: () => void;
  onCancel: () => void;
}) {
  const { walletAddress } = useWalletInfoStore();
  const { decimals, usdPrice } = useGlobalDataStore();
  const [isEnteringAddress, setEnteringAddress] = useState(false);
  const [isEnteringAmount, setEnteringAmount] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [amount, setAmount] = useState('');
  const [address, setAddress] = useState('');

  const pricePreview = useMemo(() => {
    try {
      const val = G6Math.from(amount || '0')
        .multiply(usdPrice)
        .toString();

      if (isNaN(+val)) {
        return '-';
      }

      return val;
    } catch (err) {
      return '-';
    }
  }, [amount, usdPrice]);

  const { send, loading } = useSendTransactionByTomoV2({
    to: address,
    amount: parseUnits(amount, decimals).toString(),
    walletAddress: walletAddress!,
    openSuccessModal: false,
    onSendSuccess() {
      setIsConfirming(false);
      setIsConfirmed(true);
    },
  });

  // 关闭弹窗时重置所有状态
  useEffect(() => {
    if (!props.open) {
      setEnteringAddress(true);
      setEnteringAmount(false);
      setIsConfirming(false);
      setIsConfirmed(false);
      setAmount('');
      setAddress('');
    }
  }, [props.open]);

  return (
    <SaDrawer title="Send" open={props.open} onClose={props.onCancel}>
      {isEnteringAddress && (
        <EnterAddress
          address={address}
          setAddress={setAddress}
          onContinue={() => {
            setEnteringAddress(false);
            setEnteringAmount(true);
          }}
        />
      )}
      {isEnteringAmount && (
        <EnterAmount
          pricePreview={pricePreview}
          amount={amount}
          setAmount={setAmount}
          goBack={() => {
            setEnteringAmount(false);
            setEnteringAddress(true);
          }}
          onConfirm={() => {
            setEnteringAmount(false);
            setIsConfirming(true);
          }}
        />
      )}
      {isConfirming && (
        <Confirming
          pricePreview={pricePreview}
          address={address}
          amount={amount}
          loading={loading}
          onConfirm={send}
          goBack={() => {
            setIsConfirming(false);
            setEnteringAmount(true);
          }}
        />
      )}
      {isConfirmed && <Confirmed onCancel={props.onCancel} />}
    </SaDrawer>
  );
}
