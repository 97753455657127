import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { TradeCreate } from '@/test';
import { uniqBy } from 'lodash';

export const SolWebSocketContext = createContext<{
  sendMessage?: (data: string | ArrayBufferLike | Blob | ArrayBufferView) => void;
  messageHistory: TradeCreate[];
  readyState?: WebSocket['readyState'];
  latestMintMessage?: TradeCreate;
}>({
  sendMessage: () => {},
  messageHistory: [],
  readyState: undefined,
  latestMintMessage: undefined,
});

export const useSolWebSocketStore = () => useContext(SolWebSocketContext);

export const useSolWebSocketContext = () => {
  const [messageHistory, setMessageHistory] = useState<TradeCreate[]>([]);
  const pathname = window.location.pathname;
  const mint = pathname.includes('/coin/') && pathname.split('/')[2];
  const [currentMint, setCurrentMint] = useState('');
  const [ws, setWs] = useState<WebSocket>();
  const [latestMintMessage, setLatestMintMessage] = useState<TradeCreate>();

  useEffect(() => {
    const socket = new WebSocket(process.env.REACT_APP_SOL_SOCKET_URL!);
    setWs(socket);

    const pathname = window.location.pathname;
    const mint = pathname.includes('/coin/') && pathname.split('/')[2];

    socket.onopen = () => {
      console.log(`wss open`);
    };

    socket.onmessage = (event) => {
      const data: string = event.data as unknown as string;

      if (data.startsWith(`0{"sid":`)) {
        console.log(`0{"sid":`);

        socket.send(`40`);
      } else if (data.startsWith(`40{"sid":`)) {
        if (mint) {
          socket?.send(`42["joinTradeRoom",{"mint":"${mint}"}]`);
          setCurrentMint(mint);
        }
      } else if (data === `2`) {
        socket.send(`3`);
      } else if (data.startsWith(`42["tradeCreated"`)) {
        const input = JSON.parse(data.substring(2));
        const body = input[1] as TradeCreate;

        setMessageHistory((pre) => uniqBy([...pre, body].slice(-20), 'signature'));
      } else if (data.startsWith(`42["tradeCreated:${mint}"`)) {
        const input = JSON.parse(data.substring(2));
        const body = input[1] as TradeCreate;

        setLatestMintMessage(body);
      }
    };

    socket.onclose = (event) => {
      console.log(`sol client closed: ${JSON.stringify(event)}`);
    };

    socket.onerror = (error) => {
      console.log(`wss error: ${error}`);
    };

    return () => {
      socket.close();
    };
  }, []);

  const joinRoom = useCallback(
    (mint: string) => {
      ws?.send(`42["joinTradeRoom",{"mint":"${mint}"}]`);
      setCurrentMint(mint);
    },
    [ws],
  );

  useEffect(() => {
    if (mint && ws?.readyState === 1) {
      joinRoom(mint);
    }
  }, [joinRoom, mint, ws]);

  const leaveRoom = useCallback(() => {
    if (currentMint) {
      ws?.send(`42["leaveTradeRoom",{"mint":"${currentMint}"}]`);
      setCurrentMint('');
    }
  }, [currentMint, ws]);

  useEffect(() => {
    if (!mint && ws?.readyState === 1) {
      leaveRoom();
    }
  }, [leaveRoom, mint, ws]);

  return {
    sendMessage: ws?.send,
    messageHistory,
    latestMintMessage,
  };
};

const SolWebSocketProvider = ({ children }: { children: React.ReactNode }) => {
  const data = useSolWebSocketContext();

  return <SolWebSocketContext.Provider value={data}>{children}</SolWebSocketContext.Provider>;
};

export default SolWebSocketProvider;
