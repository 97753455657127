import { useWalletInfoStore } from '@/stores/useWalletInfoStore';
import { useFetchTonCoinList } from './useFetchTonCoinList';
import { useFetchSolanaCoinList } from './useFetchSolanaCoinList';

export function useFetchAllListToken() {
  const { isTon } = useWalletInfoStore();

  const tonRes = useFetchTonCoinList({ enabled: isTon });

  const solanaRes = useFetchSolanaCoinList({ enabled: !isTon });

  return isTon ? tonRes : solanaRes;
}
