import HttpRequest from '@/utils/Axios';
import { getJWTToken } from '@/stores/useUserStore';
import { errorHandler } from './errorHandler';
import { getTomoMockData } from '@/mock/tomo';

const api = new HttpRequest({ baseURL: process.env.REACT_APP_API });

api.instance.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = getJWTToken() || getTomoMockData().token;

    return config;
  },
  (err) => Promise.reject(err),
);

api.instance.interceptors.response.use(
  (res) => {
    return res.data;
  },
  (err) => errorHandler(err?.response?.data || err),
);

export default api;
