import { SaDrawer } from '@/components/SaDrawer';
import { useAppMessage } from '@/hooks/useAppMessage';
import { Button, ConfigProvider, Input, Tag } from 'antd';
import classNames from 'classnames';
import { useContext, useEffect, useState } from 'react';
import { formatBigNumber } from '..';
import { SwapContext } from '../context/SwapContext';
import { DetailField } from './Common';

function Item({
  title,
  description,
  className,
  isCurrent,
  footer,
  onClick,
}: {
  title: any;
  description: string;
  className?: string;
  footer?: any;
  isCurrent?: boolean;
  onClick?: () => void;
}) {
  return (
    <div
      onClick={onClick}
      className={classNames(
        'p-[16px] border border-solid border-primaryBorder rounded-xl',
        className,
        {
          '!border-primary': isCurrent,
        },
      )}
    >
      <div className="text-base text-primaryText mb-[8px]">{title}</div>
      <div className="text-[12px] text-secondaryText font-normal">{description}</div>
      {footer && <div className="mt-[8px]">{footer}</div>}
    </div>
  );
}

export function SlippageSetting(props: {
  open: boolean;
  onSetSlippage?: () => void;
  onCancel: () => void;
}) {
  const [slippage, setSlippage] = useState('1');

  const [slippageType, setSlippageType] = useState<'auto' | 'custom'>('auto');

  const onBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^\d.]/g, '');

    setSlippage(value);
  };

  const message = useAppMessage();

  const { setSlippageConf, slippageConf, swapData, toToken } = useContext(SwapContext);

  const handleConfirm = () => {
    if (slippageType !== 'auto' && (+slippage < 1 || +slippage > 50)) {
      message.info('Slippage must be greater than 1% and less than 50%');
      return;
    }

    setSlippageConf?.({
      type: slippageType,
      value: slippageType === 'auto' ? '1' : slippage,
    });

    props.onSetSlippage?.();

    props.onCancel?.();
  };

  useEffect(() => {
    if (props.open) {
      setSlippageType(slippageConf.type);
      setSlippage(slippageConf.value);
    }
  }, [props.open, slippageConf.type, slippageConf.value]);

  return (
    <SaDrawer title="Slippage" open={props.open} onClose={props.onCancel} height="auto">
      <div className="flex justify-start items-start flex-col gap-y-[12px] py-[12px]">
        <Item
          onClick={() => {
            setSlippageType('auto');
          }}
          isCurrent={slippageType === 'auto'}
          title="Auto (1%)"
          description="Slippage is automatically adjusted based on the chosen token to increase the chance of
        successful transaction"
        ></Item>

        <Item
          onClick={() => {
            setSlippageType('custom');
          }}
          isCurrent={slippageType === 'custom'}
          title={
            <div className="flex items-center gap-1">
              Custom{' '}
              <Tag className="rounded-xl text-secondaryText" bordered={false}>
                Single-chain
              </Tag>
            </div>
          }
          description="The transaction will be executed according to the slippage you set."
          footer={
            slippageType === 'custom' && (
              <ConfigProvider
                theme={{
                  components: {
                    Input: {
                      controlHeight: 36,
                      borderRadius: 4,
                    },
                  },
                }}
              >
                <Input
                  value={slippage}
                  placeholder="1-50"
                  suffix={<span className="text-secondaryText">%</span>}
                  onChange={onBlur}
                  onBlur={onBlur}
                ></Input>
              </ConfigProvider>
            )
          }
        ></Item>
      </div>
      <div className="w-full px-[8px] mt-[16px]">
        <DetailField
          label="Minimum received"
          value={formatBigNumber(swapData?.min_receive_amount, toToken?.decimals)}
        />

        <ConfigProvider
          theme={{
            components: {
              Button: {
                controlHeight: 48,
              },
            },
          }}
        >
          <Button className="mt-[16px]" type="primary" block onClick={handleConfirm}>
            Continue
          </Button>
        </ConfigProvider>
      </div>
    </SaDrawer>
  );
}
