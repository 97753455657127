import BigNumber from 'bignumber.js';

type G6MathInput = number | string | bigint | BigNumber | G6Math;

BigNumber.config({ DECIMAL_PLACES: 18, ROUNDING_MODE: BigNumber.ROUND_HALF_UP });

export class G6Math {
  private value: BigNumber;

  constructor(value: G6MathInput) {
    this.value = new BigNumber(value?.toString());
  }

  static from(value: G6MathInput): G6Math {
    return new G6Math(value);
  }

  add(other: G6MathInput): G6Math {
    const otherValue = new BigNumber(other.toString());
    return new G6Math(this.value.plus(otherValue));
  }

  subtract(other: G6MathInput): G6Math {
    const otherValue = new BigNumber(other.toString());
    return new G6Math(this.value.minus(otherValue));
  }

  multiply(other: G6MathInput): G6Math {
    const otherValue = new BigNumber(other.toString());
    return new G6Math(this.value.multipliedBy(otherValue));
  }

  divide(other: G6MathInput, decimalPlaces: number = 10): G6Math {
    const otherValue = new BigNumber(other.toString());
    const result = this.value.dividedBy(otherValue);
    return new G6Math(result.decimalPlaces(decimalPlaces, BigNumber.ROUND_HALF_UP));
  }

  compareTo(other: G6MathInput): number {
    const otherValue = new BigNumber(other.toString());
    return this.value.comparedTo(otherValue);
  }

  isGreaterThan(other: G6MathInput): boolean {
    const otherValue = new BigNumber(other.toString());
    return this.value.isGreaterThan(otherValue);
  }

  isGreaterThanOrEqualTo(other: G6MathInput): boolean {
    const otherValue = new BigNumber(other.toString());
    return this.value.isGreaterThanOrEqualTo(otherValue);
  }

  isLessThan(other: G6MathInput): boolean {
    const otherValue = new BigNumber(other.toString());
    return this.value.isLessThan(otherValue);
  }

  isLessThanOrEqualTo(other: G6MathInput): boolean {
    const otherValue = new BigNumber(other.toString());
    return this.value.isLessThanOrEqualTo(otherValue);
  }

  isEqualTo(other: G6MathInput): boolean {
    const otherValue = new BigNumber(other.toString());
    return this.value.isEqualTo(otherValue);
  }

  toString(): string {
    // Use the BigNumber's toFixed method to prevent scientific notation
    return this.value.toFixed();
  }

  toFixed(decimalPlaces: number): string {
    return this.value.toFixed(decimalPlaces);
  }

  toNumber(): number {
    return this.value.toNumber();
  }

  toBigInt(): bigint {
    return BigInt(this.value.integerValue(BigNumber.ROUND_DOWN).toString());
  }
}
