import classNames from 'classnames';
import { useMemo } from 'react';
import { Divider, Typography } from 'antd';
import { useAppRouter } from '@/hooks/router/useAppRouter';
import { getDisplayName } from '@/utils';
import { G6Image } from '@/components/Image';
import { G6Math } from '../../contract/utils/Math';
import { Coin, CoinDetail } from '@/types/Coin';
import { useGlobalDataStore } from '@/context/GlobalDataContext';
import { ReactComponent as Gold } from '@/assets/images/gold.svg';
import { CoinsForYou } from '@/test';
import { useWalletInfoStore } from '@/stores/useWalletInfoStore';
import numeral from 'numeral';
import { useCoinDetailStore } from '@/views/Coin/context/CoinDetailContext';
import { getImageUri } from '@/utils/coin-detail';

export function CoinCard({
  children,
  isG6,
  isImportant,
}: {
  children?: React.ReactNode;
  isG6?: boolean;
  isImportant?: boolean;
}) {
  if (isG6) {
    return <G6Card isImportant={isImportant}>{children}</G6Card>;
  }

  return <DefaultCard>{children}</DefaultCard>;
}

function DefaultCard({ children }: { children?: React.ReactNode }) {
  return (
    <div
      className={classNames(
        'w-full rounded-[12px] border border-solid  h-full  transition-all duration-200 ease-in-out overflow-hidden border-transparent md:rounded-[15px] bg-secondaryBackground',
      )}
    >
      {children}
    </div>
  );
}

function G6Card({ children, isImportant }: { children?: React.ReactNode; isImportant?: boolean }) {
  const border = 'border border-solid  border-[#FFA420]';

  return (
    <div
      className={classNames(
        'rounded-[12px] h-full overflow-hidden md:rounded-[15px] w-full bg-secondaryBackground',
        !isImportant && border,
      )}
    >
      <div
        style={{
          height: '100%',
        }}
        className={classNames(
          'rounded-[12px] transition-all duration-200 ease-in-out md:rounded-[15px]',
          isImportant && 'outline outline-[30px] outline-[#FFA420]',
          isImportant && border,
        )}
      >
        {children}
      </div>
    </div>
  );
}

const { Paragraph } = Typography;

function Field({ label, value, className }: { label: string; value: any; className?: string }) {
  return (
    <div className={classNames(className)}>
      <div className="text-[12px] leading-none">{value}</div>
      <div className="text-[10px] leading-none mt-[4px] opacity-50">{label}</div>
    </div>
  );
}

export function CoinMarketCap({ detail, unit }: { detail?: CoinDetail; unit?: string }) {
  const { isTonData } = useCoinDetailStore();

  return isTonData(detail) ? (
    <MarketCap tonAmount={detail.marketCap} unit={unit} />
  ) : (
    <SolMarketCap value={detail?.usd_market_cap} />
  );
}

export function MarketCap({ tonAmount, unit }: { tonAmount?: string | number; unit?: string }) {
  const { usdPrice } = useGlobalDataStore();

  const val = useMemo(() => {
    try {
      const value = new G6Math(tonAmount || 0)
        .multiply(usdPrice)
        .divide(10 ** 9)
        .toString();

      if (!+value) {
        return '-';
      }

      return unit === 'USDT'
        ? `${parseFloat((+value).toFixed(4))} ${unit || ''}`
        : `${unit || ''}${parseFloat((+value).toFixed(4))}`;
    } catch (err) {
      console.log('err', err);

      return '-';
    }
  }, [tonAmount, usdPrice, unit]);

  return <span>{val}</span>;
}

export function SolMarketCap({ value }: { value?: number }) {
  return <span>{numeral(value).format('0.00a').toLocaleUpperCase()}</span>;
}

export function CoinItem({
  item,
  isG6,
  isImportant,
}: {
  item: Coin | CoinsForYou;
  isG6?: boolean;
  isImportant?: boolean;
}) {
  const { isTon } = useWalletInfoStore();

  return isTon ? (
    <Item item={item as Coin} isG6={isG6} isImportant={isImportant} />
  ) : (
    <SolanaItem item={item as CoinsForYou} />
  );
}

export function Item({
  item,
  isG6,
  isImportant,
}: {
  item: Coin;
  isG6?: boolean;
  isImportant?: boolean;
}) {
  const { goCoinDetail } = useAppRouter();

  return (
    <CoinCard isG6={isG6 || item.isG6} isImportant={item.isImportant || isImportant}>
      <div
        className="flex items-center cursor-pointer p-[4px] h-full w-full relative"
        onClick={() => {
          goCoinDetail({ poolAddress: item.poolAddress });
        }}
      >
        {item.isImportant && (
          <div className="absolute left-[4px] top-[4px] z-10">
            <Gold />
          </div>
        )}

        <div className="rounded-lg overflow-hidden shrink-0 w-[113px] h-[113px] object-contain  mr-[12px]">
          <G6Image className="w-full h-full" src={item.imageUri}></G6Image>
        </div>

        <div className="flex flex-col w-full py-[5px] h-full shrink">
          <div className="w-full flex justify-between items-center text-[15px]">
            <span>{item.symbol}</span>
          </div>

          <div className="min-h-[32px]">
            <Paragraph
              ellipsis={{ rows: 3, expandable: false }}
              className="!mb-[0px] text-[12px]  text-secondaryText leading-[14.4px]"
            >
              {item.description}
            </Paragraph>
          </div>

          <Divider className="border-primaryBorder my-[8px]"></Divider>

          <div className="w-full flex justify-start items-center gap-x-[28px] shrink-1 h-full">
            <Field
              label="Market Cap"
              value={<MarketCap unit="$" tonAmount={item?.marketCap} key={item?.marketCap} />}
            ></Field>

            <Field
              label="Created by"
              value={getDisplayName(item.creatorUsername || item.creator) || '-'}
            ></Field>
          </div>
        </div>
      </div>
    </CoinCard>
  );
}

export function SolanaItem({ item }: { item: CoinsForYou }) {
  const { goCoinDetail } = useAppRouter();

  return (
    <CoinCard isG6={false} isImportant={false}>
      <div
        className="flex items-center cursor-pointer p-[4px] h-full w-full relative"
        onClick={() => {
          goCoinDetail({ poolAddress: item.mint });
        }}
      >
        {/* {item.isImportant && (
          <div className="absolute left-[4px] top-[4px] z-10">
            <Gold />
          </div>
        )} */}

        <div className="rounded-lg overflow-hidden shrink-0 w-[113px] h-[113px] object-contain  mr-[12px]">
          <G6Image className="w-full h-full" src={getImageUri(item.image_uri)}></G6Image>
        </div>

        <div className="flex flex-col w-full py-[5px] h-full shrink">
          <div className="w-full flex justify-between items-center text-[15px]">
            <span>{item.symbol}</span>
          </div>

          <div className="min-h-[32px]">
            <Paragraph
              ellipsis={{ rows: 3, expandable: false }}
              className="!mb-[0px] text-[12px]  text-secondaryText leading-[14.4px]"
            >
              {item.description}
            </Paragraph>
          </div>

          <Divider className="border-primaryBorder my-[8px]"></Divider>

          <div className="w-full flex justify-start items-center gap-x-[28px] shrink-1 h-full">
            <Field label="Market Cap" value={<SolMarketCap value={item?.usd_market_cap} />}></Field>

            <Field label="Created by" value={item.creator ? item.creator.slice(0, 6) : '-'}></Field>
          </div>
        </div>
      </div>
    </CoinCard>
  );
}
