import React, { forwardRef, useImperativeHandle, useState } from 'react';
import styles from './SlideButton.module.css';

const SlideButton = forwardRef(
  (
    {
      isDisabled,
      loading = false,
      displayText,
      onConfirm,
    }: {
      isDisabled?: boolean;
      loading?: boolean;
      displayText?: string;
      onConfirm: () => void;
    },
    ref,
  ) => {
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [value, setValue] = useState('0');

    const handleSlideEnd = (e: any) => {
      if (isConfirmed || isDisabled) return;
      if (e.target.value === '100') {
        setIsConfirmed(true);
        onConfirm();
      } else {
        setValue('0');
      }
    };

    const reset = () => {
      setIsConfirmed(false);
      setValue('0');
    };

    useImperativeHandle(ref, () => ({
      reset,
    }));

    return (
      <div className="relative">
        <input
          disabled={isDisabled || isConfirmed || loading}
          value={value}
          type="range"
          min="0"
          max="100"
          className={styles['custom-range']}
          onMouseUp={(e) => {
            handleSlideEnd(e);
          }}
          onTouchEnd={(e) => {
            e.preventDefault();
            handleSlideEnd(e);
          }}
          onChange={(e) => setValue(e.target.value)}
        />
        <div className="absolute left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%] text-[#474747]">
          {displayText ? displayText : 'Swipe to Send'}
        </div>
      </div>
    );
  },
);

export default SlideButton;
