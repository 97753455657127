import React, { createContext, useContext, ReactNode } from 'react';

import { useSessionStorageState } from 'ahooks';

interface VerifyContextProps {
  email?: string;
  code?: string;
  password?: string;
  confirmPassword?: string;
  setEmail: (email: string) => void;
  setCode: (code: string) => void;
  setPassword: (password: string) => void;
  setConfirmPassword: (password: string) => void;
  reset: () => void;
}

const VerifyContext = createContext<VerifyContextProps | undefined>(undefined);

export const VerifyContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [email, setEmail] = useSessionStorageState<string | undefined>('VerifyContext-Email');

  const [code, setCode] = useSessionStorageState<string | undefined>('VerifyContext-Code');

  const [password, setPassword] = useSessionStorageState<string | undefined>(
    'VerifyContext-Password',
  );

  const [confirmPassword, setConfirmPassword] = useSessionStorageState<string | undefined>(
    'VerifyContext-ConfirmPassword',
  );

  const reset = () => {
    setEmail(undefined);
    setCode(undefined);
    setPassword(undefined);
    setConfirmPassword(undefined);
  };

  return (
    <VerifyContext.Provider
      value={{
        email,
        code,
        password,
        confirmPassword,
        setEmail,
        setCode,
        setPassword,
        setConfirmPassword,
        reset,
      }}
    >
      {children}
    </VerifyContext.Provider>
  );
};

export const useVerifyContext = (): VerifyContextProps => {
  const context = useContext(VerifyContext);

  if (!context) {
    throw new Error('useVerifyContext must be used within a VerifyContextProvider');
  }

  return context;
};
