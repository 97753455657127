import { evmChainList } from '@/config/wagmi-config';
import { useWalletInfoStore } from '@/stores/useWalletInfoStore';
import { useMemo } from 'react';
import { Address, isAddress } from 'viem';
import { useBalance } from 'wagmi';

export function useGetTokenBalance({ chainId, token }: { chainId?: number; token: string }) {
  const { walletAddress } = useWalletInfoStore();

  const isEvmChain = useMemo(() => {
    return !!chainId && (evmChainList.map((item) => item.id) as number[]).includes(chainId);
  }, [chainId]);

  const result = useBalance({
    address: walletAddress as Address,
    chainId,
    token: token as Address,
    query: {
      enabled: isEvmChain && !!walletAddress && isAddress(walletAddress),
    },
  });

  return result;
}
