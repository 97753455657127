import { fetchG6Graduated } from '@/service/coin';
import { useRequest } from 'ahooks';

export function useFetchG6List({
  search,
  filter,
  sort,
  size,
  order,
}: {
  search?: string;
  filter?: string;
  sort?: string;
  size: number;
  order?: string;
}) {
  const hook = useRequest(
    () => {
      return fetchG6Graduated({
        current: 1,
        size: size,
        search,
        filter,
        sort,
        order,
      });
    },
    {
      refreshDeps: [search, filter, sort, size, order],
    },
  );

  return hook;
}
