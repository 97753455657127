import { VerifyContextProvider } from '@/views/VerifyEmail/VerifyContext';
import { Outlet } from 'react-router-dom';
import { Footer } from './Footer';
import { startAppParam } from '@/constants/startAppParam';
import { useSessionStorageState } from 'ahooks';
import base64url from 'base64url';
import { useAppRouter } from '@/hooks/router/useAppRouter';
import { storageKeys } from '@/constants/storage';
import { useEffect } from 'react';

// document.addEventListener(
//   'touchmove',
//   function (event) {
//     event.preventDefault();
//   },
//   { passive: false },
// );

window.addEventListener('touchend', function () {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
});

export function Layout() {
  const { goProfileWithUserId } = useAppRouter();

  const [startParam, setStartAppParam] = useSessionStorageState<string>(storageKeys.startParam, {
    listenStorageChange: true,
  });

  useEffect(() => {
    // 检查有没有startParam 有的话就跳转去对应页面
    if (startParam) {
      // startParam第一个字符是问号需要去掉
      const param = base64url.decode(startParam).slice(1);
      const params = param.split('&');
      for (let i = 0; i < params.length; i++) {
        const splitRes = params[i].split('=');
        if (
          [startAppParam.InviteCode, startAppParam.ReferCode].includes(splitRes[0] as startAppParam)
        ) {
          goProfileWithUserId(splitRes[1]);
          break;
        }
      }
      setStartAppParam('');
    }
  }, [goProfileWithUserId, setStartAppParam, startParam]);

  return (
    <>
      <div
        className="w-full h-full relative z-10 flex flex-col shrink"
        onTouchMove={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="overflow-y-auto h-full">
          <Outlet />
        </div>

        <div className="w-full py-[8px] min-h-[68px] shrink-0  flex justify-center items-center bg-primaryBackground">
          <Footer />
        </div>
      </div>

      {/* <HowItWork /> */}

      {/* <CheckPassword /> */}

      {/* <PreConnect /> */}

      {/* <Authorize /> */}
    </>
  );
}

export function DefaultLayout() {
  return (
    <div className="w-full overflow-y-auto h-full relative">
      <Outlet />
    </div>
  );
}

export function VerifyLayout() {
  return (
    <div className="w-full overflow-y-auto h-full relative">
      <VerifyContextProvider>
        <Outlet />
      </VerifyContextProvider>
    </div>
  );
}
