import { useAppRouter } from '@/hooks/router/useAppRouter';
import { useAppMessage } from '@/hooks/useAppMessage';
import { useTomoSdk } from '@/hooks/useTomoSdk';
import { LeftOutlined } from '@ant-design/icons';
import { useCountDown, useRequest, useSessionStorageState } from 'ahooks';
import { Button, ConfigProvider, Input } from 'antd';
import { useEffect, useMemo } from 'react';
// import { Password } from './Password';
import { useVerifyContext } from './VerifyContext';

export default function EmailCodeInput() {
  const [startTime, setStartTime] = useSessionStorageState<number>(`CountDown`);

  const [countdown] = useCountDown({ leftTime: (startTime || 0) - +new Date() });

  const countdownText = useMemo(() => {
    return Math.round(countdown / 1000);
  }, [countdown]);

  const { code, setCode, email } = useVerifyContext();

  const handleSubmit = () => {
    goVerifyEmailPassword();
  };

  const { goBack, goVerifyEmailPassword } = useAppRouter();

  useEffect(() => {
    if (!startTime) {
      setStartTime(+new Date() + 60 * 1000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const message = useAppMessage();

  const { tomo } = useTomoSdk();

  const { run, loading } = useRequest(
    async () => {
      const res = await tomo.onSendEmailCode({ email: email! });

      if ([8000, 10000].includes(res.code)) {
        return Promise.reject(new Error(res.message));
      }

      await tomo.onUpdateUserInfo();
    },
    {
      manual: true,
      onSuccess() {
        message.success('Email sent successfully');
        setStartTime(+new Date() + 60 * 1000);
      },
      onError(err) {
        message.error(err.message || 'Failed to send email');
      },
    },
  );

  return (
    <div className="w-full py-[18px] px-4">
      <ConfigProvider
        theme={{
          components: {
            Button: {
              controlHeight: 36,
            },
          },
        }}
      >
        <Button
          type="text"
          className="text-secondaryText ml-[-15px]"
          onClick={() => {
            goBack();
          }}
        >
          <LeftOutlined /> Back
        </Button>
      </ConfigProvider>

      <div className="text-xl mt-2">Email Code</div>

      <div className="mt-2 text-[13px]">Enter your verification code</div>
      <ConfigProvider
        theme={{
          components: {
            Input: {
              controlHeight: 48,
              borderRadius: 8,
            },
          },
        }}
      >
        <div className="mt-11 [&_.g6-otp]:!mx-auto flex justify-center items-center">
          <Input.OTP
            className="h-[50px] text-[18px] "
            style={{ width: '240px' }}
            length={4}
            value={code}
            onChange={(e) => {
              setCode(e);
            }}
          />
        </div>
      </ConfigProvider>

      <Button
        className="mt-7"
        block
        type="primary"
        onClick={() => {
          handleSubmit();
        }}
        disabled={code?.length !== 4}
      >
        Continue
      </Button>

      <Button
        className="mt-2"
        block
        type="link"
        loading={loading}
        onClick={() => {
          run();
        }}
        disabled={!!countdown}
      >
        {!countdown ? <span>Request</span> : <>Resend {countdownText} s</>}
      </Button>
    </div>
  );
}
