import { useWalletInfoStore } from '@/stores/useWalletInfoStore';
import { CopyOutlined, DeleteOutlined } from '@ant-design/icons';
import { Input, Button, ConfigProvider } from 'antd';
import { validAddress } from '../SendOnlyNative';

export const EnterAddress = ({
  address,
  onContinue,
  setAddress,
}: {
  address: string;
  setAddress: React.Dispatch<React.SetStateAction<string>>;
  onContinue: () => void;
}) => {
  const { TextArea } = Input;
  const { chainType } = useWalletInfoStore();

  const handlePaste = async () => {
    try {
      const text = await navigator.clipboard.readText();

      setAddress(text);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      <div className="font-semibold text-[24px] mb-3">Receiving address</div>
      <TextArea
        value={address}
        variant="borderless"
        className="p-0 rounded-none"
        placeholder="Please enter your address"
        autoSize={{ minRows: 2, maxRows: 6 }}
        onChange={(e) => setAddress(e.target.value)}
      />
      {address && !validAddress(address, chainType) && (
        <div className="font-normal text-[#EB4B6D] text-[12px]">Please enter a valid address</div>
      )}
      <div className="flex justify-end mt-[90px]">
        {!address ? (
          <Button className="text-right" icon={<CopyOutlined />} onClick={handlePaste}>
            Paste
          </Button>
        ) : (
          <Button className="text-right" icon={<DeleteOutlined />} onClick={() => setAddress('')}>
            Clear
          </Button>
        )}
      </div>
      <ConfigProvider
        theme={{
          components: {
            Button: {
              controlHeight: 48,
            },
          },
        }}
      >
        <Button
          type="primary"
          className="absolute bottom-6 w-[calc(100%-24px)]"
          onClick={onContinue}
          disabled={!address || !validAddress(address, chainType)}
        >
          Continue
        </Button>
      </ConfigProvider>
    </div>
  );
};
