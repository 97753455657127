import { errorHandler } from './errorHandler';
import TomoHttpRequest from '@/utils/TomoRequest';
import { getTomoMockData } from '@/mock/tomo';
import { getJWTToken } from '@/stores/useUserStore';

const api = new TomoHttpRequest({ baseURL: process.env.REACT_APP_TOMO_API });

api.instance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = getJWTToken() || getTomoMockData().token;

    config.headers['X-TOMO-TMA-USERID'] = getTomoMockData().uid;

    config.headers['X-TOMO-TMA-KEY'] = getTomoMockData().key;

    config.headers['x-tomo-tma-userid'] = getTomoMockData().uid;

    config.headers['x-tomo-tma-key'] = getTomoMockData().key;

    return config;
  },
  (err) => Promise.reject(err),
);

api.instance.interceptors.response.use(
  (res) => {
    return res.data;
  },
  (err) => errorHandler(err?.response?.data || err),
);

export default api;
