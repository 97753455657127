import { useRequest } from 'ahooks';
import { getCoinTrade, SolCoinTradeItem } from '@/service/sol-coin';
import { useCoinDetailStore } from '../context/CoinDetailContext';
import { SolCoinDetail } from '@/types/Coin';

export function useFetchSolCoinTrade({
  size = 200,
  onSuccess,
}: { size?: number; onSuccess?: (v: SolCoinTradeItem[]) => void } = {}) {
  const { detail } = useCoinDetailStore();

  const hook = useRequest(
    async () => {
      return await getCoinTrade({
        mint: (detail as SolCoinDetail).mint,
        offset: 0,
        limit: size,
      });
    },
    { ready: !!detail, onSuccess },
  );

  return hook;
}
