import { MarketCard } from './components/MarketCard';
import { CoinInfo } from './components/CoinInfo';
// import { Trade } from './components/Trade';
// import { CoinDetail } from './components/CoinDetail';
// import { CurveProgress } from './components/CurveProgress';
// import { HolderDistribution } from './components/HolderDistribution';
import CoinDetailProvider, { useCoinDetailStore } from './context/CoinDetailContext';
import { Button, ConfigProvider, Tabs } from 'antd';
import { useState, useMemo, useEffect } from 'react';
import { useWindowResize } from '@/hooks/useWindowResize';
import { LeftOutlined } from '@ant-design/icons';
import { useAppRouter } from '@/hooks/router/useAppRouter';
import { useParseRouterQuery } from '@/hooks/router/useParseRouterQuery';
import TradeTable from './components/TradeTable/TradeTable';
import { Trade } from './components/Trade';
import classNames from 'classnames';

export function Card({ children }: { children: any }) {
  return (
    <div className="flex flex-col w-full h-auto gap-4 mb-[20px]">
      <div className="flex flex-col w-full h-auto rounded-[18px] p-[18px]  border-2 border-white">
        {children}
      </div>
    </div>
  );
}

export default function () {
  const [tabItem, setTabItem] = useState('Info');

  const { isTonData, detail } = useCoinDetailStore();

  const { query, updateQuery } = useParseRouterQuery({
    current: 'Info',
  });

  // const items: any = [
  //   {
  //     key: 'THREAD',
  //     label: 'THREAD',
  //     children: (
  //       <div className="mb-[18px]">
  //         <CommentsList />
  //       </div>
  //     ),
  //   },
  //   {
  //     key: 'TRADES',
  //     label: 'TRADES',
  //     children: (
  //       <div className="mt-[-15px]">
  //         <TradesTable />
  //       </div>
  //     ),
  //   },
  // ];

  const DynamicComponent = useMemo(() => {
    switch (tabItem) {
      case 'Info':
        return (
          <div className="px-[16px] ">
            <CoinInfo />
          </div>
        );
      // case 'Gragh':
      //   return <MarketCard />;
      // case 'Thread':
      //   return (
      //     <div className="px-[16px] ">
      //       <CommentsList />
      //     </div>
      //   );
      case 'Trades':
        return <TradeTable />;
      default:
        return null;
    }
  }, [tabItem]);

  const tabsList = [
    {
      label: 'INFO',
      key: 'Info',
    },
    // {
    //   label: 'GRAPH',
    //   key: 'Gragh',
    // },
    // {
    //   label: 'THREAD',
    //   key: 'Thread',
    // },
    {
      label: 'TRADES',
      key: 'Trades',
    },
  ];

  const [tabs, setTabs] = useState([]);

  const tabsChange = (key: string) => {
    setTabItem(key);

    updateQuery({
      current: key,
    });
  };

  const { width } = useWindowResize();

  const isMobile = useMemo(() => {
    return width <= 768;
  }, [width]);

  useEffect(() => {
    const tabsCopy = JSON.parse(
      JSON.stringify(
        isTonData(detail) ? tabsList : tabsList.filter((item) => item.key !== 'Thread'),
      ),
    );

    if (isMobile) {
      setTabs(tabsCopy);
      if (tabItem === 'Info') setTabItem('Info');
    } else {
      setTabs(tabsCopy);
      if (tabItem === 'Info') setTabItem('Info');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { goBack } = useAppRouter();

  useEffect(() => {
    if (query.current) {
      setTabItem(query.current);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return (
    <CoinDetailProvider>
      <div className="bg-primaryBackground">
        <div className="flex justify-start items-center h-[48px] bg-primaryBackground sticky top-0 z-20">
          <ConfigProvider
            theme={{
              components: {
                Button: {
                  controlHeight: 36,
                },
              },
            }}
          >
            <Button
              type="text"
              className="text-secondaryText"
              onClick={() => {
                goBack();
              }}
            >
              <LeftOutlined /> Back
            </Button>
          </ConfigProvider>
        </div>

        <MarketCard />

        <div className="px-3 mt-6 mb-4">
          <div
            className={classNames(
              'flex flex-col w-full h-auto gap-4 rounded-[10px] p-[16px_14px] bg-[#1B1B1B]',
            )}
          >
            <Trade />
          </div>
        </div>

        <ConfigProvider
          theme={{
            components: {
              Tabs: {
                colorPrimary: '#fff',
                itemColor: '#656565',
              },
            },
            token: {},
          }}
        >
          <Tabs
            className="[&_.g6-tabs-nav-wrap]:px-[16px] [&_.g6-tabs-nav]:!mb-0 sticky top-[48px] bg-primaryBackground z-20"
            activeKey={tabItem}
            items={tabs}
            onChange={tabsChange}
          ></Tabs>
        </ConfigProvider>
        <div className="pt-[8px]">{DynamicComponent}</div>
      </div>
    </CoinDetailProvider>
  );
}
