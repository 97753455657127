import { BrowserRouter, Navigate, Route, Routes as ReactRoutes } from 'react-router-dom';
import Home from '@/views/Home';
import Profile from '@/views/Profile';
import Coin from '@/views/Coin';
import TomoCoin from '@/views/TomoCoin';
import { DefaultLayout, Layout, VerifyLayout } from '@/components/Layout';
import Swap from '@/views/Swap';
import Task from '@/views/Task';
import Create from '@/views/Create';
import Detail from '@/views/Detail';
import Search from '@/views/Search';
import VerifyEmail from '@/views/VerifyEmail/VerifyEmail';
import EmailCodeInput from '@/views/VerifyEmail/EmailCodeInput';
import { MnemonicPhrase } from '@/views/MnemonicPhrase';
import { AccountSetting } from '@/views/AccountSetting';
import { VerifyEmailPassword } from '@/views/VerifyEmail/Password';

const Routes = () => {
  // const isAuthenticated = true;

  return (
    <>
      <BrowserRouter>
        <ReactRoutes>
          <>
            <Route path="/" element={<Layout />}>
              <Route path="" element={<Home />} />
              <Route path="/profile/:address" element={<Profile />} />
              <Route path="/profileWithId/:userId" element={<Profile />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/swap" element={<Swap />} />
              <Route path="/task" element={<Task />} />
              <Route path="/create" element={<Create />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Route>

            <Route path="/" element={<DefaultLayout />}>
              <Route path="/user/:address" element={<Profile />} />
              <Route path="/userWithId/:userId" element={<Profile />} />
              <Route path="/search" element={<Search />} />
              <Route path="/coin/:address" element={<Coin />} />
              <Route path="/tomoCoin" element={<TomoCoin />} />
              <Route path="/detail/:address" element={<Detail />} />
              <Route path="/mnemonicPhrase" element={<MnemonicPhrase />} />
              <Route path="/accountSetting" element={<AccountSetting />} />
            </Route>

            <Route path="*" element={<Navigate to="/" />} />

            <Route path="/" element={<VerifyLayout />}>
              <Route path="/verifyEmail" element={<VerifyEmail />} />
              <Route path="/verifyEmailInput" element={<EmailCodeInput />} />
              <Route path="/verifyEmailPassword" element={<VerifyEmailPassword />} />
            </Route>
          </>
        </ReactRoutes>
      </BrowserRouter>
    </>
  );
};

export default Routes;
