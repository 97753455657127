import React from 'react';
import { SaDrawer } from '../SaDrawer';
import { Button } from 'antd';
import { CopyOutlined, UploadOutlined } from '@ant-design/icons';
import { useAppMessage } from '@/hooks/useAppMessage';
import { shareLink, startAppParam } from '@/constants/startAppParam';
import { useUserStore } from '@/stores/useUserStore';
import base64url from 'base64url';
import { shareTgUrl } from '@/utils/telegram';

const Refer = (props: { open: boolean; onCancel: () => void }) => {
  const { jwtInfo } = useUserStore();
  const message = useAppMessage({ key: 'refer' });
  const param = base64url.encode(`?${startAppParam.ReferCode}=${jwtInfo?.id}`);
  const link = `${shareLink}?startapp=${param}`;

  const refer = () => {
    shareTgUrl(link);
  };

  const copyClick = async () => {
    try {
      await navigator.clipboard.writeText(link);

      message.success('Copy success');
    } catch (error) {
      message.error('Copy failed');
    }
  };

  return (
    <SaDrawer title="Receive" open={props.open} onClose={props.onCancel}>
      <div className="absolute bottom-6 w-[calc(100vw-24px)]">
        <Button
          type="primary"
          icon={<UploadOutlined />}
          className="w-full h-[52px] mb-3"
          onClick={refer}
        >
          Refer a friend
        </Button>
        <Button icon={<CopyOutlined />} className="w-full h-[52px]" onClick={copyClick}>
          Copy link
        </Button>
      </div>
    </SaDrawer>
  );
};

export default Refer;
