import request from '@/request/tomo';
import { TomoTokenItem } from '@/types/Tomo';

export interface TomoTokenDetail {
  address: string;
  decimals: number;
  symbol: string;
  name: string;
  extensions: {
    twitter: string;
    website: string;
    telegram: string;
  };
  logoURI: string;
  liquidity: number;
  price: number;
  supply: number;
  mc: number;
  history24hPrice: number;
  priceChange24hPercent: number;
  fdvUsd: number;
  high24h: number;
  low24h: number;
}
export interface SwapData {
  source: string;
  receive_amount: string;
  min_receive_amount: string;
  dex_name: string;
  transaction: Transaction;
  origin_data: string;
}

interface Transaction {
  approveTo: string;
  approveData: string;
  data: string;
  from: string;
  gas: string;
  gasPrice: string;
  to: string;
  value: string;
  maxPriorityFeePerGas: string;
  priorityGasPrice: any;
  maxGasPrice: any;
  maxFeePerGas: any;
}

export type TomoKlineData = {
  timestamp: number;
  open: number;
  high: number;
  low: number;
  close: number;
  volume: number;
};

export type Interval =
  | 'HOUR'
  | 'FOUR_HOUR'
  | 'DAY'
  | 'WEEK'
  | 'MONTH'
  | 'MINUTE'
  | 'FIVE_MINUTE'
  | 'FIFTEEN_MINUTE';

export function fetchAllToken() {
  return request.get<TomoTokenItem[]>(`/sky/api/socialLogin/teleGram/wallet/tokens`, null);
}

export function searchToken({ address, chain }: { address: string; chain?: string }) {
  return request.get<TomoTokenItem[]>(`/sky/api/socialLogin/teleGram/wallet/tokens/search`, {
    content: address,
    chain,
  });
}

export function fetchDetailToken({ address, chain }: { address: string; chain: string }) {
  return request.get<TomoTokenDetail>(`/cloud/v4/memes-overview`, {
    address,
    chain,
  });
}

export function fetchSwapData(payload: {
  fromChainid: number;
  toChainid: number;
  fromAddress: string;
  toAddress: string;
  amount: string;
  slippage: string;
  fromWalletAddress: string;
  toWalletAddress: string;
}) {
  return request.get<SwapData>(`/sun/v1/buildTx`, {
    ...payload,
  });
}

export function fetchTokenKline({
  tokenName,
  interval,
}: {
  tokenName: string;
  interval: Interval;
}) {
  return request.get<TomoKlineData[]>(`/sky/api/socialLogin/teleGram/${tokenName}/OHLCV`, {
    interval,
  });
}
