import { Button } from 'antd';
import { useCoinDetailStore } from '../../context/CoinDetailContext';

export function PoolComplete() {
  const { detail, isTonData } = useCoinDetailStore();

  return (
    <div>
      {/* TODO: */}
      {isTonData(detail) && detail?.stonfiPool && (
        <div className="mx-auto text-center mt-[10px]">
          Completed,Go to
          <Button
            className="mx-auto text-center px-0 ml-[3px]"
            type="text"
            onClick={() => {
              window.open(detail!.stonfiPool, '_blank');
            }}
          >
            STON.fi
          </Button>
        </div>
      )}
    </div>
  );
}
