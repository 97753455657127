import { G6Math } from '../contract/utils/Math';
import { fetchTonPrice } from '../service/common';
import { generatePayload } from '../service/proof';
import { useUserStore } from '../stores/useUserStore';
import { useIsConnectionRestored, useTonConnectUI } from '@tonconnect/ui-react';
import { useLocalStorageState, useRequest } from 'ahooks';
import { Modal } from 'antd';
import { createContext, useContext, useMemo } from 'react';
import { useUpdateUserChatId } from '../hooks/app/user/useUpdateUserChatId';
import { useAppMessage } from '../hooks/useAppMessage';
import { delay } from 'lodash';
import { useGetNativeBalance } from '@/hooks/app/useGetNativeBalance';
import { useWalletInfoStore } from '@/stores/useWalletInfoStore';
import { getSolPrice } from '@/service/sol-coin';

export const GlobalDataContext = createContext<{
  usdPrice: number;
  walletReady?: boolean;
  balance: bigint;
  isBalanceLoading: boolean;
  decimals: number;
  refreshProof?: () => void;
  setSlippage?: (val: string) => void;
  refreshBalanceAsync?(): Promise<bigint>;
  slippage: string;
  slippageValue: string;
  webApp?: WebApp;
}>({
  usdPrice: 0,
  balance: 0n,
  walletReady: false,
  isBalanceLoading: false,
  decimals: 9,
  slippage: '1',
  slippageValue: '0.03',
  webApp: undefined,
});

function reload() {
  // window.location.reload();
}

const localStorageKey = 'g6-dapp-auth-token';

export const useGlobalDataStore = () => useContext(GlobalDataContext);

export const useGlobalDataContext = () => {
  const { isTon } = useWalletInfoStore();

  const { data: usdPrice = 0 } = useRequest(
    async () => {
      try {
        if (isTon) {
          const { data } = await fetchTonPrice();

          return data.markets.find((item) => item.market === 'OKX')!.usd_price;
        } else {
          const res = await getSolPrice();
          return res.solPrice;
        }
      } catch {
        return 0;
      }
    },
    {
      retryCount: 5,
      retryInterval: 3000,
      refreshDeps: [isTon],
    },
  );

  const [tonConnectUI] = useTonConnectUI();

  const message = useAppMessage();

  const userStore = useUserStore();

  const { runAsync: runUpdate } = useUpdateUserChatId({});

  const isConnectionRestored = useIsConnectionRestored();

  const { loading: ready, refresh: refreshProof } = useRequest(
    () => {
      return generatePayload();
    },
    {
      onBefore() {
        tonConnectUI.setConnectRequestParameters({
          state: 'loading',
        });

        tonConnectUI.connector.onStatusChange((wallet) => {
          if (!wallet) {
            localStorage.removeItem(localStorageKey);

            delay(() => {
              refreshProof();
            }, 1000);

            return;
          }

          const token = localStorage.getItem(localStorageKey);

          if (token) {
            userStore.setToken(token);

            return;
          }

          const tonProof = wallet.connectItems?.tonProof;

          if (tonProof) {
            if ('proof' in tonProof) {
              console.log('wallet', wallet);

              // handleCheckProof(tonProof.proof);

              return;
            }
          }

          if (!userStore.token) {
            Modal.info({
              zIndex: 10000,
              centered: true,
              content: 'Token not found please reconnect',
              className: 'error-modal',
              icon: null,
              async onOk() {
                tonConnectUI.connector.disconnect();

                useUserStore.getState().loginOut();

                refreshProof?.();

                reload();
              },
            });
          }
        });
      },
      onSuccess(data) {
        tonConnectUI.setConnectRequestParameters({
          state: 'ready',
          value: {
            tonProof: data.data.payload,
          },
        });
      },
      onError(err) {
        console.log('err', err);
        message.error(err.message || 'Failed to generate payload');

        reload();
      },
      manual: true,
      // ready: !!userStore.token,
      refreshDeps: [userStore.token, isConnectionRestored],
    },
  );

  const { balance = 0n, balanceLoading, decimals, refreshBalanceAsync } = useGetNativeBalance();

  const [slippage, setSlippage] = useLocalStorageState('SA_SLIPPAGE', {
    defaultValue: '1',
  });

  const slippageValue = useMemo(() => {
    try {
      return new G6Math(slippage!).divide(100).toString();
    } catch (e) {
      return '0.01';
    }
  }, [slippage]);

  return {
    usdPrice,
    walletReady: ready,
    isBalanceLoading: balanceLoading,
    balance,
    decimals,
    refreshProof,
    refreshBalanceAsync,
    slippage: slippage!,
    setSlippage,
    slippageValue,
    webApp: window.Telegram.WebApp,
  };
};

const GlobalDataProvider = ({ children }: { children: React.ReactNode }) => {
  const data = useGlobalDataContext();

  return <GlobalDataContext.Provider value={data}>{children}</GlobalDataContext.Provider>;
};

export default GlobalDataProvider;
