import { ButtonGroup } from './ButtonGroup';
import { ConfigProvider, Skeleton } from 'antd';
import { useContext, useState } from 'react';

import { Buy } from './Buy';
import { Sell } from './Sell';
import { BuyUseStonFi } from './BuyUseStonFi';
import { CoinDetailContext } from '../../context/CoinDetailContext';
import { SellUseStonFi } from './SellUseStonFi';

export function Trade() {
  const [type, setType] = useState('BUY');

  const onValueChange = (val: any) => {
    setType(val);
  };

  const { isGraduated, loading } = useContext(CoinDetailContext);

  return (
    <ConfigProvider
      wave={{ disabled: true }}
      theme={{
        components: {
          Input: {
            colorBgContainer: '#fff',
            activeBg: '#fff',
            addonBg: '#fff',
            activeBorderColor: '#ffffff00',
            colorSplit: '#ffffff00',
            colorBorder: '#ffffff00',
            hoverBorderColor: '#ffffff00',
            colorText: '#000',
            borderRadius: 12,
            controlHeight: 48,
            fontSize: 16,
          },
        },
      }}
    >
      <Skeleton active loading={loading}>
        <ButtonGroup onValueChange={onValueChange} />

        {type === 'BUY' ? (
          isGraduated ? (
            <BuyUseStonFi />
          ) : (
            <Buy />
          )
        ) : isGraduated ? (
          <SellUseStonFi />
        ) : (
          <Sell />
        )}
      </Skeleton>
      {/*  */}
    </ConfigProvider>
  );
}
