import { useAccountMethods } from '@/contract/hooks/useAccountMethods';
import { useWalletInfoStore } from '@/stores/useWalletInfoStore';
import { getSolanaConnection } from '@/utils/sol';
import { PublicKey } from '@solana/web3.js';
import { useRequest } from 'ahooks';

export function useGetUserNativeBalance({
  address,
  enabled,
}: {
  address?: string;
  enabled: boolean;
}) {
  const { isTon } = useWalletInfoStore();
  const { getBalanceByAddress } = useAccountMethods();

  const tonHook = useRequest(() => getBalanceByAddress(address!), {
    ready: enabled && isTon && !!address,
    refreshDeps: [enabled, isTon, address],
  });

  const solHook = useRequest(
    async () => {
      const connection = getSolanaConnection();
      const publicKey = new PublicKey(address!);
      return BigInt((await connection.getBalance(publicKey)) ?? 0);
    },
    { ready: enabled && !isTon && !!address, refreshDeps: [enabled, isTon, address] },
  );

  return isTon ? tonHook : solHook;
}
