import { CoinsForYou } from '@/test';
import { SolCoinDetail } from '@/types/Coin';
import axios from 'axios';

const baseUrl = 'https://frontend-api.pump.fun';
// const baseUrl = '/pumpApi';

// lycrus
export async function getCoinForYou({
  offset = 0,
  limit = 50,
  nsfw = false,
}: {
  offset?: number;
  limit?: number;
  nsfw?: boolean;
} = {}) {
  const res = await axios.get<CoinsForYou[]>(
    baseUrl + `/coins/for-you?offset=${offset}&limit=${limit}&includeNsfw=${nsfw}`,
  );
  return res.data;
}

export async function getCoinDetail(mint: string) {
  const res = await axios.get<SolCoinDetail>(baseUrl + `/coins/${mint}`);
  return res.data;
}

export type SolCoinTradeItem = {
  is_buy: boolean;
  mint: string;
  profile_image: string;
  signature: string;
  slot: number;
  sol_amount: number;
  timestamp: number;
  token_amount: number;
  tx_index: number;
  user: string;
  username?: string;
};

export async function getCoinTrade({
  mint,
  limit = 200,
  offset = 0,
  minimumSize = 0,
}: {
  mint: string;
  limit?: number;
  offset?: number;
  minimumSize?: number;
}) {
  const res = await axios.get<SolCoinTradeItem[]>(
    baseUrl + `/trades/all/${mint}?limit=${limit}&offset=${offset}&minimumSize=${minimumSize}`,
  );
  return res.data;
}

export async function getSolPrice() {
  const res = await axios.get<{ solPrice: number }>(baseUrl + `/sol-price`);
  return res.data;
}
