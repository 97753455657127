import { Tabs as AntTabs, ConfigProvider } from 'antd';
import classNames from 'classnames';

export function Tabs({
  ...props
}: {
  className?: string;
  items: any[];
  activeKey: string;
  onChange: (key: string) => void;
  isMobile?: boolean;
  children?: any;
}) {
  return (
    <div
      className={classNames(props.className)}
      style={
        {
          WebkitBackdropFilter: `blur(${blur}px)`,
          backdropFilter: `blur(${blur}px)`,
        } as any
      }
    >
      {props.children}
      <ConfigProvider
        wave={{ disabled: true }}
        theme={{
          components: {
            Tabs: {
              inkBarColor: '#fff',
              itemActiveColor: '#fff',
              itemSelectedColor: '#fff',
              itemColor: '#656565',
              itemHoverColor: '#fff',
              horizontalItemGutter: 0,
              horizontalItemPadding: '13px 0',
            },
          },
        }}
      >
        <AntTabs
          centered
          items={props.items}
          activeKey={props.activeKey}
          className={props.className}
          onChange={props.onChange}
        ></AntTabs>
      </ConfigProvider>
    </div>
  );
}
