// import { Icon } from 'antd';
// import { fetchFollowers } from "@/service/follows";
import { Typography } from 'antd';
import { useParseRouterParam } from '@/hooks/router/useParseRouterParam';
import { useMemo, useRef, useState } from 'react';
import { ConnectButton } from '@/components/ConnectButton';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { FollowersModel } from './Modal/FollowersModel';
import { FollowingModel } from './Modal/FollowingModel';
import classNames from 'classnames';
import { FollowIcon } from '@/components/FollowIcon';
import { useGlobalDataStore } from '@/context/GlobalDataContext';
import { G6Math } from '@/contract/utils/Math';
import { useWalletInfoStore } from '@/stores/useWalletInfoStore';
import { useAppRouter } from '@/hooks/router/useAppRouter';
import { useProfileStore } from '@/context/ProfileContext';
import ChainSelector from '@/components/ChainSelector/ChainSelector';
import { CoinOperate } from '@/components/CoinOperate';
import numeral from 'numeral';

const { Paragraph } = Typography;

{
  /* <CopyToClipboard
text={EQAddress}
onCopy={() => {
  message.success('Copied');
}}
>
<div>
  <img src={copyIcon} className="w-6 h-6 cursor-pointer hover:opacity-80" />
</div>
</CopyToClipboard> */
}

// function Card({ children }: { children?: React.ReactNode }) {
//   return <div className="pt-[12px] md:px-4">{children}</div>;
// }

const StatisticItem = ({
  value,
  title,
  onClick,
}: {
  value: number | string;
  title: string;
  onClick?: () => void;
}) => {
  return (
    <div className="flex text-[14px] leading-[21px] gap-1" onClick={() => onClick?.()}>
      <div className="text-primaryText">{value}</div>
      <div className="text-secondaryText font-normal">{title}</div>
    </div>
  );
};

const AccountBalance = () => {
  const { isTon } = useWalletInfoStore();

  const { memoNativeBalance, currentBalance } = useProfileStore();

  const { usdPrice } = useGlobalDataStore();

  const jettonToTon = useMemo(() => {
    try {
      const val = new G6Math(currentBalance || 0n)
        .multiply(usdPrice)
        .divide(10n ** 9n)
        .toFixed(6);

      if (val === 'NaN') return '0.00';

      return val;
    } catch (err) {
      return '0.00';
    }
  }, [currentBalance, usdPrice]);

  return isTon
    ? new G6Math(memoNativeBalance || 0).multiply(usdPrice).add(jettonToTon).toFixed(2) || '0'
    : new G6Math(memoNativeBalance || 0).add(jettonToTon).toFixed(2);
};

export function AccountInfo() {
  const { param } = useParseRouterParam({ userId: '', address: '' });

  const followersModalRef = useRef<{ refreshFollowers: () => void }>();

  const { walletAddress } = useWalletInfoStore();

  const { goProfile } = useAppRouter();

  const [isShow, setIsShow] = useState(false);

  const [followersModalOpen, setFollowersModalOpen] = useState(false);

  const [followingModalOpen, setFollowingModalOpen] = useState(false);

  const { userInfo, isSelfUserInfo, isUserDetail, refreshUserInfo } = useProfileStore();

  const displayUserName = useMemo(() => {
    if (userInfo?.username) {
      return userInfo?.username;
    }
    return (userInfo?.firstName ?? '') + ' ' + (userInfo?.lastName ?? '');
  }, [userInfo?.firstName, userInfo?.lastName, userInfo?.username]);

  const followCallback = () => {
    refreshUserInfo();
    followersModalRef.current?.refreshFollowers();
  };

  // const { getBalance, ready } = useAccountMethods();

  // const { data: balance = toNano(0) } = useRequest(
  //   async () => {
  //     return getBalance().then((balance) => {
  //       return balance ? balance : toNano(0);
  //     });
  //   },
  //   {
  //     ready: ready,
  //   },
  // );

  const afterDisconnect = () => {
    if (param.userId) {
      goProfile('');
    }
  };

  return (
    <>
      <div
        className={classNames(' md:px-4', {
          'pt-[12px]': !isUserDetail,
        })}
      >
        <div className="grid gap-1 mb-[27px]">
          <div className="relative flex justify-between w-full gap-4 leading-6 item-start md:gap-0">
            <div className="grid justify-items-start gap-1 md:block !md:w-[200px] w-full">
              {isSelfUserInfo && (
                <div className="flex gap-2">
                  <ConnectButton afterDisconnect={afterDisconnect} />
                  <Paragraph
                    className="truncate w-9 h-9 rounded-full flex justify-center items-center border-solid border-[#242424] border-[1px] [&_path]:fill-primaryText [&_svg]:h-4 [&_svg]:w-4 !m-0"
                    copyable={{
                      text: walletAddress,
                    }}
                  />
                  <ChainSelector />
                </div>
              )}
              <div className="flex items-center justify-between">
                <div>
                  <div className="text-white font-bold mt-2 text-[24px] md:text-[28px] leading-[42px]">
                    <span>${isShow ? <AccountBalance /> : '*'}</span>
                    {isShow ? (
                      <EyeOutlined
                        style={{
                          fontSize: '16px',
                          color: '#9B9B9B',
                          marginLeft: '5px',
                          verticalAlign: 'super',
                        }}
                        onClick={() => {
                          setIsShow(false);
                        }}
                      />
                    ) : (
                      <EyeInvisibleOutlined
                        style={{
                          fontSize: '16px',
                          color: '#9B9B9B',
                          marginLeft: '5px',
                          verticalAlign: 'super',
                        }}
                        onClick={() => {
                          setIsShow(true);
                        }}
                      />
                    )}
                  </div>
                  <div className="flex justify-start items-start gap-2 py-1 text-[16px] md:text-[13px] leading-[15.6px] text-[#9B9B9B]">
                    <span>@{displayUserName}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="shrink-0">
              {userInfo?.address && displayUserName?.[0] && (
                <img
                  className="rounded-full"
                  src={`https://avatar.vercel.sh/${userInfo?.address}.svg?text= ${displayUserName?.[0]}&size=67`}
                />
              )}
            </div>
          </div>
          <div className="flex justify-between items-center pt-[20px] gap-2">
            <div className="flex justify-self-start justify-items-start gap-10">
              <StatisticItem
                value={numeral(userInfo?.followers || 0).format('0[.][00]a')}
                title="Followers"
                onClick={() => !!userInfo?.followers && setFollowersModalOpen(true)}
              />
              <StatisticItem
                value={numeral(userInfo?.following || 0).format('0[.][00]a')}
                title="Following"
                onClick={() => !!userInfo?.following && setFollowingModalOpen(true)}
              />
            </div>
            {!isSelfUserInfo && (
              <FollowIcon userId={userInfo?.id} onSuccessCallback={followCallback} />
            )}
          </div>
        </div>
        {isSelfUserInfo && <CoinOperate />}
      </div>
      <FollowersModel
        ref={followersModalRef}
        open={followersModalOpen}
        onClose={() => setFollowersModalOpen(false)}
      />
      <FollowingModel open={followingModalOpen} onClose={() => setFollowingModalOpen(false)} />
    </>
  );
}
