import { GlobalDataContext } from '@/context/GlobalDataContext';
import { useAppMessage } from '@/hooks/useAppMessage';
import { useResponsive } from '@/hooks/useResponsive';
import { Modal as AntModal, ConfigProvider, Button } from 'antd';
import { useG6RouterMethods } from '@/contract/hooks/useG6RouterMethods.tsx';
import { FileImageOutlined, LoadingOutlined } from '@ant-design/icons';
import { toNano } from '@ton/core';
import { useRequest } from 'ahooks';
import { Col, Form, GetProp, Input, Row, Upload, UploadProps } from 'antd';
import { useContext, useMemo, useState } from 'react';
import { ConnectButton } from '@/components/ConnectButton';
import { FormTheme } from '@/components/Theme/FormTheme';
import { useFetchFee } from '@/components/NewCoin/hooks/useFetchFee';
import { InitialPurchaseModal } from '@/components/NewCoin/InitialPurchase';
import { onPressEnterBlur } from '@/utils';
import { SendError } from '@/hooks/useSendTransactionByTomo';
import { useNativeToken } from '@/hooks/app/useNativeToken';
import { getJWTToken } from '@/stores/useUserStore';
import SuccessDrawer from './components/SuccessDrawer';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

type FieldType = {
  name: string;
  ticker: string;
  description: string;
  image: string;
  website?: string;
  twitter?: string;
  telegram?: string;
};

function isTwitterUrl(url: string) {
  const twitterRegex = /^(https?:\/\/)?(www\.)?(twitter\.com|x\.com)\/([a-zA-Z0-9_]+)/;

  return twitterRegex.test(url);
}

function isWebsiteUrl(url: string) {
  const websiteRegex =
    /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})(\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;%=]*)?$/;

  return websiteRegex.test(url);
}

function isTelegramUrl(url: string) {
  const telegramRegex =
    /^(https?:\/\/)?(www\.)?(t\.me|telegram\.me)\/[a-zA-Z0-9_]+$|^tg:\/\/resolve\?domain=[a-zA-Z0-9_]+$/;

  return telegramRegex.test(url);
}

const getBase64 = (img: FileType, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

export const onPressEnter = onPressEnterBlur;

function MobileForm() {
  return (
    <div className="flex flex-col flex-1">
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: 'Please input your name!' }]}
      >
        <Input placeholder="Enter Name" onPressEnter={onPressEnter} />
      </Form.Item>
      <Form.Item
        label="Ticker"
        name="ticker"
        rules={[{ required: true, message: 'Please input ticker!' }]}
      >
        <Input placeholder="Enter Ticker" onPressEnter={onPressEnter} />
      </Form.Item>
    </div>
  );
}

export default function Create() {
  const [symbolName] = useNativeToken();
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [initialAmount, setInitialAmount] = useState('');
  const [queryId, setQueryId] = useState<bigint | undefined>();
  const [form] = Form.useForm<FieldType>();
  const message = useAppMessage();

  const { handleLaunch } = useG6RouterMethods();

  const [imageUrl, setImageUrl] = useState<string | null>(null);

  const { refreshBalanceAsync } = useContext(GlobalDataContext);

  const { run, loading } = useRequest(
    async () => {
      const field = form.getFieldsValue();

      return handleLaunch({
        name: field.name,
        symbol: field.ticker,
        description: field.description,
        image: field.image,
        twitter: field.twitter,
        telegram: field.telegram,
        website: field.website,
        tonAmountToPayForInitBuy: toNano(initialAmount),
      });
    },
    {
      manual: true,
      onSuccess(res) {
        setQueryId(res);
        setInitialPurchaseOpen(false);
        setSuccessModalOpen(true);
      },
      onError(err) {
        console.log('handleLaunch-err', err);
        if (err.message === SendError.CANCEL) {
          return;
        }

        message.error(err.message || 'Failed to create coin');
      },
    },
  );

  const closeSuccessModal = () => {
    setSuccessModalOpen(false);
    form.resetFields();
    setImageUrl('');
    setInitialAmount('');
    refreshBalanceAsync?.();
  };

  const [initialPurchaseOpen, setInitialPurchaseOpen] = useState(false);

  const runCreate = () => {
    form
      .validateFields()
      .then(() => {
        setInitialPurchaseOpen(true);
      })
      .catch(() => null);
  };

  const [uploadLoading, setLoading] = useState(false);

  const UploadButton = (
    <div className="text-[#444]">
      {uploadLoading ? <LoadingOutlined /> : <FileImageOutlined />}
      <div style={{ marginTop: 8, fontSize: '12px' }}>Click to upload</div>
    </div>
  );

  const beforeUpload = (file: FileType) => {
    const isImage =
      file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';

    if (!isImage) {
      message.error('You can only upload JPG/PNG/GIF file!');
    }

    const isLt10M = file.size / 1024 / 1024 < 10;

    if (!isLt10M) {
      AntModal.info({
        zIndex: 10000,
        centered: true,
        content: 'Exceeding 10M will be compressed',
        className: 'error-modal',
        icon: null,
      });
    }

    return isImage;
  };

  const handleChange: UploadProps['onChange'] = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);

      return;
    }

    if (info.file.status === 'error') {
      setLoading(false);

      return message.error('Failed to upload image');
    }

    if (info.file.status === 'done') {
      form.setFieldValue('image', info.file.response.data.url);

      getBase64(info.file.originFileObj as FileType, (url) => {
        setLoading(false);

        setImageUrl(url);
      });
    }
  };

  const { isMobile } = useResponsive();

  const { fee } = useFetchFee({});

  const commonColProps = useMemo(() => {
    if (isMobile) {
      return {
        md: 24,
        xs: 24,
      };
    }

    return {};
  }, [isMobile]);

  return (
    <>
      <div className="text-[24px] leading-9 font-medium px-[16px] py-[6px] flex justify-between items-center text-primaryText">
        New Coin
      </div>
      <div className="p-3 new-coin pb-[120px]">
        <FormTheme>
          <div
            className="w-full md:max-h-[600px] md:overflow-y-auto overflow-x-hidden mb-6 md:mb-[6px]"
            style={isMobile ? {} : { borderBottom: '2px solid #EBEBEB' }}
          >
            <Form<FieldType> layout="vertical" form={form} autoComplete="off">
              <div className="flex justify-between gap-2">
                <Form.Item
                  label="Image"
                  name="image"
                  rules={[{ required: true, message: 'Please upload image' }]}
                >
                  <ConfigProvider
                    theme={{
                      token: {},
                    }}
                  >
                    <Upload
                      name="file"
                      listType="picture-card"
                      showUploadList={false}
                      multiple={false}
                      headers={{ Authorization: getJWTToken() || '' }}
                      action={`${process.env.REACT_APP_API}/ipfs/file`}
                      beforeUpload={beforeUpload}
                      onChange={handleChange}
                    >
                      {imageUrl ? (
                        <img
                          src={imageUrl}
                          className="rounded-[12px] max-h-[120px] object-contain"
                          alt="avatar"
                          style={{ width: '100%' }}
                        />
                      ) : (
                        UploadButton
                      )}
                    </Upload>
                  </ConfigProvider>
                </Form.Item>
                <MobileForm />
              </div>
              <Row gutter={[20, 0]}>
                <Col span={20} {...commonColProps}>
                  <Form.Item
                    label="Description"
                    name="description"
                    rules={[{ message: 'Please input description' }]}
                  >
                    <Input.TextArea
                      style={{
                        resize: 'none',
                        height: isMobile ? '96px' : '102px',
                      }}
                      placeholder="Say something about the token here"
                    />
                  </Form.Item>
                </Col>
                <Col span={16} {...commonColProps}>
                  <Form.Item
                    label="Website"
                    name="website"
                    rules={[
                      {
                        validator(rule, value, callback) {
                          if (!value) {
                            return callback();
                          }

                          if (!isWebsiteUrl(value)) {
                            return callback('Invalid website url');
                          }

                          return callback();
                        },
                      },
                    ]}
                  >
                    <Input placeholder="Optional" onPressEnter={onPressEnter} />
                  </Form.Item>
                </Col>
                <Col span={8} {...commonColProps}>
                  <Form.Item
                    label="Twitter"
                    name="twitter"
                    rules={[
                      {
                        validator(rule, value, callback) {
                          if (!value) {
                            return callback();
                          }

                          if (!isTwitterUrl(value)) {
                            return callback('Invalid twitter url');
                          }

                          return callback();
                        },
                      },
                    ]}
                  >
                    <Input placeholder="Optional" onPressEnter={onPressEnter} />
                  </Form.Item>
                </Col>
                {/* <Col span={8} {...commonColProps}>
                  <Form.Item
                    label="Telegram"
                    name="telegram"
                    rules={[
                      {
                        validator(rule, value, callback) {
                          if (!value) {
                            return callback();
                          }

                          if (!isTelegramUrl(value)) {
                            return callback('Invalid telegram url');
                          }

                          return callback();
                        },
                      },
                    ]}
                  >
                    <Input placeholder="Optional" onPressEnter={onPressEnter} />
                  </Form.Item>
                </Col> */}
              </Row>
            </Form>
          </div>
          <div className="text-[14px] pt-[2px]">
            <div className="flex items-center mb-5 justify-between">
              <span className="text-tertiaryText mr-[5px] font-normal">COST OF DEPLOY:</span>
              <span className="text-[16px]">
                {fee || '-'} {symbolName}
              </span>
            </div>

            <div className="md:w-[100%]">
              <ConnectButton block className="w-[275px] md:w-[100%]">
                <ConfigProvider
                  theme={{
                    components: {
                      Button: {
                        controlHeight: 48,
                      },
                    },
                  }}
                >
                  <Button
                    type="primary"
                    block
                    disabled={uploadLoading}
                    onClick={() => {
                      runCreate();
                    }}
                  >
                    Continue
                  </Button>
                </ConfigProvider>
              </ConnectButton>
            </div>
          </div>

          <InitialPurchaseModal
            amount={initialAmount}
            open={initialPurchaseOpen}
            imageUri={imageUrl!}
            form={form}
            loading={loading}
            setAmount={setInitialAmount}
            onClose={() => {
              setInitialPurchaseOpen(false);
            }}
            onCreate={run}
          />
        </FormTheme>
      </div>
      <SuccessDrawer
        queryId={queryId}
        form={form}
        initialAmount={initialAmount}
        open={successModalOpen}
        onClose={closeSuccessModal}
      />
    </>
  );
}
