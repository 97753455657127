import { List, Avatar } from 'antd';
import { useFetchUserCreateCoins } from '../hooks/useFetchUserCreateCoins';
import { useAppRouter } from '@/hooks/router/useAppRouter';
import dayjs from 'dayjs';
import { useProfileStore } from '@/context/ProfileContext';

type CreatedItem = {
  name: string;
  imageUri: string;
  description: string;
  createdTimestamp: number;
  poolAddress: string;
};

export function CreatedItem({ item }: { item: CreatedItem }) {
  const { goCoinDetail } = useAppRouter();

  const handleSeeThreadClick = () => {
    goCoinDetail({ poolAddress: item.poolAddress });
  };

  function formatTime(time: number) {
    return dayjs.unix(time).format('YYYY-MM-DD HH:mm:ss');
  }

  return (
    <List.Item
      className="h-full bg-secondaryBackground"
      style={{
        padding: '0',
        margin: '0 12px 8px 12px',
        borderBottomWidth: '0',
        borderRadius: '12px',
      }}
    >
      <div
        className="flex items-center"
        onClick={() => {
          handleSeeThreadClick();
        }}
      >
        <Avatar
          className="shrink-0"
          size={113}
          style={{ margin: '4px', borderRadius: '8px' }}
          src={item.imageUri}
        />
        <div className="ml-2 flex flex-col justify-between max-h-[121px] py-[14px] min-h-[121px]">
          <div className="text-[15px] leading-[15px] text-white">
            <span className="font-medium">{item.name}</span>
            <span className="bg-[#282828] inline-block rounded-sm py-[3px] px-2 mx-1 text-[12px]">
              Dev
            </span>
            <div className="text-[12px] leading-[14.4px] mt-1 text-[#9B9B9B] break-all line-clamp-2">
              {item.description}
            </div>
          </div>
          <div className="text-[#9B9B9B] mt-1 text-[12px] leading-[14.4px]">
            {formatTime(item.createdTimestamp)}
          </div>
        </div>
      </div>
    </List.Item>
  );
}

export function CoinsCreated() {
  const { userInfo } = useProfileStore();
  const { data, loading } = useFetchUserCreateCoins(userInfo?.address);

  return (
    <List
      loading={loading}
      itemLayout="horizontal"
      dataSource={data}
      renderItem={(item) => <CreatedItem item={item} />}
    />
  );
}
