import { TonConnectUIProvider } from '@tonconnect/ui-react';
import { GlobalWarp } from './components/GlobalWarp';
import Routes from './routes';
import { App as AntdApp, ConfigProvider, theme } from 'antd';
import locale from 'antd/es/locale/en_GB';
import WalletInfoProvider from './stores/useWalletInfoStore';
import { WagmiProvider } from 'wagmi';
import { config } from './config/wagmi-config';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

// const generateLargeData = (sizeInMB: number) => {
//   const sizeInBytes = sizeInMB * 1024 * 1024;
//   const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
//   let result = '';

//   for (let i = 0; i < sizeInBytes; i++) {
//     result += chars.charAt(Math.floor(Math.random() * chars.length));
//   }

//   return result;
// };

function App() {
  return (
    <TonConnectUIProvider
      manifestUrl={process.env.REACT_APP_TON_MANIFEST_URL}
      language="en"
      walletsListConfiguration={
        {
          // includeWallets: [
          //   {
          //     appName: 'Trust Wallet',
          //     name: 'Trust Wallet',
          //     imageUrl:
          //       'https://storage.googleapis.com/ton-strapi/Trust_Wallet_faebe816dc/Trust_Wallet_faebe816dc.svg',
          //     aboutUrl: 'https://trustwallet.com',
          //     universalLink: 'https://link.trustwallet.com',
          //     jsBridgeKey: 'trustwallet',
          //     platforms: ['ios', 'android'],
          //   },
          // ],
        }
      }
    >
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <ConfigProvider
            renderEmpty={() => (
              <span className="text-white inline-block w-full text-center">Empty~</span>
            )}
            theme={{
              algorithm: theme.darkAlgorithm,
              components: {
                Button: {
                  controlHeight: 48,
                  borderRadius: 41,
                },
                Input: {
                  controlHeight: 40,
                  borderRadius: 44,
                },
              },
              token: {
                fontFamily: 'Poppins',
                colorPrimary: '#483FFF',
              },
            }}
            prefixCls="g6"
            locale={locale}
          >
            <AntdApp className="w-full h-full">
              <WalletInfoProvider>
                <GlobalWarp>
                  <Routes />
                </GlobalWarp>
              </WalletInfoProvider>
            </AntdApp>
          </ConfigProvider>
        </QueryClientProvider>
      </WagmiProvider>
    </TonConnectUIProvider>
  );
}

export default App;
