import request from '@/request';
import { useRequest } from 'ahooks';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { G6Math } from '@/contract/utils/Math';
import classNames from 'classnames';
import { Skeleton, Tag } from 'antd';
import { Coin } from '@/types/Coin';
import { AppKline } from '@/components/AppKline';
import { fetchTokenKline, Interval, TomoKlineData } from '@/service/tomo';
import { LoadingOutlined } from '@ant-design/icons';
import { ReactComponent as NoData } from '@/assets/icon/app/no-data.svg';

function parseValue(value: string) {
  return G6Math.from(value)
    .multiply(10 ** 18)
    .toNumber();
}

type DateType = Interval;

const dateOptions = [
  // {
  //   label: '1m',
  //   value: 'MINUTE',
  // },
  // {
  //   label: '5m',
  //   value: 'FIVE_MINUTE',
  // },
  // {
  //   label: '15m',
  //   value: 'FIFTEEN_MINUTE',
  // },
  {
    label: '1H',
    value: 'HOUR',
  },
  {
    label: '4H',
    value: 'FOUR_HOUR',
  },
  {
    label: '1D',
    value: 'DAY',
  },
  {
    label: '1W',
    value: 'WEEK',
  },
  {
    label: '1M',
    value: 'MONTH',
  },
];

function parseData({ data, type }: { data: TomoKlineData[]; type: DateType }) {
  const val =
    data?.map((item) => {
      return {
        time: formatXAxis(item.timestamp, type)!,
        ...item,
        volume: item.volume,
      };
    }) || [];

  return val;
}

function formatXAxis(value: number, type: DateType) {
  switch (type) {
    case 'HOUR':
    case 'FOUR_HOUR':
      return dayjs(value).format('MM/DD HH:mm');
    case 'DAY':
      return dayjs(value).format('MM-DD');
    case 'WEEK':
      return dayjs(value).format('MM-DD');
    case 'MONTH':
      return dayjs(value).format('MM-DD');
    default:
      return dayjs(value).format('MM-DD');
  }
}

export function TomoCustomKLine({ tokenName }: { tokenName?: string }) {
  const [dateType, setDateType] = useState<DateType>('HOUR');

  const [loading, setLoading] = useState(true);

  const { data } = useRequest(
    () => {
      return fetchTokenKline({
        tokenName: tokenName!,
        interval: dateType,
      });
    },
    {
      ready: !!tokenName,
      refreshDeps: [dateType],
      onSuccess(data) {
        console.log('kdata----------->', data);
        setLoading(false);
      },
      onError(err) {
        setLoading(false);
        console.log('kdata error----------->', err);
      },
    },
  );

  const klineData = useMemo(() => {
    if (!data) return [];

    return parseData({
      data: data!.result,
      type: dateType,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <div className="w-full overflow-hidden">
        {loading ? (
          <Skeleton.Node
            active={true}
            className="!w-full !h-[350px] left-0 top-0 z-20 !rounded-[0px]"
          >
            <LoadingOutlined className="hidden" />
          </Skeleton.Node>
        ) : data?.result.length ? (
          <AppKline
            detail={{ name: tokenName }}
            data={klineData}
            enableFormatters={false}
          ></AppKline>
        ) : (
          <div className="flex justify-center items-center flex-col text-secondaryText py-[100px]">
            <NoData />
            <div>No Data</div>
          </div>
        )}
      </div>

      <div className="flex justify-start items-center gap-x-[25px] overflow-x-auto">
        {dateOptions.map((item) => {
          return (
            <Tag.CheckableTag
              key={item.value}
              className={classNames(
                'active:text-[#282828] bg-transparent rounded-full px-4 py-[6px] text-secondaryText text-3 leading-[12px]',
                dateType === item.value && '!text-[#000] border-primaryText !bg-primaryText',
              )}
              checked={item.value === dateType}
              onChange={() => setDateType(item.value as any)}
            >
              {item.label}
            </Tag.CheckableTag>
          );
        })}
      </div>
    </>
  );
}
