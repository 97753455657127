import { storageKeys } from '@/constants/storage';
import preferences from '@/preferences';
import { UserWallets, JwtInfo, User } from '@/types/User';
import { create } from 'zustand';

export const getJWTToken = () => {
  return preferences.get<string | null>(storageKeys.token);
};

interface UserStateState {
  token: string | null;

  info: User | null;

  profileModalOpen: boolean;

  replyModalOpen: boolean;

  jwtInfo?: JwtInfo;

  userWallets?: UserWallets[];

  setToken: (token?: string) => void;

  setInfo: (info?: User) => void;

  setProfileModalOpen: (open: boolean) => void;

  setReplyModalOpen: (open: boolean) => void;

  loginOut: () => void;

  setJwtInfo: (v: JwtInfo) => void;

  setUserWallets: (v: UserWallets[]) => void;
}

export const useUserStore = create<UserStateState>((set) => ({
  token: getJWTToken(),

  info: null,

  profileModalOpen: false,

  replyModalOpen: false,

  jwtInfo: undefined,

  userWallets: undefined,

  setToken(token) {
    if (!token) return;

    preferences.set(storageKeys.token, token);

    set({ token });
  },

  setInfo(info) {
    set({ info });
  },

  setProfileModalOpen(open) {
    set({ profileModalOpen: open });
  },

  // TODO move component
  setReplyModalOpen(open) {
    set({ replyModalOpen: open });
  },

  loginOut() {
    set((state) => {
      state.info = null;

      return state;
    });
  },

  setJwtInfo(jwtInfo) {
    set({ jwtInfo });
  },

  setUserWallets(userWallets) {
    set({ userWallets });
  },
}));
