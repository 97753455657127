import { fetchG6List } from '@/service/coin';
import { useRequest } from 'ahooks';

export function useFetchG6List({
  createdTimestampEnd,
  createdTimestampStart,
  nsfw,
  search,
  filter,
  sort,
  size,
}: {
  createdTimestampEnd?: number;
  createdTimestampStart?: number;
  nsfw?: boolean;
  search?: string;
  filter?: string;
  sort?: string;
  size: number;
}) {
  const hook = useRequest(
    () => {
      return fetchG6List({
        current: 1,
        size: size,
        createdTimestampEnd,
        createdTimestampStart,
        nsfw,
        search,
        filter,
        sort,
      });
    },
    {
      refreshDeps: [createdTimestampEnd, createdTimestampStart, nsfw, search, filter, sort, size],
    },
  );

  return hook;
}
