import { authenticateUser } from '@/service/user';
import { useUserStore } from '@/stores/useUserStore';
import { useRequest } from 'ahooks';
import { jwtDecode } from 'jwt-decode';

export function useAuthenticateUser() {
  const { setToken, setJwtInfo } = useUserStore();

  const { runAsync: authenticate } = useRequest(
    async () => {
      const res = await authenticateUser(
        window.Telegram.WebApp?.initData ||
          'user=%7B%22id%22%3A6219163550%2C%22first_name%22%3A%22yan%22%2C%22last_name%22%3A%22lu%22%2C%22language_code%22%3A%22zh-hans%22%2C%22allows_write_to_pm%22%3Atrue%7D&chat_instance=1091111940802718391&chat_type=private&auth_date=1731332894&hash=21e9cf828904aafef31ebf8f9ce9c3a38ca91e07c54a7212f8c9e7a9b26ee690',
      );
      setToken(res.token);
      setJwtInfo(jwtDecode(res.token));
    },
    {
      manual: true,
      // ready: !!window.Telegram.WebApp.initData,
    },
  );

  return { authenticate };
}
