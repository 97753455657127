import { useRequest } from 'ahooks';
import { useTransactionContext } from '@/context/TransactionContext';
import { ChainType, useWalletInfoStore } from '@/stores/useWalletInfoStore';
import { useSendTransaction } from '../useSendTransaction';

function log({ type, param }: { type?: string; param?: any }) {
  console.log('start------------------------');

  console.log('type:', type);

  console.log('param', param);

  console.log('end------------------------');
}

export function useSendTransactionByTomoV2({
  to,
  amount,
  walletAddress,
  payload,
  openSuccessModal = true,
  onSendSuccess,
  onSendError,
}: {
  to: string;
  amount: string;
  walletAddress: string;
  value?: string;
  payload?: any;
  openSuccessModal?: boolean;
  onSendSuccess?: () => void;
  onSendError?: (err: any) => void;
}) {
  const { chainType } = useWalletInfoStore();

  const { sendTransaction } = useSendTransaction({ openSuccessModal });

  const { runAsync: send, loading } = useRequest(
    async ({ data }: { data?: string } = {}) => {
      // if (!userInfo) return Promise.reject('Invalid user');

      if (!amount) return Promise.reject('Invalid amount');

      if (chainType !== ChainType.SOLANA) {
        if (!to) return Promise.reject('Invalid address');
      }

      switch (chainType) {
        // case 'BITCOIN':
        //   if (!getBitcoinAddressType(to!)) return Promise.reject('Invalid address');

        //   log({
        //     type: 'sendBtcTransaction',
        //     param: {
        //       network: 'MAINNET',
        //       addressType: getBitcoinAddressType(to!)!,
        //       value: amount,
        //       toAddress: to,
        //       mfaType: MFAType.password,
        //       password,
        //     },
        //   });

        //   return sendBtcTransaction({
        //     network: 'MAINNET',
        //     addressType: getBitcoinAddressType(to!)!,
        //     value: amount,
        //     toAddress: to,
        //     mfaType: MFAType.password,
        //     password,
        //   });

        case ChainType.TON:
          log({
            type: 'sendTonTransaction',
            param: {
              fromAddress: walletAddress,
              amount,
              toAddress: to!,
            },
          });

          return sendTransaction({
            sendTransactionRequest: {
              messages: [
                {
                  address: to,
                  amount,
                  payload,
                },
              ],
              validUntil: Math.floor(Date.now() / 1000) + 60, // 60 sec
              from: walletAddress,
            },
          });

        // case 'SOLANA':
        //   log({
        //     type: 'sendSolTransaction',
        //     param: {
        //       fromAddress: walletAddress,
        //       toAddress: to,
        //       value: token!.isNative
        //         ? parseUnits(G6Math.from(amount).add('0.03').toString(), token!.decimals)
        //         : toNano('0.03'),
        //       token: {
        //         chainId: chains[token!.chain].chainId!,
        //         image: token!.imageUrl,
        //         name: token!.name,
        //         symbol: token!.symbol,
        //         decimals: token!.decimals,
        //         address: token!.address as any,
        //       },
        //       mfaType: MFAType.password,
        //       password,
        //       data: data,
        //     },
        //   });

        //   return sendSolTransaction({
        //     fromAddress: walletAddress,
        //     toAddress: to,
        //     value: token!.isNative ? parseUnits(amount, token!.decimals) : toNano('0.03'),
        //     token: {
        //       chainId: chains[token!.chain].chainId!,
        //       image: token!.imageUrl,
        //       name: token!.name,
        //       symbol: token!.symbol,
        //       decimals: token!.decimals,
        //       address: token!.address as any,
        //     },
        //     mfaType: MFAType.password,
        //     password,
        //     data: data,
        //   });

        // default:
        // if (!chains[token!.chain].chainId) return Promise.reject('Invalid chain');

        // log({
        //   type: 'EVM',
        //   param: {
        //     chainId: chains[token!.chain].chainId! as any,
        //     fromAddress: walletAddress,
        //     toAddress: to,
        //     value: token!.isNative
        //       ? toHex(parseEther(amount, token!.decimals === 9 ? 'gwei' : 'wei'))
        //       : toHex(parseUnits(amount, token!.decimals)),
        //     data,
        //     mfaType: MFAType.password,
        //   },
        // });

        // const transactionParameters = {
        //   to,
        //   from: walletAddress,
        //   value: token!.isNative
        //     ? toHex(parseEther(amount, token!.decimals === 9 ? 'gwei' : 'wei'))
        //     : toHex(parseUnits(amount, token!.decimals)),
        //   data,
        //   chainId: toHex(chains[token!.chain].chainId!),
        // };

        // const rpcUrls = supportChains.find((item) => item.id === chains[token!.chain].chainId!)
        //   ?.rpcUrls.default.http[0];

        // const provider = new ethers.JsonRpcProvider(rpcUrls);
        // const { result } = await ethereum.request({
        //   method: 'eth_signETHTransaction',
        //   params: [transactionParameters],
        // });

        // return await provider.broadcastTransaction(result);
      }
    },
    {
      manual: true,
      onSuccess(res) {
        console.log('transaction(useSendTransactionByTomo)-response---------->', res);

        openSuccessModal && openModal('success');

        onSendSuccess && onSendSuccess();
      },
      onError(err) {
        console.log('transaction(useSendTransactionByTomo)-err---------->', err, err?.message);

        openModal('error');

        onSendError && onSendError(err);
      },
    },
  );

  const { startAuth, openModal } = useTransactionContext();

  // const send = ({ data }: { data?: string } = {}) => {
  //   return new Promise((resolve, reject) => {
  //     startAuth(MFAType.password, (password) => {
  //       if (!password) return reject(new Error(SendError.CANCEL));

  //       console.log('password', password);

  //       runAsync({
  //         password,
  //         data,
  //       })
  //         .then((res) => {
  //           resolve(res);
  //         })
  //         .catch(reject);
  //     });
  //   });
  // };

  return {
    send,
    loading,
  };
}
