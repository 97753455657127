import { useAppRouter } from '@/hooks/router/useAppRouter';
import { useAppMessage } from '@/hooks/useAppMessage';
import { useTomoWallet } from '@/hooks/useTomoWallet';
import { ExportOutlined, KeyOutlined, LeftOutlined, MailOutlined } from '@ant-design/icons';
import { Button, ConfigProvider } from 'antd';
import { NavLink } from 'react-router-dom';

export function AccountSetting() {
  const { goBack } = useAppRouter();

  const { goVerifyEmail } = useAppRouter();

  const { userInfo } = useTomoWallet();

  const message = useAppMessage({ key: 'AccountSetting' });

  return (
    <div className="w-full py-[18px] px-4">
      <ConfigProvider
        theme={{
          components: {
            Button: {
              controlHeight: 36,
            },
          },
        }}
      >
        <Button
          type="text"
          className="text-secondaryText ml-[-15px]"
          onClick={() => {
            goBack();
          }}
        >
          <LeftOutlined /> Back
        </Button>
      </ConfigProvider>

      <div className="py-5 px-4 bg-secondaryBackground rounded-2xl flex justify-start items-start flex-col gap-y-[20px]">
        <div className="pb-4 border-0 border-b border-solid border-primaryBorder w-full">
          <div className="text-secondaryText"> Email</div>
          <div className="mt-4 break-all">
            <MailOutlined /> {userInfo?.email || '-'}
          </div>
        </div>

        <div className="pb-4 border-0 border-b border-solid border-primaryBorder w-full">
          <div className="text-secondaryText">Password</div>
          <div className="mt-4 flex justify-between items-center">
            <KeyOutlined />
            <div
              className="text-red active:opacity-85"
              onClick={() => {
                message.info('Coming soon');
                // goVerifyEmail();
              }}
            >
              Reset
            </div>
          </div>
        </div>

        <div className="pb-4 border-0 border-b border-solid border-primaryBorder w-full">
          <div className="text-secondaryText">Export</div>
          <NavLink to="/mnemonicPhrase">
            <div className="mt-4">
              <ExportOutlined /> Mnemonic phrase
            </div>
          </NavLink>
        </div>
      </div>
    </div>
  );
}
