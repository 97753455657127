import request from '@/request';
import { ListQuery } from '@/types/App';
// import { Trade } from '@/types/Trade';

export type TradeHistoryItem = {
  _id: string;
  user: string;
  username: string;
  address: string;
  tonAmount: number;
  jettonAmount: number;
  isBuy: boolean;
  timestamp: number;
  hash: string;
};

export type ListResult<T> = {
  docs: T[];
  hasNextPage: boolean;
  hasPrevPage: false;
  limit: number;
  nextPage: number;
  page: number;
  pagingCounter: number;
  prevPage: number;
  totalDocs: number;
  totalPages: number;
};

export type ApiTXResult = { status: 'success' | 'failed' | 'not_found' };

export async function fetchCoinTrade(poolAddress: string, { current, size }: ListQuery) {
  const res = await request.get<ListResult<TradeHistoryItem>>(`/trade/${poolAddress}`, {
    current,
    size,
  });
  return res.data;
}

export function fetchTXResult(queryId: string, type: 'buy' | 'sell') {
  return request.get<ApiTXResult>(`/sync/result/${queryId}`, { type });
}
