import React, { createContext, useContext, useState, ReactNode } from 'react';
import {
  TransactionModal as AppTransactionModal,
  TransactionCustomContent,
} from '@/components/TransactionModal';
import { AuthModal } from '@/components/AuthModal';
import { MFAType } from '@/types/App';
import { TransactionDetailModal } from '@/components/TransactionDetailModal';
import { TonSendPayload } from '@/hooks/useSendTransactionByTomo';

export type TransactionModalType =
  | 'gas'
  | 'network'
  | 'success'
  | 'pending'
  | 'trade-password'
  | 'error'
  | 'custom';

interface ModalContextProps {
  openModal: (type: TransactionModalType, customContent?: TransactionCustomContent) => void;
  closeModal: () => void;
  setType: (type: TransactionModalType) => void;
  startAuth: (mfa: MFAType, onConfirm: (password?: string) => void) => void;
  startConfirmAuth: (payload: {
    onConfirm: (password?: string) => void;
    data: TonSendPayload;
  }) => void;
  closeDetail: () => void;
  transactionLoading: boolean;
  setTransactionLoading: (loading: boolean) => void;
}

const ModalContext = createContext<ModalContextProps | undefined>(undefined);

export const TransactionContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [open, setOpen] = useState(false);

  const [type, setType] = useState<TransactionModalType>('gas');

  const [customContent, setCustomContent] = useState<TransactionCustomContent>();

  const [authOpen, setAuthOpen] = useState(false);

  const [onAuthConfirm, setOnAuthConfirm] = useState<(password?: string) => void | null>();

  const [detailOpen, setDetailOpen] = useState(false);

  const [sendPayload, setSendPayload] = useState<TonSendPayload | null>(null);

  const [transactionLoading, setTransactionLoading] = useState<boolean>(false);

  const openModal = (type: TransactionModalType, customContent?: TransactionCustomContent) => {
    setType(type);
    type === 'custom' && setCustomContent(customContent);
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const startAuth = (mfa: MFAType, onConfirm: (password?: string) => void) => {
    setAuthOpen(true);

    setOnAuthConfirm(() => onConfirm);
  };

  const handleAuthConfirm = (password: string) => {
    if (onAuthConfirm) {
      onAuthConfirm(password);
    }
    setAuthOpen(false);
  };

  const startConfirmAuth = ({
    onConfirm,
    data,
  }: {
    onConfirm: (password?: string) => void;
    data: TonSendPayload;
  }) => {
    setDetailOpen(true);

    setSendPayload(data);

    console.log('data', data);

    setOnAuthConfirm(() => onConfirm);
  };

  const closeDetail = () => {
    setDetailOpen(false);
  };

  return (
    <ModalContext.Provider
      value={{
        transactionLoading,
        openModal,
        closeModal,
        setType,
        startAuth,
        startConfirmAuth,
        closeDetail,
        setTransactionLoading,
      }}
    >
      {children}
      <AppTransactionModal
        type={type}
        open={open}
        customContent={customContent}
        onClose={closeModal}
      />

      <AuthModal open={authOpen} onClose={() => setAuthOpen(false)} onConfirm={handleAuthConfirm} />

      <TransactionDetailModal
        transactionLoading={transactionLoading}
        open={detailOpen}
        onClose={() => {
          onAuthConfirm?.('');
          setDetailOpen(false);
        }}
        onConfirm={() => {
          setAuthOpen(true);
        }}
        sendPayload={sendPayload}
      />
    </ModalContext.Provider>
  );
};

export const useTransactionContext = (): ModalContextProps => {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error('useTransactionContext must be used within a TransactionContextProvider');
  }

  return context;
};
