import { useAppRouter } from '@/hooks/router/useAppRouter';
import { useParseRouterQuery } from '@/hooks/router/useParseRouterQuery';
import { LeftOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import { useState } from 'react';
import { CoinList } from '../Home/modules/Departure/All/components/CoinList';

export default function Search() {
  const [search, setSearch] = useState('');

  const { goBack } = useAppRouter();

  const { query, updateQuery } = useParseRouterQuery({
    search: '',
  });

  return (
    <>
      <div className="w-full h-[48px] flex fixed top-0 z-10 bg-primaryBackground justify-between items-center">
        <Button
          onClick={() => {
            goBack();
          }}
          type="text"
          className="shrink-0 text-[20px]"
          shape="circle"
        >
          <LeftOutlined />
        </Button>

        <Input
          placeholder="Token name or address"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              updateQuery({ search });
            }
          }}
        ></Input>

        <Button
          onClick={() => {
            updateQuery({ search });
          }}
          type="text"
          className="shrink-0 text-primaryText font-[400] text-[15px]"
        >
          Search
        </Button>
      </div>

      <div className="mt-12 overflow-y-auto">{query.search && <CoinList inSearch></CoinList>}</div>
    </>
  );
}
