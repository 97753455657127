import { getTomoMockData } from '@/mock/tomo';
import { UserInfo } from '@/types/Tomo';
import { toInternalAddress, toUserFriendlyAddress } from '@/utils';
import { useMemo } from 'react';
import { displayAddress as parseDisplayAddress } from '../utils/ton';
import { useTomoSdk } from './useTomoSdk';

export function isSameAddress({ address1, address2 }: { address1: string; address2?: string }) {
  return !!address1 && !!address2 && toInternalAddress(address1) === toInternalAddress(address2);
}

export function useTomoWallet() {
  const { tomo } = useTomoSdk();

  const userInfo = useMemo<UserInfo | undefined>(() => {
    return getTomoMockData().userInfo || (tomo?.userInfo as UserInfo);
  }, [tomo?.userInfo]);

  const userAddress = useMemo(() => {
    return userInfo?.tonAddress;
  }, [userInfo]);

  const isConnect = useMemo(() => {
    return !!userAddress;
  }, [userAddress]);

  const displayAddress = useMemo(() => {
    return parseDisplayAddress(userAddress);
  }, [userAddress]);

  const disconnect = () => {
    return tomo.onLogout();
  };

  // api use this address
  const internalAddress = useMemo(() => {
    try {
      return toInternalAddress(userAddress);
    } catch (err) {
      return '';
    }
  }, [userAddress]);

  const userFriendlyAddress = useMemo(() => {
    try {
      return toUserFriendlyAddress(userAddress);
    } catch (err) {
      return '';
    }
  }, [userAddress]);

  function isSelf(address: string) {
    return isSameAddress({ address1: userAddress!, address2: address });
  }

  return {
    isConnect,
    address: userAddress,
    displayAddress,
    disconnect,
    internalAddress,
    userFriendlyAddress,
    isSelf,
    isTomoWallet: !!userInfo,
    userInfo,
  };
}
