import { CloseOutlined, LeftOutlined } from '@ant-design/icons';
import { Button, ConfigProvider, Drawer } from 'antd';

export function SaDrawer({
  title,
  children,
  open,
  onClose,
  height,
  titleCenter,
  canClose = true,
}: {
  title?: any;
  children?: any;
  open?: boolean;
  onClose?: () => void;
  height?: string;
  titleCenter?: boolean;
  canClose?: boolean;
}) {
  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            controlHeight: 36,
          },
          Drawer: {
            borderRadius: 12,
            colorBgElevated: '#121212',
          },
        },
      }}
    >
      <Drawer
        open={open}
        placement="bottom"
        height={height || '100%'}
        closable={false}
        className="[&_.g6-drawer-body]:!px-[12px] [&_.g6-drawer-body]:!pt-0 !rounded-t-[12px] bg-primaryBackground"
      >
        <div className="flex justify-start items-start flex-col w-full h-full">
          {titleCenter ? (
            <div className="flex justify-center items-center relative h-[68px] shrink-0 w-full">
              <Button
                type="text"
                className="absolute left-0 text-[20px]"
                shape="circle"
                onClick={onClose}
              >
                <LeftOutlined />
              </Button>

              <div className="text-primaryText text-[20px] font-medium">{title}</div>
            </div>
          ) : (
            <div className="flex justify-between items-center h-[68px]  shrink-0 w-full">
              <div className="text-primaryText text-[20px] font-medium">{title}</div>

              {canClose && (
                <Button type="default" shape="circle" onClick={onClose}>
                  <CloseOutlined />
                </Button>
              )}
            </div>
          )}

          <div className="w-full h-full shrink">{children}</div>
        </div>
      </Drawer>
    </ConfigProvider>
  );
}
