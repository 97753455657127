import { useAppRouter } from '@/hooks/router/useAppRouter';
import { CheckCircleFilled } from '@ant-design/icons';
import { Button } from 'antd';

const Confirmed = ({ onCancel }: { onCancel: () => void }) => {
  const { goHome } = useAppRouter();

  const toHome = () => {
    onCancel();
    goHome();
  };

  return (
    <div>
      <div className="flex pt-[145px] items-center flex-col">
        <div className="bg-primaryText rounded-full">
          <CheckCircleFilled className="text-[64px] text-[#17CF82]" />
        </div>
        <div className="text-[24px] leading-[32px] font-semibold text-primaryText mt-4 mb-2">
          Submit successfully
        </div>
        <div className="text-center text-[12px] leading-4 font-normal text-[#666666]">
          Your transaction has been uploaded to the blockchain. It will take 1-3 minutes to
          complete. Please check the history for details.
        </div>
      </div>
      <Button
        type="primary"
        className="absolute bottom-6 h-12 text-[16px] font-medium w-[calc(100%-24px)]"
        onClick={toHome}
      >
        Back to home
      </Button>
    </div>
  );
};

export default Confirmed;
