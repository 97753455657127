import { FetchCoinPayload, fetchCoinResult } from '@/service/coin';
import { Coin } from '@/types/Coin';
import { useRequest } from 'ahooks';

export function useFetchCreateResult(
  props: FetchCoinPayload & { enabled: boolean; onSuccess: (v: Coin) => void },
) {
  const hook = useRequest(() => fetchCoinResult(props), {
    ready: props.enabled && !!props.name && !!props.queryId && !!props.ticker,
    pollingInterval: 5 * 1000,
    onSuccess: props.onSuccess,
  });

  return hook;
}
