import { ConnectButton } from '@/components/ConnectButton';
import { useAppRouter } from '@/hooks/router/useAppRouter';
import { SearchOutlined } from '@ant-design/icons';
import { Button, ConfigProvider } from 'antd';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import Search from '../Search';
import { useWalletInfoStore } from '@/stores/useWalletInfoStore';
import ChainSelector from '@/components/ChainSelector/ChainSelector';

export function Header() {
  const { goSearch } = useAppRouter();

  const { walletAddress: address } = useWalletInfoStore();

  return (
    <div>
      <div
        className={classNames(
          'flex justify-between items-center py-[12px] lg:px-[12px] lg:py-[9px] lg:h-[60px]',
        )}
      >
        <div className={classNames('flex items-center ', {})}>
          <NavLink to="/" className="shrink-0">
            <img src="/static/images/logo.svg" className="w-[36px] shrink-0" />
          </NavLink>
        </div>

        <div className={classNames('flex justify-end items-center gap-x-[6px]', {})}>
          <ConfigProvider
            theme={{
              components: {
                Button: {
                  controlHeight: 36,
                },
              },
            }}
          >
            <Button
              shape="circle"
              onClick={() => {
                goSearch();
              }}
            >
              <SearchOutlined />
            </Button>

            {address && <ChainSelector />}
          </ConfigProvider>

          <ConnectButton controlHeight={36}></ConnectButton>
        </div>
      </div>
    </div>
  );
}
