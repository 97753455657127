import { SaDrawer } from '../SaDrawer';
import { ConfigProvider, QRCode } from 'antd';
import { Button } from '../Button';
import { useAppMessage } from '@/hooks/useAppMessage';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useWalletInfoStore } from '@/stores/useWalletInfoStore';

export function ReceiveOnlyNative(props: {
  open: boolean;
  onSetRoute?: () => void;
  onCancel: () => void;
}) {
  const message = useAppMessage({ key: 'Recevie' });

  const { walletAddress, chainType } = useWalletInfoStore();

  return (
    <SaDrawer title="Receive" open={props.open} onClose={props.onCancel} height="auto">
      <div className="w-full">
        <div className="py-6 flex justify-center">
          <QRCode value={walletAddress || '-'} bgColor="#FFF" color="#000" />
        </div>
        <div className="text-center bg-[#1e1e1e] text-[12px] leading-3 text-primaryText py-3 rounded mb-2">
          <span>{chainType}</span>
        </div>
        <div className="bg-[#1e1e1e] py-3 px-4 rounded flex items-center justify-between">
          <div className="mr-2 break-all">{walletAddress}</div>
        </div>
        <ConfigProvider
          theme={{
            components: {
              Button: {
                controlHeight: 48,
              },
            },
          }}
        >
          <CopyToClipboard
            text={walletAddress}
            onCopy={() => {
              message.success('Copy success');
            }}
          >
            <Button className="mt-[30px]" block type="primary">
              Copy Address
            </Button>
          </CopyToClipboard>
        </ConfigProvider>
      </div>
    </SaDrawer>
  );
}
