import { chains } from '@/constants';
import { memo } from 'react';

export const MultiChainToken = memo(
  ({
    symbol,
    token,
    chain,
    logoSize = 36,
  }: {
    symbol?: string;
    token?: string;
    chain?: string;
    logoSize?: number;
  }) => {
    const chainLogo = chains[chain!]?.logo;

    return (
      <div>
        <div className="relative w-[37px] h-[37px]">
          <div className="rounded-[50%] overflow-hidden">
            {token ? (
              <img src={token} style={{ width: logoSize + 'px', height: logoSize + 'px' }} />
            ) : (
              <div
                className="rounded-full flex justify-center items-center text-[18px] bg-[#1b1b1b]"
                style={{ width: logoSize + 'px', height: logoSize + 'px' }}
              >
                {symbol?.[0]}
              </div>
            )}
          </div>
          <div className="absolute bottom-[0px] right-[0px] border-solid border-[1px] border-[#ffffff] bg-white rounded-[50%] overflow-hidden">
            <img src={chainLogo} className="w-[14px] h-[14px]" />
          </div>
        </div>
      </div>
    );
  },
);
