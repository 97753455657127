import { toNano } from '@ton/ton';
import { pTON } from '@ston-fi/sdk';
import { useStonFiRouter } from './useStonFiRouter';
import { getRandomBigInt } from '@/utils';
import { useRequest } from 'ahooks';
import { useSendTransaction } from '../useSendTransaction';
import { useWalletInfoStore } from '@/stores/useWalletInfoStore';

export function useSwapJettonToTon({
  offerJettonAddress,
  offerAmount,
  minAskAmount,
}: {
  offerJettonAddress?: string;
  offerAmount: string;
  minAskAmount: string;
}) {
  const { router } = useStonFiRouter();

  const { walletAddress: address, isTomoWallet } = useWalletInfoStore();

  const { sendTransaction } = useSendTransaction();

  const getSwapJettonToTonTxParams = async () => {
    const txParams = await router.getSwapJettonToTonTxParams({
      userWalletAddress: address!,
      offerJettonAddress: offerJettonAddress!,
      offerAmount: toNano(offerAmount),
      proxyTon: new pTON.v1(),
      minAskAmount,
      queryId: getRandomBigInt(),
    });

    return txParams;
  };

  const hooks = useRequest(
    async () => {
      const txParams = await getSwapJettonToTonTxParams();

      const res = await sendTransaction({
        sendTransactionRequest: {
          validUntil: Date.now() + 1000000,
          messages: [
            {
              address: txParams.to.toString(),
              amount: txParams.value.toString(),
              payload: isTomoWallet
                ? txParams.body?.toBoc().toString('hex')
                : txParams.body?.toBoc().toString('base64'),
            },
          ],
          from: address,
        },
      });

      return res;
    },
    { manual: true },
  );

  return {
    ...hooks,
  };
}
