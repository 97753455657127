import { toNano } from '@ton/ton';
import { pTON } from '@ston-fi/sdk';
import { useStonFiRouter } from './useStonFiRouter';
import { getRandomBigInt } from '@/utils';
import { useRequest } from 'ahooks';
import { G6Math } from '@/contract/utils/Math';
import { useSendTransaction } from '../useSendTransaction';
import { useWalletInfoStore } from '@/stores/useWalletInfoStore';

type SwapTonToJettonParams = {
  askJettonAddress?: string;
  offerAmount: string | bigint;
  slippage: string;
};

export function useSwapTonToJetton({
  askJettonAddress,
  offerAmount,
  slippage,
}: SwapTonToJettonParams) {
  const { router } = useStonFiRouter();

  const { walletAddress: address, isTomoWallet } = useWalletInfoStore();

  const { sendTransaction } = useSendTransaction();

  const getSwapTonToJettonTxParams = async (payload?: Partial<SwapTonToJettonParams>) => {
    const minAskAmount = new G6Math(offerAmount)
      .multiply(new G6Math(1).subtract(new G6Math(slippage)))
      .toString();

    const txParams = await router.getSwapTonToJettonTxParams({
      userWalletAddress: address!,
      proxyTon: new pTON.v1(),
      offerAmount: payload?.offerAmount || offerAmount,
      askJettonAddress: askJettonAddress!,
      minAskAmount: toNano(minAskAmount),
      queryId: getRandomBigInt(),
    });

    return txParams;
  };

  const hooks = useRequest(
    async (payload?: Partial<SwapTonToJettonParams>) => {
      const txParams = await getSwapTonToJettonTxParams(payload);

      const res = await sendTransaction({
        sendTransactionRequest: {
          validUntil: Date.now() + 1000000,
          messages: [
            {
              address: txParams.to.toString(),
              amount: txParams.value.toString(),
              payload: isTomoWallet
                ? txParams.body?.toBoc().toString('hex')
                : txParams.body?.toBoc().toString('base64'),
            },
          ],
          from: address,
        },
      });

      return res;
    },
    { manual: true },
  );

  return {
    ...hooks,
  };
}
