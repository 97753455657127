import { SaDrawer } from '@/components/SaDrawer';
import { Avatar, List } from 'antd';
import { useFetchFollowing } from '../../hooks/useFetchFellowing';
import { Button } from '@/components/Button';
import classNames from 'classnames';
import { useState } from 'react';
import { useUnFollowUser } from '../../hooks/useUnFollowUser';
import { useAppRouter } from '@/hooks/router/useAppRouter';
import { useAppMessage } from '@/hooks/useAppMessage';
import { useProfileStore } from '@/context/ProfileContext';

export function FollowingModel({
  open,
  onClose,
  onSave,
  showDisconnect = true,
}: {
  open: boolean;
  onClose: () => void;
  onSave?: () => void;
  showDisconnect?: boolean;
}) {
  const { userInfo, isSelfUserInfo } = useProfileStore();
  const { data, loading, refresh: refreshFollowing } = useFetchFollowing(userInfo?.id);
  const message = useAppMessage();

  const { goCreatorDetailWithUserId } = useAppRouter();
  const [followAddress, setFollowAddress] = useState('');
  const { run: unFollowUser, loading: unFollowUserLoading } = useUnFollowUser({
    onSuccess() {
      message.success('UnFollow success');
      refreshFollowing();
    },
  });

  const toggleFollow = async (userId: string) => {
    setFollowAddress(userId);
    unFollowUser(userId);
  };

  return (
    <SaDrawer
      title={`Following (${(data?.following || []).length})`}
      height="calc(100vh - 100px)"
      open={open}
      onClose={onClose}
    >
      <List
        itemLayout="horizontal"
        loading={loading}
        dataSource={data?.following || []}
        renderItem={(item) => (
          <List.Item
            className="h-full bg-secondaryBackground !p-3 mb-2 rounded-lg"
            style={{ borderBlockEnd: '0' }}
            onClick={() => {
              goCreatorDetailWithUserId(item.user_id);
              onClose();
            }}
          >
            <div className="flex items-center gap-3">
              <Avatar size={56}>
                {item.username[0] || ((item.firstName ?? '') + ' ' + (item.lastName ?? ''))[0]}
              </Avatar>
              <div className="flex flex-col">
                <div className="text-[16px] font-medium text-white leading-6">
                  {item.username || (item.firstName ?? '') + ' ' + (item.lastName ?? '')}
                </div>
                <div className="font-normal text-[#949BA5]">
                  <span className="mr-1 text-primaryText">{item.follows_count}</span>Followers
                </div>
              </div>
            </div>
            {isSelfUserInfo && (
              <Button
                className={classNames(
                  'text-[12px] leading-[12px] p-[10px] font-normal h-auto min-w-20 bg-[#282828] text-[#9B9B9B]',
                )}
                loading={followAddress === item.user_id ? unFollowUserLoading : false}
                onClick={(e) => {
                  e.stopPropagation();
                  toggleFollow(item.user_id);
                }}
              >
                <span>Following</span>
              </Button>
            )}
          </List.Item>
        )}
      />
    </SaDrawer>
  );
}
