import { List, Skeleton } from 'antd';
import { useMemo } from 'react';
import { Item } from '@/components/CoinCard';
import { useFilter } from '@/hooks/useFilter';
import { useFetchG6List } from '../hooks/useFetchG6List';
import { range, uniqBy } from 'lodash';

export function CoinList() {
  const { query } = useFilter();

  const { data, loading } = useFetchG6List({
    createdTimestampStart: Number(query.createdTimestampStart),
    search: query.search,
    filter: query.filter,
    sort: query.sort,
    size: +query.size,
  });

  const list = useMemo(() => {
    const val = uniqBy(
      [...(data?.data?.docs ? data?.data?.docs || [] : data?.data || [])],
      'poolAddress',
    );

    return val;
  }, [data]);

  return (
    <div>
      <List
        className="coins"
        loading={loading}
        dataSource={list}
        renderItem={(item) => {
          return (
            <List.Item className="h-full">
              {item && (
                <Skeleton
                  active={loading}
                  loading={loading}
                  avatar={{ shape: 'square' }}
                  paragraph={{ rows: 2 }}
                >
                  <Item item={item} />
                </Skeleton>
              )}
            </List.Item>
          );
        }}
      ></List>
    </div>
  );
}
