import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal } from 'antd';
import React from 'react';

const OpenInTelegram = ({ open }: { open: boolean }) => {
  return (
    <Modal zIndex={1001} centered open={open} footer={null} closable={false}>
      <div className="flex justify-center flex-col items-center gap-6 h-[200px]">
        <ExclamationCircleFilled className="text-[64px] text-[#F54976]" />
        <div className="text-[16px] leading-[24px] text-primaryText">
          Please open the page in telegram
        </div>
      </div>
    </Modal>
  );
};

export default OpenInTelegram;
