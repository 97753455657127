import request from '@/request';
import { FollowingResult, FollowingItem } from '@/types/Following';

// 关注用户
export function follow(userId: string) {
  return request.post<{ token: string }>(`/follows/follow`, { user_id: userId });
}

// 取消关注用户
export function unFollow(userId: string) {
  return request.post<{ token: string }>(`/follows/unfollow`, { user_id: userId });
}

// 获取该用户的关注列表
export async function fetchFollowing(userId: string) {
  const res = await request.get<FollowingResult>(`/follows/following/${userId}`);
  return res.data;
}

// 获取关注该用户的列表
export async function fetchFollowers(userId: string) {
  const res = await request.get<FollowingItem[]>(`/follows/followers/${userId}`);
  return res.data;
}
