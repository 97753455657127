export type Tasks = {
  userAddress: string;
  totalPoints: number;
  tasks: TaskInfo[];
};

export enum TaskCode {
  InviteNewUser = 'InviteNewUser',
  // SwapComplete = 'SwapComplete',
  FollowTwitter = 'FollowTwitter',
  FollowTelegram = 'FollowTelegram',
  CreateG6Token = 'CreateG6Token',
  BuyMeMeCoin = 'BuyMeMeCoin',
  SellMeMeCoin = 'SellMeMeCoin',
  JoinAnyTokenGroup = 'JoinAnyTokenGroup',
}

export type TaskInfo = {
  taskCode: string;
  taskName: TaskCode;
  description: string;
  points: number;
  frequency: string;
  isCompleted: boolean;
  lastCompletedAt: string;
  lastCompletionPoints: number;
  taskTotalPoints: number;
};
