import { Select, ConfigProvider } from 'antd';
import { SwapOutlined } from '@ant-design/icons';

import { CoinDetail } from '@/types/Coin';
import { useNativeToken } from '@/hooks/app/useNativeToken';

export function NativeSuffix() {
  const [nativeName] = useNativeToken();

  return (
    <div className="inline-flex justify-start items-center gap-x-[5px] relative">
      <span className="text-[#FFEC3E] text-[12px] font-semibold">{nativeName}</span>
    </div>
  );
}

export function SymbolSwitch({
  symbol,
  setSymbol,
  detail,
}: {
  symbol: string;
  setSymbol: (e: string) => void;
  detail?: CoinDetail;
}) {
  const [nativeName] = useNativeToken();

  return (
    <ConfigProvider
      wave={{ disabled: true }}
      theme={{
        components: {
          Select: {
            colorBgContainer: '#ffffff00',
            colorBorder: '#ffffff00',
            optionSelectedColor: '#ffffff00',
            colorText: '#000',
            optionFontSize: 15,
            fontSize: 15,
            colorPrimary: '#000',
          },
          Input: {
            colorPrimaryText: '#000',
            colorPrimary: '#000',
          },
        },
        token: {
          colorText: '#000',
          colorBgTextHover: '#000',
        },
      }}
    >
      {symbol === nativeName ? (
        <div
          className="ml-1 flex items-center"
          onClick={() => {
            setSymbol(detail ? detail.symbol : '');
          }}
        >
          <NativeSuffix />
          <SwapOutlined className="text-[#FFEC3E] font-bold ml-[2px]" />
        </div>
      ) : (
        <div
          className="ml-1 flex items-center"
          onClick={() => {
            setSymbol(nativeName);
          }}
        >
          <div className="text-[#FFEC3E] font-semibold">{detail?.symbol}</div>
          <SwapOutlined className="text-[#FFEC3E] font-bold ml-[2px]" />
        </div>
      )}
      {/* <Select
        value={symbol}
        defaultValue="TON"
        suffixIcon={<SwapOutlined className="text-[#FFEC3E] font-bold" />}
        onChange={(e) => {
          setSymbol(e);
        }}
        className="!mr-[-15px] !mt-0"
      >
        <Select.Option value="TON">
          <TonSuffix />
        </Select.Option>

        <Select.Option value={detail?.symbol} class="m-auto">
          <div className="inline-flex justify-start items-center gap-x-[6px] overflow-hidden relative bottom-[-1px]">
            <span className="text-[#FFEC3E] text-[15px]  font-semibold max-w-[140px] text-ellipsis overflow-hidden">
              {detail?.symbol}
            </span>
          </div>
        </Select.Option>
      </Select> */}
    </ConfigProvider>
  );
}
